<template>
  <div>
    <div id="jiDi_Map" class="map" />
  </div>
</template>
<script>
import AMap from 'AMap'
export default {
  props: {
    modalData: {
      type: Object,
      default: function() {
        return {
          matrixPosition: ''
        }
      }
    }
  },
  data() {
    return {
      map: undefined,
      marker: undefined
    }
  },
  mounted() {
    this.initMap()
  },
  methods: {
    initMap() {
      const self = this
      const map = new AMap.Map('jiDi_Map', {
        zoom: 15,
        center: [116.96429, 33.647413]
      })
      if (this.modalData.matrixPosition) {
        const location = JSON.parse(this.modalData.matrixPosition)
        const marker = new AMap.Marker({
          position: [location.lng, location.lat]
        })
        marker.setMap(map)
        map.panTo([location.lng, location.lat])
      }
      self.map = map
    }
  }
}
</script>
<style lang="less" scoped>
.map {
  height: 500px;
}
</style>
