import Login from '@/pages/Login';
import Regist from '@/pages/Regist';
import NotFound from '@/pages/NotFound';
 import Agreements from '@/pages/Agreement'
// import Layout from '@/layouts/BasicLayout/index.vue'

export default [
    {
        path: '/login',
        name: 'login',
        meta: {
                title: '登录'
        },
        component: Login
    },
    {
        path: '/regist',
        name: 'regist',
        meta: {
           title: '注册'
        },
        component: Regist
    },
    {
        path:'/greements',
        component:Agreements 
    },
    {
        path: '*',
        name: 'notfound',
        component: NotFound,
    }
]