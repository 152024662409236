import { render, staticRenderFns } from "./BjUpload.vue?vue&type=template&id=636f0fdf&scoped=true&"
import script from "./BjUpload.vue?vue&type=script&lang=js&"
export * from "./BjUpload.vue?vue&type=script&lang=js&"
import style0 from "./BjUpload.vue?vue&type=style&index=0&id=636f0fdf&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "636f0fdf",
  null
  
)

export default component.exports