<template>
  <div class="elegant">
      <div class="elegant-title">
          <div class="point1"></div>
          <div class="point2"></div>
          <div class="point3"></div>
          企业风采
          <div class="point3"></div>
          <div class="point2"></div>
          <div class="point1"></div>
      </div>
      <div id="scroll-container" class="elegant-scroll">
          <ul @click="handleClick">
            <template v-for="item in  data">
                <li :data-url="item.url" :key="item.id">
                    <img :data-url="item.url" :src="item.picture" alt="">
                    <div class="elegantMsg">
                        <span :data-url="item.url" class="msg" v-html="item.content"></span>
                        <div class="company">
                            <div class="locImg"/>
                            <span :data-url="item.url" style="margin-top: 14px;">{{ item.name }}</span>
                        </div>
                    </div>
                </li>
            </template>
          </ul>
      </div>
  </div>
</template>

<script>
var timer = null
const icon = require('@/assets/img/location.png');
const iconWhite = require('@/assets/img/location-white.png');

export default {
name: "EnterpriseElegant",
components: {
},
data () {
return {
    img: icon
}
},
props: {
    data: {
        type: Array,
        default: () => {}
    }
},
watch: {

},
methods: {
    openUrl(url) {
        window.open(url, '_blank')
    },
    handleClick(e) {
        // 注意一定要过滤掉ul，不然会出问题
        if (e.target.nodeName.toLowerCase() !== 'ul') {
            const url = e.target.dataset.url
            // 获得引索后，只需要修改data数据就能改变UI了
            url &&  this.openUrl(url)
        }
    },
    autoScroll() {
       //主方法
        function move(){
            if (oUl.offsetWidth < -oUl.offsetLeft + oDiv.offsetWidth) {
                oUl.style.left = '0'
            }
            oUl.style.left = oUl.offsetLeft-2+'px';//进行左横向滚动
        }
        if(this.data.length>3){

        var oDiv = document.getElementById('scroll-container');
        var oUl = oDiv.getElementsByTagName('ul')[0];
        var Li = oUl.getElementsByTagName('li');//获取ul下的所有li
        oUl.innerHTML = oUl.innerHTML + oUl.innerHTML;//li下的内容进行想加
        oUl.style.width = Li[0].offsetWidth * Li.length + 'px';//ul的宽度等于每个li的宽度乘以所有li的长度
       
          //调用方法
          timer = setInterval(move,30)
          //鼠标指向的时候 暂停
          oDiv.onmouseover = function(){
              clearInterval(timer);
              // this.img = iconWhite
          }
          //鼠标离开之后 继续滚动
          oDiv.onmouseout = function(){
              timer = setInterval(move,30)
              // this.img = icon
          }
        }
        

        
    }
},
computed: {

},
created () {

},
mounted () {
    setTimeout(() => {
        this.autoScroll()
    }, 1000)
},
beforeDestroy() {
    clearInterval(timer)
},
destroyed () {

},
}
</script>

<style scoped lang="less">
.elegant {
    margin-top: 21px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
    border-radius: 1px;
    .elegant-title {
        font-size: 24px;
        font-weight: 500;
        color: #000000;
        padding: 14px 0;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        div {
            display: inline-block;
            border-radius: 50%;
            line-height: 30px;
            margin: 0 15px;
        }
        .point1 {
            width: 4px;
            height: 4px;
            background: @primary-color;
        }
        .point2 {
            width: 6px;
            height: 6px;
            background: @primary-color;
        }
        .point3 {
            width: 8px;
            height: 8px;
            background: @primary-color;
        }
    }
    .elegant-scroll {
        height: 360px; 
        margin: 0 auto; 
        position: relative;
        overflow: hidden;
        ul {
            position:absolute;
            left: 0;
            top: 0;
            height: 100%;
            li {
                cursor: pointer;
                float: left;
                width: 387px; 
                height: 100%; 
                list-style: none;
                padding-left: 20px; 
                img {
                    width: 100%;
                    height: 220px;
                }
                .elegantMsg {
                    padding: 10px 14px;
                    .msg {
                        font-size: 14px; 
                        font-weight: 400;
                        color: #333333;
                        height: 42px;
                        line-height: 21px; 
                        
                        word-break: break-all;
                        text-overflow: ellipsis;
                        display: -webkit-box; 
                        -webkit-box-orient: vertical; 
                        -webkit-line-clamp: 2; 
                        overflow: hidden;
                    }
                    .company {
                        font-size: 16px; 
                        font-weight: 500;
                        color: @primary-color;
                        line-height: 21px; 
                        margin-top: 12px;
                        .locImg {
                            display: inline-block;
                            vertical-align: top;
                            width: 20px;
                            height: 20px;
                            background: url(../../assets/img/location.png) center no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                }
                
            }
        }
    }
}
</style>
