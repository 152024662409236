<template>
  <div class="my_reservation_container">
    <div class="box_shadow_wrap">
      <div class="global_title">我的预约</div>
      <!-- 查询表单 start -->
      <a-form class="query_form" layout="inline">
        <a-form-item label="服务主体">
          <a-input v-model="servicePrincipal" placeholder="请输入服务主体名称" allow-clear />
        </a-form-item>
        <a-form-item label="预约时间">
          <a-range-picker v-model="dateString" @change="onChange" />
        </a-form-item>
        <a-form-item class="query_btn">
          <a-button type="primary" class="btn_lightgreen" icon="search" @click="getTabelData">
            查询
          </a-button>
          <a-button type="primary" style="margin-left: 10px" icon="redo" @click="resetForm">
            重置
          </a-button>
        </a-form-item>
      </a-form>
      <!-- 查询表单 end -->
    </div>

     <!-- 表格区域 start -->
    <div class="box_shadow_wrap tabs_wrap">
      <a-tabs :tabBarGutter="0" :animated="false" v-model="tabIndex" @change="callback">
          <a-tab-pane
            :key="index"
            :tab="`${item}(${countList[index]})`"
            v-for="(item, index) in tabs"
          >
            <!-- 表格 start -->
            <a-table :columns="columns" :data-source="tabelData" row-key="id" :scroll="{ x: 1300, y: 260 }" :pagination="false" :loading="isLoading">
              <template slot="status" slot-scope="text, record">
                <span class="orange_color" v-if="record.status == 0">待评价</span>
                <span class="link_color" v-else>已评价</span>
              </template>
              <template slot="handle" slot-scope="text, record">
                <a-button class="table_btn link_color" type="link" @click="clickHandle(1, record)">查看</a-button>
                <template v-if="record.status == 0">
                  <a-divider type="vertical" />
                  <a-button class="table_btn link_color" type="link" @click="clickHandle(2, record)">评价</a-button>
                </template>
              </template>
            </a-table>
            <!-- 表格 end -->
          </a-tab-pane>
        </a-tabs>
      <!-- 分页 -->
      <div class="pagination_wrap">
        <a-space>
          <a-pagination 
          show-quick-jumper 
          show-size-changer
          :total="total" 
          :show-total="total => `共有 ${total} 条`"
          :page-size-options="pageSizeOptions"
          :page-size="size"
          v-model="page"
          @showSizeChange="onShowSizeChange"
          @change="changePage">
            <template slot="buildOptionText" slot-scope="props">
              <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
              <span v-if="props.value === '50'">全部</span>
            </template>
          </a-pagination>
          <a-button>确定</a-button>
        </a-space>
      </div>
    </div>
    <!-- 表格区域 end -->

    <!-- 查看评价对话框 starat -->
    <a-modal v-model="visible" width="auto" dialog-class="ignore" destroy-on-close :title="modalTitle" :after-close="afterClose">
      <template slot="footer">
        <a-button a-button key="back" @click="visible = false">
          取消
        </a-button>
        <a-button key="submit" class="btn_lightgreen" :loading="loading" @click="handleOk">
          确定
        </a-button>
      </template>
      <ul class="detail_list">
        <li>
          <div class="item_label">农机类型</div>
          <div class="item_value">
            <div class="tag_wrap">
              <template v-for="(item, index) in typeSecondaryDict">
                <span class="icon_wrap" :key="item" v-if="index == 3" @click="showMore(1)"><a-icon type="down" :rotate="visibleList.includes(1) ? 180 : 0" :style="{ color: '#888' }" /></span><br v-if="index !== 0 && index % 3 === 0" :key="item + index">
                <span class="tag_box" :key="index" v-if="index < 3 || visibleList.includes(1)">{{ item }}</span>
              </template>
            </div>
          </div>
        </li>
        <li>
          <div class="item_label">作业品种</div>
          <div class="item_value">
            <div class="tag_wrap">
              <template v-for="(item, index) in cropVarietiesDict">
                <span class="icon_wrap" :key="item" v-if="index == 3" @click="showMore(1)"><a-icon type="down" :rotate="visibleList.includes(1) ? 180 : 0" :style="{ color: '#888' }" /></span><br v-if="index !== 0 && index % 3 === 0" :key="item + index">
                <span class="tag_box" :key="index" v-if="index < 3 || visibleList.includes(1)">{{ item }}</span>
              </template>
            </div>
          </div>
        </li>
        <li>
          <div class="item_label">作业时期</div>
          <div class="item_value">
            <div class="tag_wrap">
              <template v-for="(item, index) in workTimeDict">
                <span class="icon_wrap" :key="item" v-if="index == 3" @click="showMore(1)"><a-icon type="down" :rotate="visibleList.includes(1) ? 180 : 0" :style="{ color: '#888' }" /></span><br v-if="index !== 0 && index % 3 === 0" :key="item + index">
                <span class="tag_box" :key="index" v-if="index < 3 || visibleList.includes(1)">{{ item }}</span>
              </template>
            </div>
          </div>
        </li>
        <li class="preview_wrap">
          <div class="item_label">农机图片</div>
          <div class="item_value">
            <bj-upload :default-list="machineImg" :disabled="true" action="test"></bj-upload>
          </div>
        </li>
        <li>
          <div class="item_label">服务主体</div>
          <div class="item_value">{{ bookingDetails.businessName }}</div>
        </li>
        <li>
          <div class="item_label">所在位置</div>
          <div class="item_value">
            <span class="job_location">{{ bookingDetails.jobLocation }}</span>
            <span class="distance"><a-icon type="environment" :style="{ fontSize: '16px' }" /> 距离您{{ bookingDetails.distance }}米</span>
          </div>
        </li>
        <li>
          <div class="item_label">价格</div>
          <div class="item_value">{{ bookingDetails.price }}元/亩</div>
        </li>
        <li>
          <div class="item_label">联系人</div>
          <div class="item_value">{{ bookingDetails.machineMasterName }}</div>
        </li>
        <li>
          <div class="item_label">联系方式</div>
          <div class="item_value">{{ bookingDetails.machineMasterPhone }}</div>
        </li>
        <template v-if="bookingDetails.status == 1">
          <li><a-divider /></li>
          <li>
            <div class="item_label">评价</div>
            <div class="item_value">{{bookingDetails.evaluate ? bookingDetails.evaluate : '暂无评价'}}</div>
          </li>
          <li>
            <div class="item_label">农机</div>
            <div class="item_value"><a-rate :default-value="bookingDetails.startLevel" disabled /></div>
          </li>
          <li>
            <div class="item_label">机手</div>
            <div class="item_value"><a-rate :default-value="bookingDetails.machinistStartLevel" disabled /></div>
          </li>
        </template>
      </ul>
      
      <template v-if="activetdIndex == 2">
        <a-divider />
        <a-form-model-item label="评价" prop="evaluate" style="margin-bottom:0">
            <a-textarea v-model="bookingDetails.evaluate" placeholder="请输入评价" :rows="4" allow-clear />
        </a-form-model-item>
        <a-form-model ref="ruleForm" :model="bookingDetails" :rules="rules">
          <a-form-model-item label="农机" prop="startLevel"  style="margin-bottom:0">
            <a-rate v-model="bookingDetails.startLevel" />
          </a-form-model-item>
          <a-form-model-item label="机手" prop="machinistStartLevel">
            <a-rate v-model="bookingDetails.machinistStartLevel" />
          </a-form-model-item>
        </a-form-model>
      </template>
      
    </a-modal>
    <!-- 查看评价对话框 end -->

  </div>
</template>

<script>
import apiConfig from '@/config/apiConfig'
const { key } = apiConfig[process.env.VUE_APP_ENV ? process.env.VUE_APP_ENV : 'dev']
const columns = [
  {
    title: '农机类型',
    dataIndex: 'typeSecondaryDict',
    key: 'typeSecondaryDict',
    ellipsis: true
  },
  {
    title: '作物品种',
    dataIndex: 'cropVarietiesDict',
    key: 'cropVarietiesDict'
  },
  {
    title: '作业时期',
    dataIndex: 'workTimeDict',
    key: 'workTimeDict',
    ellipsis: true
  },
  {
    title: '服务主体',
    key: 'businessName',
    dataIndex: 'businessName',
    ellipsis: true
  },
  {
    title: '所在位置',
    key: 'jobLocation',
    dataIndex: 'jobLocation',
    ellipsis: true
  },
  {
    title: '距离（米）',
    key: 'distance',
    dataIndex: 'distance',
  },
  {
    title: '预约时间',
    key: 'createdTime',
    dataIndex: 'createdTime',
    width: 180
  },
  {
    title: '价格',
    key: 'price',
    dataIndex: 'price',
  },
  {
    title: '状态',
    key: 'status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    key: 'handle',
    dataIndex: 'handle',
    scopedSlots: { customRender: 'handle' },
    fixed: 'right'
  }
];

export default {
  name: 'MyReservation',
  data() {
    const validateStartLevel = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择农机评价'));
      } else {
        callback();
      }
    }
    const validateMachinistStartLevel = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择机手评价'));
      } else {
        callback();
      }
    }
    
    const validateEvaluate = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入评价内容'));
      } else {
        callback();
      }
    }
    return {
      servicePrincipal: '', // 服务主体名称
      appointmentTime: '',
      dateString: [], // 日期组件绑定的时间-用于重置组件显示的时间
      tabs: ['全部预约', '已评价', '待评价'],
      countList: [0, 0, 0],
      tabIndex: 0,
      activetdIndex: 1,
      page: 1,
      size: 5,
      total: 0,
      pageSizeOptions: ['5', '20', '30', '40', '50'],
      columns,
      loading: false,
      isLoading: false,
      tabelData: [],
      bookingDetails: {},
      visible: false,
      visibleList: [],
      rules: {
        startLevel: [{ validator: validateStartLevel, trigger: 'change' }],
        machinistStartLevel: [{ validator: validateMachinistStartLevel, trigger: 'change' }],
        evaluate: [{ validator: validateEvaluate, trigger: 'change' }]
      },
      ipAddress: {} // IP 所在坐标
    }
  },
  computed: {
    modalTitle() {
      return this.bookingDetails?.status == 0 ? '评价' : '详情'
    },
    typeSecondaryDict() {
      return this.bookingDetails?.typeSecondaryDict?.split?.(',') || []
    },
    cropVarietiesDict() {
      return this.bookingDetails?.cropVarietiesDict?.split?.(',') || []
    },
    workTimeDict() {
      return this.bookingDetails?.workTimeDict?.split?.(',') || []
    },
    machineImg() {
      if (this.bookingDetails.machineImg) {
        const arr = this.bookingDetails.machineImg.split(',') || []
        return arr.map((item, index) => ({uid: `-${index + 1}`, name: 'image.png', status: 'done', url: item}))
      } else {
        return []
      }
    }
  },
  async created() {
    await this.getLocationByIp()
    this.getMyAppointmentCount()
    this.getMyAppointmentList()
  },
  methods: {
    async getLocationByIp() {
      const { message, result, status } = await this.$jsonp('https://apis.map.qq.com/ws/location/v1/ip', {
        key,
        output: 'jsonp'
      })
      if (status != 0) return this.$message.error(message)
      this.ipAddress = result
    },
    // 查询表格数据
    getTabelData() {
      this.getMyAppointmentCount()
      this.getMyAppointmentList()
    },
    // 重置
    resetForm() {
      this.servicePrincipal = ''
      this.appointmentTime = ''
      this.dateString = []
      this.getTabelData()
    },
    // 选择日期
    onChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        // const dateStr = dateString.map(item => `${item} 00:00:00`)
        this.appointmentTime =`${dateString[0]} 00:00:00~${dateString[1]} 23:59:59`
      } else {
        this.appointmentTime = ''
      }
    },
    // 切换tab
    callback() {
      this.page = 1
      this.size = 5
      this.getMyAppointmentCount()
      this.getMyAppointmentList()
    },
    // 分页
    changePage(page, pageSize) {
      this.page = page
      this.size = pageSize
      this.getMyAppointmentList()
    },
    // 每页数量
    onShowSizeChange(current, pageSize) {
      this.page = current
      this.size = pageSize
      this.getMyAppointmentList()
    },
    // 操作
    clickHandle(res, item = {}) {
      this.activetdIndex = res
      const obj = JSON.parse(JSON.stringify(item))
      this.bookingDetails = obj
      this.visible = true
      // this.$nextTick(() => {
      //   const els = document.querySelectorAll('.tag_wrap')
      //   const elw = [...els].map(el => el.offsetWidth - 375)
      //   const maxW = Math.max(...elw)
      //   this.modelWidth = maxW > 0 ? maxW + 520 : 520
      // })
    },
    // 点击模态框确认按钮
    handleOk() {
      if (this.bookingDetails.status != 0 || this.activetdIndex == 1) return this.visible = false

      this.$refs.ruleForm.validate(valid => {
        if (!valid) return false
        this.appointmentEvaluate()
      });
    },
    // 弹框完全关闭后
    afterClose() {
      this.visibleList = []
    },
    // 获取数量
    async getMyAppointmentCount() {
      const { data: { code, msg, body } } = await this.$axios.getMyAppointmentCount({
        body:{
          pageNum: this.page,
          pageSize: this.size,
          orders: "",   
          params: {
            businessName: this.servicePrincipal,
            createdTime: this.appointmentTime // 需求订单详情
          }
        }
      })
      if (code != 0) return this.$message.error(msg)
      this.countList = [
        body.total || 0,
        body.evaluated || 0,
        body.notEvaluate || 0
      ]
    },
    // 获取表格数据
    async getMyAppointmentList() {
      let status = ''
      if (this.tabIndex == 0) status = ''
      else if (this.tabIndex == 1) status = '1'
      else if (this.tabIndex == 2) status = '0'
      try {
        this.isLoading = true
        const { data: { code, msg, body } } = await this.$axios.getMyAppointment({
          body:{
            pageNum: this.page,
            pageSize: this.size,
            orders: "",   
            params: {
              businessName:this.servicePrincipal ? '*' + this.servicePrincipal + '*' : '',
              createdTime: this.appointmentTime, // 需求订单详情
              status: status // 0：执行中 1：待评价 2：已完成 :3:已取消
            }
          }
        })
        this.isLoading = false
        if (code != 0) return this.$message.error(msg)
        this.tabelData = body.dataList || []
        this.total = Number(body.totalCount)
        this.tabelData.length > 0 && this.distanceCalculation()
      } catch (error) {
        this.isLoading = false
      }
    },
    // 距离计算
    async distanceCalculation() {
      const url = 'https://apis.map.qq.com/ws/distance/v1/matrix?parameters'
      const toArr = this.tabelData.map(item => `${item.jobSiteLatitude},${item.jobSiteLongitude}`)
      const toArrs = []
      toArr.map(s=>{
        if(s.split(',')[0]!=='undefined') {
          toArrs.push(s)
        }
      })
      const { message, result, status } = await this.$jsonp(url, {
        mode: 'driving',
        from: `${this.ipAddress.location.lat},${this.ipAddress.location.lng}`,
        to: toArrs.join(';'),
        key,
        output: 'jsonp'
      })
      if (status != 0) return this.$message.error(message)
      result?.rows?.[0]?.elements.forEach((element, index) => {
        this.$set(this.tabelData[index], 'distance', element.distance)
      });
    },
    // 评价
    async appointmentEvaluate() {
      try {
        this.loading = true;
        const { data: { code, msg } } = await this.$axios.appointmentEvaluate({
          body:{
            id: this.bookingDetails.id, // 预约列表唯一ID
            evaluate: this.bookingDetails.evaluate, // 评价
            startLevel: this.bookingDetails.startLevel, // 星级评价（1-5星）农机
            machinistStartLevel: this.bookingDetails.machinistStartLevel //新增机手
          }
        })
        this.loading = false;
        this.visible = false;
        if (code != 0) return this.$message.error(msg)
        this.getTabelData()
        this.$message.success('评价成功')
      } catch (error) {
        this.loading = false;
        this.visible = false;
      }
    },
    // 显示更多
    showMore(i) {
      if (this.visibleList.includes(i)) {
        this.visibleList = this.visibleList.filter(item => item !== i)
      } else {
        this.visibleList.push(i)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../styles/pages/personalcenter.less';
.my_reservation_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 2px;
  .query_form {
    padding-right: 200px;
  }
  .tabs_wrap {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 16px;
  }
}
.pagination_wrap {
  display: flex;
  justify-content: flex-end;
}
.table_btn {
  padding: 0;
}
.link_color {
  color: @link-color;
}
.orange_color {
  color: #FF9900;
}
.detail_list {
  margin: 0;
  padding: 0;
  li {
    display: flex;
    align-items: baseline;
    list-style: none;
    &:nth-child(n + 2) {
      margin-top: 15px;
    }
    &:nth-child(-n + 3) {
      .item_value {
        width: auto;
      }
    }
    .ant-divider-horizontal {
      margin: 5px 0;
    }
  }
  .preview_wrap {
    align-items: flex-start;
  }
  .item_label {
    width: 100px;
    text-align: right;
    color: rgba(0, 0, 0, 0.85);
    &:after {
      content: ':';
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }
  }
  .item_value {
    width: 375px;
  }
}
.tag_wrap {
  display: inline-table;
  align-items: baseline;
  white-space: nowrap;
}
.tag_box {
  display: inline-block;
  height: 26px;
  line-height: 24px;
  padding: 0 14px;
  margin-right: 11px;
  border-radius: 13px;
  border: 1px solid #20BF8E;
  color: #333;
  background-color: rgba(32, 191, 142, 0.08);
  &:nth-child(n + 4) {
    margin-top: 16px;
  }
}
.icon_wrap {
  width: 20px;
  height: 20px;
  background: #F7F6F5;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.job_location {
  margin-right: 10px;
}
.distance {
  font-size: 12px;
  white-space: nowrap;
  color: #ccc;
}
.ant-rate {
  line-height: normal;
  font-size: 20px;
}
/deep/ .ant-modal-body {
  padding-top: 6px;
  padding-bottom: 0;
  .ant-divider-horizontal {
    margin-top: 20px;
  }
  .ant-form-item {
    display: flex;
    .ant-form-item-label {
      width: 100px;
    }
    .ant-form-item-control-wrapper {
      width: 375px;
    }
  }
}
/deep/ .ant-modal-wrap {
  display: flex;
  justify-content: center;
}
/deep/ .ignore {
  min-width: 520px;
}
</style>