<template>
  <div class="news-list">
    <a-spin :spinning="loading" style="position: relative; top: 50%; transform: translateY(-50%);">
    </a-spin>
    <template v-if="data.length > 0">
      <template v-for="(record, index) in data">
        <div :key="index" class="news-row" :style="{ borderLeft: data.length > 1 && index === data.length - 1 ? 'none' : '1px solid #4DAB6E' }" @click="view(record.id)">
          <div class="news-row-left">
            <div class="news-title">{{record.title}}</div>
            <div class="news-time">{{record.createdTime ? record.createdTime.slice(0, 10) : ''}}</div>
          </div>
          <div class="news-row-right">
            <a-icon type="eye" />
            <span style="margin-left:5px;">阅读次数{{ record.visitStatistic || 0 }}</span>
          </div>
        </div>
      </template>
      <a-pagination 
        class="news-pager"
        size="small" 
        :total="pagination.total" 
        :page-size="pagination.pageSize" 
        :page-size-options="pagination.pageSizeOptions" 
        show-size-changer
        show-quick-jumper 
        @showSizeChange="onShowSizeChange"
        @change="onPageChange"
      />
    </template>
    <template v-if="data.length === 0 && !loading">
      <div class="emptyBox">
        <div class="emptyMsg1">啊哦，搜索为空！</div>
        <div class="emptyMsg2">您可以尝试搜索其他的类目哦！</div>
        <img :src="emptyImg"/>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
const columns = [
    {
        dataIndex: 'content',
        key: 'content',
        scopedSlots: { customRender: 'content' },
        width:'60%'
    },
    {
        dataIndex: 'createdTime',
        key: 'createdTime',
        scopedSlots: { customRender: 'time' },
        width:'30%'
    },
    {
        dataIndex: 'visitStatistic',
        key: 'visitStatistic',
        scopedSlots: { customRender: 'view' },
        width:'100%'
    },
    ]
export default {
name: "NewsList",
components: {
},
computed: {
    ...mapState(['searchData']),
},
created () {
    this.newsTypeId = this.$route.path.split('/')[2]
},
mounted () {
    if(this.searchData.type=== '新闻资讯') {
        this.searchTitle = this.searchData.title 
    }
    this.search()
},
destroyed () {

},
data () {
return {
    newsTypeId: '',
    loading: false,
    columns: Object.freeze(columns),
    data: [],
    pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
    },
    emptyImg: require('@/assets/img/sdEmpty.png'),
    searchTitle: ''
}
},
props: {

},
watch: {
    searchData(newVal) {
        if(newVal.type === '新闻资讯'){
            this.searchTitle = newVal.title
            this.search();
        }   
    }
},
methods: {
    search() {
        const params = {
            body: {
              pageNum: this.pagination.current,
              pageSize: this.pagination.pageSize,
              params: {
                title: this.searchTitle?`*${this.searchTitle}*`:'',
                publishStatus: 1,
                newsTypeId: this.newsTypeId
              }
            }
          }
        this.loading = true
        this.$axios.newsManageQueryByPage(params).then(res => {
            this.loading = false
            const { dataList, totalCount } = res.data.body
            this.data = []
            this.pagination.total = 0
            if (res.data.code === '0') {
                this.data = [...dataList]
                this.pagination.total = totalCount ? parseFloat(totalCount) : 0
            }
        })
    },
    view(id) {
			const routePath = this.$route.path || ''
			const pre = routePath.substring(0,routePath.length-4);
			console.log('pre', pre);
			this.$router.push(`${pre}detail/${id}`)
    },
    // page change
    onPageChange(current) {
      this.pagination.current = current
      this.search()
    },
    // 每页数量 change
    onShowSizeChange(current, pageSize) {
      this.pagination.current = 1
      this.pagination.pageSize = pageSize
      this.search()
    },
}
}
</script>

<style scoped lang="less">
.news-list {
  position: relative;
  text-align: center;
  height: 100%;
  .news-row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: #888888;
    line-height: 22px;
    padding: 0 0 32px 36px;
    border-left: 1px solid @primary-color;
    .news-row-left {
      text-align: left;
      width: 73%;
      .news-title {
        margin-top: -8px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        cursor: pointer;
      }
      .news-time {
        margin-top: 8px;
      }
    }

    .news-row-right {
      width: 12%;
    } 
    &::before {
      content: ' ';
      position: absolute;
      width: 8px;
      height: 8px;
      left: -4px;
      top: 0;
      background: #FFFFFF;
      border: 1px solid @primary-color;
      box-shadow: 0px 0px 2px 0px rgba(18, 126, 93, 0.3);
      border-radius: 4px;
    }
  }

  .news-row:last-child {
    border: none;
  }

  .news-row:hover { 
    transition: all 0.5s ease-in-out;
    .news-title { 
      color: @primary-color;
      margin-top: -8px;
    }
    &::before {
      content: ' ';
      position: absolute;
      width: 8px;
      height: 8px;
      left: -4px;
      top: 0;
      background: @primary-color;
      border: 1px solid #FFFFFF;
      box-shadow: 0px 0px 2px 0px rgba(18, 126, 93, 0.3);
      border-radius: 4px;
    }
  }

  .news-pager {
    margin-top: 96px;
    text-align: right;
    /deep/ .ant-pagination-prev {
      border: 1px solid #D9D9D9;
      margin-right: 8px;
    }
  
    /deep/ .ant-pagination-next {
      border: 1px solid #D9D9D9;
      margin-right: 8px;
    }

    /deep/ .ant-pagination-item-active {
      background: @primary-color!important;
      border-radius: 2px;
      font-size: 14px;
      color: #FFFFFF;
      margin-right: 8px;
      a {
       color: #FFFFFF!important;
      }
    }

    /deep/ .ant-pagination-item:not(.ant-pagination-item-active) {
      border: 1px solid #D9D9D9;
      margin-right: 8px;
    }

  }
  .emptyBox {
    text-align: center;
    margin-top: 75px;
    .emptyMsg1 {
      font-size: 12px;
      font-weight: 500;
      color: #333333;
      line-height: 18px;
    }
    .emptyMsg2 {
      font-size: 9px;
      font-weight: 400;
      color: #555555;
      line-height: 15px;
      margin-top: 6px;
    }
    img {
      width: 300px; 
      height: 300px;
    }
  }

  /deep/ .ant-table {
  .ant-table-body {
    .ant-table-tbody {

      > tr.ant-table-row-selected td {
        color: inherit;
        background: #F3FFF8;
      }
      > tr.ant-table-row-hover td {
        color: #20BF8E;
        background: #FAFAFA;
      }
    }
  }
}
}
</style>
