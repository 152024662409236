import VueRouter from 'vue-router';
import Cookies from 'js-cookie'

import common from './common' // 引入通用模块
import modules from './modules' // 引入通用模块
console.log('BASE_URL', process.env.BASE_URL);
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...common, ...modules]
})

router.beforeEach((to, from, next) => {
  //to and from are Route Object,next() must be called to resolve the hook}
  const accessToken = Cookies.get('accessToken')
  
  if (to.path == '/login' || to.path == '/regist') {
    next()
  } else if (!accessToken && to.path.indexOf('personalCenter') > -1) {
    next('/login')
  } else {
    next()
  }
})

export default router;
