<template>
  <div class="su-yuan">
    <a-form-model label-align="left">
      <div class="module">
        <SectionLine title="生产信息" />
        <div v-if="allProInfo.length" class="con b-g">
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-model-item label="作用名称："> {{ proInfo.name }} </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="作业日期："> {{ proInfo.workDate }} </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="负责人："> {{ proInfo.header }} </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-model-item label="投入品："> {{ proInfo.inputs }} </a-form-model-item>
            </a-col>
            <a-col :span="16">
              <a-form-model-item label="投入品量："> {{ proInfo.inputsCount }} </a-form-model-item>
            </a-col>
          </a-row>
          <div v-if="formData.batchManagementResponseBody && formData.batchManagementResponseBody.chainStatus && formData.batchManagementResponseBody.chainStatus == 2" class="shang-lian">已上链</div>
          <div class="qie-huan">
            <a-icon v-if="proCount > 0"  class="left" type="caret-left" @click="previous" />
            <a-icon v-if="proCount < allProInfo.length - 1" class="right" type="caret-right" @click="next" />
          </div>
        </div>
      </div>
      <div class="module">
        <SectionLine title="初加工信息" />
        <div v-if="formData.batchManagementResponseBody" class="con">
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-model-item label="原材料："> {{ formData.batchManagementResponseBody.feedstock }} </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="加工工艺："> <div v-html="formData.batchManagementResponseBody.processingTechnic" /> </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="module">
        <SectionLine title="加工信息" />
        <div v-if="formData.processingInfo" class="con">
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-model-item label="企业名称："> {{ formData.processingInfo.name }} </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="企业代码："> {{ formData.processingInfo.enterpriseCode }} </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="原材料："> {{ formData.processingInfo.feedstock }} </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="进厂日期："> {{ formData.processingInfo.intoTime ? formData.processingInfo.intoTime.split(' ')[0] : '' }} </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="出厂日期："> {{ formData.processingInfo.outTime ? formData.processingInfo.outTime.split(' ')[0] : '' }} </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </div>
      
      <div class="module">
        <SectionLine title="销售信息" />
        <div v-if="formData.salesInfoResponseBody" class="con">
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-model-item label="企业名称："> {{ formData.salesInfoResponseBody.entName }} </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="企业代码："> {{ formData.salesInfoResponseBody.entCode }} </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="入库日期："> {{ formData.salesInfoResponseBody.inboundDate }} </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="module">
        <SectionLine title="环境信息" />
        <div v-if="formData.batchManagementResponseBody.entDioxideExternalResponseBodies && formData.batchManagementResponseBody.entDioxideExternalResponseBodies.length" class="con huan-jing">
          <div class="item">
            <div class="item-left">
              <div class="name">二氧化碳</div>
              <img src="@/assets/img/co2.png">
            </div>
            <div class="line" />
            <div class="item-right">
              <div class="value">2500</div>
              <div class="unit">(mg/m3)</div>
            </div>
          </div>
          <div class="item">
            <div class="item-left">
              <div class="name">二氧化氮</div>
              <img src="@/assets/img/no2.png">
            </div>
            <div class="line" />
            <div class="item-right">
              <div class="value" style="color: #F09D00;">2500</div>
              <div class="unit">(mg/m3)</div>
            </div>
          </div>
          <div class="item">
            <div class="item-left">
              <div class="name">空气湿度</div>
              <img src="@/assets/img/shidu.png">
            </div>
            <div class="line" />
            <div class="item-right">
              <div class="value" style="color: #3CA3FF;">2500</div>
              <div class="unit">(mg/m3)</div>
            </div>
          </div>
        </div>
      </div>
      <div class="module">
        <SectionLine title="基地信息" />
        <div v-if="formData.batchManagementResponseBody && formData.batchManagementResponseBody.enterpriseMatrixResponseBody &&
        formData.batchManagementResponseBody.enterpriseMatrixResponseBody.matrixPosition" class="con">
           <div id="jiDiMap" class="map" />
        </div>
      </div>
      <div class="module">
        <SectionLine title="实时视频" />
        <div v-if="formData.merchant && formData.merchant.matrix" class="con">
          <div class="video" @click="showMerchantVideo(this.formData.merchant.matrix)">
            <img src="@/assets/img/companyVideoUrl.png">
          </div>
        </div>
      </div>
      <div class="module">
        <SectionLine title="检验报告" />
        <div v-if="formData.batchManagementResponseBody && formData.batchManagementResponseBody.checkInfoResponseBodies && formData.batchManagementResponseBody.checkInfoResponseBodies.length" class="con">
          <template v-for="checkInfo in formData.batchManagementResponseBody.checkInfoResponseBodies">
            <template v-for="(attachment, j) in JSON.parse(checkInfo.attachment)">
              <div :key="j">
                <template v-if="!attachment.url.includes('pdf')">
                  <img :src="attachment.url | ossImg(130, 180)" @click="handlePictureCardPreview(attachment.url)">
                </template>
                <template v-else>
                  <div @click="handlePictureCardPreview(attachment.url)">
                    <pdf :src="attachment.url" :page="1" />
                  </div>
                </template>
              </div>
            </template>
          </template>
        </div>
      </div>
    </a-form-model>
    <a-modal v-model="showModal" width="30%" :destroy-on-close="true" :footer="null">
      <img width="100%" :src="modalImageUrl">
    </a-modal>
    <a-modal
      v-model="showTraceModal"
      width="100%"
      :destroy-on-close="true"
      :footer="null"
    >
      <pdfPreview :modal-trace-data="modalTraceData" />
    </a-modal>
    <a-modal
      v-model="showVideoModal"
      width="50%"
      :destroy-on-close="true"
      :footer="null"
    >
      <matrixVideo :modal-trace-data="modalTraceData" />
    </a-modal>
  </div>
</template>
<script>
import pdf from 'vue-pdf'
import pdfPreview from './pdfPreview'
import matrixVideo from './matrixVideo'
import AMap from 'AMap'
export default {
  components: { pdf, pdfPreview, matrixVideo },
  props: {
    formData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data () {
    return {
      showModal: false,
      showPDFModal: false,
      showVideoModal: false,
      showTraceModal: false,
      modalTraceData: undefined,
      modalImageUrl: '',
      proCount: 0,
      proInfo: {},
      allProInfo: []
    }
  },
  watch: {
    formData() {
      this.formData.batchManagementResponseBody && this.formData.batchManagementResponseBody.workRecordResponseBodies && (this.allProInfo = this.formData.batchManagementResponseBody.workRecordResponseBodies)
      if(this.allProInfo.length) {
        this.proInfo = this.allProInfo[0]
      }
      if (this.formData.batchManagementResponseBody && this.formData.batchManagementResponseBody.processingTechnic) {
        this.formData.batchManagementResponseBody.processingTechnic = this.formData.batchManagementResponseBody.processingTechnic.replace(/<img/g, '<img style="max-width: 100%;"')
      }
      this.$nextTick(()=> {
        this.initMap()
      })
    }
  },
  mounted() {
    this.formData.batchManagementResponseBody && this.formData.batchManagementResponseBody.workRecordResponseBodies && (this.allProInfo = this.formData.batchManagementResponseBody.workRecordResponseBodies)
    if(this.allProInfo.length) {
      this.proInfo = this.allProInfo[0]
    }
    if(this.formData.batchManagementResponseBody && this.formData.batchManagementResponseBody.enterpriseMatrixResponseBody &&
        this.formData.batchManagementResponseBody.enterpriseMatrixResponseBody.matrixPosition) {
      this.$nextTick(()=> {
        this.initMap()
      })
    }
    if (this.formData.batchManagementResponseBody && this.formData.batchManagementResponseBody.processingTechnic) {
      this.formData.batchManagementResponseBody.processingTechnic = this.formData.batchManagementResponseBody.processingTechnic.replace(/<img/g, '<img style="max-width: 100%;"')
    }
  },
  methods: {
    previous() {
      if (this.proCount > 0) {
        this.proCount--
        this.proInfo = this.allProInfo[this.proCount]
      }
    },
    next() {
      if (this.proCount < this.allProInfo.length - 1) {
        this.proCount++
        this.proInfo = this.allProInfo[this.proCount]
      }
    },
    handlePictureCardPreview(imgUrl) {
      const isPdf = imgUrl.includes('.pdf')
      if (isPdf) {
        this.componentName = 'pdfPreview'
        this.modalTraceData = {
          imgUrl: imgUrl
        }
        this.showTraceModal = true
      } else {
        this.showModal = true
        this.modalImageUrl = this.$utils.ossPath.ossImg(imgUrl, 520, 720)
      }
    },
    showMerchantVideo(url) {
      this.showVideoModal = true
      this.modalTraceData = url
    },
    initMap() {
      let location
      this.formData.batchManagementResponseBody && this.formData.batchManagementResponseBody.enterpriseMatrixResponseBody &&
        this.formData.batchManagementResponseBody.enterpriseMatrixResponseBody.matrixPosition &&
        (location = JSON.parse(this.formData.batchManagementResponseBody.enterpriseMatrixResponseBody.matrixPosition))
      const center = location ? [location.lng, location.lat] : [116.96419, 33.647726]
      const map = new AMap.Map('jiDiMap', {
        zoom: 15,
        center: center
      })
      if (location) {
        const marker = new AMap.Marker({
          position: center
        })
        marker.setMap(map)
      }
    },
  }
}
</script>
<style lang="less" scoped>
.su-yuan {
  /deep/ .ant-form-item {
      margin-bottom: 0;
      .ant-form-item-label {
        width: 70px;
        float: left;
      }
      .ant-form-item-control-wrapper {
        width: calc(100% - 70px);
        float: left;
      }
    }
  .module {
    position: relative;
    .con {
      padding: 5px 0;
      .map {
        height: 300px;
        width: 100%;
      }
      img {
        cursor: pointer;
        width: 130px;
        height: 180px;
      }
      img + img {
        margin-left: 16px;
      }
      .video {
        cursor: pointer;
        width: 273px;
        height: 177px;
        background-color: #e0f6ef;
        text-align: center;
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 150px;
        }
      }
    }
    .huan-jing {
      display: flex;
      padding: 20px 0;
      justify-content: space-between;
      .item {
        background: #FAFAFA;
        height: 104px;
        display: flex;
        align-items: center;
        width: 264px;
        justify-content: space-evenly;
        .item-left {
          .name {
            font-size: 12px;
            font-weight: 500;
            color: #575757;
          }
          img {
            margin-top: 10px;
            width: 56px;
            height: 36px;
          }
        }
        .line {
          width: 1px;
          height: 66px;
          background: #EBEBEB;
        }
        .item-right {
          margin-top: -10px;
          .value {
            font-size: 16px;
            font-weight: 500;
            color: #4DAB6E;
          }
          .unit {
            margin-top: 20px;
            font-size: 10px;
            font-weight: 500;
            color: #575757;
          }
        }
      }
    }
    .b-g {
      background: #FAFAFA;
      padding: 0 15px;
      margin: 8px 0;
      .shang-lian {
        width: 51px;
        height: 23px;
        background: rgba(83, 255, 0, 0.14);
        line-height: 23px;
        text-align: center;
        font-size: 8px;
        font-weight: 500;
        color: #2CBA00;
        position: absolute;
        right: 0;
        top: 43px;
      }
      .qie-huan {
        color: #2CBA00;
        cursor: pointer;
        font-size: 14px;
        .left {
          left: 0;
          top: 80px;
          position: absolute;
        }
        .right {
          right: 2px;
          top: 80px;
          position: absolute;
        }
      }
    }
  }
}
</style>