<template>
  <div class="machine_share_container">
    <!-- 地图容器 start -->
    <div id="map-container"></div>
    <!-- 地图容器 end -->

    <!-- 搜索栏 start -->
    <div class="filter_panel">
      <div class="area_selecttion">
        <area-selection
          :max-level="4"
          :default-value="['13', '232524', '232525']"
          placeholder="请选择省/市/区/街道"
          @change="getAddress"
        ></area-selection>
      </div>
      <div class="search_wrap">
        <type-selection
          ref="machine_type"
          class="machine_type"
          placeholder="农机类型"
          :machine-type="machineType"
          :machine-type-children="machineTypeChildren"
          :is-loading="isLoading"
          @change-type="getAgMachineTypesLevel2"
          @change="getTypes"
        ></type-selection>
        <type-selection
          ref="crop_variety"
          class="crop_variety"
          placeholder="作物品种"
          :max-level="1"
          :list="cropStrains"
          @change="getCropStrains"
        ></type-selection>
        <type-selection
          ref="crop_period"
          class="crop_period"
          placeholder="作物时期"
          :max-level="1"
          :list="cropPeriod"
          @change="getCropPeriod"
        ></type-selection>
        <div class="btns">
          <a-button size="large" @click="reset">重置</a-button>
          <a-button size="large" type="primary" :loading="btnLoading" @click="search"
            >搜索</a-button
          >
        </div>
      </div>
    </div>
    <!-- 搜索栏 end -->

    <!-- 表格start -->
    <a-table
      class="table_box"
      :columns="columns"
      :data-source="tableData"
      :row-key="record => record.id"
      :pagination="pagination"
      :loading="tableLoading"
    >
      <template slot="num" slot-scope="text, record, index">{{
        (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1
      }}</template>
      <template slot="handle" slot-scope="text, record"
        ><a-button size="small" type="primary" @click="reserve(record)">预约</a-button></template
      >
    </a-table>
    <!-- 表格end -->

    <!-- 预约弹框 start -->
    <a-modal title="预约服务" v-model="visible" destroy-on-close width="35%" @ok="handleOk">
      <!-- <img :src="qrcodeImg" style="width: 146px; height: 146px;"/>
        <div>扫一扫</div>
        <div>进入小程序操作</div> -->
      <a-form-model
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        v-bind="layout"
        :labelCol="{ span: 6, offset: 0 }"
        labelAlign="right"
      >
        <a-form-model-item has-feedback label="预约人电话" prop="phone">
          <a-input v-model="ruleForm.phone" placeholder="请填写预约人电话" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item has-feedback label="计划作业面积" prop="area">
          <a-input
            v-model="ruleForm.area"
            placeholder="请填写计划作业面积"
            autocomplete="off"
            suffix="亩"
          />
        </a-form-model-item>
        <a-form-model-item has-feedback label="计划作业地址" prop="address">
          <a-cascader
            v-model="ruleForm.address"
            size="large"
            :options="options"
            allow-clear
            expand-trigger="hover"
            placeholder="请选择地址"
            @change="selectAddress"
          />
        </a-form-model-item>

        <a-form-model-item has-feedback label="详细地址">
          <a-input v-model="makeAddress" :max-length="100" placeholder="请填写详细地址" />
        </a-form-model-item>
        <a-form-model-item has-feedback label="计划完成时间" prop="planTime">
          <a-date-picker @change="onChangeDatePicker" placeholder="请选择日期" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 预约弹框 end -->
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import TMap from 'TMap'
import gcoord from 'gcoord'
import { CarMark, TipMark } from '@/utils/map'
import { provinces } from '@/assets/js/province.js'
import apiConfig from '@/config/apiConfig'
const { key } = apiConfig[process.env.VUE_APP_ENV ? process.env.VUE_APP_ENV : 'dev']
let markList = [],
  tipMark
var map

const columns = [
  {
    title: '序号',
    dataIndex: 'num',
    key: 'num',
    width: 60,
    align: 'center',
    scopedSlots: { customRender: 'num' }
  },
  {
    dataIndex: 'format_typeSecondary',
    key: 'format_typeSecondary',
    title: '农机类型',
    ellipsis: true,
    width: 120
  },
  {
    dataIndex: 'format_cropVarieties',
    key: 'format_cropVarieties',
    title: '作物品种',
    ellipsis: true,
    width: 120
  },
  {
    dataIndex: 'format_workTime',
    key: 'format_workTime',
    title: '作业时期',
    ellipsis: true,
    width: 120
  },
  {
    dataIndex: 'businessName',
    key: 'businessName',
    title: '服务主体',
    ellipsis: true,
    width: 120
  },
  {
    dataIndex: 'location',
    key: 'location',
    title: '服务位置',
    ellipsis: true,
    width: 120
  },
  {
    dataIndex: 'distance',
    key: 'distance',
    title: '距离（米）',
    ellipsis: true,
    width: 120
  },
  {
    dataIndex: 'format_workPrice',
    key: 'format_workPrice',
    title: '价格',
    ellipsis: true,
    width: 120
  },
  {
    title: '预约',
    ellipsis: true,
    fixed: 'right',
    scopedSlots: { customRender: 'handle' },
    width: 120
  }
]
export default {
  name: 'MachineShare',
  data() {
    return {
      ipAddress: {}, // IP 所在坐标
      form: {
        type: [],
        typeSecondary: [],
        cropVarieties: [],
        workTime: []
      },
      address: {
        code: '',
        town: ''
      },
      isLoading: false,
      btnLoading: false,
      tableLoading: true,
      machineType: [],
      machineType2: [],
      cropStrains: [],
      cropPeriod: [],
      machineTypeChildren: {},
      list: [],
      originData: {},
      tableData: [],
      columns,
      pagination: {
        current: 1,
        pageSize: 5,
        pageSizeOptions: ['5', '20', '30', '40', '50'],
        defaultPageSize: 5,
        total: 0,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: total => `共有${total}条`,
        onChange: this.changePage,
        onShowSizeChange: this.onShowSizeChange
      },
      visible: false,
      makeAddress: '',
      options: [],
      HomeworkAddress: {},
      adderssStr: '',
      reserveInformation: {},
      ruleForm: {
        planTime: '',
        address: [],
        phone: '',
        area: '',
        makeAddress: ''
      },
      rules: {
        area: [{ required: true, message: '请输入面积', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入电话', trigger: 'blur' }],
        address: [{ required: true, message: '请选择地址', trigger: 'change' }],
        planTime: [{ required: true, message: '请选择时间', trigger: 'change' }],
        makeAddress: [{ required: true, message: '请输入详细地址', trigger: 'blur' }]
      },
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 15 }
      }
    }
  },
  computed: {
    ...mapState(['userInfo']),
    ...mapGetters(['isLogin'])
    // tableData() {
    //   const { typeSecondary } = this.form
    //   const data = this.list.filter(item => {
    //     const filterType = typeSecondary.length ? item.typeSecondary.split(',').every(val => typeSecondary.includes(val)) : true
    //     return filterType
    //   })
    //   return data.slice((this.pagination.current - 1) * this.pagination.pageSize, this.pagination.current * this.pagination.pageSize)
    // }
  },
  watch: {
    visible(val) {
      if (!val) {
        try {
          this.HomeworkAddress = {}
          this.adderssStr = {}
          this.reserveInformation = {}
          this.makeAddress = ''
          this.$refs['ruleForm'].resetFields() // 重置编辑表单
          // addForm 为ref指向的表单
        } catch (error) {
          console.log(error)
        }
      }
    }
  },
  async created() {
    await this.getAgMachineTypes()
    this.queryTableList()
    this.getSuzhou()
  },
  mounted() {
    this.getLocationByIp().then(() => {
      this.initMap()
    })
  },
  destroyed() {
    map = null
  },
  methods: {
    getSuzhou() {
      let suzhouData = []
      provinces.forEach(p => {
        if (p.label === '安徽省') {
          let pro = JSON.parse(JSON.stringify(p))
          delete pro.children
          suzhouData.push({ ...pro })
          p.children.forEach(pp => {
            if (pp.label === '宿州市') {
              let city = JSON.parse(JSON.stringify(pp))
              suzhouData[0].children = [city]
              this.options = suzhouData
            }
          })
        }
      })
    },
    // ip定位
    async getLocationByIp() {
      const { message, result, status } = await this.$jsonp(
        'https://apis.map.qq.com/ws/location/v1/ip',
        {
          key,
          output: 'jsonp'
        }
      )
      console.log(message)
      // if (status != 0) return this.$message.error(message);
      if (status != 0) return
      this.ipAddress = result
    },
    // 初始化地图
    initMap() {
      //定义地图中心点坐标
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      map = new TMap.Map(document.getElementById('map-container'), {
        center: new TMap.LatLng(this.ipAddress.location.lat, this.ipAddress.location.lng), //设置地图中心点坐标
        zoom: 11, //设置地图缩放级别
        viewMode: '2D',
        baseMap: {
          type: 'vector' // 地图类型 vector、satellite
        }
      })
      // 移除地图控件
      map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM)
      map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.SCALE)
      map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION)
    },
    // 重置
    reset() {
      this.$refs.machine_type.resetHandle()
      this.$refs.crop_variety.resetHandle()
      this.$refs.crop_period.resetHandle()
      Object.keys(this.form).forEach(key => {
        this.form[key] = []
      })
      this.pagination.current = 1
      this.getTableData()
    },
    // 获取农机类型
    async getAgMachineTypes() {
      const {
        data: { code, msg, body }
      } = await this.$axios.getByTypes('NJLX,NJLX2,NCPPZ,NCPZYSQ')
      if (code !== '0') return this.$message.error(msg)
      this.machineType = body['NJLX'] || []
      this.machineType2 = body['NJLX2'] || []
      this.cropStrains = body['NCPPZ'] || []
      this.cropPeriod = body['NCPZYSQ'] || []

      this.$set(this.originData, 'machineType2', body['NJLX2'] || [])
      this.$set(this.originData, 'cropStrains', body['NCPPZ'] || [])
      this.$set(this.originData, 'cropPeriod', body['NCPZYSQ'] || [])

      this.machineType = this.machineType.filter(item => item.deleted == 0)
      this.machineType2 = this.machineType2.filter(item => item.deleted == 0)
      this.cropStrains = this.cropStrains.filter(item => item.deleted == 0)
      this.cropPeriod = this.cropPeriod.filter(item => item.deleted == 0)
    },
    // 获取农机类型2
    async getAgMachineTypesLevel2(pid) {
      this.isLoading = true
      const {
        data: { code, msg, body }
      } = await this.$axios.getByParentId(pid)
      this.isLoading = false
      if (code !== '0') return this.$message.error(msg)
      let obj = body || []
      obj = obj.filter(item => item.deleted == 0)
      this.$set(this.machineTypeChildren, pid, obj)
    },
    // 选择地址
    getAddress(e) {
      this.address.code = e.code
      if (e?.id?.length > 3) {
        this.address.town = e.name
      } else {
        this.address.town = ''
      }
      if (e.lat) {
        map.setCenter(new TMap.LatLng(e.lat, e.lng))
      } else {
        map.setCenter(new TMap.LatLng(this.ipAddress.location.lat, this.ipAddress.location.lng))
      }
    },
    // 选择农机类型
    getTypes(e) {
      console.log(e)
      const { children } = e
      // this.form.type = type
      this.form.typeSecondary = children
    },
    // 选择作物品种
    getCropStrains(e) {
      console.log(e)
      const { children } = e
      this.form.cropVarieties = children
    },
    // 选择作物时间
    getCropPeriod(e) {
      console.log(e)
      const { children } = e
      this.form.workTime = children
    },
    // 点击搜索
    search() {
      this.pagination.current = 1
      // this.btnLoading = true
      this.getTableData()
    },
    // 获取原始表格数据
    async queryTableList() {
      this.tableLoading = true
      const { type, typeSecondary, cropVarieties, workTime } = this.form
      try {
        const {
          data: { code, msg, body }
        } = await this.$axios.queryModelShareList({
          body: {
            params: {
              type: type.join(),
              typeSecondary: typeSecondary.join(),
              cropVarieties: cropVarieties.join(),
              workTime: workTime.join()
            }
          }
        })
        this.btnLoading = false
        if (code !== '0') return this.$message.error(msg)
        this.mapData(body)
      } catch (error) {
        this.btnLoading = false
      }
    },
    // 坐标转换
    transformLatLng(lat, lng) {
      return gcoord.transform([lat, lng], gcoord.WGS84, gcoord.GCJ02)
    },
    // 绘制农机覆盖物
    showMachines(list) {
      if (markList.length > 0) {
        markList.forEach(ele => {
          ele.setMap(null)
        })
      }
      map.setZoom(13)
      markList = []
      if (list.length > 0) {
        list.forEach((obj, index) => {
          if (obj.latitude && obj.longitude) {
            const [lat, lng] = this.transformLatLng(obj.latitude, obj.longitude)
            markList[index] = new CarMark({
              map,
              position: new TMap.LatLng(lat, lng),
              modalShow: false,
              data: obj
            })
          }
        })
        if (markList.length > 0) {
          markList.forEach((ele, index) => {
            ele.on('click', () => {
              if (tipMark) {
                tipMark.setMap(null)
              }
              map.setCenter(new TMap.LatLng(ele.position.lat, ele.position.lng))
              map.setZoom(15)
              if (ele instanceof CarMark) {
                tipMark = new TipMark({
                  map,
                  position: new TMap.LatLng(ele.position.lat, ele.position.lng),
                  modalShow: false,
                  data: ele.data
                })
                markList[index] = tipMark
              }
            })
          })
          tipMark.on('click', () => {
            setTimeout(() => {
              tipMark.modalShow = false
            }, 1000)
          })
        }
      }
    },
    // 数据处理
    mapData(list = []) {
      const toArr = []
      list = list.map(val => {
        val.latitude && toArr.push(`${val.latitude},${val.longitude}`)
        val.format_typeSecondary = this.mapType(val.typeSecondary, this.originData.machineType2)
        val.format_cropVarieties = this.mapType(val.cropVarieties, this.originData.cropStrains)
        val.format_workTime = this.mapType(val.workTime, this.originData.cropPeriod)
        val.format_workPrice = `${val.workPrice}${val.priceUnit ? `（${val.priceUnit}）` : ''}`
        return val
      })
      this.distanceCalculation(list, toArr)
      this.showMachines(list)
    },
    // 映射类型
    mapType(value, list = []) {
      if (!value) return ''
      return value
        .split(',')
        .map(key => {
          let str = ''
          list.some(item => {
            if (item.dictKey == key) {
              str = item.dictValue
              return true
            }
          })
          return str
        })
        .join()
    },
    // 距离计算
    async distanceCalculation(data = [], toArr = []) {
      const arr = []
      const max = Math.ceil(toArr.length / 3)
      for (let index = 0; index < max; index++) {
        arr.push(toArr.slice(index * 3, (index + 1) * 3))
      }
      const results = await Promise.all(
        arr.map(async (item, index) => {
          return new Promise((resolve, reject) => {
            setTimeout(async () => {
              const locationResults = await Promise.all(
                item.map(async val => await this.getLocationDescription(val))
              )
              const url = 'https://apis.map.qq.com/ws/distance/v1/matrix?parameters'
              const { message, result, status } = await this.$jsonp(url, {
                mode: 'driving',
                from: `${this.ipAddress.location.lat},${this.ipAddress.location.lng}`,
                to: item.join(';'),
                key,
                output: 'jsonp'
              })
              console.log(message, status)
              // if (status != 0) {
              //   console.log(message);
              // this.$message.error(message);
              //   reject({});
              //   return;
              // }
              resolve({
                distance: result?.rows?.[0]?.elements || '',
                location: locationResults
              })
            }, (index + 0.1) * 1000)
          })
        })
      )
      const distanceArr = []
      const locationArr = []
      console.log(results)
      results.forEach(item => {
        distanceArr.push(...item.distance)
        locationArr.push(...item.location)
      })
      this.list = data
        .map((item, index) => {
          const ADDRESS = locationArr[index] || {}
          item.distance = distanceArr[index] ? distanceArr[index].distance : ''
          item.location = ADDRESS?.formatted_addresses?.recommend || ADDRESS.address
          try {
            item.address_component = {
              city: ADDRESS.address_component.city,
              city_id: ADDRESS.ad_info.adcode,
              district: ADDRESS.address_component?.district,
              nation: ADDRESS.address_component.nation,
              province: ADDRESS.address_component.province,
              street: ADDRESS.address_component?.street,
              town: ADDRESS?.address_reference?.town?.title,
              town_id: ADDRESS?.address_reference?.town?.id
            }
          } catch (error) {
            item.address_component = {
              city: '',
              city_id: '',
              district: '',
              nation: '',
              province: '',
              street: '',
              town: '',
              town_id: ''
            }
          }
          return item
        })
        .sort((a, b) => a.distance - b.distance)
      this.tableLoading = false
      this.getTableData()
    },
    // 获取坐标位置描述
    async getLocationDescription(location) {
      const url = 'https://apis.map.qq.com/ws/geocoder/v1'
      //发起JSONP请求，获取坐标描述
      const { message, result, status } = await this.$jsonp(url, {
        location,
        key,
        output: 'jsonp'
      })
      console.log(message)
      // if (status != 0) return this.$message.error(message);
      if (status != 0) return ''
      return result
    },
    // 分页
    changePage(page) {
      this.pagination.current = page
      this.getTableData()
    },
    // 每页数量
    onShowSizeChange(current, pageSize) {
      this.pagination.current = current
      this.pagination.pageSize = pageSize
      this.getTableData()
    },
    // 获取表格数据-根据分页
    getTableData() {
      const { typeSecondary, cropVarieties, workTime } = this.form
      const { code, town } = this.address
      const addressCode = code.replace(/0+$/, '')
      const data = this.list.filter(item => {
        let filterAddress = true
        if (town) {
          filterAddress = item.address_component.town === town
        } else {
          if (item.address_component.city_id) {
            filterAddress = item.address_component.city_id.startsWith(addressCode)
          }
        }
        const filterType = typeSecondary.length
          ? item.typeSecondary.split(',').every(val => typeSecondary.includes(val))
          : true
        const filterVariety = cropVarieties.length
          ? item.cropVarieties.split(',').every(val => cropVarieties.includes(val))
          : true
        const filterTime = workTime.length
          ? item.workTime.split(',').every(val => workTime.includes(val))
          : true
        return filterType && filterVariety && filterTime && filterAddress
      })
      console.log(data)
      this.pagination.total = data.length
      this.tableData = data.slice(
        (this.pagination.current - 1) * this.pagination.pageSize,
        this.pagination.current * this.pagination.pageSize
      )
    },
    // 计划完成时间
    onChangeDatePicker(value, dateString) {
      this.ruleForm.planTime = dateString
    },
    // 选择计划作业地址
    selectAddress(value, selectedOptions) {
      const addressArr = selectedOptions?.map?.(item => item.label)
      const adderssStr = addressArr?.join?.('')
      this.adderssStr = adderssStr
      adderssStr && this.getLocationByAddress(adderssStr)
    },
    // 地址解析（地址转坐标）
    async getLocationByAddress(address) {
      const url = 'https://apis.map.qq.com/ws/geocoder/v1'
      //发起JSONP请求，获取坐标描述
      const { message, result, status } = await this.$jsonp(url, {
        address,
        key,
        output: 'jsonp'
      })
      console.log(message)
      // if (status != 0) return this.$message.error(message);
      if (status != 0) return
      this.HomeworkAddress = result
    },
    // 农机预约
    async machineryAppointment() {
      try {
        const {
          data: { code, msg }
        } = await this.$axios.machineryAppointment({
          body: {
            machineModel: this.reserveInformation.format_typeSecondary, // 农机类型
            machineNumber: this.reserveInformation.number, // 车牌号
            locationLongitude: this.reserveInformation.longitude, // 所在位置经度
            localLatitude: this.reserveInformation.latitude, // 所在位置纬度
            serviceOrganization: '', // 服务组织
            price: this.reserveInformation.workPrice, // 价格
            myPhone: this.ruleForm.phone, // 本人手机号
            jobArea: this.ruleForm.area, // 作业面积
            endDate: this.ruleForm.planTime, // 完成时间
            jobSiteLongitude: this.HomeworkAddress?.location?.lng, // 作业地址经度
            jobSiteLatitude: this.HomeworkAddress?.location?.lat, // 作业地址纬度
            adderssStr: this.adderssStr,
            jobLocation: this.makeAddress, // 作业地址
            modelId: this.reserveInformation.id, // 农机详情ID
            id: '', // 农机机主ID
            openId: '', // 当前用户openId
            reservedUserId: this.userInfo.id // 登录用户id
          }
        })

        if (code != 0) return this.$message.error(msg)
        this.$message.success('预约成功', 3)
        this.visible = false
      } catch (error) {
        console.log('err', error)
        this.visible = false
      }
    },
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.machineryAppointment()
        } else {
          return false
        }
      })
    },
    reserve(data) {
      console.log(data)
      if (this.isLogin) {
        this.reserveInformation = data
        this.visible = true
      } else {
        this.$confirm({
          title: '请先进行登录',
          onOk: () => this.$router.push('/login')
        })
      }
    }
  }
}
</script>
<style scoped lang="less">
.machine_share_container {
  position: relative;
  margin: 24px auto;
  height: 100%;
  width: 1190px;
  #map-container {
    width: 100%;
    height: 420px;
    /deep/ a > img {
      display: none;
    }
    /deep/ .logo-text {
      display: none;
    }
  }

  .filter_panel {
    position: absolute;
    left: 8px;
    top: 8px;
    display: flex;
    width: calc(100% - 16px);
    height: 60px;
    z-index: 1040;
    .area_selecttion {
      flex: none;
      width: 341px;
      height: 100%;
      padding: 10px 12px;
      background-color: #fff;
      box-shadow: 1px 1px 5px 5px rgba(210, 210, 210, 0.18);
      border-radius: 4px;
      /deep/.ant-cascader-picker-label {
        color: inherit;
      }
    }
    .search_wrap {
      flex: auto;
      display: flex;
      align-items: stretch;
      margin-left: 8px;
      height: 100%;
      padding: 0 12px;
      background-color: #fff;
      box-shadow: 1px 1px 5px 5px rgba(210, 210, 210, 0.18);
      border-radius: 4px;
      .machine_type {
        flex: auto;
      }
      .crop_variety {
        width: 126px;
        margin-left: 8px;
      }
      .crop_period {
        width: 134px;
        margin-left: 8px;
      }
      .btns {
        display: flex;
        align-items: center;
        margin-left: 16px;
        button {
          height: 40px;
          padding: 0 22px;
          &:nth-child(n + 2) {
            margin-left: 8px;
          }
        }
      }
    }
  }
  // .table_box {
  // margin-top: 24px;
  // margin-top: -12px;
  // }
}

/deep/ .ant-modal {
  .ant-modal-title {
    font-size: 14px;
    font-weight: 500;
  }
  .ant-modal-body {
    // padding: 72px 18px;
    padding: 16px 0 0 33px;
    background: #fff;
  }
  .ant-modal-header {
    background: #f5f5f5;
  }
  .ant-modal-close-x {
    width: 56px;
    padding-top: 7px;
  }
  .ant-modal-footer {
    padding: 16px 16px 30px;
  }
}

/deep/ .ant-table {
  .ant-table-thead > tr > th {
    padding: 16px;
    font-weight: 600;
  }

  .ant-table-tbody > tr > td {
    padding: 16px;
    color: #555;
  }
}
</style>
