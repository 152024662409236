<template>
  <div class="news">
    <div class="breadcrumb">
      <!-- <a-breadcrumb>
        <a-breadcrumb-item><a href="/news">新闻资讯</a></a-breadcrumb-item>
        <a-breadcrumb-item><a :href="$route.path">{{ newsType }}</a></a-breadcrumb-item>
      </a-breadcrumb> -->
      <a-breadcrumb>
        <a-breadcrumb-separator style="color: #333333">当前位置：</a-breadcrumb-separator>  
        <a-breadcrumb-item><a href="/news">新闻资讯</a></a-breadcrumb-item>
        <a-breadcrumb-item><span @click="typeClick" :style="{color: '#333333',cursor: 'pointer'}">{{ newsType }}</span></a-breadcrumb-item>
        <a-breadcrumb-item><span v-if="isDetail">文章详情</span></a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="news-center-container">
      <div class="news-left">
          <!-- <img :src="iconImg" style="display: block; width:26px;margin: 7px 16px;"/> -->
            <a-menu
                class="menuStyle"
                :selectedKeys="[onRoutes]"
                mode="inline"
                :style="{ backgroundColor: '#F5F5F5' }"
            >
                <template v-for="item in menus">
                    <template>
                        <a-menu-item :key="resolvePath(item.path)" @click="menuClick">
                            <span>{{ item.title }}</span>
                        </a-menu-item>
                    </template>
                </template>
            </a-menu>
      </div>
      <div class="news-right">
        <router-view :key="$route.path"/>
      </div>
      </div>
  </div>
</template>

<script>
export default {
name: "News",
components: {
},
data () {
return {
   iconImg: require('@/assets/img/bulb.png'),
   menus: [],
   menuOne:null,
   newsType:"",
   isDetail: false,
   onRoutes: []
}
},
props: {

},
watch: {
    $route(data) {
        this.isDetail = data.name==='NewsDetail'
    }
},
created () {
  this.newsSelectOptions()
},
mounted () {
  this.isDetail = this.$route.name === 'NewsDetail'
  if(this.$route.query && this.$route.query.type === 'detail'){
      this.onRoutes = `/news/${this.$route.query.typeId}/list`
  }else {
      this.onRoutes = this.$route.path
  }
  
},
destroyed () {

},
methods: {
    typeClick() {
      this.$router.push({
        path: this.onRoutes,
      });
    },
    menuClick({ item, key }) {
      console.log('menuClick', item, key)
      this.newsType = this.menus[item.index].title
      this.$router.push({
        path: key
      })
      this.onRoutes = key
    },
    resolvePath(path) {
        return `/news/${path}/list`
    },
    // 新闻分类下拉
    newsSelectOptions() {
      const param = {
        body: {}
      }
      this.$axios.newsSelectOptions(param).then(res => {
        console.log('res', res);
        this.menus = []
        if (res.data.code == '0') {
          res.data.body.forEach((ele, index) => {
            this.menus[index] = {
              title: ele.name,
              path: ele.id
            }
          })
          console.log('this.menus', this.menus);
          if (!this.$route.query.type && this.menus.length > 0) {
            this.$router.push(`/news/${this.menus[0].path}/list`)
            this.onRoutes = `/news/${this.menus[0].path}/list`
            this.newsType = this.menus[0].title
          }
          if(this.$route.query && this.$route.query.typeId) {
            const currentMenu = this.menus.filter(item => item.path === this.$route.query.typeId);
            console.log('currentMenu', currentMenu)
            this.newsType = currentMenu[0].title
          }
        }
      })
    }
}
}
</script>

<style scoped lang="less">
@import url('../../styles/pages/news.less');
</style>
