<template>
  <div class="goods">
    <a-form-model label-align="left">
      <div class="module">
        <SectionLine title="商品简介" />
        <div v-if="formData.id" class="con">
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-model-item label="商品名称："> {{ formData.goodsName }} </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="包装类型："> {{ formData.packageTypeName }} </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item class="l-w-100" label="计量单位/规格："> {{ formData.packageTypeName === '散装' ? formData.measuringUnitName : formData.specificationsNumber + formData.specificationsUnitName + 'x' + formData.specificationsCount }} </a-form-model-item>
            </a-col>
            <a-col v-if="formData.packageTypeName == '包装'" :span="8">
              <a-form-model-item label="包装单位："> {{ formData.packageUnitName }} </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="包装产地："> {{ formData.produceProvinces + formData.produceCity + formData.produceArea }} </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="商品品牌："> {{ formData.goodsBrand }} </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="商品等级："> {{ formData.goodsLevelName }} </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="商品价格："> {{ formData.goodsPrice && formData.goodsPrice.indexOf('元') > -1 ? formData.goodsPrice : formData.goodsPrice + '元' }} </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="保质期："> {{ (formData.expirationDate ? formData.expirationDate : '') + (formData.expirationDateUnitName ? formData.expirationDateUnitName : '') }} </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item class="l-w-85" label="最佳口感期："> {{ (formData.optimumDate ? formData.optimumDate : '') + (formData.optimumDateUnitName ? formData.optimumDateUnitName : '') }} </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="module">
        <SectionLine title="商品详情" />
        <div v-if="formData.introduction" class="con" v-html="formData.introduction" />
      </div>
      <div class="module">
        <SectionLine title="商品认证" />
        <div v-if="formData.certificates" class="con">
          <img v-for="(item, index) in formData.certificates" :key="index" :src="item.imgUrl | ossImg(130, 180)" @click="handlePictureCardPreview(item.imgUrl)">
        </div>
      </div>
      <div class="module">
        <SectionLine title="购买信息" />
        <div class="con">
          <a-row :gutter="24" v-for="(item, index) in formData.purchasing" :key="index">
            <a-col :span="24">
              <a-form-model-item label="购买渠道："> {{ item.channelName }} </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="店铺名称："> {{ item.shopName }} </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item class="link" label="店铺链接：">
                <a v-if="item.addressLink" :href="item.addressLink.indexOf('https://') > -1 ? item.addressLink: 'https://' + item.addressLink" target="_blank">{{ item.addressLink }}</a>
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-form-model>
    <a-modal v-model="showModal" width="30%" :destroy-on-close="true" :footer="null">
      <img width="100%" :src="modalImageUrl">
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    formData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data () {
    return {
      showModal: false,
      modalImageUrl: ''
    }
  },
  created() {
    this.formData.introduction = this.formData.introduction ? this.formData.introduction.replace(/<img/g, '<img style="max-width: 100%;"') : ''
  },
  methods: {
    handlePictureCardPreview(imgUrl) {
      this.showModal = true
      this.modalImageUrl = this.$utils.ossPath.ossImg(imgUrl, 520, 720)
    }
  }
}
</script>
<style lang="less" scoped>
.goods {
  /deep/ .ant-form-item {
      margin-bottom: 0;
      .ant-form-item-label {
        width: 70px;
        float: left;
      }
      .ant-form-item-control-wrapper {
        width: calc(100% - 70px);
        float: left;
      }
    }
  .module {
    .l-w-100 {
      /deep/ .ant-form-item-label {
        width: 100px;
      }
      /deep/ .ant-form-item-control-wrapper {
        width: calc(100% - 100px);
      }
    }
    .l-w-85 {
      /deep/ .ant-form-item-label {
        width: 80px;
      }
      /deep/ .ant-form-item-control-wrapper {
        width: calc(100% - 80px);
      }
    }
    .con {
      padding: 5px 0;
      img {
        cursor: pointer;
      }
      img + img {
        margin-left: 16px;
      }
      .link {
        color: #20BF8E;
      }
    }
  }
}
</style>