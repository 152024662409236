<template>
  <div class="ag-tech">
    <div class="ag-item" v-if="experts.pageResult.dataList.length>0">
		<div class="ag-title">农技专家</div>
		<div class="expertsBox">
			<template v-for="(item, idx) in experts.pageResult.dataList.slice(0, 3)">
				<div class="expert-item" :key="idx" @click="goMore('expertDetail', item.id)">
					<div class="avatar">
						<img :src="item.avatar"/>
						<div class="expert-detail">
							<div class="name" :title="`${item.name}-${item.jobPosition}`">
								{{ item.name }} -{{ item.jobPosition }}
							</div>
							<div class="major" :title="item.majorDomain">{{ item.majorDomain }}</div>
						</div>
					</div>
					<div class="count">
						<div><span class="num">{{ item.askCount }}</span><span>咨询量</span></div>
						<div><span class="num">{{ item.replyCount }}</span><span>回复量</span></div>
						<div><span class="num">{{ Math.round(item.replyRate*100) }}%</span><span>回复率</span></div>
					</div>
					<div class="ask"  @click="goMore('expertDetail', item.id)">去提问</div>
				</div>
			</template>
		</div>
		<div class="more" align="center">
			<a-button class="more-btn" shape="round" @click="goMore('expert')">查看更多&nbsp;>></a-button>
		</div>
    </div>
    <div class="ag-item" v-if="articles.pageResultDetail.dataList.length>0">
			<div class="ag-title">农技文章</div>
			<div class="ag-article">
				<template v-for="(item, idx) in articles.pageResultDetail.dataList.slice(0, 3)">
					<div class="artItem" :key="idx" @click="goMore('articleDetail', item.id)">
            			<div class="artImg" :style="'background-image:url('+item.coverImg+')'"></div>
						<div class="artContent">
							<div class="title">{{ item.name }}</div>
						    <div><span>{{ item.categoryName }}</span><span style="float: right;">{{ item.readCount }}人阅读</span></div>
						</div>
						
					</div>
				</template>
			</div>
		    <div class="more" align="center">
				<a-button class="more-btn" shape="round" @click="goMore('article')">查看更多&nbsp;>></a-button>
			</div>
		</div>
		<div class="ag-item" v-if="courses.pageResultDetail.dataList.length>0">
			<div class="ag-title">农技课程</div>
			<div class="ag-content">
				<template v-for="(item, idx) in courses.pageResultDetail.dataList.slice(0, 3)">
					<div class="ag-course" :key="idx" @click="goMore('courseDetail', item.id)">
            			<div class="backImg" :style="'background-image:url('+item.coverImg+')'">
						</div>
						<div class="ag-foot">
							<!-- <div class="title">{{ item.name }}</div> -->
							<div class="middel-wrap">
								<div class="middle" :title="item.name">
									{{item.name}}
								</div>
								<span class="pv">{{getPv(item)}}</span>
							</div>
						    <div class="bottomMsg"><span>课程长度：{{ item.chapterCount }} 节</span><span style="float: right;color: #FA6400;">免费</span></div>
						</div>
					</div>
				</template>
			</div>
		    <div class="more" align="center">
				<a-button class="more-btn" shape="round" @click="goMore('course')">查看更多&nbsp;>></a-button>
			</div>
		</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
name: "AgTech",
components: {
},
data () {
return {
	jumpUrls: {
		expert: '/njkt/expert',
		expertDetail: '/njkt/expertDetail',
		course: '/njkt/course',
		courseDetail: '/njkt/courseDetail',
		article: '/njkt/article',
		articleDetail: '/njkt/articleDetail'
	}
}
},
props: {
    expertsData: {
		type: Object,
		default: () => {}
	},
	coursesData: {
		type: Object,
		default: () => {}
	},
	articlesData: {
		type: Object,
		default: () => {}
	},
},
watch: {

},
methods: {
	getPv({pv}){
				if (pv>=10000) {
					return `${(pv/10000).toFixed(1)}万次播放`
				} else {
					return `${pv||0}次播放`
				}
			},
	goMore(url, id) {
		if (!this.isLogin) {
			this.$message.warning('请先登录再浏览农技学堂哦！');
			setTimeout(() => {
				// window.location.href = '/login'
				this.$router.replace('/login')
			}, 1000);
			return
		} else {
			if(url === 'expertDetail'){
				const path =`${this.jumpUrls[url]}/${id}`
				this.$router.push(path)
			} else {
				const path = id ? `${this.jumpUrls[url]}?id=${id}` : `${this.jumpUrls[url]}`
				this.$router.push(path)
			}
		}
	}
},
computed: {
	...mapGetters(['isLogin']),
    experts() {
		return this.expertsData
	},
	courses() {
		return this.coursesData
	},
	articles() {
		return this.articlesData
	},
},
created () {

},
mounted () {

},
destroyed () {

},
}
</script>

<style scoped lang="less">
.ag-tech {
    display: flex;
    width: 100%;
	margin-top: 20px;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
		.ag-item {
			width: 100%;
			background: #FFFFFF;
			.ag-title {
				padding: 40px 0;
				font-size: 24px;
				font-weight: 600;
				color: #333333;
				line-height: 30px;
			}

			.expertsBox {
				display: flex;
				justify-content: space-between;
    			align-items: center;
				.expert-item {
					width: 376px;
					background:  #F7F8FA;
					border-radius: 4px;
					cursor: pointer;
					.avatar {
						margin: 12px 15px 0 10px;

						img {
							width: 60px;
							height: 60px;
							border-radius: 50%;
							object-fit: cover;
						}
						.expert-detail {
							display: inline-block;
							vertical-align: top;
							width: calc(100% - 60px);
							.name {
								margin-left: 8px;
								font-size: 16px;
								font-weight: 500;
								color: #000000;
								line-height: 22px;
								text-overflow: ellipsis;
								overflow-wrap: break-word;
								word-break: break-all;
								white-space: nowrap;
								overflow: hidden;
								-webkit-line-clamp: 2;
							}
							span.job {
								display: inline-block;
								vertical-align: top;
								font-size: 16px;
								font-weight: 500;
								color: #000000;
								line-height: 22px;
							}
							span.detail {
								font-size: 14px;
								font-weight: 400;
								color: #20BF8E;
								line-height: 20px;
								cursor: pointer;
							}
							div.major {
								margin-left: 8px;
								font-size: 14px;
								font-weight: 400;
								color: #000000;
								line-height: 20px;
								text-overflow: ellipsis;
								overflow-wrap: break-word;
								word-break: break-all;
								white-space: nowrap;
								overflow: hidden;
								-webkit-line-clamp: 2;
							}
							
						}
					}
					
					.ask {
						height: 48px;
						background: @primary-color;
						border-radius: 0px 0px 4px 4px;
						opacity: 0.8;
						font-size: 16px;
						font-weight: 500;
						color: #FFFFFF;
						line-height: 48px;
						text-align: center;
					}
					.count {
						margin: 23px 40px;
						display: flex;
						flex-direction: columns;
						justify-content: space-between;
						align-items: center;
						span {
							display: block;
							text-align: center;
							font-size: 16px;
							font-weight: 400;
							line-height: 20px;
						}
						span.num {
							font-size: 18px;
							font-weight: 500;
							color: #FF6A00;
							line-height: 25px;
						}
					}
					.ant-btn {
						margin-top: 8px;
						width: 172px;
						height: 40px;
						border-radius: 20px;
						border: 1px solid @primary-color;
						color: @primary-color;
						background-color: #f5f5f5;
					}
				}
				.expert-item:hover {
					box-shadow: 0px 4px 20px 0px rgba(19, 66, 56, 0.1), 1px 5px 20px 0px rgba(19, 66, 56, 0.1);
					background: #FFFFFF;
					margin-bottom: 10px;
					margin-top: -10px;
					transition: all 0.5s ease;
				}
			}
			.more {
				margin-top: 40px;
				.more-btn {
					border-color: @primary-color;
					color: @primary-color;
				}
				.more-btn:hover {
					background: @primary-color;
					color: #ffffff;
				}
			}
			
			.ag-content {
				height: 100%;
				display: flex;
				width: 100%;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-between;
				align-items: stretch;
				font-size: 12px;
				font-weight: 400;
				color: #333333;
				line-height: 20px;
				.ag-course {
					width: 378px;
				    background: #F5F5F5;
					border-radius: 4px;
					cursor: pointer;
					.backImg {
						position: relative;
						height: 220px;
						background-size:cover; /* 看情况，有 cover 和 contain 模拟小程序里边的 image 的 mode 属性 */
						background-repeat: no-repeat; /* 不让重复 */
						background-position: center center ; /* 固定展示的位置 */
						z-index: 100;
					}
					
					.ag-foot {
						margin: 8px 16px 12px 16px;
						.middel-wrap{
							overflow: hidden;
							margin-top: 8px;
							.pv {
								display: inline-block;
								vertical-align: middle;
								width: 104px;
								text-align: right;
								line-height: 22px;
							}
						}
						.middle {
							font-weight: 500;
							width: 240px;
							line-height: 22px;
							color: #000;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							display: inline-block;
							vertical-align: middle;
						}
						.title {
							margin-top: 8px;
							font-size: 14px;
							font-weight: 600;
							color: #333333;
							line-height: 22px;
						}
						.bottomMsg {
							margin-top: 6px;
						}
					}
					
				}

				.ag-course:hover {
					background: #FFFFFF;
					box-shadow: 0px 4px 20px 0px rgba(19, 66, 56, 0.1);
				    margin-bottom: 10px;
					margin-top: -10px;
				}
				
				
			}

			.ag-article {
				display: flex;
    			flex-direction: row;
				justify-content: space-between;
				.artItem {
					width: 366px;
					display: flex;
					flex-direction: column;
					align-items: stretch;
					justify-content: space-between;
					cursor: pointer;
					.artImg {
						height: 260px;
						border-radius: 4px;
            background-repeat: no-repeat;
            background-size: 100%,100%;
					}
					.artContent {
						font-size: 12px;
						font-weight: 400;
						padding: 5px 10px;
						color: #999999;
						line-height: 20px;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						align-items: stretch;
						border-bottom: 1px solid #979797;
						border-left: 1px solid #979797;
						border-right: 1px solid #979797;
						.title {
							font-size: 14px;
							font-weight: 500;
							color: #333333;
							line-height: 22px;
							overflow: hidden;
							text-overflow: ellipsis;
							-o-text-overflow: ellipsis;
							white-space: nowrap;
						}
						.title:hover {
							color: @primary-color;
						}
					}
				}

				.artItem:hover {
					border: none;
					box-shadow: 0px 4px 20px 0px rgba(19, 66, 56, 0.1), 1px 5px 20px 0px rgba(19, 66, 56, 0.1);
					margin-bottom: 10px;
					margin-top: -10px;
					.artContent {
						border: none;
					}
				}
			}
		}
}
</style>
