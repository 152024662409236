<template>
  <div class="news-detail">
      <div class="detail-title">{{ detail.title }}</div>
      <div class="detail-line">
          <span>{{ detail.modifiedTime }}</span>
            <span style="margin-left: 35px;">
              <a-icon type="eye" />
             {{ detail.visitStatistic }}
            </span>
      </div>
      <div ref="article" class="detail-content">

      </div>
  </div>
</template>

<script>
export default {
name: "NewsDetail",
components: {
},
data () {
return {
    id: this.$route.params.id,
    detail: {
        title: '',
				createdTime: '',
        modifiedTime: '',
        visitStatistic: 0,
				content: ''
    }
}
},
props: {

},
watch: {

},
methods: {
	search() {
		this.$axios.newGetById(this.id, true).then(res => {
			if (res.data.code === '0') {
				Object.keys(this.detail).forEach(key => {
					this.detail[`${key}`] = res.data.body[`${key}`]
				})
        this.$refs.article.innerHTML = this.detail.content
			}
		})
	}
},
computed: {

},
created () {

},
mounted () {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    this.search()
},
destroyed () {

},
}
</script>

<style scoped lang="less">
.news-detail {
    .detail-title {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: @primary-color;
        line-height: 50px;
    }

    .detail-line {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #8C8C8C;
        line-height: 50px;
    }
    .detail-content {
        margin: 0 34px;
        
    }
    
}
</style>
<style>
.detail-content p {
  text-indent: 2em;
}
</style>
