<template>
  <a-cascader
    :options="options"
    :load-data="loadData"
    :default-value="defaultValue"
    :field-names="{ label: 'name', value: 'id', children: 'children' }"
    :placeholder="placeholder"
    change-on-select
    @change="onAreaChange"
  />
</template>

<script>
const format = (arr, arr2, ids) => {
    let result = arr
    ids.forEach(item => {
        const children = arr2.find(v => v[0]?.parentId == item)
        result = arr.map(val => {
            if (val.id == item) {
                val.children = children
                return val
            } else if (val.children) {
                val.children = format(val.children, arr2, ids)
                return val
            } else {
                return val
            }
        })
    })
    if (ids.length > 1) {
        result = format(result, arr2, ids.slice(1))
    }
    return result
}
export default {
  name: "AreaSelection",
  props: {
    maxLevel: {
        type: Number,
        default: 3
    },
    placeholder: {
        type: String,
        default: '请选择'
    },
    defaultValue: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      options: []
    }
  },
  created() {
    this.getAreaList()
  },
  methods: {
    // 获取省市区数据
    async getAreaList() {
      const { data: { code, msg, body } } = await this.$axios.GetAreaList({
        body: {
          params: {
            level: 1,
            parentId: ''
          }
        }
      });

      if (code != 0) return this.$message.error(msg);

      const list = body.map((item) => {
        if (item.level < this.maxLevel) item.isLeaf = false;
        return item;
      });

      if (this.defaultValue?.length > 1) {

        const defaultList = this.defaultValue.slice(0, -1)

        let results = await Promise.all(defaultList.map(async (item, index) => {
          // 等待异步操作完成，返回执行结果
          const { data: { code, msg, body } } = await this.$axios.GetAreaList({
            body: {
              params: {
                level: index + 2,
                parentId: item
              }
            }
          });

          if (code != 0) this.$message.error(msg);

          const arr = body || []

          const arr2 = arr.map((item) => {
            if (item.level < this.maxLevel) item.isLeaf = false;
            return item;
          });

          return arr2
        }));

        this.options = format(list, results, defaultList)

      } else {
        this.options = list
      }
    },
    // 选择地区
    onAreaChange(value, opt) {
      if (opt) {
        const { lat, lng, code, name } = opt?.slice(-1)[0]
        let address = ''
        opt.forEach(item => address += item.name);
        this.$emit('change', { id: value, code, name, address, lat, lng })
      } else {
        let address = ''
        this.$emit('change', { id: value, code: '', name: '', address, lat: '', lng: '' })
      }
    },
    // 加载地区数据
    async loadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      const { data: { code, msg, body } } = await this.$axios.GetAreaList({
        body: {
          params: {
            level: targetOption.level + 1,
            parentId: targetOption.id
          }
        }
      });

      targetOption.loading = false;

      if (code != 0) return this.$message.error(msg);

      targetOption.children = body.map((item) => {
        if (item.level < this.maxLevel) item.isLeaf = false;
        return item;
      });

      this.options = [...this.options];
    }
  }
}
</script>