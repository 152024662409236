<template>
  <div class="my_supply_container mySupply_box">
    <div class="box_shadow_wrap">
      <div class="global_title">我的供应</div>
      <!-- 查询表单 start -->
      <a-form class="query_form" layout="inline">
        <a-form-item label="品类">
          <a-input v-model.trim="queryForm.categoryName" placeholder="请输入品类" />
        </a-form-item>
        <a-form-item label="品种">
          <a-input v-model.trim="queryForm.varietyName" placeholder="请输入品种" />
        </a-form-item>
        <a-form-item label="标题">
          <a-input v-model.trim="queryForm.title" placeholder="请输入标题" />
        </a-form-item>
        <a-form-item label="创建日期">
          <a-range-picker v-if="isShow" @change="onChange" />
        </a-form-item>
        <a-form-item class="query_btn">
          <a-button type="primary" class="btn_lightgreen" icon="search" @click="queryBtn">
            查询
          </a-button>
          <a-button style="margin-left:10px;" type="primary" class="btn_lightgreen" icon="redo" @click="resetBtn">
            重置
          </a-button>
        </a-form-item>
      </a-form>
      <!-- 查询表单 end -->
    </div>
   
    <!-- 表格区域 start -->
    <div class="box_shadow_wrap tabs_wrap">
      <a-button type="primary" @click="publish"><a-icon type="plus" />我要发布</a-button>
      <a-divider />
      <a-tabs :tabBarGutter="0" :animated="false" @change="callback">
        <a-tab-pane
          :key="item.val"
          :tab="`${item.msg}(${item.count})`"
          v-for="(item) in tabs"
        >
          <!-- 表格 start -->
          <a-table :columns="columns" :data-source="data" row-key="id" :scroll="{ x: 1300, y: 260 }" :pagination="pagination">
            <template slot="num" slot-scope="text, record, index">
              {{
                (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1
              }}
            </template>
            <template slot="index" slot-scope="index">
              <span class="color_blue">{{index}}</span>
            </template>
            <template slot="order" slot-scope="text, record">
              <span class="color_blue pointer" @click="see(record.id)">查看</span>
            </template>
            <template slot="evaluate" slot-scope="evaluate">
              <span class="color_blue pointer">{{evaluate || '暂无评价'}}</span>
            </template>
            <template slot="comment" slot-scope="text, record">
              <span class="color_blue">{{text}}</span>
            </template>
            <template slot="handle" slot-scope="text, record">
              <span v-if="queryForm.auditStatus === 1" class="color_red pointer" @click="offShelf(record.id)">下架</span>
              <span v-if="queryForm.auditStatus === 0" class="color_red pointer" @click="deleteFunc(record.id)">删除</span>
              <template v-if="queryForm.auditStatus === 3">
                <span class="color_blue pointer" @click="editFunc(record.id)">编辑</span>
                <a-divider type="vertical" />
                <span class="color_blue pointer" @click="putOnShelf(record.id)">重新上架</span>
              </template>
              <template v-if="queryForm.auditStatus === 2">
                <span class="color_blue pointer" @click="editFunc(record.id)">编辑</span>
                <a-divider type="vertical" />
                <span class="color_red pointer" @click="deleteFunc(record.id)">删除</span>
              </template>
            </template>
          </a-table>
          <!-- 表格 end -->
        </a-tab-pane>
      </a-tabs>
    </div>
    <!-- 表格区域 end -->
    
  </div>
</template>
<script>
const allColumns = {
  1: [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      scopedSlots: { customRender: 'num' },
    },
    {
      title: '品类',
      dataIndex: 'categoryName',
      key: 'categoryName',
      ellipsis: true,
    },
    {
      title: '品种',
      dataIndex: 'varietyName',
      key: 'varietyName',
      ellipsis: true,
    },
    {
      title: '标题',
      key: 'title',
      dataIndex: 'title',
      ellipsis: true,
    },
    {
      title: '价格',
      key: 'price',
      dataIndex: 'price',
      ellipsis: true,
    },
    {
      title: '订单',
      key: 'order',
      dataIndex: 'order',
      scopedSlots: { customRender: 'order' },
    },
    {
      title: '评价',
      key: 'evaluate',
      dataIndex: 'evaluate',
      scopedSlots: { customRender: 'evaluate' },
    },
    {
      title: '创建时间',
      key: 'createdTime',
      dataIndex: 'createdTime',
      ellipsis: true,
    },
    {
      title: '浏览量',
      key: 'visit',
      dataIndex: 'visit',
    },
    {
      title: '收藏量',
      key: 'favorite',
      dataIndex: 'favorite',
    },
    {
      title: '操作',
      key: 'handle',
      dataIndex: 'handle',
      scopedSlots: { customRender: 'handle' },
      fixed: 'right'
    }
  ],
  0: [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      scopedSlots: { customRender: 'num' },
    },
    {
      title: '品类',
      dataIndex: 'categoryName',
      key: 'categoryName',
      ellipsis: true,
    },
    {
      title: '品种',
      dataIndex: 'varietyName',
      key: 'varietyName',
      ellipsis: true,
    },
    {
      title: '标题',
      key: 'title',
      dataIndex: 'title',
      ellipsis: true,
    },
    {
      title: '价格',
      key: 'price',
      dataIndex: 'price',
      ellipsis: true,
    },
    {
      title: '创建时间',
      key: 'createdTime',
      dataIndex: 'createdTime',
      ellipsis: true,
    },
    {
      title: '操作',
      key: 'handle',
      dataIndex: 'handle',
      scopedSlots: { customRender: 'handle' },
      fixed: 'right'
    }
  ],
  3: [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      scopedSlots: { customRender: 'num' },
    },
    {
      title: '品类',
      dataIndex: 'categoryName',
      key: 'categoryName',
      ellipsis: true,
    },
    {
      title: '品种',
      dataIndex: 'varietyName',
      key: 'varietyName',
      ellipsis: true,
    },
    {
      title: '标题',
      key: 'title',
      dataIndex: 'title',
      ellipsis: true,
    },
    {
      title: '价格',
      key: 'price',
      dataIndex: 'price',
      ellipsis: true,
    },
    {
      title: '订单',
      key: 'order',
      dataIndex: 'order',
      scopedSlots: { customRender: 'order' },
    },
    {
      title: '评价',
      key: 'evaluate',
      dataIndex: 'evaluate',
      scopedSlots: { customRender: 'comment' },
    },
    {
      title: '创建时间',
      key: 'createdTime',
      dataIndex: 'createdTime',
      ellipsis: true,
    },
    {
      title: '操作',
      key: 'handle',
      dataIndex: 'handle',
      scopedSlots: { customRender: 'handle' },
      fixed: 'right'
    }
  ],
  2: [
     {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      scopedSlots: { customRender: 'num' },
    },
    {
      title: '品类',
      dataIndex: 'categoryName',
      key: 'categoryName',
      ellipsis: true,
    },
    {
      title: '品种',
      dataIndex: 'varietyName',
      key: 'varietyName',
      ellipsis: true,
    },
    {
      title: '标题',
      key: 'title',
      dataIndex: 'title',
      ellipsis: true,
    },
    {
      title: '价格',
      key: 'price',
      dataIndex: 'price',
      ellipsis: true,
    },
    {
      title: '创建时间',
      key: 'createdTime',
      dataIndex: 'createdTime',
      ellipsis: true,
    },
    {
      title: '驳回原因',
      key: 'auditOpinion',
      dataIndex: 'auditOpinion',
      ellipsis: true
    },
    {
      title: '操作',
      key: 'handle',
      dataIndex: 'handle',
      scopedSlots: { customRender: 'handle' },
      fixed: 'right'
    }
  ]
};
const data = []
export default {
  name: "mySupply",
  data() {
    return {
      isShow: true,
      tabs: [
        {
          field: 'audited',
          msg: "已审核",
          count: 0,
          val: 1
        },
        {
          field: 'waitAudit',
          msg: "待上架",
          count: 0,
          val: 0
        }, 
        {
          field: 'pullOffShelf',
          msg: "已下架",
          count: 0,
          val: 3
        },{
          field: 'reject',
          msg: "被驳回",
          count: 0,
          val: 2
        }],
      tabIndex: 0,
      page: 1,
      size: 5,
      data,
      queryForm: {
        categoryName: '',
        varietyName: '',
        title: '',
        createdTime: '',
        auditStatus: 1
      },
      pagination: {
        current: 1,
        defaultCurrent: 1,
        pageSize: 10,
        pageSizeOptions: ['5', '10', '20'],
        defaultPageSize: 10,
        total: 0,
        showTotal: total => `共有${total}条`,
        showQuickJumper: true,
        showSizeChanger: true,
        hideOnSinglePage: false,
        onChange: current => {
          this.pagination.current = current
          this.getTabelData()
        },
        onShowSizeChange: (current, pageSize) => {
          this.pagination.current = current
          this.pagination.pageSize = pageSize
          this.getTabelData()
        }
      },
    };
  },
  computed: {
   columns() {
     return allColumns[this.queryForm.auditStatus] || []
   }
  },
  created() {
    this.querySupplyCount()
    this.getTabelData()
  },
  methods: {
    // 查询按钮
    queryBtn() {
      this.querySupplyCount()
      this.getTabelData()
    },
    resetBtn() {
      this.queryForm = {
        categoryName: '',
        varietyName: '',
        title: '',
        createdTime: '',
        auditStatus: 1
      }
      this.isShow = false
      this.$nextTick(() => {
        this.isShow = true
      })
      this.getTabelData()
    },
    // 查询供应数量
    querySupplyCount() {
      const { categoryName, varietyName, title, createdTime } = this.queryForm
      const params = {
         body: {
            params: {
                categoryName,  // 品类
                varietyName, // 品种
                title, // 标题
                createdTime, // 创建日期
            }
        }
      }
      this.$axios.querySupplyCount(params).then(res => {
        console.log('querySupplyCount', res);
        if (res.data.code === '0') {
          if (JSON.stringify(res.data.body) !== '') {
            this.tabs.map(item => {
              const val = res.data.body[item.field]
              item.count = val ? parseFloat(val) : 0
            })
          }
        }
      })
    },
    // 查询表格数据
    getTabelData() {
      const { categoryName, varietyName, title, createdTime, auditStatus } = this.queryForm
      const params = {
         body: {
            pageNum: this.pagination.current,
            pageSize: this.pagination.pageSize,
            params: {
                categoryName,  // 品类
                varietyName, // 品种
                title, // 标题
                createdTime, // 创建日期
                auditStatus // 0:待审核 1:已审核 2:已驳回3：已下架
            }
        }
      }
      this.$axios.queryMySupply(params).then(res => {
        console.log('queryMySupply', res);
        this.data = []
        if (res.data.code === '0') {
          this.data = [...res.data.body.dataList]
          this.pagination.total = res.data.body.totalCount ? parseFloat(res.data.body.totalCount) : 0
        }
      })
    },
    // 选择日期
    onChange(date, dateString) {
      console.log(date, dateString);
      this.queryForm.createdTime = ''
      if (dateString[0] !== '' && dateString[1] !== '') {
        this.queryForm.createdTime = `${dateString[0]} 00:00:00~${dateString[1]} 23:59:59`
      }
    },
    // 切换tab
    callback(key) {
      console.log(key);
      this.queryForm.auditStatus = key
      this.getTabelData()
    },
    // 查看
    see(id) {
      this.$router.push(`/personalCenter/serviceOrder?id=${id}`)
    },
    // 下架
    offShelf(id) {
      this.$axios.supplyPullOffShelf(id).then(res => {
        if (res.data.code === '0') {
          this.$message.success('下架成功！')
          this.querySupplyCount()
          this.getTabelData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 重新上架
    putOnShelf(id) {
      this.$axios.rePutOnShelf(id).then(res => {
        if (res.data.code === '0') {
          this.$message.success(res.data.msg)
          this.querySupplyCount()
          this.getTabelData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 删除
    deleteFunc(id) {
      this.$axios.deleteSupplyById(id).then(res => {
        if (res.data.code === '0') {
          this.$message.success('删除成功！')
          this.querySupplyCount()
          this.getTabelData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    editFunc(id) {
      this.$router.push({path: `/personalCenter/mySupplyEdit/edit/${id}`})
    },
    // 分页
    changePage(e) {
      this.page = e.current
      this.size = e.pageSize
    },
    //我要发布
    publish(){
      this.$router.push({path: `/personalCenter/mySupplyEdit/add/0`})
    }
  }
};
</script>
<style lang="less" scoped>
@import '../../../styles/pages/personalcenter.less';
.my_supply_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 2px;
  .tabs_wrap {
    flex: auto;
    margin-top: 16px;
  }
}
</style>