import Blockchain from '@/pages/Blockchain/index.vue'
import Layout from '@/layouts/BasicLayout/index.vue'
import Inquire from '@/pages/Blockchain/inquire.vue'
import Merchant from '@/pages/Blockchain/merchant.vue'
import Statistics from '@/pages/Blockchain/statistics.vue'
import MerchantDetail from '@/pages/Blockchain/merchantDetail.vue'
import MerchantSuYuan from '@/pages/Blockchain/merchantSuYuan.vue'

export default [
    {
        path: '/blockchain',
        name: 'blockchain',
        component: Layout,
        meta: {
          title: '区块链溯源',
          basePath: 'blockchain'
        },
        children: [
          {
            path: '',
            component: Blockchain,
            name: '',
            meta: {
              basePath: 'blockchain',
              title: '区块链溯源'
            },
            children: [
              {
                path: '/*/inquire',
                component: Inquire,
                name: 'inquire',
                meta: {
                  basePath: 'blockchain',
                  title: '查询'
                }
              },
              {
                path: '/*/merchant',
                component: Merchant,
                name: 'merchant',
                meta: {
                  basePath: 'blockchain',
                  title: '企业'
                }
              },
              {
                path: '/*/merchantDetail/:id',
                component: MerchantDetail,
                name: 'merchantDetail',
                meta: {
                  basePath: 'blockchain',
                  title: '企业详情'
                }
              },
              {
                path: '/*/merchantSuYuan/:id',
                component: MerchantSuYuan,
                name: 'merchantSuYuan',
                meta: {
                  basePath: 'blockchain',
                  title: '企业溯源'
                }
              },
              {
                path: '/*/statistics',
                component: Statistics,
                name: 'statistics',
                meta: {
                  basePath: 'blockchain',
                  title: '统计'
                }
              },
            ]
          }
        ]
      },
]