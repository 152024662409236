<template>
  <div class="my_service_container">
    <div class="box_shadow_wrap">
      <div class="global_title">我的服务情况</div>
      <a-tabs class="tabs_wrap" :tabBarGutter="0" :animated="false" v-model="tabIndex" @change="callback">
        <a-tab-pane v-for="(item, index) in tabs" :key="index" :tab="item" force-render>
          <a-row>
            <a-col :span="14">
              <div class="echants_main" style="width: 100%; height: 400px"></div>
            </a-col>
            <a-col :offset="2" :span="8">
              <div class="order_list">
                <a-row :gutter="[16,16]" v-for="(item, index) in orderList" :key="index">
                  <a-col :span="9">
                    <span class="dot" :style="{backgroundColor: colorList[index]}"></span>
                    {{item.name}}
                  </a-col>
                  <a-col>
                    {{item.percent}}
                  </a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>
          <div class="good_comment">
            <div class="global_title">我的好评率</div>
            <a-row class="margin_top">
              <a-col :span="10" :offset="2">
                <div class="progress_wrap">
                  <div class="progress" :style="{ width: progress }">
                    <div class="explain_wrap">
                      <div class="explain">{{ progress }}</div>
                      <div class="triangle"></div>
                    </div>
                  </div>
                </div>
              </a-col>
              <a-col :span="10" :offset="2">
                <div class="ranking">
                  <img
                    src="../../../assets/img/ranking2.png"
                    width="14px"
                    style="vertical-align: middle"
                  />
                  相关排名：
                  <span>{{rankAll}}</span>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import * as echarts from "echarts";
let myChart = null;
// const orderList = [
//   { value: 40, name: "梨膏加工" },
//   { value: 38, name: "草莓加工" },
//   { value: 32, name: "面粉加工" },
//   { value: 30, name: "小麦加工" },
//   { value: 28, name: "轻食加工" },
//   { value: 26, name: "其它" }
// ]
const colorList = [
  "#8378EA",
  "#E7BCF4",
  "#FF9E81",
  "#FFDA5E",
  "#9FE6B8",
  "#34C4E8",
]
export default {
  name: "myService",
  data() {
    return {
      tabs: ["全部订单", "执行中", "已完成"],
      tabIndex: 0,
      progress: "0%",
      allOrder: 0,
      rankAll: 0,
      orderList: [],
      colorList
    };
  },
  computed: {
    ...mapState(['userInfo'])
  },
  created() {
    this.getMyServiceByStatus()
  },
  mounted() {
    this.showLoading()
  },
  methods: {
    // 获取我的服务情况
    async getMyServiceByStatus() {
      const { data: { code, msg, body } } = await this.$axios.getMyService({
        body: {
          params: {
            userId: this.userInfo.id,
            type: this.tabIndex + 1  // 1、查询全部   2、查询执行中     3、查询已完成
          }
        }
      })

      if (code != 0) return this.$message.error(msg)

      const arr = body.orders.map(item => ({ name: item.orderType, value: item.orderCount, percent: item.orderBFB }))

      console.log(arr);

      this.orderList = arr

      this.allOrder = body.numberAll

      this.progress = body.goodJobCount || '0%'

      this.rankAll = body.rankAll

      this.initEchants()
    },
    callback() {
      this.showLoading()
      this.getMyServiceByStatus()
    },
    showLoading() {
      if (myChart) myChart.dispose()
      myChart = echarts.init(document.querySelectorAll(".echants_main")[this.tabIndex])
      myChart.showLoading()
    },
    initEchants() {

      const show = this.orderList.length > 0

      colorList[0] = show ? '#8378EA' : '#eee'

      myChart.hideLoading();

      var option;
      
      option = {
        title: {
          text: this.allOrder,//主标题文本
          subtext:'订单量',//副标题文本
          left:'center',
          top:'44%',
          textStyle:{
            fontSize: 16,
            color:'#454c5c',
            align:'center'
          },
          itemGap: 5,
          subtextStyle:{
            fontFamily : "微软雅黑",
            fontSize: 14,
            color:'#6c7a89',
          }
        },
        tooltip: {
          trigger: "item",
          show: show,
          formatter: "{b}: {c} ({d}%)",
          textStyle: {
            align: "right",
          },
        },
        series: [
          {
            type: "pie",
            silent: !show,
            radius: [60, show ? 165 : 100],
            center: ["50%", "50%"],
            roseType: "area",
            color: colorList,
            label: {
              show: show,
              formatter: '{c}'
            },
            data: show ? this.orderList : [{ value: 1, name: "订单量" }]
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../styles/pages/personalcenter.less";
.my_service_container {
  height: 100%;
  padding-top: 2px;
  .box_shadow_wrap {
    height: 100%;
  }
  .tabs_wrap {
    margin-top: 16px;
  }
  .order_list {
    margin-top: 30px;
    .dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 5px;
      border-radius: 50%;
    }
  }
  .progress_wrap {
    margin-top: 5px;
    border-radius: 6px;
    background-color: #eee;
  }
  .progress {
    position: relative;
    height: 12px;
    border-radius: 6px;
    background-color: @primary-color;
    .explain_wrap {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(90%, -100%);
      .triangle {
        width: 0;
        height: 0;
        line-height: 0;
        font-size: 0;
        border: 12px solid transparent;
        margin-top: -12px;
        border-radius: 5px;
        border-left-color: @primary-color;
      }
    }
    .explain {
      padding: 10px 15px;
      border-radius: 5px;
      color: #fff;
      background-color: @primary-color;
    }
  }
  .margin_top {
    margin-top: 50px;
  }
}
</style>