<template>
  <div class="collection_container">
    <div class="box_shadow_wrap">
      <div class="global_title">需求收藏</div>
      <!-- 查询表单 start -->
      <a-form class="query_form" layout="inline">
        <a-form-item label="品类">
          <a-input v-model="varieties" placeholder="请输入品类" allow-clear />
        </a-form-item>
        <a-form-item label="品种">
          <a-input v-model="category" placeholder="请输入品种" allow-clear />
        </a-form-item>
        <a-form-item label="标题">
          <a-input v-model="title" placeholder="请输入标题" allow-clear />
        </a-form-item>
        <a-form-item label="创建日期">
          <a-range-picker @change="onChange" />
        </a-form-item>
        <a-form-item class="query_btn">
          <a-button
            type="primary"
            class="btn_lightgreen"
            icon="search"
            @click="getTabelData"
          >
            查询
          </a-button>
        </a-form-item>
      </a-form>
      <!-- 查询表单 end -->
    </div>

    <!-- 表格区域 start -->
    <div class="box_shadow_wrap tabs_wrap">
      <a-table
        :columns="columns"
        :data-source="tabelData"
        :scroll="{ x: 900, y: 400 }"
        :pagination="false"
        :loading="isLoading"
        row-key="id"
      >
        <template slot="handle" slot-scope="text, record">
          <span class="color_blue pointer" @click="cancelHandle(record)">取消收藏</span>
        </template>
      </a-table>

      <!-- 分页 -->
      <div class="pagination_wrap">
        <a-space>
          <a-pagination 
          show-quick-jumper 
          show-size-changer
          :total="total" 
          :show-total="total => `共有 ${total} 条`"
          :page-size-options="pageSizeOptions"
          :page-size="size"
          v-model="page"
          @showSizeChange="onShowSizeChange"
          @change="changePage">
            <template slot="buildOptionText" slot-scope="props">
              <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
              <span v-if="props.value === '50'">全部</span>
            </template>
          </a-pagination>
          <a-button>确定</a-button>
        </a-space>
      </div>
    </div>
    <!-- 表格区域 end -->

  </div>
</template>
<script>
import { mapState } from 'vuex'
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    customRender: (text, row, index) => {
      //  this.pagination ? `${(this.pagination.defaultCurrent-1)*(this.pagination.defaultPageSize)+index+1}` :index+1
      return index + 1;
    }
  },
  {
    title: '品类',
    dataIndex: 'dataVarieties',
    key: 'dataVarieties',
    ellipsis: true
  },
  {
    title: '品种',
    dataIndex: 'dataCategory',
    key: 'dataCategory',
  },
  {
    title: '标题',
    key: 'dataTitle',
    dataIndex: 'dataTitle',
  },
  {
    title: '联系人',
    key: 'dataUserName',
    dataIndex: 'dataUserName',
  },
  {
    title: '联系方式',
    key: 'dataUserTel',
    dataIndex: 'dataUserTel',
  },
  {
    title: '操作',
    key: 'handle',
    dataIndex: 'handle',
    scopedSlots: { customRender: 'handle' },
    fixed: 'right'
  }
];
export default {
  name: "demandCollection",
  data() {
    return {
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      page: 1,
      size: 10,
      total: 0,
      category: '',
      varieties: '',
      title: '',
      createTime: '',
      columns,
      isLoading: false,
      tabelData: []
    };
  },
  computed: {
    ...mapState(['userInfo'])
  },
  created() {
    this.getCollectListByFormData()
  },
  methods: {
    // 查询表格数据
    getTabelData() {
      this.getCollectListByFormData()
    },
    // 选择日期
    onChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.createTime = dateString.join('~')
      } else {
        this.createTime = ''
      }
    },
    // 分页
    changePage(page, pageSize) {
      this.page = page
      this.size = pageSize
      this.getCollectListByFormData()
    },
    // 每页数量
    onShowSizeChange(current, pageSize) {
      this.page = current
      this.size = pageSize
      this.getCollectListByFormData()
    },
    // 获取收藏数据
    async getCollectListByFormData() {
      this.isLoading = true
      const { data: { code, msg, body } } = await this.$axios.getCollectList({
        body: {
          pageNum: this.page,
          pageSize: this.size,
          params: {
            createId: this.userInfo.id, //当前登录用户id
            dataType: 1,
            dataCategory: this.category, //品种
            dataTitle: this.title, //主题
            dataVarieties: this.varieties, //品类
            createTime: this.createTime
          }
        }
      })
      this.isLoading = false
      if (code != 0) return this.$message.error(msg)
      const arr = body.dataList || []
      this.total = Number(body.totalCount)
      this.tabelData = arr
    },
    // 取消收藏
    async cancelHandle(res) {
      this.isLoading = true
      const { data: { code, msg } } = await this.$axios.cancelCollect({ body: { id: res.id } })
      this.isLoading = false
      if (code != 0) return this.$message.error(msg)
      this.$message.success('取消收藏')
      this.getCollectListByFormData()
    }
  }
};
</script>
<style lang="less" scoped>
@import '../../../styles/pages/personalcenter.less';
.collection_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 2px;
  .tabs_wrap {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 16px;
  }
}
.pagination_wrap {
  display: flex;
  justify-content: flex-end;
}
</style>
