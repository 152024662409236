<template>
  <div>
    <a-modal v-model="visible" :width="680" :centered="true" :footer="null" @ok="handleOk" @cancel="handleCancel">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="类型" prop="applyEntityType">
          <a-radio-group v-model="form.applyEntityType" @change="() => {$refs.ruleForm.clearValidate('applyUserName')}">
            <a-radio :value="1">
              企业
            </a-radio>
            <a-radio :value="2">
              个人
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="form.applyEntityType === 1" label="企业名称" prop="companyName">
          <a-input v-model.trim="form.companyName" placeholder="请输入企业名称" />
        </a-form-model-item>
        <a-form-model-item :label="form.applyEntityType === 1 ? '申请人' : '个人姓名'" prop="applyUserName">
          <a-input v-model.trim="form.applyUserName" :placeholder="form.applyEntityType === 1 ? '请输入申请人' : '请输入个人姓名'" />
        </a-form-model-item>
        <a-form-model-item label="申请人电话" prop="applyUserPhone">
          <a-input v-model.trim="form.applyUserPhone" placeholder="请输入申请人电话"/>
        </a-form-model-item>
        <a-form-model-item label="地址" prop="addressProvince">
          <AreaSelectView
            style="width: 120px"
            v-model="form.addressProvince"
            placeholder="省"
            @onChange="handleProvinceChange"
            @getObjectId="getprovinceIdAction"
            level="1"
            DataType="province"
          ></AreaSelectView>
          <AreaSelectView
            style="width: 100px"
            v-model="form.addressCity"
            :parentId="provinceid"
            placeholder="市"
            DataType="city"
            @getObjectId="getCityIdAction"
            @onChange="handleCityChange"
          ></AreaSelectView>
          <AreaSelectView
            style="width: 118px"
            v-model="form.addressArea"
            :parentId="areaid"
            placeholder="区"
            DataType="city"
            @onChange="handleAreaChange"
            @getObjectId="getareaIdAction"
          ></AreaSelectView>
          <div style="width: calc(100% - 780px)"></div>
          <a-input style="width:358px" v-model="form.addressDetail" allow-clear placeholder="请输入详细地址" />
        </a-form-model-item>
        <a-form-model-item label="申请金额" prop="applyAmount">
          <a-input v-model.trim="form.applyAmount" placeholder="请输入申请金额"/>
        </a-form-model-item>
        <a-form-model-item label="用途" prop="applyRemarkType">
          <a-select 
            v-model="form.applyRemarkType"
            :options="purpose"
            placeholder="请选择用途">
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div style="display: flex;justify-content: center;">
        <a-button icon="close-circle" size="large" @click="handleCancel">取消</a-button>
        <a-button type="primary" size="large" icon="check-circle" style="margin-left: 20px" @click="handleOk">确认</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
name: "Information",
components: {
},
data () {
  let userNameValidator = (rule, value, callback) => {
    if (!value && this.form.applyEntityType === 1) {
      callback(new Error('请输入申请人'));
    }
    if (!value && this.form.applyEntityType === 2) {
      callback(new Error('请输入个人姓名'));
    }

    if (this.ruleForm.applyUserName !== '' && this.ruleForm.applyUserName !== undefined) {
      this.$refs.ruleForm.validateField('applyUserName');
      callback();
    }
    callback()
  }
return {
  visible: true,
  form: {
    userId: '73', // 用户id
    applyEntityType: 1,
    companyName: undefined,
    applyUserName: undefined,
    applyUserPhone: undefined,
    applyAmount: undefined,
    applyRemarkType: undefined,
    addressProvince: '', 
    addressCity: '',
    addressArea: '',
    addressDetail: ''
  },
  labelCol: { span: 5 },
  wrapperCol: { span: 14 },
  rules: {
    applyEntityType: [
      { required: true, message: '请选择类型', trigger: 'change' }
    ],
    companyName: [
      { required: true, message: '请输入企业名称', trigger: 'blur' }
    ],
    applyUserName: [
      { required: true, message: '请输入申请人', trigger: 'blur' }
    ],
    applyUserPhone: [
      { required: true, message: '请输入申请人电话', trigger: 'blur' }
    ],
    addressProvince: [
      { required: true, message: '请选择地址', trigger: 'change' }
    ],
    applyAmount: [
      { required: true, message: '请输入申请金额', trigger: 'blur' }
    ],
    applyRemarkType: [
      { required: true, message: '请选择用途', trigger: 'change' }
    ]
  },
  purpose: [], // 用途
  provinceid: '',
  areaid: '',
  townsid: '',
}
},
props: {

},
watch: {

},
computed: {

},
created () {

},
mounted () {
  this.getPurpose()
},
destroyed () {

},
methods: {
  handleOk() {
    this.$refs.ruleForm.validate((valid) => {
      console.log('valid', valid);
      if (!valid) {
        this.$message.error('请添加必填字段！');
      } else {
        this.$emit('on-ok', { ...this.form })
      }
    });
  },
  handleCancel() {
    this.$emit('on-cancel');
  },
  getPurpose() {
    this.$axios.getByTypes('BAZ4LIZ6').then(res => {
      if (res.data.code === '0') {
        res.data.body.BAZ4LIZ6.forEach(ele => {
          this.purpose.push({
            label: ele.dictValue,
            value: ele.dictValue,
            id: ele.id
          })
        })
      }
    })
  },
  handleProvinceChange(value, option) {
    this.provinceid = option.id
    this.form.addressProvince = option.name
  },
  getprovinceIdAction(v) {
    this.provinceid = v
  },
  getCityIdAction(v) {
    this.areaid = v
  },
  handleCityChange(value, option) {
    this.areaid = option.id
    this.form.addressCity = option.name
  },
  handleAreaChange(value, option) {
    this.townsid = option.id
    this.form.addressArea = option.name
  },
  getareaIdAction(v) {
    this.townsid = v
    this.$forceUpdate()
  },
},
}
</script>

<style scoped lang="less">

</style>
