import { POST, ApiUrl } from '@/config/index'

// 获取所有省份
const getProvinces = POST(`${ApiUrl}/api/backapp/system/regionCity/action/queryList`)
// 根据省份ID获取所有城市
const getCitysByProvinceId = POST(`${ApiUrl}/api/backapp/system/regionCity/action/queryList`)
// 根据城市ID获取所有区县
const getAreasByCityId = POST(`${ApiUrl}/api/backapp/system/regionCity/action/queryList`)
// 根据区县ID获取所有街道
const getStreetsByAreaId = POST(`${ApiUrl}/api/backapp/system/regionCity/action/queryList`)
export default {
  getProvinces: getProvinces,
  getCitysByProvinceId: getCitysByProvinceId,
  getAreasByCityId: getAreasByCityId,
  getStreetsByAreaId: getStreetsByAreaId
}
