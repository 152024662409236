<template>
  <div class="edit_container box_shadow_wrap">
    <a-spin :spinning="spinning">
      <div class="global_title">{{title}}</div>
      <a-form-model ref="ruleForm" class="form_wrap" :model="formData" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <template v-if="!spinning">
          <a-form-model-item label="品类" required prop="demandCategory">
            <a-select :default-value="formData.demandCategory || undefined" placeholder="请选择" @change="handleCateChange">
              <a-select-option
                v-for="(item, index) in cateList"
                :key="index"
                :data-delete="item.deleted"
                >{{ item.cateName }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="品种" required prop="demandVarieties">
            <a-select v-model="varietyIndex" :default-value="formData.demandVarieties || undefined" placeholder="请选择" @change="handleVarietyChange">
              <a-select-option
                v-for="(item, index) in varietyList"
                :key="index"
                :data-delete="item.deleted"
                >{{ item.cateName }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="所在地区" required prop="shippingAddress">
            <area-selection @change="getAddress" v-if="path == 'add'"></area-selection>
            <area-selection :default-value="formData.provinceCode.split(',')" @change="getAddress" v-else></area-selection>
          </a-form-model-item>
        </template>
        <a-form-model-item label="需求日期">
          <a-date-picker v-model="formData.releaseTime" @change="onChange" />
        </a-form-model-item>
        <a-form-model-item label="联系人" required prop="contactName">
          <a-input v-model="formData.contactName" placeholder="请输入联系人" />
        </a-form-model-item>
        <a-form-model-item label="联系人电话" required prop="contactTel">
          <a-input v-model.number="formData.contactTel" placeholder="请输入电话" />
        </a-form-model-item>
        <a-form-model-item label="价格">
          <a-input v-model.number="formData.price" default-value="mysite" type="number" placeholder="请输入价格">
            <a-select slot="addonAfter" :default-value="formData.priceUnit" style="min-width: 60px" @change="workPriceConfirm" :key="formData.priceUnit">
                <a-select-option :value="item.dictKey" v-for="(item,index) in workPriceSelect" :key="index" :data-delete="item.deleted">
                    {{item.dictValue}}
                </a-select-option>
            </a-select>
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="标题" required prop="demandTitle">
          <a-input v-model="formData.demandTitle" placeholder="输入标题，请勿超过30个字" />
        </a-form-model-item>
        <a-form-model-item label="需求详情">
          <Editor v-model="formData.demandTail" placeholder="请输入文字..." height="155" />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button @click="cancel">取消</a-button>
          <a-button style="margin-left: 10px" type="primary" :loading="loading" @click="submitForm">{{ path == 'add' ? '发布' : '保存'}}</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </div>
</template>
<script>
import moment from 'moment';
import { mapState } from 'vuex'
export default {
  props: ['path', 'id'],
  data() {
    const validateDemandCategory = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请选择品类'));
      } else {
        callback();
      }
    }
    const validateDemandVarieties = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请选择品种'));
      } else {
        callback();
      }
    }
    const validateShippingAddress = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请选择所在地区'));
      } else {
        // if (this.ruleForm.checkPass !== '') {
        //   this.$refs.ruleForm.validateField('checkPass');
        // }
        callback();
      }
    }
    const validateContactName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入联系人'));
      } else {
        callback();
      }
    }
    const validateContactTel = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入联系电话'));
      } else if (!this.$utils.test.mobile(value)) {
        callback(new Error('电话证格式错误'));
      } else {
        callback();
      }
    }
    const validateDemandTitle = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入标题'));
      } else if (value.trim().length > 30) {
        callback(new Error('请勿超过30个字'));
      } else {
        callback();
      }
    }
    const validatePrice = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入价格'));
      } else {
        callback();
      }
    }

    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      cateList: [],
      loading: false,
      spinning: false,
      varietyIndex: undefined,
      cateIndex: 0,
      formData: {
        demandCategory: '',
        demandVarieties: '',
        shippingAddress: '',
        contactName: '',
        contactTel: '',
        demandTitle: '',
        demandTail: '',
        price: '',
        priceUnitKey: 1,
        priceUnit: '元',
      },
      rules: {
        demandCategory: [{ validator: validateDemandCategory, trigger: 'change' }],
        demandVarieties: [{ validator: validateDemandVarieties, trigger: 'change' }],
        shippingAddress: [{ validator: validateShippingAddress, trigger: 'change' }],
        contactName: [{ validator: validateContactName, trigger: 'change' }],
        contactTel: [{ validator: validateContactTel, trigger: 'change' }],
        price: [{ validator: validatePrice, trigger: 'change' }],
        demandTitle: [{ validator: validateDemandTitle, trigger: 'change' }]
      },
      workPriceSelect: [],
    };
  },
  computed: {
    ...mapState(['userInfo']),
    title() {
      return this.path == 'add' ? '新增需求' : '编辑需求'
    },
    varietyList() {
      return this.cateList[this.cateIndex]?.children || []
    }
  },
  created() {
    this.getCategory()
    if (this.path == 'edit') this.getDetailById()
  },
  mounted() {
      this.getPriceTypes()
  },
  methods: {
    moment,
    // 初始化默认值价格单位
    workPriceConfirm(key) {
        const currentItem = this.workPriceSelect.find(item => item.dictKey === key);
        if(currentItem) {
            this.formData.priceUnitKey = currentItem.dictKey
            this.formData.priceUnit = currentItem.dictValue
        }
    },
    // 获取价格单位下拉数据
    async getPriceTypes() {
      const res = await this.$axios.getByTypes("CZT0DXY3");
      const { code, body } = res.data;
      if (code === "0") {
        this.workPriceSelect = body.CZT0DXY3
        this.formData.priceUnitKey = this.workPriceSelect[0].dictKey
        this.formData.priceUnit = this.workPriceSelect[0].dictValue
      }
    },
    // 获取编辑详情
    async getDetailById() {
      this.spinning = true;
      const { data: { code, msg, body } } = await this.$axios.getDemandDetail({
        body: {
          params: {
            id: this.id,
            userId: this.userInfo.id
          }
        }
      })
      this.spinning = false;
      if (code != 0) return this.$message.error(msg)
      this.formData = body
      if (body.demandVarieties) this.varietyIndex = body.demandVarieties
    },
    // 获取品类数据
    async getCategory() {
      const { data: { code, msg, body } } = await this.$axios.getDemandCategory({})
      if (code != 0) return this.$message.error(msg)
      this.cateList = body || []
    },
    // 选择地址
    getAddress(e) {
      this.formData.provinceCode = e.id.join()
      this.formData.shippingAddress = e.address
    },
    // 选择品类
    handleCateChange(e) {
      this.cateIndex = e
      this.varietyIndex = undefined
      this.formData.demandVarieties = ''
      this.formData.demandCategory = this.cateList[e].cateName
    },
    // 选择品种
    handleVarietyChange(e) {
      this.varietyIndex = e
      this.formData.demandVarieties = this.varietyList[e].cateName
    },
    // 验证表单
    submitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) return false

        if (this.path == 'edit') this.editDemandHandle()

        if (this.path == 'add') this.publishDemandHandle()
      });
    },
    // 发布
    async publishDemandHandle() {
      // 1、发布人id:   releaseUserId
      // 2、发布人名称:  releaseUserName
      try {
        this.formData.releaseUserId = this.userInfo.id
        this.formData.releaseUserName = this.userInfo.userName
        this.loading = true
        const { data: { code, msg } } = await this.$axios.publishDemand({
          body: this.formData
        })
        this.loading = false

        if (code != 0) return this.$message.error(msg)
        this.$message.success('发布成功')
        this.$router.go(-1)
      } catch (error) {
        this.loading = false
      }
    },
    // 编辑
    async editDemandHandle() {
      try {
        this.loading = true
        const { data: { code, msg } } = await this.$axios.editDemand({
          body: {
            createTime: this.formData.createTime,
            contactName: this.formData.contactName,
            contactTel: this.formData.contactTel,
            demandTitle: this.formData.demandTitle,
            demandTail: this.formData.demandTail,
            id: this.formData.id,
            price: this.formData.price,
            demandVarieties: this.formData.demandVarieties,
            demandCategory: this.formData.demandCategory,
            shippingAddress: this.formData.shippingAddress
          }
        })
        this.loading = false

        if (code != 0) return this.$message.error(msg)
        this.$message.success('发布成功')
        this.$router.go(-1)
      } catch (error) {
        this.loading = false
      }
    },
    onChange(date, dateString) {
      this.formData.releaseTime = dateString
    },
    cancel() {
      this.$router.go(-1)
    }
  },
};
</script>

<style scoped lang="less">
@import "../../../styles/pages/personalcenter.less";
.edit_container {
  margin-top: 2px;
}
.form_wrap {
  margin-top: 16px;
}
.tips {
  margin-top: -10px;
  line-height: normal;
  color: #ccc;
}
</style>
