<template>
  <div class="ag-share-list">
    <div class="ag-share-header">
      <div class="title">{{ title }}</div>
      <div class="white-point"></div>
      <div class="btnStyle" @click="$router.push('/machineShare')">
        {{ btnMsg }}
      </div>
    </div>
    <div class="ag-share-container">
      <div class="ag-share-table-head">
        <template v-for="(col, idx) in columns">
          <div
            class="ag-share-table-col-head"
            :key="idx"
            :style="{ width: col.width }"
          >
            {{ col.title }}
          </div>
        </template>
      </div>
      <div id="ag-share-table" class="ag-share-table-body">
        <template v-for="(col, idx) in columns">
          <div
            class="ag-share-table-col-body"
            :key="idx"
            :style="{ width: col.width }"
          >
            <template v-for="(row, index) in data">
              <div
                v-if="col.dataIndex === 'action'"
                :key="index"
                style="color: #20bf8e"
                @click="reserve(row)"
              >
                预约
              </div>
              <div
                v-else
                :key="index"
                :style="{ color: hoverIndex === index ? '#20BF8E' : '' }"
                :title="row[col.dataIndex]"
                @mouseenter="onMouseover(index)"
                @mouseleave="onMouseout"
              >
                {{ row[col.dataIndex] }}
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <a-modal title="预约服务" v-model="visible" width="35%" @ok="handleOk">
      <a-form-model
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        v-bind="layout"
        :labelCol="{ span: 6, offset: 0 }"
        labelAlign="right"
      >
        <a-form-model-item has-feedback label="预约人电话" prop="phone">
          <a-input
            v-model="ruleForm.phone"
            placeholder="请填写预约人电话"
            autocomplete="off"
          />
        </a-form-model-item>
        <a-form-model-item has-feedback label="计划作业面积" prop="area">
          <a-input
            v-model="ruleForm.area"
            placeholder="请填写计划作业面积"
            autocomplete="off"
          />
        </a-form-model-item>
        <a-form-model-item has-feedback label="计划作业地址" prop="address">
          <a-cascader
            v-model="ruleForm.address"
            size="large"
            :options="options"
            allow-clear
            expand-trigger="hover"
            placeholder="请选择地址"
            @change="selectAddress"
          />
        </a-form-model-item>
        <a-form-model-item has-feedback label="详细地址">
          <a-input
            v-model="makeAddress"
            :max-length="100"
            placeholder="请填写详细地址"
          />
        </a-form-model-item>
        <a-form-model-item has-feedback label="计划完成时间" prop="planTime">
          <a-date-picker v-model="ruleForm.planTime" placeholder="请选择日期" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { provinces } from "@/assets/js/province.js";
import { mapState, mapGetters } from "vuex";
import apiConfig from "@/config/apiConfig";
const { key } = apiConfig[process.env.VUE_APP_ENV ? process.env.VUE_APP_ENV : "dev"];
// 定时器
var timer;
const columns = [
  {
    dataIndex: "typeSecondary",
    title: "农机类型",
    width: "17%",
  },
  {
    dataIndex: "businessName",
    title: "服务主体",
    width: "21%",
  },
  {
    dataIndex: "location",
    title: "所在位置",
    width: "17%",
  },
  {
    dataIndex: "workPrice",
    title: "价格（亩）",
    width: "14%",
  },
  {
    dataIndex: "distance",
    title: "距离",
    width: "14%",
  },
  {
    dataIndex: "action",
    title: "操作",
    width: "17%",
  },
];

export default {
  name: "AgShareList",
  components: {},
  data() {
    return {
      HomeworkAddress: {},
      adderssStr:'',
      makeAddress:'',
      reserveInformation: {},
      visible: false,
      qrcodeImg: require("@/assets/img/miniapp-qrcode.jpg"),
      title: "农机共享",
      btnMsg: "查看更多",
      imgUrl: require("@/assets/img/tuolaji.png"),
      columns: Object.freeze(columns),
      hoverIndex: "",
      ruleForm: {
        planTime: "",
        address: [],
        phone: "",
        area: "",
        makeAddress:"",
      },
      rules: {
        area: [{ required: true, message: "请输入面积", trigger: "blur" }],
        phone: [{ required: true, message: "请输入电话", trigger: "blur" }],
        address: [{ required: true, message: "请选择地址", trigger: "change" }],
        planTime: [
          { required: true, message: "请选择时间", trigger: "change" },
        ],
        makeAddress: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
      },
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 15 },
      },
      address: "",
      options: provinces,
    };
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    visible(val) {
      console.log(1111111)
      if (val) {
        this.HomeworkAddress = {};
        this.adderssStr = {};
        // this.reserveInformation = {};
        this.makeAddress = "";
        try {
          this.$refs["ruleForm"].resetFields(); // 重置编辑表单
          // addForm 为ref指向的表单
        } catch (e) {}
      }
    },
  },
  methods: {
    autoSroll(Id) {
      // flag 为true时停止滚动
      var flag = false;
      function roll() {
        var h = -1;
        timer = setInterval(function () {
          flag = true;
          //获取当前滚动条高度
          const dom = document.getElementById(Id);
          if (dom) {
            var current = dom.scrollTop;
            if (current == h) {
              //滚动到底端,返回顶端
              h = 0;
              document.getElementById(Id).scrollTop = h + 1;
            } else {
              //以25ms/3.5px的速度滚动
              h = current;
              document.getElementById(Id).scrollTop = h + 1;
            }
          }
        }, 50);
      }
      // setTimeout(function() {
      //滚动区域内单击鼠标 滚动暂停 再次点击鼠标 继续滚动
      document.getElementById(Id).onclick = () => {
        console.log("点击了", timer, flag);
        if (flag) {
          flag = false;
          clearInterval(timer);
        } else {
          roll();
        }
      };
      roll();
      // }, 2000);
    },
    onMouseover(index) {
      this.hoverIndex = index;
    },
    onMouseout() {
      this.hoverIndex = "";
    },
    reserve(data) {
      console.log(22222)
      console.log(data);
      if (this.isLogin) {
        this.reserveInformation = data;
        this.visible = true;
      } else {
        this.$confirm({
          title: "请先进行登录",
          onOk: () => this.$router.push("/login"),
        });
      }
    },
    showModal() {
      this.visible = true;
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);
          this.machineryAppointment();
        }
      });
    },
    // 选择计划作业地址
    selectAddress(value, selectedOptions) {
      const addressArr = selectedOptions?.map?.((item) => item.label);
      const adderssStr = addressArr?.join?.("");
      this.adderssStr = adderssStr
      console.log(adderssStr,'adderssStr')
      adderssStr && this.getLocationByAddress(adderssStr);
    },
    // 地址解析（地址转坐标）
    async getLocationByAddress(address) {
      const url = "https://apis.map.qq.com/ws/geocoder/v1";
      //发起JSONP请求，获取坐标描述
      const { message, result, status } = await this.$jsonp(url, {
        address,
        key,
        output: "jsonp",
      });

      if (status != 0) return this.$message.error(message);
      this.HomeworkAddress = result;
    },
    // 农机预约
    async machineryAppointment() {
      try {
        const {
          data: { code, msg },
        } = await this.$axios.machineryAppointment({
          body: {
            machineModel: this.reserveInformation.typeSecondary, // 农机类型
            machineNumber: this.reserveInformation.number, // 车牌号
            locationLongitude: this.reserveInformation.longitude, // 所在位置经度
            localLatitude: this.reserveInformation.latitude, // 所在位置纬度
            serviceOrganization: "", // 服务组织
            price: this.reserveInformation.workPrice, // 价格
            myPhone: this.ruleForm.phone, // 本人手机号
            jobArea: this.ruleForm.area, // 作业面积
            endDate: this.ruleForm.planTime, // 完成时间
            jobSiteLongitude: this.HomeworkAddress?.location?.lng, // 作业地址经度
            jobSiteLatitude: this.HomeworkAddress?.location?.lat, // 作业地址纬度
            adderssStr:this.adderssStr,
            jobLocation: this.makeAddress, // 作业地址
            modelId: this.reserveInformation.id, // 农机详情ID
            id: "", // 农机机主ID
            openId: "", // 当前用户openId
            reservedUserId: this.userInfo.id, // 登录用户id
          },
        });

        if (code != 0) return this.$message.error(msg);
        this.$message.success("预约成功", 3);
        this.visible = false;
      } catch (error) {
        console.log("err", error);
        this.visible = false;
      }
    },
  },
  computed: {
    ...mapGetters(["isLogin"]),
    ...mapState(["userInfo"]),
  },
  created() {},
  mounted() {
    this.autoSroll("ag-share-table");
  },
  beforeDestroy() {
    clearInterval(timer);
  },
  destroyed() {},
};
</script>

<style scoped lang="less">
/deep/.ant-modal-header {
  text-align: left !important;
}

/deep/ .ant-cascader-picker .ant-cascader-picker-label {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
  font-family: inherit;
}
.ag-share-list {
  margin-top: 16px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 1px;
  .ag-share-header {
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%; /* 看情况，有 cover 和 contain 模拟小程序里边的 image 的 mode 属性 */
    background-repeat: no-repeat; /* 不让重复 */
    background-position: center center; /* 固定展示的位置 */
    background-image: url("../../assets/img/ag-share-back.png");
    .title {
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
      line-height: 33px;
    }
    .white-point {
      height: 10px;
      width: 10px;
      margin-left: 10px;
      background: #ffffff;
      border-radius: 50%;
    }
    .btnStyle {
      width: 101px;
      height: 32px;
      margin-left: 10px;
      opacity: 0.8;
      background: #FFFFFF;
      border-radius: 16px;
      font-size: 14px;
      font-weight: 600;
      color: @primary-color;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
    }
    .btnStyle:hover {
      opacity: 1;
    }
  }
  .ag-share-container {
    height: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    .ag-share-table-head {
      display: flex;
      flex-direction: row;
      background: #f5f5f5;
    }
    .ag-share-table-body {
      display: flex;
      flex-direction: row;
      overflow: scroll;
    }

    .ag-share-table-col-head {
      font-size: 14px;
      color: #333333;
      line-height: 22px;
      font-weight: 600;
      padding: 15px 0 15px 24px;
      border: 1px solid #FFFFFF;
    }
    .ag-share-table-col-body {
      div {
        cursor: pointer;
        font-size: 14px;
        color: #333333;
        line-height: 14px;
        font-weight: 400;
        padding: 21px 0 21px 24px;
        text-align: left;
        text-overflow: ellipsis;
        overflow-wrap: break-word;
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
        border-bottom: 1px solid #ebebeb;
        // .reserve {
        //   width: 56px;
        //   background: #4DAB6E;
        //   border-radius: 4px;
        //   font-size: 14px;
        //   font-weight: 400;
        //   color: #FFFFFF;
        //   line-height: 14px;
        //   border-bottom: 1px solid #EBEBEB;
        // }
      }
    }
  }
}
</style>
