<template>
  <div class="matrix-video">
    <div class="content" @click="showVideoPlayer(video)">
      <video
        :id="`video${video.id}`"
        webkit-playsinline="true"
        class="video-js"
        muted
        style="margin: auto"
      />
    </div>
  </div>
</template>
<script>
import { ossUrl } from '@/config/index'
import Videojs from 'video.js'
export default {
  props: {
    modalTraceData: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      player: undefined,
      videoList: [],
      video: { showVideo: false, cameraAddress: '', id: '' }
    }
  },
  mounted() {
    this.video.showVideo = false
    this.video.cameraAddress = ossUrl + this.modalTraceData
    this.video.id = 'videoFirst'
    this.showVideoPlayer(this.video)
  },
  destroyed() {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    showVideoPlayer(video) {
      this.video.showVideo = true
      // videoLink
      this.initVideo(video.cameraAddress, `video${video.id}`)
    },
    initVideo(videoUrl, id) {
      this.$nextTick(() => {
        // 这些options属性也可直接设置在video标签上，见 muted
        let options = {
          autoplay: true, // 设置自动播放
          controls: true, // 显示播放的控件
          isFullscreen: true,
          loop: true,
          sources: [
            {
              src: videoUrl,
              type: 'video/mp4'
            }
          ]
        }
        this.player = Videojs(id, options)
        // this.player.requestFullscreen()
      })
    }
  }
}
</script>
<style lang="less" scoped>
.matrix-video {
  padding-top: 20px;
  .video {
    margin-bottom: 12px;
    .title {
      width: 343px;
      margin: 0 auto 6px;
      font-size: 14px;
      color: #333333;
      font-weight: 500;
    }
    .content {
      width: 343px;
      // height: 193px;
      margin: auto;
      .video-js {
        width: 343px;
        height: 100% !important;
      }
      img {
        width: 343px;
        height: 193px;
      }
      .video-mask {
        width: 100%;
        height: 100%;
        position: absolute;
        margin-top: -193px;
        line-height: 193px;
        text-align: center;
        background: rgba(0, 0, 0, 0.46);
      }
    }
  }
}
</style>
