<template>
  <div class="my_supply_container mySupply_box">
    <div class="box_shadow_wrap">
      <div class="global_title">我的需求</div>
      <!-- 查询表单 start -->
      <a-form class="query_form" layout="inline">
        <a-form-item label="品类">
          <a-input v-model="category" placeholder="请输入品类" allow-clear />
        </a-form-item>
        <a-form-item label="品种">
          <a-input v-model="varieties" placeholder="请输入品种" allow-clear />
        </a-form-item>
        <a-form-item label="标题">
          <a-input v-model="title" placeholder="请输入标题" allow-clear />
        </a-form-item>
        <a-form-item label="创建日期">
          <a-range-picker v-model="dateString" @change="onChange" />
        </a-form-item>
        <a-form-item class="query_btn">
          <a-button type="primary" class="btn_lightgreen" icon="search" @click="getTabelData">
            查询
          </a-button>
          <a-button type="primary" style="margin-left: 10px" icon="redo" @click="resetForm">
            重置
          </a-button>
        </a-form-item>
      </a-form>
      <!-- 查询表单 end -->
    </div>
   
    <!-- 表格区域 start -->
    <div class="box_shadow_wrap tabs_wrap">
      <div>
        <a-button type="primary" @click="editById('add')"><a-icon type="plus" />我要发布</a-button>
        <a-divider />
        <a-tabs :tabBarGutter="0" :animated="false" v-model="tabIndex" @change="callback">
          <a-tab-pane
            :key="index"
            :tab="`${item.name}(${countList[index]})`"
            v-for="(item, index) in tabs"
          >
            <!-- 表格 start -->
            <a-table :columns="columns" :data-source="tabelData" row-key="id" :customRow="Rowclick" :scroll="{ x: 1300, y: 260 }" :pagination="false" :loading="isLoading">
              <template slot="handle" slot-scope="handle">
                <a-space>
                  <template v-if="tabIndex == 0">
                    <span class="color_blue pointer" @click.stop="outById(handle.id)">下架</span>
                  </template>
                  <template v-if="tabIndex == 1">
                    <span class="color_blue pointer" @click.stop="editById('edit', handle.id)">编辑</span>
                    <span class="color_red pointer" @click.stop="putawayById(handle.id)">重新上架</span>
                  </template>
                  <template v-if="tabIndex == 2">
                    <span class="color_red pointer" @click.stop="deleteById(handle.id)">删除</span>
                  </template>
                  <template v-if="tabIndex == 3">
                    <span class="color_blue pointer" @click.stop="editById('edit', handle.id)">编辑</span>
                    <span class="color_red pointer" @click.stop="putawayById(handle.id)">重新上架</span>
                    <span class="color_red pointer" @click.stop="deleteById(handle.id)">删除</span>
                  </template>
                </a-space>
              </template>
            </a-table>
            <!-- 表格 end -->
          </a-tab-pane>
        </a-tabs>
      </div>
      <!-- 分页 -->
      <div class="pagination_wrap">
        <a-space>
          <a-pagination 
          show-quick-jumper 
          show-size-changer
          :total="total" 
          :show-total="total => `共有 ${total} 条`"
          :page-size-options="pageSizeOptions"
          :page-size="size"
          v-model="page"
          @showSizeChange="onShowSizeChange"
          @change="changePage">
            <template slot="buildOptionText" slot-scope="props">
              <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
              <span v-if="props.value === '50'">全部</span>
            </template>
          </a-pagination>
          <a-button>确定</a-button>
        </a-space>
      </div>
    </div>
    <!-- 表格区域 end -->
    
  </div>
</template>
<script>
import { mapState } from 'vuex'

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    customRender: (text, row, index) => {
      //  this.pagination ? `${(this.pagination.defaultCurrent-1)*(this.pagination.defaultPageSize)+index+1}` :index+1
      return index + 1;
    }
  },
  {
    title: '品类',
    dataIndex: 'demandCategory',
    key: 'demandCategory',
    ellipsis: true
  },
  {
    title: '品种',
    dataIndex: 'demandVarieties',
    key: 'demandVarieties',
  },
  {
    title: '标题',
    key: 'demandTitle',
    dataIndex: 'demandTitle',
  },
  {
    title: '价格',
    key: 'price',
    dataIndex: 'price',
    ellipsis: true,
  },
  {
    title: '创建时间',
    key: 'createTime',
    dataIndex: 'createTime',
  },
  {
    title: '浏览量',
    key: 'views',
    dataIndex: 'views',
  },
  {
    title: '收藏量',
    key: 'collection',
    dataIndex: 'collection',
  },
  {
    title: '操作',
    key: 'handle',
    dataIndex: 'handle',
    align: 'center',
    scopedSlots: { customRender: 'handle' },
    fixed: 'right'
  }
];
export default {
  data() {
    return {
    //   tabs: ["已发布", "已下架"],
      tabs: [
        {
            type: 1,
            name: '已发布',
            total: 0
        },
        {
            type: '2,3',
            name: '已下架',
            total: 0
        },
        
        {
            type: 4,
            name: '待上架',
            total: 0
        },
        {
            type: 5,
            name: '被驳回',
            total: 0
        }
      ],
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      countList: [0, 0],
      tabIndex: 0,
      currentTab:1,
      page: 1,
      size: 10,
      total: 0,
      category: '',
      varieties: '',
      title: '',
      createTime: '',
      dateString: [],
      columns,
      isLoading: false,
      tabelData: []
    };
  },
  computed: {
    ...mapState(['userInfo'])
  },
  created() {
    this.getDemandCount()
    this.getDemandListByFormData()
  },
  mounted() {
    
  },
  methods: {
    // 查询表格数据
    getTabelData() {
      this.getDemandCount()
      this.getDemandListByFormData()
    },
    // 选择日期
    onChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.createTime = dateString.join('~')
      } else {
        this.createTime = ''
      }
    },
    // 切换tab
    callback(key) {
      this.currentTab = this.tabs[key].type;
      const findIndex = this.columns.findIndex(item => item.title === '驳回原因');
        if(key === 3 && findIndex <= -1){
            this.columns.splice(this.columns.length-1, 0, {
                title: '驳回原因',
                key: 'auditOpinion',
                dataIndex: 'auditOpinion',
                ellipsis: true,
                width: 160
            })
        } else {
            if(findIndex>-1){
                this.columns.splice(findIndex, 1)
            }
            
        }
      this.getDemandCount()
      this.getDemandListByFormData()
    },
    // 分页
    changePage(page, pageSize) {
      this.page = page
      this.size = pageSize
      this.getDemandListByFormData()
    },
    // 每页数量
    onShowSizeChange(current, pageSize) {
      this.page = current
      this.size = pageSize
      this.getDemandListByFormData()
    },
    // 获取上下架数量
    async getDemandCount() {
      try {
        this.isLoading = true
        const { data: { code, msg, body } } = await this.$axios.getDemandCount({
          body: {
            releaseUserId: this.userInfo.id,
            demandCategory: this.category.trim() ? `*${this.category}*` : '',
            demandVarieties: this.varieties.trim() ? `*${this.varieties}*` : '',
            demandTitle: this.title.trim() ? `*${this.title}*` : '',
            createTimeQueryCount: this.createTime
          }
        })
        this.isLoading = false
        if (code != 0) return this.$message.error(msg)
        this.countList = body
      } catch (error) {
        this.isLoading = false
      }
    },
    // 获取表格数据
    async getDemandListByFormData() {
      try {
        this.isLoading = true
        const { data: { code, msg, body } } = await this.$axios.getDemandList({
          body: {
            pageNum: this.page,
            pageSize: this.size,
            params: {
              releaseUserId: this.userInfo.id, //当前登录用户id
              demandCategory: this.category,
              demandVarieties: this.varieties,
              demandTitle: this.title,
              shelvesStatus: this.currentTab,
              releaseTime: '',
              views: '',
              createTime: this.createTime
            }
          }
        })
        this.isLoading = false
        if (code != 0) return this.$message.error(msg)
        const arr = body.dataList || []
        this.total = Number(body.totalCount)
        this.tabelData = arr.map(item => {
          item.handle = item
          return item
        })
      } catch (error) {
        this.isLoading = false
      }
    },
    // 发布、编辑
    editById(path, id = 0) {
      this.$router.push({ path: `/personalCenter/myNeedsEdit/${path}/${id}` })
    },
    // 下架
    async outById(id) {
      try {
        this.isLoading = true
        const { data: { code, msg } } = await this.$axios.outDemand({
          body: {
            id,
            shelvesStatus: 2 //1、上架，2、下架，3、强制下架
          }
        })
        this.isLoading = false
        if (code != 0) return this.$message.error(msg)
        this.$message.success('下架成功')
        await this.getDemandCount()
        this.getDemandListByFormData()
      } catch (error) {
        this.isLoading = false
      }
    },
    // 上架
    async putawayById(id) {
      try {
        this.isLoading = true
        const { data: { code, msg } } = await this.$axios.putawayDemand({
          body: {
            id,
            shelvesStatus: 1 //1、上架，2、下架，3、强制下架
          }
        })
        this.isLoading = false
        if (code != 0) return this.$message.error(msg)
        this.$message.success('发布成功')
        await this.getDemandCount()
        this.getDemandListByFormData()
      } catch (error) {
        this.isLoading = false
      }
    },
    // 删除
    deleteById(id) {
      this.$confirm({
        title: '确定删除吗?',
        confirmLoading: true,
        onOk: async () => {
          this.isLoading = true
          const { data: { code, msg } } = await this.$axios.deleteDemand({ body: { id } })
          this.isLoading = false
          if (code != 0) return this.$message.error(msg)
          this.$message.success('删除成功')
          await this.getDemandCount()
          this.getDemandListByFormData()
        },
        onCancel: () => {
          this.isLoading = false
        }
      });
    },
    Rowclick(record) {
      return {
        on: {
          click: () => {
            this.$router.push(`/npyx/sdDetail/d/${record.id}`)
          }
        }
      }
    },
    // 重置
    resetForm() {
      this.category = ''
      this.varieties = ''
      this.title = ''
      this.createTime = ''
      this.dateString = []
      this.getTabelData()
    }
  }
};
</script>
<style lang="less" scoped>
@import '../../../styles/pages/personalcenter.less';
.my_supply_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 2px;
  .tabs_wrap {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 16px;
  }
}
.pagination_wrap {
  display: flex;
  justify-content: flex-end;
}
/deep/ .ant-table-tbody {
  cursor: pointer;
}
</style>