import Hall from '@/pages/SupplyDemand/hall/index.vue'
import SDetail from '@/pages/SupplyDemand/detail/sdDetail.vue'
import Layout from '@/layouts/BasicLayout/index.vue'

export default [
    {
        path: '/npyx',
        name: 'npyx',
        redirect: '/npyx/index',
        component: Layout,
        meta: {
          title: '供求大厅',
          basePath: 'npyx'
        },
        children: [
          {
            path: 'index',
            component: Hall,
            name: '',
            meta: {
              basePath: 'npyx',
              title: '供求大厅'
            }
          },
          {
            path: 'sdDetail/:type/:id',
            component: SDetail,
            name: '',
            meta: {
              basePath: 'npyx',
              title: '需求大厅详情'
            }
          }
        ]
      },
]