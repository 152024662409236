<template>
  <div class="homepage">
    About page
  </div>
</template>
<script>
export default {
  methods: {

  }
}
</script>