<template>
  <a-steps :current="0" size="small" style="width:60%">
    <a-step
      v-for="(item, index) in stepsData.slice(0, 3)"
      :key="index"
      :title="item.title"
    />
  </a-steps>
</template>

<script>
export default {
  name: "Steps",
  data() {
    return {
      stepsData: [
        {
          title: "提交认证",
        },
        {
          title: "认证审核",
        },
        {
          title: "认证完成",
        },
      ],
    };
  },
};
</script>