<template>
  <div class="demandHall">
      <div class="filter-panel">
          <div class="hotmen">
              <span style="margin-right: 22px;font-weight:600"><img style="width: 14px; height: 14px;" :src="hotImg"/>热门：</span>
              <span style="margin-right: 50px;font-weight:600" v-for="(hot) in hots" :key="hot.varietyId" @click="selectHot(hot)">
                  {{ hot.varietyName }}
              </span>
          </div>
          <div :class="moreFlag ? 'categoryMore' : 'category'">
            <span class="label">所有品类：</span>
            <div ref="categorys" class="cateLines">
              <div
                :class="categoryObject('')"
                @click="selectChild('demandCategory', '')"
              >
                全部
              </div>
              <template v-for="(item, idx) in category">
                  <div
                      :class="categoryObject(item.cateName)"
                      @click="selectChild('demandCategory', item)"
                      :key="idx"
                  >
                      {{ item.cateName }}
                  </div>
              </template>
            </div>
            <!-- <span v-if="showMoreFlag" style="width: 5%;display: inline-block;position: absolute;margin-top: 5px;right: 60px;color: #20BF8E; cursor: pointer;" @click="showMore">更多<a-icon :type="moreFlag ? 'up' : 'down'" /></span> -->
          </div>
          <div class="category">
            <span class="label">所有品种：</span>
            <template v-for="(item, idx) in varieties">
                <div
                    :class="varietiesObject(item.cateName)"
                    @click="selectChild('demandVarieties', item)"
                    :key="idx"
                >
                    {{ item.cateName }}
                </div>
            </template>
          </div>
      </div>
      <div class="demandTable">
        <template v-if="tableConfig.data.length > 0">
          <div style="border-bottom: 1px solid #EBEBEB;margin-top: 20px;margin-bottom: 20px;">
            <template v-for="(obj, idx) in orders">
              <div :key="idx" :class="{'filterBtn': true, 'btnActive': orderActive === idx }" @click="selectOrder(idx)">
                {{ obj.text }}
                <img v-if="ordersValue.slice(0, 1) === '+' && orderActive === idx" :src="upArrowWhite"/>
                <img v-else-if="ordersValue.slice(0, 1) === '-' && orderActive === idx" :src="downArrowWhite"/>
                <img v-else :src="downArrowBlack"/>
              </div>
            </template>
          </div>
          <!-- <div style="border-bottom: 1px solid #EBEBEB;margin-top: 20px;margin-bottom: 20px;">
            <template v-for="(obj, idx) in orders">
              <div :key="idx" :class="{'filterBtn': true, 'btnActive': orderActive === idx }" @click="selectOrder(idx)">
                {{ obj.text }}
                <img v-if="ordersValue.slice(0, 1) === '+' && orderActive === idx" :src="upArrowWhite"/>
                <img v-else-if="ordersValue.slice(0, 1) === '-' && orderActive === idx" :src="downArrowWhite"/>
                <img v-else :src="downArrowBlack"/>
              </div>
            </template>
          </div> -->
          <a-table
              style="margin: 0 26px;cursor: pointer;"
              rowKey="id"
              :columns="tableConfig.columns"
              :customRow="rowclick"
              :data-source="tableConfig.data"
              :pagination="tableConfig.pagination"
              :loading="tableConfig.loading"
              :scroll="{ x: 568 }"
              @change="handleTableChange"
            >
              <template slot="num" slot-scope="text, record, index">
                {{
                  (tableConfig.pagination.current - 1) * tableConfig.pagination.pageSize + parseInt(index) + 1
                }}
              </template>
              <template slot="demandTitle" slot-scope="text">
                <div v-html="formatHtml(text)"></div>
              </template>
              <template slot="price" slot-scope="text">
                <div style="color: #FF5001;">{{ text ? `${parseFloat(text).toFixed(2)}元` : '当面议价' }}</div>
              </template>
          </a-table>
        </template>
        <template v-else>
          <div style="text-align: center;margin-top: 75px;">
            <div style="font-size: 12px;font-weight: 500;color: #333333;line-height: 18px;">啊哦，搜索为空！</div>
            <div style="font-size: 9px;font-weight: 400;color: #555555;line-height: 15px;margin-top: 6px;">您可以尝试搜索其他的类目哦！</div>
            <img style="width: 300px; height: 300px;" :src="emptyImg"/>
          </div>
        </template>
        
         
      </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
var timer = null
export default {
name: "DemandHall",
components: {
},
data () {
return {
    emptyImg: require('@/assets/img/sdEmpty.png'),
    varieties: [],
    queryForm: {
        demandCategory: '',
        demandVarieties: '',
        demandTitle: ''
    },
    orders: [
      {
        text: '发布时间',
        value: '-createTime'
      },
      {
        text: '关注度',
        value: '-views'
      },
      {
        text: '需求时间',
        value: '-releaseTime'
      },
    ],
    orderActive: 0,
    ordersValue: '-createTime',
    moreFlag: false,
    showMoreFlag: false,
    tableConfig: {
        columns: [
          {
            title: '序号',
            dataIndex: 'num',
            key: 'num',
            width: 60,
            align: 'center',
            scopedSlots: { customRender: 'num' }
          },
          {
            dataIndex: 'demandCategory',
            key: 'demandCategory',
            title: '品类',
            ellipsis: true,
            width: 100
          },
          {
            dataIndex: 'demandVarieties',
            key: 'demandVarieties',
            title: '品种',
            ellipsis: true,
            width: 100
          },
          {
            dataIndex: 'demandTitle',
            key: 'demandTitle',
            title: '需求',
            ellipsis: true,
            width: 120
          },
          {
            dataIndex: 'price',
            key: 'price',
            title: '价格',
            ellipsis: true,
            width: 120,
            scopedSlots: { customRender: 'price' }
          },
          {
            dataIndex: 'contactName',
            key: 'contactName',
            title: '联系人',
            ellipsis: true,
            width: 100
          },
          {
            dataIndex: 'releaseTime',
            key: 'releaseTime',
            title: '需求时间',
            ellipsis: true,
            width: 120
          },
          {
            dataIndex: 'createTime',
            key: 'createTime',
            title: '发布时间',
            ellipsis: true,
            width: 120
          },
        ],
        data: [],
        pagination: {
            current: 1,
            defaultCurrent: 1,
            pageSize: 10,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            defaultPageSize: 10,
            total: 0,
            showTotal: total => `共有${total}条`,
            showQuickJumper: true,
            showSizeChanger: true,
            onChange: current => {
              this.tableConfig.pagination.current = current
              this.search()
            },
            onShowSizeChange: (current, pageSize) => {
              this.tableConfig.pagination.current = current
              this.tableConfig.pagination.pageSize = pageSize
              this.search()
            }
        },
        loading: false, // table loading
    },
    downArrowBlack: require('@/assets/img/downArrowBlack.png'),
    upArrowWhite: require('@/assets/img/upArrowWhite.png'),
    downArrowWhite: require('@/assets/img/downArrowWhite.png'),
    upArrowBlack: require('@/assets/img/upArrowBlack.png'),
    hotImg: require('@/assets/img/hotmen.png'),
}
},
props: {
  preData: {
    type: Object,
    default: () => ({
      hots: [],
      category: [],
      searchTitle: ''
    })
  }
},
watch: {
    'preData.category': function (val) {
        console.log('watch c', val);
        // if (val.length > 0) {
        //   const { id, cateName } = val[0]
        //   this.queryForm.demandCategory = cateName
        //   this.queryVarietyById(id)
        // }
    },
    queryForm: {
        handler(newVal){
            this.search()
        },
        deep:true
    },
    searchData(newVal) {
        console.log('newVal', newVal)
        if(newVal.type === '供求大厅') {
            this.queryForm.demandTitle = newVal.title
            // this.search();
        }
    }
},
computed: {
    ...mapState(['searchData']),
    ...mapGetters(['userInfo']),
    hots() {
      return this.preData.hots || []
    },
    category() {
      return this.preData.category || []
    },
    categoryObject() {
      return (name) => {
        const flag = this.queryForm.demandCategory === name;
        return {
          unselect: true,
          select: flag,
        };
      };
    },
    varietiesObject() {
      return (name) => {
        const flag = this.queryForm.demandVarieties === name;
        return {
          unselect: true,
          select: flag,
        };
      };
    },
},
created () {
   console.log('demand created');
    if(this.searchData.type=== '供求大厅') {
        this.queryForm.demandTitle = this.searchData.title 
    }
   this.search() 
},
mounted () {
  timer = setInterval(() => {
    if (this.$refs.categorys && this.$refs.categorys.offsetHeight > 20) {
      this.showMoreFlag = true
      clearInterval(timer)
    } else {
      this.showMoreFlag = false
    }
  }, 500);
},
destroyed () {

},
methods: {
    selectHot({ categoryName, varietyName, categoryId }) {
      this.queryForm.demandCategory = categoryName
      this.queryForm.demandVarieties = varietyName
      this.queryVarietyById(categoryId)
    },
    selectChild(type, { cateName = '', id }) {
    //   this.queryForm.demandTitle = ''
      this.queryForm[type] = cateName
      if (type === 'demandCategory') {
        this.queryForm.demandVarieties = ''
        this.queryVarietyById(id)
      }
    },
    // 查询品种
    queryVarietyById(id) {
      if(!id) {
        this.varieties = []
        return 
      } 
      this.$axios.queryVarietyById(id).then(res => {
        console.log('queryVarietyById', res);
        if (res.data.code === '0') {
          this.varieties = [...res.data.body]
        }
      })
    },
    formatHtml(value) {
        value = value.replace(/<\/?[^>]*>/g,'')
        value = value.replace(/<\/?.+?>/g,'')
          value = value.replace(/(^\s+)|(\s+$)/g,"")
          return value;
    },
    showMore() {
        this.moreFlag = !this.moreFlag
    },
    selectOrder(idx) {
      this.orderActive = idx
      const symbol = this.ordersValue.slice(0, 1)
      const val = this.ordersValue.slice(1)
      const val1 = this.orders[idx].value.slice(1)
      if (val === val1) {
        symbol === '+' ? this.ordersValue = `-${val}` : this.ordersValue = `+${val}`
      } else {
        this.ordersValue = this.orders[idx].value
      }
      this.search()
    },
    search() {
      const { current, pageSize } = this.tableConfig.pagination
      const {  demandCategory, demandVarieties, demandTitle } = this.queryForm
      const param = {
        body: {
          pageNum: current,
          pageSize,
          orders: this.ordersValue,
          params: {
            demandTitle,
            demandCategory,
            demandVarieties,
            shelvesStatus: '1'
          }
        }
      }
      this.tableConfig.loading = true
      this.$axios.queryDemandHall(param).then(res => {
        console.log('queryDemandHall', res)
        this.tableConfig.loading = false
        this.tableConfig.data = []
        this.tableConfig.pagination.total = 0
        if (res.data.code == '0') {
          if (res.data.body.dataList) {
            res.data.body.dataList.forEach((obj, index) => {
              this.tableConfig.data[index] = { ...obj }
            })
            this.tableConfig.pagination.total = res.data.body.totalCount
              ? parseFloat(res.data.body.totalCount)
              : 0
          }
        }
      })
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination, filters, sorter)
    },
    rowclick({ id }) {
      return {
        on: {
          click: () => {
            this.$router.push(`/npyx/sdDetail/d/${id}`)
          }
        }
      };
    }
}
}
</script>

<style scoped lang="less">
.demandHall {
   .filter-panel {
			height: 180px;
			background: #FFFFFF;
			border-left: 1px solid #E2E2E2;
			border-right: 1px solid #E2E2E2;
			border-bottom: 1px solid #E2E2E2;
			width: 100%;
			padding: 0 60px 0 30px;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
      font-size: 14px;
      font-weight: 400;
      line-height: 15px;
			.hotmen {
				// font-size: 11px;
				font-weight: 400;
				color: #CC0200;
				line-height: 15px;
				span {
				cursor: pointer;
				}
			}
			.category {
				height: 20px;
        // overflow: hidden;
				.label {
					color: #888888;
					margin-right: 10px;
          font-size: 16px;
				}

        .cateLines {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          width: 89%;
          flex-direction: row;
          flex-wrap: wrap;
        }

				div {
					cursor: pointer;
					display: inline-block;
				}

					.unselect {
          padding: 2px 14px;
          margin-top: 5px;
					color: #333333;
          word-break: keep-all;
				}
				.select {
					color: #fff;
          background: #4DAB6E;
          border-radius: 11px;
				}
			}
      .categoryMore {
				height: auto;
        overflow: auto;
				.label {
					color: #888888;
					margin-right: 10px;
          font-size: 16px;
				}

        .cateLines {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          width: 89%;
          flex-direction: row;
          flex-wrap: wrap;
        }

				div {
					cursor: pointer;
					display: inline-block;
				}

				.unselect {
					color: #333333;
          margin-top: 5px;
				}
				.select {
					color: @primary-color;
				}
			}
		}
    .demandTable {

      .filterBtn {
        cursor: pointer;
        text-align: center;
        display: inline-block;
        height: 30px;
        line-height: 30px;
        background: #FFFFFF;
        border: 1px solid #EBEBEB;
        border-bottom: none;
        color: #333333;
        padding: 0 16px;
        img {
          width: 7px;
          height: 11px;
        }
      }
      .filterBtn + .filterBtn {
        border-left: none ;
      }
      .btnActive {
        background: @primary-color;
        border: 1px solid @primary-color;
        color: #FFFFFF;
      }
      // .filterBtn {
      //   cursor: pointer;
      //   text-align: center;
      //   display: inline-block;
      //   height: 40px;
      //   background: #FFFFFF;
      //   border: 1px solid #EBEBEB;
      //   font-size: 14px;
      //   font-weight: 400;
      //   color: #333333;
      //   line-height: 40px;
      //   padding: 0 16px;
      //   img {
      //     width: 7px;
      //     height: 11px;
      //   }
      // }
      // .btnActive {
      //   background: @primary-color;
      //   border: 1px solid #EBEBEB;
      //   color: #FFFFFF;
      // }

      /deep/ .ant-table-wrapper {
        margin: 0!important;
      }
    }
}
</style>
