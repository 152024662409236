<template>
  <div class="matrix-video">
    <div class="content">
      <div v-for="(video, index) in videoList" :key="index" class="play_icon" @click="initVideo(video)">
        <img src="@/assets/img/companyVideoUrl.png" width="120">
      </div>
    </div>
    <a-modal v-model="visible" :width="680" :footer="null" destroy-on-close @cancel="cancelVideo">
      <div v-if="visible">
        <video id="example-video" height="300" class="video-js vjs-default-skin matrix_video" controls>
          <source :src="videoUrl" type="application/x-mpegURL">
        </video>
      </div>
    </a-modal>
  </div>
</template>
<script>
import Videojs from 'video.js'
export default {
  props: {
    modalTraceData: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      videoList: [],
      videoUrl: '',
      visible: false
    }
  },
  created() {
    this.videoList = this.modalTraceData[0]?.cameraAddress || []
  },
  methods: {
    initVideo(video) {
      console.log(video.address)
      this.videoUrl = video.address || ''
      if (this.videoUrl == '') return this.$message.warning('暂无视频')
      this.visible = true
      this.$nextTick(() => {
        this.player = Videojs('example-video')
        this.player.play() // 播放
      })
    },
    cancelVideo() {
      this.player.dispose()
    }
  }
}
</script>
<style lang="less" scoped>
.matrix-video {
  padding-top: 70px;
  min-height: 100vh;
  overflow-y: auto;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    .play_icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 343px;
      height: 193px;
      margin-top: 10px;
      background-color: #e0f6ef;
    }
  }
}
.matrix_video {
  width: calc(100% - 32px);
}
</style>
