/**
 * ApiUrl 后台接口域名
 * ossUrl oss文件域名
 */
 export default {
    dev: {
      ApiUrl: '/10004', // 10004端口请求
      OutUrl: '/10016', // 10016端口请求
      syUrl: 'http://qy.szprod.iot-farm.cn:8888', // 嵌入溯源页面url
      ossUrl: 'http://srm.szprod.iot-farm.cn:8888/oss/', //'http://123.57.67.20:10001'
      key: 'DKIBZ-FOXWU-PYJVZ-43SE3-MPRRQ-VBB6U'
    },
    test: {
      ApiUrl: '/gateway',
      OutUrl: 'https://srm.sz.iot-farm.cn/gateway',
      syUrl: 'https://qy.sz.iot-farm.cn', // 嵌入溯源页面url
      ossUrl: 'https://suzhou-test.oss-cn-shanghai.aliyuncs.com/',
      key: 'DKIBZ-FOXWU-PYJVZ-43SE3-MPRRQ-VBB6U'
    },
    pro: {
      ApiUrl: '/gateway',
      OutUrl: 'http://srm.szprod.iot-farm.cn:8888/gateway',
      ossUrl: 'http://srm.szprod.iot-farm.cn:8888/oss/',
      syUrl: 'http://qy.szprod.iot-farm.cn:8888', // 嵌入溯源页面url
      key: 'DKIBZ-FOXWU-PYJVZ-43SE3-MPRRQ-VBB6U'
    }
  }
  