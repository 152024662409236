<template>
  <div style="position: relative;">
    <div class="go-back" @click="goBack">
      <div class="icon">
        <a-icon type="left" />
      </div>
      <div class="back-name">后退</div>
    </div>
    <div class="table">
      <a-table
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        :pagination="pagination"
        size="middle"
        row-key="id"
      > 
        <template slot="traceStatus" slot-scope="scope">
          <a-tag v-if="scope.traceStatus === -1" color="red">上链失败</a-tag>
          <a-tag v-else-if="scope.traceStatus === 1" color="cyan">已上链</a-tag>
          <a-tag v-else-if="scope.traceStatus === 2" color="blue">上链中</a-tag>
          <a-tag v-else color="orange">未上链</a-tag>
        </template>
        <template slot="action" slot-scope="scope">
          
            <a-button type="link" style="color: #1869FF" @click="viewSuYuan(scope.id)">溯源信息</a-button>
        </template>
      </a-table>
      <suYuanModal ref="suYuanModal" />
    </div>
  </div>
</template>
<script>
import suYuanModal from './suYuanModal.vue'
export default {
  components: { suYuanModal },
  data() {
    return {
      columns: [
        {
          title: "商品名称",
          dataIndex: "goodsName",
          align: 'left',
          ellipsis: true,
        },
        {
          title: "生产批次",
          align: 'left',
          dataIndex: "batchNo",
        },
        {
          title: "商品数量",
          align: 'left',
          width: 100,
          dataIndex: "goodCount",
        },
        {
          title: "生产日期",
          align: 'left',
          width: 150,
          dataIndex: 'productionDate'
        },
        {
          title: "上链时间",
          align: 'left',
          width: 200,
          dataIndex: "traceTime",
        },
        {
          title: "操作",
          width: 120,
          fixed: "right",
          align: 'center',
          scopedSlots: { customRender: "action" }
        },
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "20", "30", "50"], // 这里注意只能是字符串，不能是数字
        showTotal: (total) => `共有 ${total}条`,
        onChange: (current) => {
          this.pagination.current = current;
          this.getMerchantSuYuanList()
        },
        onShowSizeChange: (current, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getMerchantSuYuanList()
        },
      },
      loading: false,
      tableData: [],
      searchName: '',
      id: ''
    };
  },
  mounted() {
    this.id = this.$route.params.id
    this.getMerchantSuYuanList()
  },
  methods: {
    goBack() {
      this.$router.push({
        path: '/blockchain/merchant'
      })
    },
    viewSuYuan(id) {
      this.$refs['suYuanModal'].showSuYuan(id)
    },
    async getMerchantSuYuanList() {
      const params = {
        body: {
          pageNum: this.pagination.current,
          pageSize: this.pagination.pageSize,
          params: {
            enterpriseId: parseInt(this.id)
          }
        }
      }
      this.loading = true
      this.$axios.merchantSuYuanList(params).then(res => {
        this.loading = false
        const { dataList, totalCount } = res.data.body
        this.tableData = []
        this.pagination.total = 0
        if (res.data.code === '0') {
            this.tableData = [...dataList]
            this.pagination.total = totalCount ? parseFloat(totalCount) : 0
          }
        })
    }
  }
};
</script>
<style lang="less" scoped>
.go-back {
    position: absolute;
    top: -30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .icon {
      width: 20px;
      height: 20px;
      background: #EFEFEF;
      line-height: 20px;
      border: 1px solid #E2E2E2;
      font-size: 12px;
      text-align: center;
      color: #717171;
    }
    .back-name {
      font-size: 10px;
      font-weight: 400;
      color: #898989;
      margin-left: 5px;
    }
  }
.table {
  /deep/ .ant-table {
    .ant-table-column-title {
      font-weight: 500;
    }
  }
}
</style>