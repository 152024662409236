<template>
  <div class="blockchain">
    <div class="breadcrumb">
      <a-breadcrumb>
        <a-breadcrumb-separator style="color: #333333"
          >当前位置：</a-breadcrumb-separator
        >
        <a-breadcrumb-item
          ><a href="/blockchain">区块链溯源</a></a-breadcrumb-item
        >
        <a-breadcrumb-item
          ><span
            @click="typeClick"
            :style="{ color: '#333333', cursor: 'pointer' }"
            >{{ newsType }}</span
          ></a-breadcrumb-item
        >
      </a-breadcrumb>
    </div>
    <div class="blockchain-center-container">
      <div class="blockchain-left">
        <a-menu
          class="menuStyle"
          :selectedKeys="[onRoutes]"
          mode="inline"
          :style="{ backgroundColor: '#F5F5F5' }"
        >
          <template v-for="item in menus">
            <template>
              <a-menu-item :key="resolvePath(item.path)" @click="menuClick">
                <span>{{ item.title }}</span>
              </a-menu-item>
            </template>
          </template>
        </a-menu>
      </div>
      <div class="blockchain-right">
        <router-view :key="$route.path" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsType: undefined,
      menus: [
        {
          path: 'inquire',
          title: '查询'
        },
        {
          path: 'merchant',
          title: '企业'
        },
        {
          path: 'statistics',
          title: '统计'
        }
      ],
      onRoutes: `/blockchain/inquire`
    }
  },
  mounted() {
    this.$router.push({
      path: this.onRoutes
    })
    this.newsType = this.menus[0].title
  },
  methods: {
    typeClick() {
      this.$router.push({
        path: this.onRoutes
      })
    },
    resolvePath(path) {
      return `/blockchain/${path}`
    },
    menuClick({ item, key }) {
      this.newsType = this.menus[item.index].title
      this.$router.push({
        path: key
      })
      this.onRoutes = key
    },
  }
}
</script>
<style scoped lang="less">
@import url("../../styles/pages/blockchain.less");
</style>