import TMap from 'TMap'

// 乡镇标记覆盖物
export class TownMark extends TMap.DOMOverlay {
  constructor(options) {
    super(options) // 调用 super() 代替父类构造函数,初始化与父类共同的属性name
  }
  // 初始化
  onInit({ name, count = '0台', position } = {}) {
    Object.assign(this, {
      name,
      count,
      position
    })
  }
  // 销毁时需解绑事件监听
  onDestroy() {
    if (this.onClick) {
      this.dom.removeEventListener(this.onClick)
    }
  }
  // 创建DOM元素，返回一个DOMElement，使用this.dom可以获取到这个元素
  createDOM() {
    var wrapper = document.createElement('div')
    wrapper.style.cssText = `width: 107px; height: 110px;position: absolute; text-align: center; top:0px;left:0px; line-height: 20px;font-size: 14px; background-repeat: no-repeat; background-size: cover;background-image: url(${require('@/assets/img/townMarker.png')})`
    var message = document.createElement('div')
    message.style.cssText = 'display: inline-block;margin-top: 5px;margin-left: 16px;'
    var message1 = document.createElement('span')
    message1.innerText = this.name
    message1.style.cssText = 'display: block;font-size: 14px; color: #FFFFFF'
    var message2 = document.createElement('span')
    message2.innerText = this.count
    message2.style.cssText = 'display: block;font-size: 14px; color: #FFFFFF'
    message.appendChild(message1)
    message.appendChild(message2)
    wrapper.appendChild(message)

    // wrapper.appendChild(wrapper)
    // click事件回调
    this.onClick = () => {
      // DOMOverlay继承自EventEmitter，可以使用emit触发事件
      this.emit('click')
    }
    // 使用addEventListener实现DOM元素的click监听
    wrapper.addEventListener('click', this.onClick)
    return wrapper
  }
  // 更新DOM元素，在地图移动/缩放后执行
  updateDOM() {
    if (!this.map) {
      return
    }
    // 经纬度坐标转容器像素坐标
    let pixel = this.map.projectToContainer(this.position)

    // 使饼图中心点对齐经纬度坐标点
    let left = pixel.getX() - this.dom.clientWidth / 2 + 'px'
    let top = pixel.getY() - this.dom.clientHeight / 2 + 'px'
    this.dom.style.transform = `translate(${left}, ${top})`
  }
}

// 农机标记覆盖物
export class CarMark extends TMap.DOMOverlay {
  constructor(options) {
    super(options) // 调用 super() 代替父类构造函数,初始化与父类共同的属性name
  }
  // 初始化
  onInit({ position, data = {} } = {}) {
    Object.assign(this, {
      position,
      data
    })
  }
  // 销毁时需解绑事件监听
  onDestroy() {
    if (this.onClick) {
      this.dom.removeEventListener(this.onClick)
    }
  }
  // 创建DOM元素，返回一个DOMElement，使用this.dom可以获取到这个元素
  createDOM() {
    var wrapper = document.createElement('div')
    wrapper.style.cssText = 'width: 32px; height: 32px; text-align: center; position:absolute;top:0px;left:0px;'
    var img = document.createElement('img')
    img.src = require(`@/assets/img/car.png`)
    img.style.cssText = 'width: 30px; height: 30px;'
    wrapper.appendChild(img)
    // click事件回调
    this.onClick = () => {
      // DOMOverlay继承自EventEmitter，可以使用emit触发事件
      this.emit('click')
    }
    // 使用addEventListener实现DOM元素的click监听
    wrapper.addEventListener('click', this.onClick)
    return wrapper
  }

  // 更新DOM元素，在地图移动/缩放后执行
  updateDOM() {
    if (!this.map) {
      return
    }
    // 经纬度坐标转容器像素坐标
    let pixel = this.map.projectToContainer(this.position)

    // 使饼图中心点对齐经纬度坐标点
    let left = pixel.getX() - this.dom.clientWidth / 2 + 'px'
    let top = pixel.getY() - this.dom.clientHeight / 2 + 'px'
    this.dom.style.transform = `translate(${left}, ${top})`
  }
}

// 收割机状态标记覆盖物
export class TipMark extends TMap.DOMOverlay {
  constructor(options) {
    super(options)
  }

  onInit({ position, modalShow = false, data = {} } = {}) {
    Object.assign(this, {
      position,
      modalShow,
      data
    })
  }

  // 销毁时需解绑事件监听
  onDestroy() {
    if (this.onClick) {
      this.dom.removeEventListener(this.onClick)
    }
  }

  // 创建DOM元素，返回一个DOMElement，使用this.dom可以获取到这个元素
  createDOM() {
    console.log('createDOM', this)
    const { distance = '0', businessName = '', format_workPrice = ''} = this.data
    const avator = this.data.modelImgUrl ? this.data.modelImgUrl.split(',').filter(ele => ele !== '')[0] : ''
    var wrapper = document.createElement('div')
    wrapper.style.cssText =
      'text-align: center; position:absolute;top: -60px;left:0px;padding: 8px;z-index: 1100;width: 283px;height: 80px;background: #FFFFFF;box-shadow: 0px 0px 5px 5px rgba(210, 210, 210, 0.18);border-radius: 10px;'
    var head = document.createElement('div')
    head.style.cssText = 'position:relative;overflow: auto;'
    wrapper.appendChild(head)
    var header = document.createElement('div')
    header.style.cssText = 'display: flex;margin-top: 3px;margin-left: 17px;'

    header.innerHTML = `<div style="border-radius: 26px;width:52px;height:52px; background: url(${avator}) no-repeat center;background-size:100% 100%"></div><div style="display: inline-block;padding: 7px 0;margin-left: 14px;width: calc(100% - 52px);text-align: left;"><span style="display: block;font-size: 14px;font-weight: 400;color: #262626;line-height: 20px;display: inline-block;text-align: left;">${businessName}</span><span style="display: block;font-size: 12px;font-weight: 400;color: #A0A0A0;line-height: 18px;display: inline-block;">距离您${distance}米&nbsp;&nbsp;${format_workPrice}</span></div>`
    wrapper.appendChild(header)
    // click事件回调
    this.onClick = () => {
      this.modalShow = !this.modalShow
      wrapper.style.display = this.modalShow ? 'none' : 'block'
      // DOMOverlay继承自EventEmitter，可以使用emit触发事件
      this.emit('click')
    }
    // 使用addEventListener实现DOM元素的click监听
    wrapper.addEventListener('click', this.onClick)
    return wrapper
  }
  // 更新DOM元素，在地图移动/缩放后执行
  updateDOM() {
    if (!this.map) {
      return
    }
    // 经纬度坐标转容器像素坐标
    let pixel = this.map.projectToContainer(this.position)

    // 使饼图中心点对齐经纬度坐标点
    let left = pixel.getX() - this.dom.clientWidth / 2 + 'px'
    let top = pixel.getY() - this.dom.clientHeight / 2 + 'px'
    this.dom.style.transform = `translate(${left}, ${top})`
  }
}

// 历史轨迹P点覆盖物
export class PMark extends TMap.DOMOverlay {
  constructor(options) {
    super(options)
  }

  onInit({ position, modalShow = false, data = {}, trackObj = {} } = {}) {
    Object.assign(this, {
      position,
      modalShow,
      data,
      trackObj
    })
  }

  onDestroy() {
    if (this.onClick) {
      this.dom.removeEventListener(this.onClick)
    }
  }

  createDOM() {
    const winds = {
      0: '北风',
      90: '东风',
      180: '南风',
      270: '西风'
    }
    const { address = '', locateTime = '', machinistName = '', machinistPhone = '', speed = 0 } = this.trackObj
    let direction = ''
    if (winds[`${this.trackObj.direction}`]) {
      direction = winds[`${this.trackObj.direction}`]
    } else if (this.trackObj.direction > 0 && this.trackObj.direction < 90) {
      direction = '东北风'
    } else if (this.trackObj.direction > 90 && this.trackObj.direction < 180) {
      direction = '东南风'
    } else if (this.trackObj.direction > 180 && this.trackObj.direction < 270) {
      direction = '西南风'
    } else if (this.trackObj.direction > 270 && this.trackObj.direction < 360) {
      direction = '西北风'
    }
    const avator = this.trackObj.modelImgUrl ? this.trackObj.modelImgUrl.split(',').filter(ele => ele !== '')[0] : ''
    var wrapper = document.createElement('div')
    wrapper.style.cssText = 'width: 32px; height: 32px; text-align: center; position:absolute;top:0px;left:0px;'
    var img = document.createElement('img')
    img.src = require(`@/assets/img/locus.png`)
    img.style.cssText = 'width: 30px; height: 30px;'
    wrapper.appendChild(img)
    var empty = document.createElement('div')
    empty.style.cssText = 'position: relatvie'
    var modal = document.createElement('div')
    modal.style.cssText =
      'text-align: center; position:absolute;display: none; top: -205px;left:84px;padding: 8px;z-index: 1100;width: 242px;height: 240px;background: #FFFFFF;box-shadow: 0px 0px 3px 3px rgba(210, 210, 210, 0.18);border-radius: 5px;'
    var plate = document.createElement('div')
    plate.style.cssText = 'text-align: left;font-size: 14px;font-weight: 400;color: #262626;line-height: 20px;'
    plate.innerHTML = `${this.data.licensePlate}`
    modal.appendChild(plate)
    var header = document.createElement('div')
    header.style.cssText = 'display: flex;margin-top: 3px;margin-left: 17px;'
    header.innerHTML = `<div style="border-radius: 26px;width:52px;height:52px; background: url(${avator}) no-repeat center;background-size:100% 100%;"></div><div style="display: inline-block;padding: 7px 0;margin-left: 14px;width: calc(100% - 52px);text-align: left;"><span style="display: block;font-size: 14px;font-weight: 400;color: #262626;line-height: 20px;">机手${machinistName}${machinistPhone}</span><span style="display: block;font-size: 12px;font-weight: 400;color: #A0A0A0;line-height: 18px;">${direction}｜车速${speed}km/h</span></div>`
    modal.appendChild(header)
    var info = document.createElement('ul')
    info.style.cssText = 'margin-top: 12px;color: #20BF8E;'
    info.innerHTML = `<li><div style="color: #000;text-align: left;">${address}</div></li><li><div style="color: #000;text-align: left;">${locateTime}</div></li>`
    modal.appendChild(info)
    empty.appendChild(modal)
    wrapper.appendChild(empty)
    // click事件回调
    this.onClick = () => {
      this.modalShow = !this.modalShow
      modal.style.display = this.modalShow ? 'none' : 'block'
      // DOMOverlay继承自EventEmitter，可以使用emit触发事件
      this.emit('click')
    }
    // 使用addEventListener实现DOM元素的click监听
    wrapper.addEventListener('click', this.onClick)
    return wrapper
  }

  // 更新DOM元素，在地图移动/缩放后执行
  updateDOM() {
    if (!this.map) {
      return
    }
    // 经纬度坐标转容器像素坐标
    let pixel = this.map.projectToContainer(this.position)

    // 使饼图中心点对齐经纬度坐标点
    let left = pixel.getX() - this.dom.clientWidth / 2 + 'px'
    let top = pixel.getY() - this.dom.clientHeight / 2 + 'px'
    this.dom.style.transform = `translate(${left}, ${top})`
  }
}

