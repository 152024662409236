import { GET, POST, PUT, DELETE, ApiUrl } from '@/config/index'

// 分页查询跟进记录
const applyFollowRecordQueryByPage = POST(`${ApiUrl}/api/backapp/finance/applyFollowRecord/action/queryByPage`)
// 查询单个跟进记录
const getApplyFollowRecord = id => {
  return GET(`${ApiUrl}/api/backapp/finance/applyFollowRecord/${id}`)
}
// 删除跟进记录
const deleteApplyFollowRecord = id => {
  return DELETE(`${ApiUrl}/api/backapp/finance/applyFollowRecord/${id}`)
}
// 修改跟进记录
const applyFollowRecordModify = PUT(`${ApiUrl}/api/backapp/finance/applyFollowRecord/`)
// 新增跟进记录
const applyFollowRecordAdd = POST(`${ApiUrl}/api/backapp/finance/applyFollowRecord/`)
// 收藏列表
const getFavoritesList = POST(`${ApiUrl}/api/backapp/finance/favorites/action/getFavoritesList`)

export default {
  applyFollowRecordQueryByPage,
  getApplyFollowRecord,
  deleteApplyFollowRecord,
  applyFollowRecordModify,
  applyFollowRecordAdd,
  getFavoritesList
}
