const cityData = [
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '34',
            'label': '东城区',
            'value': '110101000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '229',
            'label': '西城区',
            'value': '110102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '504',
            'label': '朝阳区',
            'value': '110105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '1169',
            'label': '丰台区',
            'value': '110106000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '1590',
            'label': '石景山区',
            'value': '110107000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '1750',
            'label': '海淀区',
            'value': '110108000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '2444',
            'label': '门头沟区',
            'value': '110109000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '2759',
            'label': '房山区',
            'value': '110111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '3407',
            'label': '通州区',
            'value': '110112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '4031',
            'label': '顺义区',
            'value': '110113000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '4622',
            'label': '昌平区',
            'value': '110114000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '5185',
            'label': '大兴区',
            'value': '110115000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '5991',
            'label': '怀柔区',
            'value': '110116000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '6329',
            'label': '平谷区',
            'value': '110117000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '6665',
            'label': '密云区',
            'value': '110118000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '7116',
            'label': '延庆区',
            'value': '110119000000'
          }
        ],
        'id': '33',
        'label': '市辖区',
        'value': '110100000000'
      }
    ],
    'id': '2',
    'label': '北京',
    'value': '11'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '7559',
            'label': '和平区',
            'value': '120101000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '7630',
            'label': '河东区',
            'value': '120102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '7806',
            'label': '河西区',
            'value': '120103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '7970',
            'label': '南开区',
            'value': '120104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '8163',
            'label': '河北区',
            'value': '120105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '8290',
            'label': '红桥区',
            'value': '120106000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '8425',
            'label': '东丽区',
            'value': '120110000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '8587',
            'label': '西青区',
            'value': '120111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '8889',
            'label': '津南区',
            'value': '120112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '9181',
            'label': '北辰区',
            'value': '120113000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '9467',
            'label': '武清区',
            'value': '120114000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '10229',
            'label': '宝坻区',
            'value': '120115000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '11069',
            'label': '滨海新区',
            'value': '120116000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '11559',
            'label': '宁河区',
            'value': '120117000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '11894',
            'label': '静海区',
            'value': '120118000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '12356',
            'label': '蓟州区',
            'value': '120119000000'
          }
        ],
        'id': '7558',
        'label': '市辖区',
        'value': '120100000000'
      }
    ],
    'id': '3',
    'label': '天津',
    'value': '12'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '13367',
            'label': '长安区',
            'value': '130102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '13553',
            'label': '桥西区',
            'value': '130104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '13712',
            'label': '新华区',
            'value': '130105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '13844',
            'label': '井陉矿区',
            'value': '130107000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '13901',
            'label': '裕华区',
            'value': '130108000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '14020',
            'label': '藁城区',
            'value': '130109000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '14273',
            'label': '鹿泉区',
            'value': '130110000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '14519',
            'label': '栾城区',
            'value': '130111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '14704',
            'label': '井陉县',
            'value': '130121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '15044',
            'label': '正定县',
            'value': '130123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '15242',
            'label': '行唐县',
            'value': '130125000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '15589',
            'label': '灵寿县',
            'value': '130126000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '15889',
            'label': '高邑县',
            'value': '130127000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '16002',
            'label': '深泽县',
            'value': '130128000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '16135',
            'label': '赞皇县',
            'value': '130129000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '16360',
            'label': '无极县',
            'value': '130130000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '16589',
            'label': '平山县',
            'value': '130131000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '17330',
            'label': '元氏县',
            'value': '130132000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '17559',
            'label': '赵县',
            'value': '130133000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '17852',
            'label': '石家庄高新技术产业开发区',
            'value': '130171000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '17902',
            'label': '石家庄循环化工园区',
            'value': '130172000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '17917',
            'label': '辛集市',
            'value': '130181000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '18302',
            'label': '晋州市',
            'value': '130183000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '18537',
            'label': '新乐市',
            'value': '130184000000'
          }
        ],
        'id': '13366',
        'label': '石家庄市',
        'value': '130100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '18729',
            'label': '路南区',
            'value': '130202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '18900',
            'label': '路北区',
            'value': '130203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '19158',
            'label': '古冶区',
            'value': '130204000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '19371',
            'label': '开平区',
            'value': '130205000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '19560',
            'label': '丰南区',
            'value': '130207000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '20061',
            'label': '丰润区',
            'value': '130208000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '20622',
            'label': '曹妃甸区',
            'value': '130209000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '20793',
            'label': '滦南县',
            'value': '130224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '21420',
            'label': '乐亭县',
            'value': '130225000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '21924',
            'label': '迁西县',
            'value': '130227000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '22372',
            'label': '玉田县',
            'value': '130229000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '23167',
            'label': '河北唐山芦台经济开发区',
            'value': '130271000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '23201',
            'label': '唐山市汉沽管理区',
            'value': '130272000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '23241',
            'label': '唐山高新技术产业开发区',
            'value': '130273000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '23317',
            'label': '河北唐山海港经济开发区',
            'value': '130274000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '23387',
            'label': '遵化市',
            'value': '130281000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '24110',
            'label': '迁安市',
            'value': '130283000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '24716',
            'label': '滦州市',
            'value': '130284000000'
          }
        ],
        'id': '18728',
        'label': '唐山市',
        'value': '130200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '25262',
            'label': '海港区',
            'value': '130302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '25660',
            'label': '山海关区',
            'value': '130303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '25791',
            'label': '北戴河区',
            'value': '130304000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '25850',
            'label': '抚宁区',
            'value': '130306000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '26231',
            'label': '青龙满族自治县',
            'value': '130321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '26664',
            'label': '昌黎县',
            'value': '130322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '27122',
            'label': '卢龙县',
            'value': '130324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '27685',
            'label': '秦皇岛市经济技术开发区',
            'value': '130371000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '27802',
            'label': '北戴河新区',
            'value': '130372000000'
          }
        ],
        'id': '25261',
        'label': '秦皇岛市',
        'value': '130300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '27868',
            'label': '邯山区',
            'value': '130402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '28074',
            'label': '丛台区',
            'value': '130403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '28277',
            'label': '复兴区',
            'value': '130404000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '28403',
            'label': '峰峰矿区',
            'value': '130406000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '28613',
            'label': '肥乡区',
            'value': '130407000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '28889',
            'label': '永年区',
            'value': '130408000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '29271',
            'label': '临漳县',
            'value': '130423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '29711',
            'label': '成安县',
            'value': '130424000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '29964',
            'label': '大名县',
            'value': '130425000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '30636',
            'label': '涉县',
            'value': '130426000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '30968',
            'label': '磁县',
            'value': '130427000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '31239',
            'label': '邱县',
            'value': '130430000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '31472',
            'label': '鸡泽县',
            'value': '130431000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '31651',
            'label': '广平县',
            'value': '130432000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '31830',
            'label': '馆陶县',
            'value': '130433000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '32116',
            'label': '魏县',
            'value': '130434000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '32699',
            'label': '曲周县',
            'value': '130435000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '33052',
            'label': '邯郸经济技术开发区',
            'value': '130471000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '33169',
            'label': '邯郸冀南新区',
            'value': '130473000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '33322',
            'label': '武安市',
            'value': '130481000000'
          }
        ],
        'id': '27867',
        'label': '邯郸市',
        'value': '130400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '33863',
            'label': '桥东区',
            'value': '130502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '34009',
            'label': '桥西区',
            'value': '130503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '34140',
            'label': '邢台县',
            'value': '130521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '34677',
            'label': '临城县',
            'value': '130522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '34906',
            'label': '内丘县',
            'value': '130523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '35230',
            'label': '柏乡县',
            'value': '130524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '35359',
            'label': '隆尧县',
            'value': '130525000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '35651',
            'label': '任县',
            'value': '130526000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '35855',
            'label': '南和县',
            'value': '130527000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '36091',
            'label': '宁晋县',
            'value': '130528000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '36487',
            'label': '巨鹿县',
            'value': '130529000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '36789',
            'label': '新河县',
            'value': '130530000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '36965',
            'label': '广宗县',
            'value': '130531000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '37187',
            'label': '平乡县',
            'value': '130532000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '37454',
            'label': '威县',
            'value': '130533000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '38002',
            'label': '清河县',
            'value': '130534000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '38340',
            'label': '临西县',
            'value': '130535000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '38657',
            'label': '河北邢台经济开发区',
            'value': '130571000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '38742',
            'label': '南宫市',
            'value': '130581000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '39223',
            'label': '沙河市',
            'value': '130582000000'
          }
        ],
        'id': '33862',
        'label': '邢台市',
        'value': '130500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '39501',
            'label': '竞秀区',
            'value': '130602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '39671',
            'label': '莲池区',
            'value': '130606000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '39939',
            'label': '满城区',
            'value': '130607000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '40154',
            'label': '清苑区',
            'value': '130608000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '40452',
            'label': '徐水区',
            'value': '130609000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '40799',
            'label': '涞水县',
            'value': '130623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '41108',
            'label': '阜平县',
            'value': '130624000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '41335',
            'label': '定兴县',
            'value': '130626000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '41634',
            'label': '唐县',
            'value': '130627000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '42020',
            'label': '高阳县',
            'value': '130628000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '42200',
            'label': '容城县',
            'value': '130629000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '42336',
            'label': '涞源县',
            'value': '130630000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '42649',
            'label': '望都县',
            'value': '130631000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '42813',
            'label': '安新县',
            'value': '130632000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '43055',
            'label': '易县',
            'value': '130633000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '43553',
            'label': '曲阳县',
            'value': '130634000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '43950',
            'label': '蠡县',
            'value': '130635000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '44202',
            'label': '顺平县',
            'value': '130636000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '44455',
            'label': '博野县',
            'value': '130637000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '44601',
            'label': '雄县',
            'value': '130638000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '44913',
            'label': '保定高新技术产业开发区',
            'value': '130671000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '44955',
            'label': '保定白沟新城',
            'value': '130672000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '44990',
            'label': '涿州市',
            'value': '130681000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '45454',
            'label': '定州市',
            'value': '130682000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '46022',
            'label': '安国市',
            'value': '130683000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '46241',
            'label': '高碑店市',
            'value': '130684000000'
          }
        ],
        'id': '39500',
        'label': '保定市',
        'value': '130600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '46700',
            'label': '桥东区',
            'value': '130702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '46802',
            'label': '桥西区',
            'value': '130703000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '46872',
            'label': '宣化区',
            'value': '130705000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '47263',
            'label': '下花园区',
            'value': '130706000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '47329',
            'label': '万全区',
            'value': '130708000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '47528',
            'label': '崇礼区',
            'value': '130709000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '47756',
            'label': '张北县',
            'value': '130722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '48151',
            'label': '康保县',
            'value': '130723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '48504',
            'label': '沽源县',
            'value': '130724000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '48752',
            'label': '尚义县',
            'value': '130725000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '48945',
            'label': '蔚县',
            'value': '130726000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '49529',
            'label': '阳原县',
            'value': '130727000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '49853',
            'label': '怀安县',
            'value': '130728000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '50148',
            'label': '怀来县',
            'value': '130730000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '50471',
            'label': '涿鹿县',
            'value': '130731000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '50871',
            'label': '赤城县',
            'value': '130732000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '51330',
            'label': '张家口经济开发区',
            'value': '130771000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '51389',
            'label': '张家口市察北管理区',
            'value': '130772000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '51420',
            'label': '张家口市塞北管理区',
            'value': '130773000000'
          }
        ],
        'id': '46699',
        'label': '张家口市',
        'value': '130700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '51438',
            'label': '双桥区',
            'value': '130802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '51569',
            'label': '双滦区',
            'value': '130803000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '51673',
            'label': '鹰手营子矿区',
            'value': '130804000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '51705',
            'label': '承德县',
            'value': '130821000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '52115',
            'label': '兴隆县',
            'value': '130822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '52433',
            'label': '滦平县',
            'value': '130824000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '52668',
            'label': '隆化县',
            'value': '130825000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '53057',
            'label': '丰宁满族自治县',
            'value': '130826000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '53404',
            'label': '宽城满族自治县',
            'value': '130827000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '53633',
            'label': '围场满族蒙古族自治县',
            'value': '130828000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '53994',
            'label': '承德高新技术产业开发区',
            'value': '130871000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '54048',
            'label': '平泉市',
            'value': '130881000000'
          }
        ],
        'id': '51437',
        'label': '承德市',
        'value': '130800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '54319',
            'label': '新华区',
            'value': '130902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '54392',
            'label': '运河区',
            'value': '130903000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '54525',
            'label': '沧县',
            'value': '130921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '55059',
            'label': '青县',
            'value': '130922000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '55434',
            'label': '东光县',
            'value': '130923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '55901',
            'label': '海兴县',
            'value': '130924000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '56116',
            'label': '盐山县',
            'value': '130925000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '56583',
            'label': '肃宁县',
            'value': '130926000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '56853',
            'label': '南皮县',
            'value': '130927000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '57181',
            'label': '吴桥县',
            'value': '130928000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '57675',
            'label': '献县',
            'value': '130929000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '58208',
            'label': '孟村回族自治县',
            'value': '130930000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '58343',
            'label': '河北沧州经济开发区',
            'value': '130971000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '58353',
            'label': '沧州高新技术产业开发区',
            'value': '130972000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '58363',
            'label': '沧州渤海新区',
            'value': '130973000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '58448',
            'label': '泊头市',
            'value': '130981000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '59175',
            'label': '任丘市',
            'value': '130982000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '59626',
            'label': '黄骅市',
            'value': '130983000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '59999',
            'label': '河间市',
            'value': '130984000000'
          }
        ],
        'id': '54318',
        'label': '沧州市',
        'value': '130900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '60663',
            'label': '安次区',
            'value': '131002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '61000',
            'label': '广阳区',
            'value': '131003000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '61243',
            'label': '固安县',
            'value': '131022000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '61698',
            'label': '永清县',
            'value': '131023000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '62110',
            'label': '香河县',
            'value': '131024000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '62437',
            'label': '大城县',
            'value': '131025000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '62855',
            'label': '文安县',
            'value': '131026000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '63261',
            'label': '大厂回族自治县',
            'value': '131028000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '63390',
            'label': '廊坊经济技术开发区',
            'value': '131071000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '63416',
            'label': '霸州市',
            'value': '131081000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '63823',
            'label': '三河市',
            'value': '131082000000'
          }
        ],
        'id': '60662',
        'label': '廊坊市',
        'value': '131000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '64317',
            'label': '桃城区',
            'value': '131102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '64598',
            'label': '冀州区',
            'value': '131103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '64991',
            'label': '枣强县',
            'value': '131121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '65562',
            'label': '武邑县',
            'value': '131122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '66094',
            'label': '武强县',
            'value': '131123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '66342',
            'label': '饶阳县',
            'value': '131124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '66550',
            'label': '安平县',
            'value': '131125000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '66791',
            'label': '故城县',
            'value': '131126000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '67343',
            'label': '景县',
            'value': '131127000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '68217',
            'label': '阜城县',
            'value': '131128000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '68838',
            'label': '河北衡水高新技术产业开发区',
            'value': '131171000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '68966',
            'label': '衡水滨湖新区',
            'value': '131172000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '69044',
            'label': '深州市',
            'value': '131182000000'
          }
        ],
        'id': '64316',
        'label': '衡水市',
        'value': '131100000000'
      }
    ],
    'id': '4',
    'label': '河北省',
    'value': '13'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '69535',
            'label': '小店区',
            'value': '140105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '69693',
            'label': '迎泽区',
            'value': '140106000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '69815',
            'label': '杏花岭区',
            'value': '140107000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '69976',
            'label': '尖草坪区',
            'value': '140108000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '70142',
            'label': '万柏林区',
            'value': '140109000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '70312',
            'label': '晋源区',
            'value': '140110000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '70441',
            'label': '清徐县',
            'value': '140121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '70651',
            'label': '阳曲县',
            'value': '140122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '70790',
            'label': '娄烦县',
            'value': '140123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '70948',
            'label': '山西转型综合改革示范区',
            'value': '140171000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '71002',
            'label': '古交市',
            'value': '140181000000'
          }
        ],
        'id': '69534',
        'label': '太原市',
        'value': '140100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '71187',
            'label': '新荣区',
            'value': '140212000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '71345',
            'label': '平城区',
            'value': '140213000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '71560',
            'label': '云冈区',
            'value': '140214000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '71855',
            'label': '云州区',
            'value': '140215000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '72047',
            'label': '阳高县',
            'value': '140221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '72328',
            'label': '天镇县',
            'value': '140222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '72581',
            'label': '广灵县',
            'value': '140223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '72746',
            'label': '灵丘县',
            'value': '140224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '73021',
            'label': '浑源县',
            'value': '140225000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '73350',
            'label': '左云县',
            'value': '140226000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '73595',
            'label': '山西大同经济开发区',
            'value': '140271000000'
          }
        ],
        'id': '71186',
        'label': '大同市',
        'value': '140200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '73606',
            'label': '城区',
            'value': '140302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '73678',
            'label': '矿区',
            'value': '140303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '73747',
            'label': '郊区',
            'value': '140311000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '73934',
            'label': '平定县',
            'value': '140321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '74272',
            'label': '盂县',
            'value': '140322000000'
          }
        ],
        'id': '73605',
        'label': '阳泉市',
        'value': '140300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '74731',
            'label': '潞州区',
            'value': '140403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '74988',
            'label': '上党区',
            'value': '140404000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '75265',
            'label': '屯留区',
            'value': '140405000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '75577',
            'label': '潞城区',
            'value': '140406000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '75797',
            'label': '襄垣县',
            'value': '140423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '76143',
            'label': '平顺县',
            'value': '140425000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '76423',
            'label': '黎城县',
            'value': '140426000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '76688',
            'label': '壶关县',
            'value': '140427000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '77094',
            'label': '长子县',
            'value': '140428000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '77515',
            'label': '武乡县',
            'value': '140429000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '77864',
            'label': '沁县',
            'value': '140430000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '78192',
            'label': '沁源县',
            'value': '140431000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '78469',
            'label': '山西长治高新技术产业园区',
            'value': '140471000000'
          }
        ],
        'id': '74730',
        'label': '长治市',
        'value': '140400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '78473',
            'label': '城区',
            'value': '140502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '78628',
            'label': '沁水县',
            'value': '140521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '78894',
            'label': '阳城县',
            'value': '140522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '79343',
            'label': '陵川县',
            'value': '140524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '79676',
            'label': '泽州县',
            'value': '140525000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '80300',
            'label': '高平市',
            'value': '140581000000'
          }
        ],
        'id': '78472',
        'label': '晋城市',
        'value': '140500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '80782',
            'label': '朔城区',
            'value': '140602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '81131',
            'label': '平鲁区',
            'value': '140603000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '81434',
            'label': '山阴县',
            'value': '140621000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '81715',
            'label': '应县',
            'value': '140622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '82021',
            'label': '右玉县',
            'value': '140623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '82333',
            'label': '山西朔州经济开发区',
            'value': '140671000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '82337',
            'label': '怀仁市',
            'value': '140681000000'
          }
        ],
        'id': '80781',
        'label': '朔州市',
        'value': '140600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '82537',
            'label': '榆次区',
            'value': '140702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '82902',
            'label': '榆社县',
            'value': '140721000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '83141',
            'label': '左权县',
            'value': '140722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '83362',
            'label': '和顺县',
            'value': '140723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '83618',
            'label': '昔阳县',
            'value': '140724000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '83972',
            'label': '寿阳县',
            'value': '140725000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '84200',
            'label': '太谷县',
            'value': '140726000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '84429',
            'label': '祁县',
            'value': '140727000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '84609',
            'label': '平遥县',
            'value': '140728000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '84913',
            'label': '灵石县',
            'value': '140729000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '85243',
            'label': '介休市',
            'value': '140781000000'
          }
        ],
        'id': '82536',
        'label': '晋中市',
        'value': '140700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '85513',
            'label': '盐湖区',
            'value': '140802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '85954',
            'label': '临猗县',
            'value': '140821000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '86342',
            'label': '万荣县',
            'value': '140822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '86636',
            'label': '闻喜县',
            'value': '140823000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '86944',
            'label': '稷山县',
            'value': '140824000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '87150',
            'label': '新绛县',
            'value': '140825000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '87383',
            'label': '绛县',
            'value': '140826000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '87593',
            'label': '垣曲县',
            'value': '140827000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '87686',
            'label': '夏县',
            'value': '140828000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '87951',
            'label': '平陆县',
            'value': '140829000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '88184',
            'label': '芮城县',
            'value': '140830000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '88371',
            'label': '永济市',
            'value': '140881000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '88663',
            'label': '河津市',
            'value': '140882000000'
          }
        ],
        'id': '85512',
        'label': '运城市',
        'value': '140800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '88842',
            'label': '忻府区',
            'value': '140902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '89132',
            'label': '定襄县',
            'value': '140921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '89291',
            'label': '五台县',
            'value': '140922000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '89567',
            'label': '代县',
            'value': '140923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '89899',
            'label': '繁峙县',
            'value': '140924000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '90148',
            'label': '宁武县',
            'value': '140925000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '90547',
            'label': '静乐县',
            'value': '140926000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '90923',
            'label': '神池县',
            'value': '140927000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '91061',
            'label': '五寨县',
            'value': '140928000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '91189',
            'label': '岢岚县',
            'value': '140929000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '91307',
            'label': '河曲县',
            'value': '140930000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '91518',
            'label': '保德县',
            'value': '140931000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '91769',
            'label': '偏关县',
            'value': '140932000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '92032',
            'label': '五台山风景名胜区',
            'value': '140971000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '92062',
            'label': '原平市',
            'value': '140981000000'
          }
        ],
        'id': '88841',
        'label': '忻州市',
        'value': '140900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '92476',
            'label': '尧都区',
            'value': '141002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '92943',
            'label': '曲沃县',
            'value': '141021000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '93101',
            'label': '翼城县',
            'value': '141022000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '93320',
            'label': '襄汾县',
            'value': '141023000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '93673',
            'label': '洪洞县',
            'value': '141024000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '94129',
            'label': '古县',
            'value': '141025000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '94236',
            'label': '安泽县',
            'value': '141026000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '94350',
            'label': '浮山县',
            'value': '141027000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '94547',
            'label': '吉县',
            'value': '141028000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '94633',
            'label': '乡宁县',
            'value': '141029000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '94826',
            'label': '大宁县',
            'value': '141030000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '94916',
            'label': '隰县',
            'value': '141031000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '95019',
            'label': '永和县',
            'value': '141032000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '95106',
            'label': '蒲县',
            'value': '141033000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '95208',
            'label': '汾西县',
            'value': '141034000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '95339',
            'label': '侯马市',
            'value': '141081000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '95454',
            'label': '霍州市',
            'value': '141082000000'
          }
        ],
        'id': '92475',
        'label': '临汾市',
        'value': '141000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '95676',
            'label': '离石区',
            'value': '141102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '95942',
            'label': '文水县',
            'value': '141121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '96165',
            'label': '交城县',
            'value': '141122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '96315',
            'label': '兴县',
            'value': '141123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '96726',
            'label': '临县',
            'value': '141124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '97404',
            'label': '柳林县',
            'value': '141125000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '97666',
            'label': '石楼县',
            'value': '141126000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '97796',
            'label': '岚县',
            'value': '141127000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '97978',
            'label': '方山县',
            'value': '141128000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '98145',
            'label': '中阳县',
            'value': '141129000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '98253',
            'label': '交口县',
            'value': '141130000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '98357',
            'label': '孝义市',
            'value': '141181000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '98805',
            'label': '汾阳市',
            'value': '141182000000'
          }
        ],
        'id': '95675',
        'label': '吕梁市',
        'value': '141100000000'
      }
    ],
    'id': '5',
    'label': '山西省',
    'value': '14'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '99122',
            'label': '新城区',
            'value': '150102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '99211',
            'label': '回民区',
            'value': '150103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '99283',
            'label': '玉泉区',
            'value': '150104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '99405',
            'label': '赛罕区',
            'value': '150105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '99615',
            'label': '土默特左旗',
            'value': '150121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '99962',
            'label': '托克托县',
            'value': '150122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '100106',
            'label': '和林格尔县',
            'value': '150123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '100276',
            'label': '清水河县',
            'value': '150124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '100394',
            'label': '武川县',
            'value': '150125000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '100511',
            'label': '呼和浩特金海工业园区',
            'value': '150171000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '100514',
            'label': '呼和浩特经济技术开发区',
            'value': '150172000000'
          }
        ],
        'id': '99121',
        'label': '呼和浩特市',
        'value': '150100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '100528',
            'label': '东河区',
            'value': '150202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '100657',
            'label': '昆都仑区',
            'value': '150203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '100778',
            'label': '青山区',
            'value': '150204000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '100866',
            'label': '石拐区',
            'value': '150205000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '100899',
            'label': '白云鄂博矿区',
            'value': '150206000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '100906',
            'label': '九原区',
            'value': '150207000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '100999',
            'label': '土默特右旗',
            'value': '150221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '101223',
            'label': '固阳县',
            'value': '150222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '101313',
            'label': '达尔罕茂明安联合旗',
            'value': '150223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '101411',
            'label': '包头稀土高新技术产业开发区',
            'value': '150271000000'
          }
        ],
        'id': '100527',
        'label': '包头市',
        'value': '150200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '101467',
            'label': '海勃湾区',
            'value': '150302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '101517',
            'label': '海南区',
            'value': '150303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '101544',
            'label': '乌达区',
            'value': '150304000000'
          }
        ],
        'id': '101466',
        'label': '乌海市',
        'value': '150300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '101579',
            'label': '红山区',
            'value': '150402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '101681',
            'label': '元宝山区',
            'value': '150403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '101810',
            'label': '松山区',
            'value': '150404000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '102143',
            'label': '阿鲁科尔沁旗',
            'value': '150421000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '102423',
            'label': '巴林左旗',
            'value': '150422000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '102629',
            'label': '巴林右旗',
            'value': '150423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '102813',
            'label': '林西县',
            'value': '150424000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '102941',
            'label': '克什克腾旗',
            'value': '150425000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '103106',
            'label': '翁牛特旗',
            'value': '150426000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '103368',
            'label': '喀喇沁旗',
            'value': '150428000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '103556',
            'label': '宁城县',
            'value': '150429000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '103912',
            'label': '敖汉旗',
            'value': '150430000000'
          }
        ],
        'id': '101578',
        'label': '赤峰市',
        'value': '150400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '104180',
            'label': '科尔沁区',
            'value': '150502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '104677',
            'label': '科尔沁左翼中旗',
            'value': '150521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '105254',
            'label': '科尔沁左翼后旗',
            'value': '150522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '105587',
            'label': '开鲁县',
            'value': '150523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '105874',
            'label': '库伦旗',
            'value': '150524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '106080',
            'label': '奈曼旗',
            'value': '150525000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '106470',
            'label': '扎鲁特旗',
            'value': '150526000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '106755',
            'label': '通辽经济技术开发区',
            'value': '150571000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '106840',
            'label': '霍林郭勒市',
            'value': '150581000000'
          }
        ],
        'id': '104179',
        'label': '通辽市',
        'value': '150500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '106873',
            'label': '东胜区',
            'value': '150602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '107000',
            'label': '康巴什区',
            'value': '150603000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '107024',
            'label': '达拉特旗',
            'value': '150621000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '107202',
            'label': '准格尔旗',
            'value': '150622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '107418',
            'label': '鄂托克前旗',
            'value': '150623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '107509',
            'label': '鄂托克旗',
            'value': '150624000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '107617',
            'label': '杭锦旗',
            'value': '150625000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '107717',
            'label': '乌审旗',
            'value': '150626000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '107798',
            'label': '伊金霍洛旗',
            'value': '150627000000'
          }
        ],
        'id': '106872',
        'label': '鄂尔多斯市',
        'value': '150600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '107983',
            'label': '海拉尔区',
            'value': '150702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '108057',
            'label': '扎赉诺尔区',
            'value': '150703000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '108085',
            'label': '阿荣旗',
            'value': '150721000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '108275',
            'label': '莫力达瓦达斡尔族自治旗',
            'value': '150722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '108581',
            'label': '鄂伦春自治旗',
            'value': '150723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '108704',
            'label': '鄂温克族自治旗',
            'value': '150724000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '108787',
            'label': '陈巴尔虎旗',
            'value': '150725000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '108875',
            'label': '新巴尔虎左旗',
            'value': '150726000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '108954',
            'label': '新巴尔虎右旗',
            'value': '150727000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '109027',
            'label': '满洲里市',
            'value': '150781000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '109068',
            'label': '牙克石市',
            'value': '150782000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '109168',
            'label': '扎兰屯市',
            'value': '150783000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '109422',
            'label': '额尔古纳市',
            'value': '150784000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '109498',
            'label': '根河市',
            'value': '150785000000'
          }
        ],
        'id': '107982',
        'label': '呼伦贝尔市',
        'value': '150700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '109534',
            'label': '临河区',
            'value': '150802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '109787',
            'label': '五原县',
            'value': '150821000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '109947',
            'label': '磴口县',
            'value': '150822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '110064',
            'label': '乌拉特前旗',
            'value': '150823000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '110249',
            'label': '乌拉特中旗',
            'value': '150824000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '110368',
            'label': '乌拉特后旗',
            'value': '150825000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '110435',
            'label': '杭锦后旗',
            'value': '150826000000'
          }
        ],
        'id': '109533',
        'label': '巴彦淖尔市',
        'value': '150800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '110582',
            'label': '集宁区',
            'value': '150902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '110698',
            'label': '卓资县',
            'value': '150921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '110827',
            'label': '化德县',
            'value': '150922000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '110931',
            'label': '商都县',
            'value': '150923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '111175',
            'label': '兴和县',
            'value': '150924000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '111362',
            'label': '凉城县',
            'value': '150925000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '111515',
            'label': '察哈尔右翼前旗',
            'value': '150926000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '111659',
            'label': '察哈尔右翼中旗',
            'value': '150927000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '111858',
            'label': '察哈尔右翼后旗',
            'value': '150928000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '111968',
            'label': '四子王旗',
            'value': '150929000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '112119',
            'label': '丰镇市',
            'value': '150981000000'
          }
        ],
        'id': '110581',
        'label': '乌兰察布市',
        'value': '150900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '112246',
            'label': '乌兰浩特市',
            'value': '152201000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '112388',
            'label': '阿尔山市',
            'value': '152202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '112427',
            'label': '科尔沁右翼前旗',
            'value': '152221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '112746',
            'label': '科尔沁右翼中旗',
            'value': '152222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '112985',
            'label': '扎赉特旗',
            'value': '152223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '113245',
            'label': '突泉县',
            'value': '152224000000'
          }
        ],
        'id': '112245',
        'label': '兴安盟',
        'value': '152200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '113469',
            'label': '二连浩特市',
            'value': '152501000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '113487',
            'label': '锡林浩特市',
            'value': '152502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '113607',
            'label': '阿巴嘎旗',
            'value': '152522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '113694',
            'label': '苏尼特左旗',
            'value': '152523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '113765',
            'label': '苏尼特右旗',
            'value': '152524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '113852',
            'label': '东乌珠穆沁旗',
            'value': '152525000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '113943',
            'label': '西乌珠穆沁旗',
            'value': '152526000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '114062',
            'label': '太仆寺旗',
            'value': '152527000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '114261',
            'label': '镶黄旗',
            'value': '152528000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '114332',
            'label': '正镶白旗',
            'value': '152529000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '114426',
            'label': '正蓝旗',
            'value': '152530000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '114563',
            'label': '多伦县',
            'value': '152531000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '114645',
            'label': '乌拉盖管委会',
            'value': '152571000000'
          }
        ],
        'id': '113468',
        'label': '锡林郭勒盟',
        'value': '152500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '114674',
            'label': '阿拉善左旗',
            'value': '152921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '114866',
            'label': '阿拉善右旗',
            'value': '152922000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '114923',
            'label': '额济纳旗',
            'value': '152923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '114973',
            'label': '内蒙古阿拉善经济开发区',
            'value': '152971000000'
          }
        ],
        'id': '114673',
        'label': '阿拉善盟',
        'value': '152900000000'
      }
    ],
    'id': '6',
    'label': '内蒙古自治区',
    'value': '15'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '114980',
            'label': '和平区',
            'value': '210102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '115103',
            'label': '沈河区',
            'value': '210103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '115230',
            'label': '大东区',
            'value': '210104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '115373',
            'label': '皇姑区',
            'value': '210105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '115513',
            'label': '铁西区',
            'value': '210106000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '115726',
            'label': '苏家屯区',
            'value': '210111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '115922',
            'label': '浑南区',
            'value': '210112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '116197',
            'label': '沈北新区',
            'value': '210113000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '116409',
            'label': '于洪区',
            'value': '210114000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '116630',
            'label': '辽中区',
            'value': '210115000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '116885',
            'label': '康平县',
            'value': '210123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '117079',
            'label': '法库县',
            'value': '210124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '117344',
            'label': '新民市',
            'value': '210181000000'
          }
        ],
        'id': '114979',
        'label': '沈阳市',
        'value': '210100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '117747',
            'label': '中山区',
            'value': '210202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '117816',
            'label': '西岗区',
            'value': '210203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '117871',
            'label': '沙河口区',
            'value': '210204000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '117971',
            'label': '甘井子区',
            'value': '210211000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '118242',
            'label': '旅顺口区',
            'value': '210212000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '118365',
            'label': '金州区',
            'value': '210213000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '118732',
            'label': '普兰店区',
            'value': '210214000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '118938',
            'label': '长海县',
            'value': '210224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '118974',
            'label': '瓦房店市',
            'value': '210281000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '119334',
            'label': '庄河市',
            'value': '210283000000'
          }
        ],
        'id': '117746',
        'label': '大连市',
        'value': '210200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '119643',
            'label': '铁东区',
            'value': '210302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '119767',
            'label': '铁西区',
            'value': '210303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '119880',
            'label': '立山区',
            'value': '210304000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '120015',
            'label': '千山区',
            'value': '210311000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '120084',
            'label': '台安县',
            'value': '210321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '120264',
            'label': '岫岩满族自治县',
            'value': '210323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '120496',
            'label': '海城市',
            'value': '210381000000'
          }
        ],
        'id': '119642',
        'label': '鞍山市',
        'value': '210300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '120948',
            'label': '新抚区',
            'value': '210402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '121034',
            'label': '东洲区',
            'value': '210403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '121156',
            'label': '望花区',
            'value': '210404000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '121304',
            'label': '顺城区',
            'value': '210411000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '121428',
            'label': '抚顺县',
            'value': '210421000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '121534',
            'label': '新宾满族自治县',
            'value': '210422000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '121744',
            'label': '清原满族自治县',
            'value': '210423000000'
          }
        ],
        'id': '120947',
        'label': '抚顺市',
        'value': '210400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '121969',
            'label': '平山区',
            'value': '210502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '122041',
            'label': '溪湖区',
            'value': '210503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '122136',
            'label': '明山区',
            'value': '210504000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '122228',
            'label': '南芬区',
            'value': '210505000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '122272',
            'label': '本溪满族自治县',
            'value': '210521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '122419',
            'label': '桓仁满族自治县',
            'value': '210522000000'
          }
        ],
        'id': '121968',
        'label': '本溪市',
        'value': '210500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '122559',
            'label': '元宝区',
            'value': '210602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '122612',
            'label': '振兴区',
            'value': '210603000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '122714',
            'label': '振安区',
            'value': '210604000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '122787',
            'label': '宽甸满族自治县',
            'value': '210624000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '123006',
            'label': '东港市',
            'value': '210681000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '123267',
            'label': '凤城市',
            'value': '210682000000'
          }
        ],
        'id': '122558',
        'label': '丹东市',
        'value': '210600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '123527',
            'label': '古塔区',
            'value': '210702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '123605',
            'label': '凌河区',
            'value': '210703000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '123696',
            'label': '太和区',
            'value': '210711000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '123873',
            'label': '黑山县',
            'value': '210726000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '124222',
            'label': '义县',
            'value': '210727000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '124494',
            'label': '凌海市',
            'value': '210781000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '124804',
            'label': '北镇市',
            'value': '210782000000'
          }
        ],
        'id': '123526',
        'label': '锦州市',
        'value': '210700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '125073',
            'label': '站前区',
            'value': '210802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '125127',
            'label': '西市区',
            'value': '210803000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '125178',
            'label': '鲅鱼圈区',
            'value': '210804000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '125292',
            'label': '老边区',
            'value': '210811000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '125359',
            'label': '盖州市',
            'value': '210881000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '125688',
            'label': '大石桥市',
            'value': '210882000000'
          }
        ],
        'id': '125072',
        'label': '营口市',
        'value': '210800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '125996',
            'label': '海州区',
            'value': '210902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '126074',
            'label': '新邱区',
            'value': '210903000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '126102',
            'label': '太平区',
            'value': '210904000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '126152',
            'label': '清河门区',
            'value': '210905000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '126187',
            'label': '细河区',
            'value': '210911000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '126255',
            'label': '阜新蒙古族自治县',
            'value': '210921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '126684',
            'label': '彰武县',
            'value': '210922000000'
          }
        ],
        'id': '125995',
        'label': '阜新市',
        'value': '210900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '126910',
            'label': '白塔区',
            'value': '211002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '126995',
            'label': '文圣区',
            'value': '211003000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '127061',
            'label': '宏伟区',
            'value': '211004000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '127110',
            'label': '弓长岭区',
            'value': '211005000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '127149',
            'label': '太子河区',
            'value': '211011000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '127230',
            'label': '辽阳县',
            'value': '211021000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '127456',
            'label': '灯塔市',
            'value': '211081000000'
          }
        ],
        'id': '126909',
        'label': '辽阳市',
        'value': '211000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '127683',
            'label': '双台子区',
            'value': '211102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '127743',
            'label': '兴隆台区',
            'value': '211103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '127879',
            'label': '大洼区',
            'value': '211104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '128087',
            'label': '盘山县',
            'value': '211122000000'
          }
        ],
        'id': '127682',
        'label': '盘锦市',
        'value': '211100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '128279',
            'label': '银州区',
            'value': '211202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '128389',
            'label': '清河区',
            'value': '211204000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '128452',
            'label': '铁岭县',
            'value': '211221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '128701',
            'label': '西丰县',
            'value': '211223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '128907',
            'label': '昌图县',
            'value': '211224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '129460',
            'label': '调兵山市',
            'value': '211281000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '129528',
            'label': '开原市',
            'value': '211282000000'
          }
        ],
        'id': '128278',
        'label': '铁岭市',
        'value': '211200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '129845',
            'label': '双塔区',
            'value': '211302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '129968',
            'label': '龙城区',
            'value': '211303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '130071',
            'label': '朝阳县',
            'value': '211321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '130400',
            'label': '建平县',
            'value': '211322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '130727',
            'label': '喀喇沁左翼蒙古族自治县',
            'value': '211324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '130955',
            'label': '北票市',
            'value': '211381000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '131291',
            'label': '凌源市',
            'value': '211382000000'
          }
        ],
        'id': '129844',
        'label': '朝阳市',
        'value': '211300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '131606',
            'label': '连山区',
            'value': '211402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '131850',
            'label': '龙港区',
            'value': '211403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '131951',
            'label': '南票区',
            'value': '211404000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '132130',
            'label': '绥中县',
            'value': '211421000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '132462',
            'label': '建昌县',
            'value': '211422000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '132789',
            'label': '兴城市',
            'value': '211481000000'
          }
        ],
        'id': '131605',
        'label': '葫芦岛市',
        'value': '211400000000'
      }
    ],
    'id': '7',
    'label': '辽宁省',
    'value': '21'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '133093',
            'label': '南关区',
            'value': '220102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '133173',
            'label': '宽城区',
            'value': '220103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '133340',
            'label': '朝阳区',
            'value': '220104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '133430',
            'label': '二道区',
            'value': '220105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '133521',
            'label': '绿园区',
            'value': '220106000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '133615',
            'label': '双阳区',
            'value': '220112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '133778',
            'label': '九台区',
            'value': '220113000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '134138',
            'label': '农安县',
            'value': '220122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '134549',
            'label': '长春经济技术开发区',
            'value': '220171000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '134592',
            'label': '长春净月高新技术产业开发区',
            'value': '220172000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '134666',
            'label': '长春高新技术产业开发区',
            'value': '220173000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '134706',
            'label': '长春汽车经济技术开发区',
            'value': '220174000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '134742',
            'label': '榆树市',
            'value': '220182000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '135175',
            'label': '德惠市',
            'value': '220183000000'
          }
        ],
        'id': '133092',
        'label': '长春市',
        'value': '220100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '135507',
            'label': '昌邑区',
            'value': '220202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '135683',
            'label': '龙潭区',
            'value': '220203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '135876',
            'label': '船营区',
            'value': '220204000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '136008',
            'label': '丰满区',
            'value': '220211000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '136110',
            'label': '永吉县',
            'value': '220221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '136259',
            'label': '吉林经济开发区',
            'value': '220271000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '136301',
            'label': '吉林高新技术产业开发区',
            'value': '220272000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '136333',
            'label': '吉林中国新加坡食品区',
            'value': '220273000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '136354',
            'label': '蛟河市',
            'value': '220281000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '136658',
            'label': '桦甸市',
            'value': '220282000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '136848',
            'label': '舒兰市',
            'value': '220283000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '137112',
            'label': '磐石市',
            'value': '220284000000'
          }
        ],
        'id': '135506',
        'label': '吉林市',
        'value': '220200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '137430',
            'label': '铁西区',
            'value': '220302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '137498',
            'label': '铁东区',
            'value': '220303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '137609',
            'label': '梨树县',
            'value': '220322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '137979',
            'label': '伊通满族自治县',
            'value': '220323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '138204',
            'label': '公主岭市',
            'value': '220381000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '138708',
            'label': '双辽市',
            'value': '220382000000'
          }
        ],
        'id': '137429',
        'label': '四平市',
        'value': '220300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '138954',
            'label': '龙山区',
            'value': '220402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '139007',
            'label': '西安区',
            'value': '220403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '139045',
            'label': '东丰县',
            'value': '220421000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '139299',
            'label': '东辽县',
            'value': '220422000000'
          }
        ],
        'id': '138953',
        'label': '辽源市',
        'value': '220400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '139566',
            'label': '东昌区',
            'value': '220502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '139636',
            'label': '二道江区',
            'value': '220503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '139684',
            'label': '通化县',
            'value': '220521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '139880',
            'label': '辉南县',
            'value': '220523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '140058',
            'label': '柳河县',
            'value': '220524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '140318',
            'label': '梅河口市',
            'value': '220581000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '140673',
            'label': '集安市',
            'value': '220582000000'
          }
        ],
        'id': '139565',
        'label': '通化市',
        'value': '220500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '140845',
            'label': '浑江区',
            'value': '220602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '140955',
            'label': '江源区',
            'value': '220605000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '141057',
            'label': '抚松县',
            'value': '220621000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '141245',
            'label': '靖宇县',
            'value': '220622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '141373',
            'label': '长白朝鲜族自治县',
            'value': '220623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '141469',
            'label': '临江市',
            'value': '220681000000'
          }
        ],
        'id': '140844',
        'label': '白山市',
        'value': '220600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '141592',
            'label': '宁江区',
            'value': '220702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '141791',
            'label': '前郭尔罗斯蒙古族自治县',
            'value': '220721000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '142113',
            'label': '长岭县',
            'value': '220722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '142398',
            'label': '乾安县',
            'value': '220723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '142594',
            'label': '吉林松原经济开发区',
            'value': '220771000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '142612',
            'label': '扶余市',
            'value': '220781000000'
          }
        ],
        'id': '141591',
        'label': '松原市',
        'value': '220700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '143046',
            'label': '洮北区',
            'value': '220802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '143279',
            'label': '镇赉县',
            'value': '220821000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '143461',
            'label': '通榆县',
            'value': '220822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '143684',
            'label': '吉林白城经济开发区',
            'value': '220871000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '143711',
            'label': '洮南市',
            'value': '220881000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '143982',
            'label': '大安市',
            'value': '220882000000'
          }
        ],
        'id': '143045',
        'label': '白城市',
        'value': '220800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '144267',
            'label': '延吉市',
            'value': '222401000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '144423',
            'label': '图们市',
            'value': '222402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '144502',
            'label': '敦化市',
            'value': '222403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '144943',
            'label': '珲春市',
            'value': '222404000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '145122',
            'label': '龙井市',
            'value': '222405000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '145231',
            'label': '和龙市',
            'value': '222406000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '145374',
            'label': '汪清县',
            'value': '222424000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '145653',
            'label': '安图县',
            'value': '222426000000'
          }
        ],
        'id': '144266',
        'label': '延边朝鲜族自治州',
        'value': '222400000000'
      }
    ],
    'id': '8',
    'label': '吉林省',
    'value': '22'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '145898',
            'label': '道里区',
            'value': '230102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '146108',
            'label': '南岗区',
            'value': '230103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '146326',
            'label': '道外区',
            'value': '230104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '146522',
            'label': '平房区',
            'value': '230108000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '146579',
            'label': '松北区',
            'value': '230109000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '146684',
            'label': '香坊区',
            'value': '230110000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '146880',
            'label': '呼兰区',
            'value': '230111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '147152',
            'label': '阿城区',
            'value': '230112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '147360',
            'label': '双城区',
            'value': '230113000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '147671',
            'label': '依兰县',
            'value': '230123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '147850',
            'label': '方正县',
            'value': '230124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '147950',
            'label': '宾县',
            'value': '230125000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '148158',
            'label': '巴彦县',
            'value': '230126000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '148314',
            'label': '木兰县',
            'value': '230127000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '148432',
            'label': '通河县',
            'value': '230128000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '148607',
            'label': '延寿县',
            'value': '230129000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '148758',
            'label': '尚志市',
            'value': '230183000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '149009',
            'label': '五常市',
            'value': '230184000000'
          }
        ],
        'id': '145897',
        'label': '哈尔滨市',
        'value': '230100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '149336',
            'label': '龙沙区',
            'value': '230202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '149385',
            'label': '建华区',
            'value': '230203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '149430',
            'label': '铁锋区',
            'value': '230204000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '149488',
            'label': '昂昂溪区',
            'value': '230205000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '149523',
            'label': '富拉尔基区',
            'value': '230206000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '149574',
            'label': '碾子山区',
            'value': '230207000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '149595',
            'label': '梅里斯达斡尔族区',
            'value': '230208000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '149662',
            'label': '龙江县',
            'value': '230221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '149847',
            'label': '依安县',
            'value': '230223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '150026',
            'label': '泰来县',
            'value': '230224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '150155',
            'label': '甘南县',
            'value': '230225000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '150292',
            'label': '富裕县',
            'value': '230227000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '150414',
            'label': '克山县',
            'value': '230229000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '150588',
            'label': '克东县',
            'value': '230230000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '150722',
            'label': '拜泉县',
            'value': '230231000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '150931',
            'label': '讷河市',
            'value': '230281000000'
          }
        ],
        'id': '149335',
        'label': '齐齐哈尔市',
        'value': '230200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '151169',
            'label': '鸡冠区',
            'value': '230302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '151236',
            'label': '恒山区',
            'value': '230303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '151291',
            'label': '滴道区',
            'value': '230304000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '151336',
            'label': '梨树区',
            'value': '230305000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '151365',
            'label': '城子河区',
            'value': '230306000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '151406',
            'label': '麻山区',
            'value': '230307000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '151429',
            'label': '鸡东县',
            'value': '230321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '151596',
            'label': '虎林市',
            'value': '230381000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '151808',
            'label': '密山市',
            'value': '230382000000'
          }
        ],
        'id': '151168',
        'label': '鸡西市',
        'value': '230300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '152055',
            'label': '向阳区',
            'value': '230402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '152078',
            'label': '工农区',
            'value': '230403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '152099',
            'label': '南山区',
            'value': '230404000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '152117',
            'label': '兴安区',
            'value': '230405000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '152142',
            'label': '东山区',
            'value': '230406000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '152214',
            'label': '兴山区',
            'value': '230407000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '152227',
            'label': '萝北县',
            'value': '230421000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '152417',
            'label': '绥滨县',
            'value': '230422000000'
          }
        ],
        'id': '152054',
        'label': '鹤岗市',
        'value': '230400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '152594',
            'label': '尖山区',
            'value': '230502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '152649',
            'label': '岭东区',
            'value': '230503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '152679',
            'label': '四方台区',
            'value': '230505000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '152715',
            'label': '宝山区',
            'value': '230506000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '152761',
            'label': '集贤县',
            'value': '230521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '152981',
            'label': '友谊县',
            'value': '230522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '153081',
            'label': '宝清县',
            'value': '230523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '153313',
            'label': '饶河县',
            'value': '230524000000'
          }
        ],
        'id': '152593',
        'label': '双鸭山市',
        'value': '230500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '153494',
            'label': '萨尔图区',
            'value': '230602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '153562',
            'label': '龙凤区',
            'value': '230603000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '153616',
            'label': '让胡路区',
            'value': '230604000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '153706',
            'label': '红岗区',
            'value': '230605000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '153754',
            'label': '大同区',
            'value': '230606000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '153852',
            'label': '肇州县',
            'value': '230621000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '153984',
            'label': '肇源县',
            'value': '230622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '154167',
            'label': '林甸县',
            'value': '230623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '154275',
            'label': '杜尔伯特蒙古族自治县',
            'value': '230624000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '154394',
            'label': '大庆高新技术产业开发区',
            'value': '230671000000'
          }
        ],
        'id': '153493',
        'label': '大庆市',
        'value': '230600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '154405',
            'label': '伊美区',
            'value': '230717000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '154477',
            'label': '乌翠区',
            'value': '230718000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '154523',
            'label': '友好区',
            'value': '230719000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '154604',
            'label': '嘉荫县',
            'value': '230722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '154710',
            'label': '汤旺县',
            'value': '230723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '154785',
            'label': '丰林县',
            'value': '230724000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '154883',
            'label': '大箐山县',
            'value': '230725000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '154952',
            'label': '南岔县',
            'value': '230726000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '155011',
            'label': '金林区',
            'value': '230751000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '155053',
            'label': '铁力市',
            'value': '230781000000'
          }
        ],
        'id': '154404',
        'label': '伊春市',
        'value': '230700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '155204',
            'label': '向阳区',
            'value': '230803000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '155292',
            'label': '前进区',
            'value': '230804000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '155317',
            'label': '东风区',
            'value': '230805000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '155380',
            'label': '郊区',
            'value': '230811000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '155563',
            'label': '桦南县',
            'value': '230822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '155839',
            'label': '桦川县',
            'value': '230826000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '155981',
            'label': '汤原县',
            'value': '230828000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '156177',
            'label': '同江市',
            'value': '230881000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '156357',
            'label': '富锦市',
            'value': '230882000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '156759',
            'label': '抚远市',
            'value': '230883000000'
          }
        ],
        'id': '155203',
        'label': '佳木斯市',
        'value': '230800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '156888',
            'label': '新兴区',
            'value': '230902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '156965',
            'label': '桃山区',
            'value': '230903000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '157013',
            'label': '茄子河区',
            'value': '230904000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '157117',
            'label': '勃利县',
            'value': '230921000000'
          }
        ],
        'id': '156887',
        'label': '七台河市',
        'value': '230900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '157295',
            'label': '东安区',
            'value': '231002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '157347',
            'label': '阳明区',
            'value': '231003000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '157450',
            'label': '爱民区',
            'value': '231004000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '157499',
            'label': '西安区',
            'value': '231005000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '157574',
            'label': '林口县',
            'value': '231025000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '157814',
            'label': '牡丹江经济技术开发区',
            'value': '231071000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '157817',
            'label': '绥芬河市',
            'value': '231081000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '157847',
            'label': '海林市',
            'value': '231083000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '158081',
            'label': '宁安市',
            'value': '231084000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '158401',
            'label': '穆棱市',
            'value': '231085000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '158638',
            'label': '东宁市',
            'value': '231086000000'
          }
        ],
        'id': '157294',
        'label': '牡丹江市',
        'value': '231000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '158792',
            'label': '爱辉区',
            'value': '231102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '158973',
            'label': '逊克县',
            'value': '231123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '159092',
            'label': '孙吴县',
            'value': '231124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '159224',
            'label': '北安市',
            'value': '231181000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '159391',
            'label': '五大连池市',
            'value': '231182000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '159638',
            'label': '嫩江市',
            'value': '231183000000'
          }
        ],
        'id': '158791',
        'label': '黑河市',
        'value': '231100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '159922',
            'label': '北林区',
            'value': '231202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '160152',
            'label': '望奎县',
            'value': '231221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '160305',
            'label': '兰西县',
            'value': '231222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '160448',
            'label': '青冈县',
            'value': '231223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '160655',
            'label': '庆安县',
            'value': '231224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '160790',
            'label': '明水县',
            'value': '231225000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '160932',
            'label': '绥棱县',
            'value': '231226000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '161072',
            'label': '安达市',
            'value': '231281000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '161242',
            'label': '肇东市',
            'value': '231282000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '161494',
            'label': '海伦市',
            'value': '231283000000'
          }
        ],
        'id': '159921',
        'label': '绥化市',
        'value': '231200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '161820',
            'label': '漠河市',
            'value': '232701000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '161843',
            'label': '呼玛县',
            'value': '232721000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '161910',
            'label': '塔河县',
            'value': '232722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '161936',
            'label': '加格达奇区',
            'value': '232761000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '161969',
            'label': '松岭区',
            'value': '232762000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '161979',
            'label': '新林区',
            'value': '232763000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '161995',
            'label': '呼中区',
            'value': '232764000000'
          }
        ],
        'id': '161819',
        'label': '大兴安岭地区',
        'value': '232700000000'
      }
    ],
    'id': '9',
    'label': '黑龙江省',
    'value': '23'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '162006',
            'label': '黄浦区',
            'value': '310101000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '162194',
            'label': '徐汇区',
            'value': '310104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '162514',
            'label': '长宁区',
            'value': '310105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '162710',
            'label': '静安区',
            'value': '310106000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '162993',
            'label': '普陀区',
            'value': '310107000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '163271',
            'label': '虹口区',
            'value': '310109000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '163486',
            'label': '杨浦区',
            'value': '310110000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '163813',
            'label': '闵行区',
            'value': '310112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '164397',
            'label': '宝山区',
            'value': '310113000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '164905',
            'label': '嘉定区',
            'value': '310114000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '165293',
            'label': '浦东新区',
            'value': '310115000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '166696',
            'label': '金山区',
            'value': '310116000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '166939',
            'label': '松江区',
            'value': '310117000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '167316',
            'label': '青浦区',
            'value': '310118000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '167654',
            'label': '奉贤区',
            'value': '310120000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '167970',
            'label': '崇明区',
            'value': '310151000000'
          }
        ],
        'id': '162005',
        'label': '市辖区',
        'value': '310100000000'
      }
    ],
    'id': '10',
    'label': '上海',
    'value': '31'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '168346',
            'label': '玄武区',
            'value': '320102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '168415',
            'label': '秦淮区',
            'value': '320104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '168543',
            'label': '建邺区',
            'value': '320105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '168619',
            'label': '鼓楼区',
            'value': '320106000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '168756',
            'label': '浦口区',
            'value': '320111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '168901',
            'label': '栖霞区',
            'value': '320113000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '169054',
            'label': '雨花台区',
            'value': '320114000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '169161',
            'label': '江宁区',
            'value': '320115000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '169383',
            'label': '六合区',
            'value': '320116000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '169556',
            'label': '溧水区',
            'value': '320117000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '169678',
            'label': '高淳区',
            'value': '320118000000'
          }
        ],
        'id': '168345',
        'label': '南京市',
        'value': '320100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '169841',
            'label': '锡山区',
            'value': '320205000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '169976',
            'label': '惠山区',
            'value': '320206000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '170103',
            'label': '滨湖区',
            'value': '320211000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '170231',
            'label': '梁溪区',
            'value': '320213000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '170406',
            'label': '新吴区',
            'value': '320214000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '170551',
            'label': '江阴市',
            'value': '320281000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '170880',
            'label': '宜兴市',
            'value': '320282000000'
          }
        ],
        'id': '169840',
        'label': '无锡市',
        'value': '320200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '171212',
            'label': '鼓楼区',
            'value': '320302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '171288',
            'label': '云龙区',
            'value': '320303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '171379',
            'label': '贾汪区',
            'value': '320305000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '171543',
            'label': '泉山区',
            'value': '320311000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '171685',
            'label': '铜山区',
            'value': '320312000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '172041',
            'label': '丰县',
            'value': '320321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '172470',
            'label': '沛县',
            'value': '320322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '172885',
            'label': '睢宁县',
            'value': '320324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '173308',
            'label': '徐州经济技术开发区',
            'value': '320371000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '173397',
            'label': '新沂市',
            'value': '320381000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '173705',
            'label': '邳州市',
            'value': '320382000000'
          }
        ],
        'id': '171211',
        'label': '徐州市',
        'value': '320300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '174243',
            'label': '天宁区',
            'value': '320402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '174382',
            'label': '钟楼区',
            'value': '320404000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '174486',
            'label': '新北区',
            'value': '320411000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '174638',
            'label': '武进区',
            'value': '320412000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '174954',
            'label': '金坛区',
            'value': '320413000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '175116',
            'label': '溧阳市',
            'value': '320481000000'
          }
        ],
        'id': '174242',
        'label': '常州市',
        'value': '320400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '175373',
            'label': '虎丘区',
            'value': '320505000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '175478',
            'label': '吴中区',
            'value': '320506000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '175696',
            'label': '相城区',
            'value': '320507000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '175851',
            'label': '姑苏区',
            'value': '320508000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '176034',
            'label': '吴江区',
            'value': '320509000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '176372',
            'label': '苏州工业园区',
            'value': '320571000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '176535',
            'label': '常熟市',
            'value': '320581000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '176904',
            'label': '张家港市',
            'value': '320582000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '177194',
            'label': '昆山市',
            'value': '320583000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '177571',
            'label': '太仓市',
            'value': '320585000000'
          }
        ],
        'id': '175372',
        'label': '苏州市',
        'value': '320500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '177736',
            'label': '崇川区',
            'value': '320602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '177857',
            'label': '港闸区',
            'value': '320611000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '177943',
            'label': '通州区',
            'value': '320612000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '178239',
            'label': '如东县',
            'value': '320623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '178523',
            'label': '南通经济技术开发区',
            'value': '320671000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '178577',
            'label': '启东市',
            'value': '320681000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '178923',
            'label': '如皋市',
            'value': '320682000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '179301',
            'label': '海门市',
            'value': '320684000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '179633',
            'label': '海安市',
            'value': '320685000000'
          }
        ],
        'id': '177735',
        'label': '南通市',
        'value': '320600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '179883',
            'label': '连云区',
            'value': '320703000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '179968',
            'label': '海州区',
            'value': '320706000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '180185',
            'label': '赣榆区',
            'value': '320707000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '180670',
            'label': '东海县',
            'value': '320722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '181055',
            'label': '灌云县',
            'value': '320723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '181412',
            'label': '灌南县',
            'value': '320724000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '181662',
            'label': '连云港经济技术开发区',
            'value': '320771000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '181703',
            'label': '连云港高新技术产业开发区',
            'value': '320772000000'
          }
        ],
        'id': '179882',
        'label': '连云港市',
        'value': '320700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '181735',
            'label': '淮安区',
            'value': '320803000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '182096',
            'label': '淮阴区',
            'value': '320804000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '182372',
            'label': '清江浦区',
            'value': '320812000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '182515',
            'label': '洪泽区',
            'value': '320813000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '182649',
            'label': '涟水县',
            'value': '320826000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '183045',
            'label': '盱眙县',
            'value': '320830000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '183318',
            'label': '金湖县',
            'value': '320831000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '183482',
            'label': '淮安经济技术开发区',
            'value': '320871000000'
          }
        ],
        'id': '181734',
        'label': '淮安市',
        'value': '320800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '183572',
            'label': '亭湖区',
            'value': '320902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '183794',
            'label': '盐都区',
            'value': '320903000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '184080',
            'label': '大丰区',
            'value': '320904000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '184367',
            'label': '响水县',
            'value': '320921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '184543',
            'label': '滨海县',
            'value': '320922000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '184867',
            'label': '阜宁县',
            'value': '320923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '185226',
            'label': '射阳县',
            'value': '320924000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '185520',
            'label': '建湖县',
            'value': '320925000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '185783',
            'label': '盐城经济技术开发区',
            'value': '320971000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '185820',
            'label': '东台市',
            'value': '320981000000'
          }
        ],
        'id': '183571',
        'label': '盐城市',
        'value': '320900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '186259',
            'label': '广陵区',
            'value': '321002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '186417',
            'label': '邗江区',
            'value': '321003000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '186617',
            'label': '江都区',
            'value': '321012000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '186970',
            'label': '宝应县',
            'value': '321023000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '187268',
            'label': '扬州经济技术开发区',
            'value': '321071000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '187332',
            'label': '仪征市',
            'value': '321081000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '187549',
            'label': '高邮市',
            'value': '321084000000'
          }
        ],
        'id': '186258',
        'label': '扬州市',
        'value': '321000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '187789',
            'label': '京口区',
            'value': '321102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '187864',
            'label': '润州区',
            'value': '321111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '187946',
            'label': '丹徒区',
            'value': '321112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '188046',
            'label': '镇江新区',
            'value': '321171000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '188109',
            'label': '丹阳市',
            'value': '321181000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '188344',
            'label': '扬中市',
            'value': '321182000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '188431',
            'label': '句容市',
            'value': '321183000000'
          }
        ],
        'id': '187788',
        'label': '镇江市',
        'value': '321100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '188637',
            'label': '海陵区',
            'value': '321202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '188813',
            'label': '高港区',
            'value': '321203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '188914',
            'label': '姜堰区',
            'value': '321204000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '189234',
            'label': '泰州医药高新技术产业开发区',
            'value': '321271000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '189306',
            'label': '兴化市',
            'value': '321281000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '189872',
            'label': '靖江市',
            'value': '321282000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '190150',
            'label': '泰兴市',
            'value': '321283000000'
          }
        ],
        'id': '188636',
        'label': '泰州市',
        'value': '321200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '190530',
            'label': '宿城区',
            'value': '321302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '190761',
            'label': '宿豫区',
            'value': '321311000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '190970',
            'label': '沭阳县',
            'value': '321322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '191498',
            'label': '泗阳县',
            'value': '321323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '191761',
            'label': '泗洪县',
            'value': '321324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '192137',
            'label': '宿迁经济技术开发区',
            'value': '321371000000'
          }
        ],
        'id': '190529',
        'label': '宿迁市',
        'value': '321300000000'
      }
    ],
    'id': '11',
    'label': '江苏省',
    'value': '32'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '192175',
            'label': '上城区',
            'value': '330102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '192236',
            'label': '下城区',
            'value': '330103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '192319',
            'label': '江干区',
            'value': '330104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '192519',
            'label': '拱墅区',
            'value': '330105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '192629',
            'label': '西湖区',
            'value': '330106000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '192852',
            'label': '滨江区',
            'value': '330108000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '192916',
            'label': '萧山区',
            'value': '330109000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '193571',
            'label': '余杭区',
            'value': '330110000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '193959',
            'label': '富阳区',
            'value': '330111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '194313',
            'label': '临安区',
            'value': '330112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '194640',
            'label': '桐庐县',
            'value': '330122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '194858',
            'label': '淳安县',
            'value': '330127000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '195321',
            'label': '建德市',
            'value': '330182000000'
          }
        ],
        'id': '192174',
        'label': '杭州市',
        'value': '330100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '195610',
            'label': '海曙区',
            'value': '330203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '195904',
            'label': '江北区',
            'value': '330205000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '196073',
            'label': '北仑区',
            'value': '330206000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '196352',
            'label': '镇海区',
            'value': '330211000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '196459',
            'label': '鄞州区',
            'value': '330212000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '196901',
            'label': '奉化区',
            'value': '330213000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '197311',
            'label': '象山县',
            'value': '330225000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '197872',
            'label': '宁海县',
            'value': '330226000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '198301',
            'label': '余姚市',
            'value': '330281000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '198649',
            'label': '慈溪市',
            'value': '330282000000'
          }
        ],
        'id': '195609',
        'label': '宁波市',
        'value': '330200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '199065',
            'label': '鹿城区',
            'value': '330302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '199248',
            'label': '龙湾区',
            'value': '330303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '199330',
            'label': '瓯海区',
            'value': '330304000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '199549',
            'label': '洞头区',
            'value': '330305000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '199650',
            'label': '永嘉县',
            'value': '330324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '200223',
            'label': '平阳县',
            'value': '330326000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '200697',
            'label': '苍南县',
            'value': '330327000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '201147',
            'label': '文成县',
            'value': '330328000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '201411',
            'label': '泰顺县',
            'value': '330329000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '201723',
            'label': '温州经济技术开发区',
            'value': '330371000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '201777',
            'label': '瑞安市',
            'value': '330381000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '202323',
            'label': '乐清市',
            'value': '330382000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '202863',
            'label': '龙港市',
            'value': '330383000000'
          }
        ],
        'id': '199064',
        'label': '温州市',
        'value': '330300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '202970',
            'label': '南湖区',
            'value': '330402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '203119',
            'label': '秀洲区',
            'value': '330411000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '203291',
            'label': '嘉善县',
            'value': '330421000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '203463',
            'label': '海盐县',
            'value': '330424000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '203602',
            'label': '海宁市',
            'value': '330481000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '203845',
            'label': '平湖市',
            'value': '330482000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '203989',
            'label': '桐乡市',
            'value': '330483000000'
          }
        ],
        'id': '202969',
        'label': '嘉兴市',
        'value': '330400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '204213',
            'label': '吴兴区',
            'value': '330502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '204579',
            'label': '南浔区',
            'value': '330503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '204847',
            'label': '德清县',
            'value': '330521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '205035',
            'label': '长兴县',
            'value': '330522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '205325',
            'label': '安吉县',
            'value': '330523000000'
          }
        ],
        'id': '204212',
        'label': '湖州市',
        'value': '330500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '205552',
            'label': '越城区',
            'value': '330602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '205928',
            'label': '柯桥区',
            'value': '330603000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '206288',
            'label': '上虞区',
            'value': '330604000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '206703',
            'label': '新昌县',
            'value': '330624000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '207009',
            'label': '诸暨市',
            'value': '330681000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '207559',
            'label': '嵊州市',
            'value': '330683000000'
          }
        ],
        'id': '205551',
        'label': '绍兴市',
        'value': '330600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '207862',
            'label': '婺城区',
            'value': '330702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '208319',
            'label': '金东区',
            'value': '330703000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '208686',
            'label': '武义县',
            'value': '330723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '208984',
            'label': '浦江县',
            'value': '330726000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '209244',
            'label': '磐安县',
            'value': '330727000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '209495',
            'label': '兰溪市',
            'value': '330781000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '209869',
            'label': '义乌市',
            'value': '330782000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '210428',
            'label': '东阳市',
            'value': '330783000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '210835',
            'label': '永康市',
            'value': '330784000000'
          }
        ],
        'id': '207861',
        'label': '金华市',
        'value': '330700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '211304',
            'label': '柯城区',
            'value': '330802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '211594',
            'label': '衢江区',
            'value': '330803000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '211901',
            'label': '常山县',
            'value': '330822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '212110',
            'label': '开化县',
            'value': '330824000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '212391',
            'label': '龙游县',
            'value': '330825000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '212683',
            'label': '江山市',
            'value': '330881000000'
          }
        ],
        'id': '211303',
        'label': '衢州市',
        'value': '330800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '213015',
            'label': '定海区',
            'value': '330902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '213188',
            'label': '普陀区',
            'value': '330903000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '213326',
            'label': '岱山县',
            'value': '330921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '213421',
            'label': '嵊泗县',
            'value': '330922000000'
          }
        ],
        'id': '213014',
        'label': '舟山市',
        'value': '330900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '213468',
            'label': '椒江区',
            'value': '331002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '213752',
            'label': '黄岩区',
            'value': '331003000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '214122',
            'label': '路桥区',
            'value': '331004000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '214366',
            'label': '三门县',
            'value': '331022000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '214667',
            'label': '天台县',
            'value': '331023000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '215078',
            'label': '仙居县',
            'value': '331024000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '215439',
            'label': '温岭市',
            'value': '331081000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '216127',
            'label': '临海市',
            'value': '331082000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '216820',
            'label': '玉环市',
            'value': '331083000000'
          }
        ],
        'id': '213467',
        'label': '台州市',
        'value': '331000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '217056',
            'label': '莲都区',
            'value': '331102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '217351',
            'label': '青田县',
            'value': '331121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '217779',
            'label': '缙云县',
            'value': '331122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '218057',
            'label': '遂昌县',
            'value': '331123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '218289',
            'label': '松阳县',
            'value': '331124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '218525',
            'label': '云和县',
            'value': '331125000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '218623',
            'label': '庆元县',
            'value': '331126000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '218843',
            'label': '景宁畲族自治县',
            'value': '331127000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '219012',
            'label': '龙泉市',
            'value': '331181000000'
          }
        ],
        'id': '217055',
        'label': '丽水市',
        'value': '331100000000'
      }
    ],
    'id': '12',
    'label': '浙江省',
    'value': '33'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '219282',
            'label': '瑶海区',
            'value': '340102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '219386',
            'label': '庐阳区',
            'value': '340103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '219470',
            'label': '蜀山区',
            'value': '340104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '219578',
            'label': '包河区',
            'value': '340111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '219704',
            'label': '长丰县',
            'value': '340121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '219997',
            'label': '肥东县',
            'value': '340122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '220353',
            'label': '肥西县',
            'value': '340123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '220646',
            'label': '庐江县',
            'value': '340124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '220896',
            'label': '合肥高新技术产业开发区',
            'value': '340171000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '220927',
            'label': '合肥经济技术开发区',
            'value': '340172000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '220992',
            'label': '合肥新站高新技术产业开发区',
            'value': '340173000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '221050',
            'label': '巢湖市',
            'value': '340181000000'
          }
        ],
        'id': '219281',
        'label': '合肥市',
        'value': '340100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '221263',
            'label': '镜湖区',
            'value': '340202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '221341',
            'label': '弋江区',
            'value': '340203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '221400',
            'label': '鸠江区',
            'value': '340207000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '221523',
            'label': '三山区',
            'value': '340208000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '221574',
            'label': '芜湖县',
            'value': '340221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '221668',
            'label': '繁昌县',
            'value': '340222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '221772',
            'label': '南陵县',
            'value': '340223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '221955',
            'label': '无为县',
            'value': '340225000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '222254',
            'label': '芜湖经济技术开发区',
            'value': '340271000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '222280',
            'label': '安徽芜湖长江大桥经济开发区',
            'value': '340272000000'
          }
        ],
        'id': '221262',
        'label': '芜湖市',
        'value': '340200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '222290',
            'label': '龙子湖区',
            'value': '340302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '222357',
            'label': '蚌山区',
            'value': '340303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '222405',
            'label': '禹会区',
            'value': '340304000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '222481',
            'label': '淮上区',
            'value': '340311000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '222571',
            'label': '怀远县',
            'value': '340321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '222956',
            'label': '五河县',
            'value': '340322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '223190',
            'label': '固镇县',
            'value': '340323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '223432',
            'label': '蚌埠市高新技术开发区',
            'value': '340371000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '223440',
            'label': '蚌埠市经济开发区',
            'value': '340372000000'
          }
        ],
        'id': '222289',
        'label': '蚌埠市',
        'value': '340300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '223489',
            'label': '大通区',
            'value': '340402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '223567',
            'label': '田家庵区',
            'value': '340403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '223722',
            'label': '谢家集区',
            'value': '340404000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '223829',
            'label': '八公山区',
            'value': '340405000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '223880',
            'label': '潘集区',
            'value': '340406000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '224073',
            'label': '凤台县',
            'value': '340421000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '224396',
            'label': '寿县',
            'value': '340422000000'
          }
        ],
        'id': '223488',
        'label': '淮南市',
        'value': '340400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '224711',
            'label': '花山区',
            'value': '340503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '224789',
            'label': '雨山区',
            'value': '340504000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '224864',
            'label': '博望区',
            'value': '340506000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '224913',
            'label': '当涂县',
            'value': '340521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '225085',
            'label': '含山县',
            'value': '340522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '225215',
            'label': '和县',
            'value': '340523000000'
          }
        ],
        'id': '224710',
        'label': '马鞍山市',
        'value': '340500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '225347',
            'label': '杜集区',
            'value': '340602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '225408',
            'label': '相山区',
            'value': '340603000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '225507',
            'label': '烈山区',
            'value': '340604000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '225579',
            'label': '濉溪县',
            'value': '340621000000'
          }
        ],
        'id': '225346',
        'label': '淮北市',
        'value': '340600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '225839',
            'label': '铜官区',
            'value': '340705000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '225904',
            'label': '义安区',
            'value': '340706000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '226031',
            'label': '郊区',
            'value': '340711000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '226123',
            'label': '枞阳县',
            'value': '340722000000'
          }
        ],
        'id': '225838',
        'label': '铜陵市',
        'value': '340700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '226359',
            'label': '迎江区',
            'value': '340802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '226416',
            'label': '大观区',
            'value': '340803000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '226489',
            'label': '宜秀区',
            'value': '340811000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '226556',
            'label': '怀宁县',
            'value': '340822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '226820',
            'label': '太湖县',
            'value': '340825000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '227026',
            'label': '宿松县',
            'value': '340826000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '227272',
            'label': '望江县',
            'value': '340827000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '227422',
            'label': '岳西县',
            'value': '340828000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '227637',
            'label': '安徽安庆经济开发区',
            'value': '340871000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '227666',
            'label': '桐城市',
            'value': '340881000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '227908',
            'label': '潜山市',
            'value': '340882000000'
          }
        ],
        'id': '226358',
        'label': '安庆市',
        'value': '340800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '228112',
            'label': '屯溪区',
            'value': '341002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '228198',
            'label': '黄山区',
            'value': '341003000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '228300',
            'label': '徽州区',
            'value': '341004000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '228364',
            'label': '歙县',
            'value': '341021000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '228590',
            'label': '休宁县',
            'value': '341022000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '228770',
            'label': '黟县',
            'value': '341023000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '228849',
            'label': '祁门县',
            'value': '341024000000'
          }
        ],
        'id': '228111',
        'label': '黄山市',
        'value': '341000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '228987',
            'label': '琅琊区',
            'value': '341102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '229051',
            'label': '南谯区',
            'value': '341103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '229155',
            'label': '来安县',
            'value': '341122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '229316',
            'label': '全椒县',
            'value': '341124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '229442',
            'label': '定远县',
            'value': '341125000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '229729',
            'label': '凤阳县',
            'value': '341126000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '229993',
            'label': '苏滁现代产业园',
            'value': '341171000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '229999',
            'label': '滁州经济技术开发区',
            'value': '341172000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '230018',
            'label': '天长市',
            'value': '341181000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '230208',
            'label': '明光市',
            'value': '341182000000'
          }
        ],
        'id': '228986',
        'label': '滁州市',
        'value': '341100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '230384',
            'label': '颍州区',
            'value': '341202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '230546',
            'label': '颍东区',
            'value': '341203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '230676',
            'label': '颍泉区',
            'value': '341204000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '230807',
            'label': '临泉县',
            'value': '341221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '231231',
            'label': '太和县',
            'value': '341222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '231574',
            'label': '阜南县',
            'value': '341225000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '231935',
            'label': '颍上县',
            'value': '341226000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '232317',
            'label': '阜阳合肥现代产业园区',
            'value': '341271000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '232328',
            'label': '阜阳经济技术开发区',
            'value': '341272000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '232346',
            'label': '界首市',
            'value': '341282000000'
          }
        ],
        'id': '230383',
        'label': '阜阳市',
        'value': '341200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '232525',
            'label': '埇桥区',
            'value': '341302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '232942',
            'label': '砀山县',
            'value': '341321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '233126',
            'label': '萧县',
            'value': '341322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '233425',
            'label': '灵璧县',
            'value': '341323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '233756',
            'label': '泗县',
            'value': '341324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '233968',
            'label': '宿州马鞍山现代产业园区',
            'value': '341371000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '233981',
            'label': '宿州经济技术开发区',
            'value': '341372000000'
          }
        ],
        'id': '232524',
        'label': '宿州市',
        'value': '341300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '233993',
            'label': '金安区',
            'value': '341502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '234359',
            'label': '裕安区',
            'value': '341503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '234693',
            'label': '叶集区',
            'value': '341504000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '234777',
            'label': '霍邱县',
            'value': '341522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '235223',
            'label': '舒城县',
            'value': '341523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '235672',
            'label': '金寨县',
            'value': '341524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '235926',
            'label': '霍山县',
            'value': '341525000000'
          }
        ],
        'id': '233992',
        'label': '六安市',
        'value': '341500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '236091',
            'label': '谯城区',
            'value': '341602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '236431',
            'label': '涡阳县',
            'value': '341621000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '236839',
            'label': '蒙城县',
            'value': '341622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '237151',
            'label': '利辛县',
            'value': '341623000000'
          }
        ],
        'id': '236090',
        'label': '亳州市',
        'value': '341600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '237539',
            'label': '贵池区',
            'value': '341702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '237792',
            'label': '东至县',
            'value': '341721000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '238063',
            'label': '石台县',
            'value': '341722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '238158',
            'label': '青阳县',
            'value': '341723000000'
          }
        ],
        'id': '237538',
        'label': '池州市',
        'value': '341700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '238304',
            'label': '宣州区',
            'value': '341802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '238539',
            'label': '郎溪县',
            'value': '341821000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '238657',
            'label': '泾县',
            'value': '341823000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '238813',
            'label': '绩溪县',
            'value': '341824000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '238908',
            'label': '旌德县',
            'value': '341825000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '238987',
            'label': '宣城市经济开发区',
            'value': '341871000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '239012',
            'label': '宁国市',
            'value': '341881000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '239149',
            'label': '广德市',
            'value': '341882000000'
          }
        ],
        'id': '238303',
        'label': '宣城市',
        'value': '341800000000'
      }
    ],
    'id': '13',
    'label': '安徽省',
    'value': '34'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '239300',
            'label': '鼓楼区',
            'value': '350102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '239380',
            'label': '台江区',
            'value': '350103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '239443',
            'label': '仓山区',
            'value': '350104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '239639',
            'label': '马尾区',
            'value': '350105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '239720',
            'label': '晋安区',
            'value': '350111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '239924',
            'label': '长乐区',
            'value': '350112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '240204',
            'label': '闽侯县',
            'value': '350121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '240550',
            'label': '连江县',
            'value': '350122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '240857',
            'label': '罗源县',
            'value': '350123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '241072',
            'label': '闽清县',
            'value': '350124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '241381',
            'label': '永泰县',
            'value': '350125000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '241670',
            'label': '平潭县',
            'value': '350128000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '241905',
            'label': '福清市',
            'value': '350181000000'
          }
        ],
        'id': '239299',
        'label': '福州市',
        'value': '350100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '242445',
            'label': '思明区',
            'value': '350203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '242555',
            'label': '海沧区',
            'value': '350205000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '242607',
            'label': '湖里区',
            'value': '350206000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '242673',
            'label': '集美区',
            'value': '350211000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '242755',
            'label': '同安区',
            'value': '350212000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '242905',
            'label': '翔安区',
            'value': '350213000000'
          }
        ],
        'id': '242444',
        'label': '厦门市',
        'value': '350200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '243034',
            'label': '城厢区',
            'value': '350302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '243162',
            'label': '涵江区',
            'value': '350303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '243376',
            'label': '荔城区',
            'value': '350304000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '243513',
            'label': '秀屿区',
            'value': '350305000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '243727',
            'label': '仙游县',
            'value': '350322000000'
          }
        ],
        'id': '243033',
        'label': '莆田市',
        'value': '350300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '244074',
            'label': '梅列区',
            'value': '350402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '244133',
            'label': '三元区',
            'value': '350403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '244222',
            'label': '明溪县',
            'value': '350421000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '244328',
            'label': '清流县',
            'value': '350423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '244462',
            'label': '宁化县',
            'value': '350424000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '244708',
            'label': '大田县',
            'value': '350425000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '245007',
            'label': '尤溪县',
            'value': '350426000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '245291',
            'label': '沙县',
            'value': '350427000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '245497',
            'label': '将乐县',
            'value': '350428000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '245665',
            'label': '泰宁县',
            'value': '350429000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '245794',
            'label': '建宁县',
            'value': '350430000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '245905',
            'label': '永安市',
            'value': '350481000000'
          }
        ],
        'id': '244073',
        'label': '三明市',
        'value': '350400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '246184',
            'label': '鲤城区',
            'value': '350502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '246277',
            'label': '丰泽区',
            'value': '350503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '246366',
            'label': '洛江区',
            'value': '350504000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '246460',
            'label': '泉港区',
            'value': '350505000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '246570',
            'label': '惠安县',
            'value': '350521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '246887',
            'label': '安溪县',
            'value': '350524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '247403',
            'label': '永春县',
            'value': '350525000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '247662',
            'label': '德化县350527000000金门县',
            'value': '350526000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '247895',
            'label': '石狮市',
            'value': '350581000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '248033',
            'label': '晋江市',
            'value': '350582000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '248457',
            'label': '南安市',
            'value': '350583000000'
          }
        ],
        'id': '246183',
        'label': '泉州市',
        'value': '350500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '248912',
            'label': '芗城区',
            'value': '350602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '249103',
            'label': '龙文区',
            'value': '350603000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '249188',
            'label': '云霄县',
            'value': '350622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '249410',
            'label': '漳浦县',
            'value': '350623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '249787',
            'label': '诏安县',
            'value': '350624000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '250063',
            'label': '长泰县',
            'value': '350625000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '250157',
            'label': '东山县',
            'value': '350626000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '250245',
            'label': '南靖县',
            'value': '350627000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '250459',
            'label': '平和县',
            'value': '350628000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '250739',
            'label': '华安县',
            'value': '350629000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '250851',
            'label': '龙海市',
            'value': '350681000000'
          }
        ],
        'id': '248911',
        'label': '漳州市',
        'value': '350600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '251176',
            'label': '延平区',
            'value': '350702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '251515',
            'label': '建阳区',
            'value': '350703000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '251753',
            'label': '顺昌县',
            'value': '350721000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '251916',
            'label': '浦城县',
            'value': '350722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '252244',
            'label': '光泽县',
            'value': '350723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '252356',
            'label': '松溪县',
            'value': '350724000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '252475',
            'label': '政和县',
            'value': '350725000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '252623',
            'label': '邵武市',
            'value': '350781000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '252824',
            'label': '武夷山市',
            'value': '350782000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '252977',
            'label': '建瓯市',
            'value': '350783000000'
          }
        ],
        'id': '251175',
        'label': '南平市',
        'value': '350700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '253253',
            'label': '新罗区',
            'value': '350802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '253617',
            'label': '永定区',
            'value': '350803000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '253924',
            'label': '长汀县',
            'value': '350821000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '254244',
            'label': '上杭县',
            'value': '350823000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '254612',
            'label': '武平县',
            'value': '350824000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '254855',
            'label': '连城县',
            'value': '350825000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '255121',
            'label': '漳平市',
            'value': '350881000000'
          }
        ],
        'id': '253252',
        'label': '龙岩市',
        'value': '350800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '255331',
            'label': '蕉城区',
            'value': '350902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '255679',
            'label': '霞浦县',
            'value': '350921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '256014',
            'label': '古田县',
            'value': '350922000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '256321',
            'label': '屏南县',
            'value': '350923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '256492',
            'label': '寿宁县',
            'value': '350924000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '256712',
            'label': '周宁县',
            'value': '350925000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '256869',
            'label': '柘荣县',
            'value': '350926000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '256995',
            'label': '福安市',
            'value': '350981000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '257506',
            'label': '福鼎市',
            'value': '350982000000'
          }
        ],
        'id': '255330',
        'label': '宁德市',
        'value': '350900000000'
      }
    ],
    'id': '14',
    'label': '福建省',
    'value': '35'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '257811',
            'label': '东湖区',
            'value': '360102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '258005',
            'label': '西湖区',
            'value': '360103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '258179',
            'label': '青云谱区',
            'value': '360104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '258278',
            'label': '湾里区',
            'value': '360105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '258344',
            'label': '青山湖区',
            'value': '360111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '258655',
            'label': '新建区',
            'value': '360112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '259125',
            'label': '南昌县',
            'value': '360121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '259575',
            'label': '安义县',
            'value': '360123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '259720',
            'label': '进贤县',
            'value': '360124000000'
          }
        ],
        'id': '257810',
        'label': '南昌市',
        'value': '360100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '260078',
            'label': '昌江区',
            'value': '360202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '260172',
            'label': '珠山区',
            'value': '360203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '260253',
            'label': '浮梁县',
            'value': '360222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '260467',
            'label': '乐平市',
            'value': '360281000000'
          }
        ],
        'id': '260077',
        'label': '景德镇市',
        'value': '360200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '260851',
            'label': '安源区',
            'value': '360302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '261001',
            'label': '湘东区',
            'value': '360313000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '261169',
            'label': '莲花县',
            'value': '360321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '261346',
            'label': '上栗县',
            'value': '360322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '261531',
            'label': '芦溪县',
            'value': '360323000000'
          }
        ],
        'id': '260850',
        'label': '萍乡市',
        'value': '360300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '261699',
            'label': '濂溪区',
            'value': '360402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '261843',
            'label': '浔阳区',
            'value': '360403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '261955',
            'label': '柴桑区',
            'value': '360404000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '262120',
            'label': '武宁县',
            'value': '360423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '262347',
            'label': '修水县',
            'value': '360424000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '262794',
            'label': '永修县',
            'value': '360425000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '263049',
            'label': '德安县',
            'value': '360426000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '263163',
            'label': '都昌县',
            'value': '360428000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '263499',
            'label': '湖口县',
            'value': '360429000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '263665',
            'label': '彭泽县',
            'value': '360430000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '263876',
            'label': '瑞昌市',
            'value': '360481000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '264121',
            'label': '共青城市',
            'value': '360482000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '264194',
            'label': '庐山市',
            'value': '360483000000'
          }
        ],
        'id': '261698',
        'label': '九江市',
        'value': '360400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '264310',
            'label': '渝水区',
            'value': '360502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '264666',
            'label': '分宜县',
            'value': '360521000000'
          }
        ],
        'id': '264309',
        'label': '新余市',
        'value': '360500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '264859',
            'label': '月湖区',
            'value': '360602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '264953',
            'label': '余江区',
            'value': '360603000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '265150',
            'label': '贵溪市',
            'value': '360681000000'
          }
        ],
        'id': '264858',
        'label': '鹰潭市',
        'value': '360600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '265437',
            'label': '章贡区',
            'value': '360702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '265667',
            'label': '南康区',
            'value': '360703000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '266018',
            'label': '赣县区',
            'value': '360704000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '266340',
            'label': '信丰县',
            'value': '360722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '266664',
            'label': '大余县',
            'value': '360723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '266797',
            'label': '上犹县',
            'value': '360724000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '266961',
            'label': '崇义县',
            'value': '360725000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '267112',
            'label': '安远县',
            'value': '360726000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '267300',
            'label': '龙南县',
            'value': '360727000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '267425',
            'label': '定南县',
            'value': '360728000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '267572',
            'label': '全南县',
            'value': '360729000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '267680',
            'label': '宁都县',
            'value': '360730000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '268041',
            'label': '于都县',
            'value': '360731000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '268458',
            'label': '兴国县',
            'value': '360732000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '268803',
            'label': '会昌县',
            'value': '360733000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '269100',
            'label': '寻乌县',
            'value': '360734000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '269300',
            'label': '石城县',
            'value': '360735000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '269467',
            'label': '瑞金市',
            'value': '360781000000'
          }
        ],
        'id': '265436',
        'label': '赣州市',
        'value': '360700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '269728',
            'label': '吉州区',
            'value': '360802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '269876',
            'label': '青原区',
            'value': '360803000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '270021',
            'label': '吉安县',
            'value': '360821000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '270396',
            'label': '吉水县',
            'value': '360822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '270700',
            'label': '峡江县',
            'value': '360823000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '270806',
            'label': '新干县',
            'value': '360824000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '270989',
            'label': '永丰县',
            'value': '360825000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '271259',
            'label': '泰和县',
            'value': '360826000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '271634',
            'label': '遂川县',
            'value': '360827000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '272006',
            'label': '万安县',
            'value': '360828000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '272183',
            'label': '安福县',
            'value': '360829000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '272479',
            'label': '永新县',
            'value': '360830000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '272773',
            'label': '井冈山市',
            'value': '360881000000'
          }
        ],
        'id': '269727',
        'label': '吉安市',
        'value': '360800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '272932',
            'label': '袁州区',
            'value': '360902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '273356',
            'label': '奉新县',
            'value': '360921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '273573',
            'label': '万载县',
            'value': '360922000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '273800',
            'label': '上高县',
            'value': '360923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '274045',
            'label': '宜丰县',
            'value': '360924000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '274309',
            'label': '靖安县',
            'value': '360925000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '274430',
            'label': '铜鼓县',
            'value': '360926000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '274556',
            'label': '丰城市',
            'value': '360981000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '275198',
            'label': '樟树市',
            'value': '360982000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '275527',
            'label': '高安市',
            'value': '360983000000'
          }
        ],
        'id': '272931',
        'label': '宜春市',
        'value': '360900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '275935',
            'label': '临川区',
            'value': '361002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '276477',
            'label': '东乡区',
            'value': '361003000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '276679',
            'label': '南城县',
            'value': '361021000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '276867',
            'label': '黎川县',
            'value': '361022000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '277026',
            'label': '南丰县',
            'value': '361023000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '277228',
            'label': '崇仁县',
            'value': '361024000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '277415',
            'label': '乐安县',
            'value': '361025000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '277625',
            'label': '宜黄县',
            'value': '361026000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '277796',
            'label': '金溪县',
            'value': '361027000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '277983',
            'label': '资溪县',
            'value': '361028000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '278074',
            'label': '广昌县',
            'value': '361030000000'
          }
        ],
        'id': '275934',
        'label': '抚州市',
        'value': '361000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '278234',
            'label': '信州区',
            'value': '361102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '278375',
            'label': '广丰区',
            'value': '361103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '278639',
            'label': '广信区',
            'value': '361104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '278924',
            'label': '玉山县',
            'value': '361123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '279182',
            'label': '铅山县',
            'value': '361124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '279386',
            'label': '横峰县',
            'value': '361125000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '279481',
            'label': '弋阳县',
            'value': '361126000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '279656',
            'label': '余干县',
            'value': '361127000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '280127',
            'label': '鄱阳县',
            'value': '361128000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '280740',
            'label': '万年县',
            'value': '361129000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '280916',
            'label': '婺源县',
            'value': '361130000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '281140',
            'label': '德兴市',
            'value': '361181000000'
          }
        ],
        'id': '278233',
        'label': '上饶市',
        'value': '361100000000'
      }
    ],
    'id': '15',
    'label': '江西省',
    'value': '36'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '281303',
            'label': '历下区',
            'value': '370102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '281437',
            'label': '市中区',
            'value': '370103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '281660',
            'label': '槐荫区',
            'value': '370104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '281864',
            'label': '天桥区',
            'value': '370105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '282143',
            'label': '历城区',
            'value': '370112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '282762',
            'label': '长清区',
            'value': '370113000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '283412',
            'label': '章丘区',
            'value': '370114000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '284358',
            'label': '济阳区',
            'value': '370115000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '285227',
            'label': '莱芜区',
            'value': '370116000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '286083',
            'label': '钢城区',
            'value': '370117000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '286326',
            'label': '平阴县',
            'value': '370124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '286692',
            'label': '商河县',
            'value': '370126000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '287668',
            'label': '济南高新技术产业开发区',
            'value': '370171000000'
          }
        ],
        'id': '281302',
        'label': '济南市',
        'value': '370100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '287869',
            'label': '市南区',
            'value': '370202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '287931',
            'label': '市北区',
            'value': '370203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '288091',
            'label': '黄岛区',
            'value': '370211000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '289348',
            'label': '崂山区',
            'value': '370212000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '289516',
            'label': '李沧区',
            'value': '370213000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '289644',
            'label': '城阳区',
            'value': '370214000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '289879',
            'label': '即墨区',
            'value': '370215000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '291005',
            'label': '青岛高新技术产业开发区',
            'value': '370271000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '291045',
            'label': '胶州市',
            'value': '370281000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '291961',
            'label': '平度市',
            'value': '370283000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '293836',
            'label': '莱西市',
            'value': '370285000000'
          }
        ],
        'id': '287868',
        'label': '青岛市',
        'value': '370200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '294746',
            'label': '淄川区',
            'value': '370302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '295230',
            'label': '张店区',
            'value': '370303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '295550',
            'label': '博山区',
            'value': '370304000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '295859',
            'label': '临淄区',
            'value': '370305000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '296345',
            'label': '周村区',
            'value': '370306000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '296612',
            'label': '桓台县',
            'value': '370321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '296972',
            'label': '高青县',
            'value': '370322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '297300',
            'label': '沂源县',
            'value': '370323000000'
          }
        ],
        'id': '294745',
        'label': '淄博市',
        'value': '370300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '297957',
            'label': '市中区',
            'value': '370402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '298139',
            'label': '薛城区',
            'value': '370403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '298436',
            'label': '峄城区',
            'value': '370404000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '298781',
            'label': '台儿庄区',
            'value': '370405000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '298999',
            'label': '山亭区',
            'value': '370406000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '299287',
            'label': '滕州市',
            'value': '370481000000'
          }
        ],
        'id': '297956',
        'label': '枣庄市',
        'value': '370400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '300560',
            'label': '东营区',
            'value': '370502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '300965',
            'label': '河口区',
            'value': '370503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '301168',
            'label': '垦利区',
            'value': '370505000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '301539',
            'label': '利津县',
            'value': '370522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '302078',
            'label': '广饶县',
            'value': '370523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '302670',
            'label': '东营经济技术开发区',
            'value': '370571000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '302678',
            'label': '东营港经济开发区',
            'value': '370572000000'
          }
        ],
        'id': '300559',
        'label': '东营市',
        'value': '370500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '302683',
            'label': '芝罘区',
            'value': '370602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '302844',
            'label': '福山区',
            'value': '370611000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '303106',
            'label': '牟平区',
            'value': '370612000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '303711',
            'label': '莱山区',
            'value': '370613000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '303844',
            'label': '长岛县',
            'value': '370634000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '303893',
            'label': '烟台高新技术产业开发区',
            'value': '370671000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '303916',
            'label': '烟台经济技术开发区',
            'value': '370672000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '304054',
            'label': '龙口市',
            'value': '370681000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '304664',
            'label': '莱阳市',
            'value': '370682000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '305477',
            'label': '莱州市',
            'value': '370683000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '306509',
            'label': '蓬莱市',
            'value': '370684000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '307110',
            'label': '招远市',
            'value': '370685000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '307871',
            'label': '栖霞市',
            'value': '370686000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '308841',
            'label': '海阳市',
            'value': '370687000000'
          }
        ],
        'id': '302682',
        'label': '烟台市',
        'value': '370600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '309593',
            'label': '潍城区',
            'value': '370702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '309856',
            'label': '寒亭区',
            'value': '370703000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '310235',
            'label': '坊子区',
            'value': '370704000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '310822',
            'label': '奎文区',
            'value': '370705000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '311062',
            'label': '临朐县',
            'value': '370724000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '311423',
            'label': '昌乐县',
            'value': '370725000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '311802',
            'label': '潍坊滨海经济技术开发区',
            'value': '370772000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '311855',
            'label': '青州市',
            'value': '370781000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '312947',
            'label': '诸城市',
            'value': '370782000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '313219',
            'label': '寿光市',
            'value': '370783000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '314247',
            'label': '安丘市',
            'value': '370784000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '315126',
            'label': '高密市',
            'value': '370785000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '316085',
            'label': '昌邑市',
            'value': '370786000000'
          }
        ],
        'id': '309592',
        'label': '潍坊市',
        'value': '370700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '316787',
            'label': '任城区',
            'value': '370811000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '317400',
            'label': '兖州区',
            'value': '370812000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '317854',
            'label': '微山县',
            'value': '370826000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '318412',
            'label': '鱼台县',
            'value': '370827000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '318817',
            'label': '金乡县',
            'value': '370828000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '319478',
            'label': '嘉祥县',
            'value': '370829000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '320183',
            'label': '汶上县',
            'value': '370830000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '320701',
            'label': '泗水县',
            'value': '370831000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '321311',
            'label': '梁山县',
            'value': '370832000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '321961',
            'label': '济宁高新技术产业开发区',
            'value': '370871000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '322158',
            'label': '曲阜市',
            'value': '370881000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '322629',
            'label': '邹城市',
            'value': '370883000000'
          }
        ],
        'id': '316786',
        'label': '济宁市',
        'value': '370800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '323547',
            'label': '泰山区',
            'value': '370902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '323765',
            'label': '岱岳区',
            'value': '370911000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '324494',
            'label': '宁阳县',
            'value': '370921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '325070',
            'label': '东平县',
            'value': '370923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '325801',
            'label': '新泰市',
            'value': '370982000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '326736',
            'label': '肥城市',
            'value': '370983000000'
          }
        ],
        'id': '323546',
        'label': '泰安市',
        'value': '370900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '327385',
            'label': '环翠区',
            'value': '371002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '327560',
            'label': '文登区',
            'value': '371003000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '328348',
            'label': '威海火炬高技术产业开发区',
            'value': '371071000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '328439',
            'label': '威海经济技术开发区',
            'value': '371072000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '328590',
            'label': '威海临港经济技术开发区',
            'value': '371073000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '328762',
            'label': '荣成市',
            'value': '371082000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '329722',
            'label': '乳山市',
            'value': '371083000000'
          }
        ],
        'id': '327384',
        'label': '威海市',
        'value': '371000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '330367',
            'label': '东港区',
            'value': '371102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '331012',
            'label': '岚山区',
            'value': '371103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '331292',
            'label': '五莲县',
            'value': '371121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '331858',
            'label': '莒县',
            'value': '371122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '332044',
            'label': '日照经济技术开发区',
            'value': '371171000000'
          }
        ],
        'id': '330366',
        'label': '日照市',
        'value': '371100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '332137',
            'label': '兰山区',
            'value': '371302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '332470',
            'label': '罗庄区',
            'value': '371311000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '332607',
            'label': '河东区',
            'value': '371312000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '332806',
            'label': '沂南县',
            'value': '371321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '333118',
            'label': '郯城县',
            'value': '371322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '333258',
            'label': '沂水县',
            'value': '371323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '333659',
            'label': '兰陵县',
            'value': '371324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '334279',
            'label': '费县',
            'value': '371325000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '334719',
            'label': '平邑县',
            'value': '371326000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '335314',
            'label': '莒南县',
            'value': '371327000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '335566',
            'label': '蒙阴县',
            'value': '371328000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '335941',
            'label': '临沭县',
            'value': '371329000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '336187',
            'label': '临沂高新技术产业开发区',
            'value': '371371000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '336229',
            'label': '临沂经济技术开发区',
            'value': '371372000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '336304',
            'label': '临沂临港经济开发区',
            'value': '371373000000'
          }
        ],
        'id': '332136',
        'label': '临沂市',
        'value': '371300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '336337',
            'label': '德城区',
            'value': '371402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '336447',
            'label': '陵城区',
            'value': '371403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '337429',
            'label': '宁津县',
            'value': '371422000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '338306',
            'label': '庆云县',
            'value': '371423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '338699',
            'label': '临邑县',
            'value': '371424000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '339596',
            'label': '齐河县',
            'value': '371425000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '340604',
            'label': '平原县',
            'value': '371426000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '341478',
            'label': '夏津县',
            'value': '371427000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '342008',
            'label': '武城县',
            'value': '371428000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '342416',
            'label': '德州经济技术开发区',
            'value': '371471000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '342634',
            'label': '德州运河经济开发区',
            'value': '371472000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '342661',
            'label': '乐陵市',
            'value': '371481000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '343730',
            'label': '禹城市',
            'value': '371482000000'
          }
        ],
        'id': '336336',
        'label': '德州市',
        'value': '371400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '344640',
            'label': '东昌府区',
            'value': '371502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '345799',
            'label': '茌平区',
            'value': '371503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '346546',
            'label': '阳谷县',
            'value': '371521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '347438',
            'label': '莘县',
            'value': '371522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '348589',
            'label': '东阿县',
            'value': '371524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '349120',
            'label': '冠县',
            'value': '371525000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '349903',
            'label': '高唐县',
            'value': '371526000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '350561',
            'label': '临清市',
            'value': '371581000000'
          }
        ],
        'id': '344639',
        'label': '聊城市',
        'value': '371500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '351148',
            'label': '滨城区',
            'value': '371602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '351998',
            'label': '沾化区',
            'value': '371603000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '352458',
            'label': '惠民县',
            'value': '371621000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '352582',
            'label': '阳信县',
            'value': '371622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '353450',
            'label': '无棣县',
            'value': '371623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '354061',
            'label': '博兴县',
            'value': '371625000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '354521',
            'label': '邹平市',
            'value': '371681000000'
          }
        ],
        'id': '351147',
        'label': '滨州市',
        'value': '371600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '355398',
            'label': '牡丹区',
            'value': '371702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '356009',
            'label': '定陶区',
            'value': '371703000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '356389',
            'label': '曹县',
            'value': '371721000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '357224',
            'label': '单县',
            'value': '371722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '357755',
            'label': '成武县',
            'value': '371723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '358254',
            'label': '巨野县',
            'value': '371724000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '358906',
            'label': '郓城县',
            'value': '371725000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '359620',
            'label': '鄄城县',
            'value': '371726000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '360029',
            'label': '东明县',
            'value': '371728000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '360453',
            'label': '菏泽经济技术开发区',
            'value': '371771000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '360566',
            'label': '菏泽高新技术开发区',
            'value': '371772000000'
          }
        ],
        'id': '355397',
        'label': '菏泽市',
        'value': '371700000000'
      }
    ],
    'id': '16',
    'label': '山东省',
    'value': '37'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '360636',
            'label': '中原区',
            'value': '410102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '360797',
            'label': '二七区',
            'value': '410103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '360990',
            'label': '管城回族区',
            'value': '410104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '361138',
            'label': '金水区',
            'value': '410105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '361424',
            'label': '上街区',
            'value': '410106000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '361490',
            'label': '惠济区',
            'value': '410108000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '361574',
            'label': '中牟县',
            'value': '410122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '361928',
            'label': '郑州经济技术开发区',
            'value': '410171000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '361999',
            'label': '郑州高新技术产业开发区',
            'value': '410172000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '362075',
            'label': '郑州航空港经济综合实验区',
            'value': '410173000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '362261',
            'label': '巩义市',
            'value': '410181000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '362602',
            'label': '荥阳市',
            'value': '410182000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '362931',
            'label': '新密市',
            'value': '410183000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '363302',
            'label': '新郑市',
            'value': '410184000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '363601',
            'label': '登封市',
            'value': '410185000000'
          }
        ],
        'id': '360635',
        'label': '郑州市',
        'value': '410100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '363943',
            'label': '龙亭区',
            'value': '410202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '364115',
            'label': '顺河回族区',
            'value': '410203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '364194',
            'label': '鼓楼区',
            'value': '410204000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '364245',
            'label': '禹王台区',
            'value': '410205000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '364302',
            'label': '祥符区',
            'value': '410212000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '364664',
            'label': '杞县',
            'value': '410221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '365287',
            'label': '通许县',
            'value': '410222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '365609',
            'label': '尉氏县',
            'value': '410223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '366148',
            'label': '兰考县',
            'value': '410225000000'
          }
        ],
        'id': '363942',
        'label': '开封市',
        'value': '410200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '366634',
            'label': '老城区',
            'value': '410302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '366692',
            'label': '西工区',
            'value': '410303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '366767',
            'label': '瀍河回族区',
            'value': '410304000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '366808',
            'label': '涧西区',
            'value': '410305000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '366894',
            'label': '吉利区',
            'value': '410306000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '366937',
            'label': '洛龙区',
            'value': '410311000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '367240',
            'label': '孟津县',
            'value': '410322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '367485',
            'label': '新安县',
            'value': '410323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '367808',
            'label': '栾川县',
            'value': '410324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '368036',
            'label': '嵩县',
            'value': '410325000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '368381',
            'label': '汝阳县',
            'value': '410326000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '368616',
            'label': '宜阳县',
            'value': '410327000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '368995',
            'label': '洛宁县',
            'value': '410328000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '369404',
            'label': '伊川县',
            'value': '410329000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '369790',
            'label': '洛阳高新技术产业开发区',
            'value': '410371000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '369831',
            'label': '偃师市',
            'value': '410381000000'
          }
        ],
        'id': '366633',
        'label': '洛阳市',
        'value': '410300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '370078',
            'label': '新华区',
            'value': '410402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '370156',
            'label': '卫东区',
            'value': '410403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '370227',
            'label': '石龙区',
            'value': '410404000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '370258',
            'label': '湛河区',
            'value': '410411000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '370372',
            'label': '宝丰县',
            'value': '410421000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '370706',
            'label': '叶县',
            'value': '410422000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '371279',
            'label': '鲁山县',
            'value': '410423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '371860',
            'label': '郏县',
            'value': '410425000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '372253',
            'label': '平顶山高新技术产业开发区',
            'value': '410471000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '372286',
            'label': '平顶山市城乡一体化示范区',
            'value': '410472000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '372338',
            'label': '舞钢市',
            'value': '410481000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '372573',
            'label': '汝州市',
            'value': '410482000000'
          }
        ],
        'id': '370077',
        'label': '平顶山市',
        'value': '410400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '373062',
            'label': '文峰区',
            'value': '410502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '373191',
            'label': '北关区',
            'value': '410503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '373318',
            'label': '殷都区',
            'value': '410505000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '373414',
            'label': '龙安区',
            'value': '410506000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '373621',
            'label': '安阳县',
            'value': '410522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '374194',
            'label': '汤阴县',
            'value': '410523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '374511',
            'label': '滑县',
            'value': '410526000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '375544',
            'label': '内黄县',
            'value': '410527000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '376094',
            'label': '安阳高新技术产业开发区',
            'value': '410571000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '376131',
            'label': '林州市',
            'value': '410581000000'
          }
        ],
        'id': '373061',
        'label': '安阳市',
        'value': '410500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '376738',
            'label': '鹤山区',
            'value': '410602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '376827',
            'label': '山城区',
            'value': '410603000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '376937',
            'label': '淇滨区',
            'value': '410611000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '377103',
            'label': '浚县',
            'value': '410621000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '377584',
            'label': '淇县',
            'value': '410622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '377770',
            'label': '鹤壁经济技术开发区',
            'value': '410671000000'
          }
        ],
        'id': '376737',
        'label': '鹤壁市',
        'value': '410600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '377789',
            'label': '红旗区',
            'value': '410702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '377876',
            'label': '卫滨区',
            'value': '410703000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '377939',
            'label': '凤泉区',
            'value': '410704000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '377991',
            'label': '牧野区',
            'value': '410711000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '378098',
            'label': '新乡县',
            'value': '410721000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '378286',
            'label': '获嘉县',
            'value': '410724000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '378528',
            'label': '原阳县',
            'value': '410725000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '379001',
            'label': '延津县',
            'value': '410726000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '379362',
            'label': '封丘县',
            'value': '410727000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '379990',
            'label': '新乡高新技术产业开发区',
            'value': '410771000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '380020',
            'label': '新乡经济技术开发区',
            'value': '410772000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '380039',
            'label': '新乡市平原城乡一体化示范区',
            'value': '410773000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '380178',
            'label': '卫辉市',
            'value': '410781000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '380559',
            'label': '辉县市',
            'value': '410782000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '381141',
            'label': '长垣市',
            'value': '410783000000'
          }
        ],
        'id': '377788',
        'label': '新乡市',
        'value': '410700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '381778',
            'label': '解放区',
            'value': '410802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '381845',
            'label': '中站区',
            'value': '410803000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '381905',
            'label': '马村区',
            'value': '410804000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '381991',
            'label': '山阳区',
            'value': '410811000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '382055',
            'label': '修武县',
            'value': '410821000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '382258',
            'label': '博爱县',
            'value': '410822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '382486',
            'label': '武陟县',
            'value': '410823000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '382854',
            'label': '温县',
            'value': '410825000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '383135',
            'label': '焦作城乡一体化示范区',
            'value': '410871000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '383246',
            'label': '沁阳市',
            'value': '410882000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '383600',
            'label': '孟州市',
            'value': '410883000000'
          }
        ],
        'id': '381777',
        'label': '焦作市',
        'value': '410800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '383902',
            'label': '华龙区',
            'value': '410902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '384065',
            'label': '清丰县',
            'value': '410922000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '384592',
            'label': '南乐县',
            'value': '410923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '384934',
            'label': '范县',
            'value': '410926000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '385530',
            'label': '台前县',
            'value': '410927000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '385917',
            'label': '濮阳县',
            'value': '410928000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '386924',
            'label': '河南濮阳工业园区',
            'value': '410971000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '386949',
            'label': '濮阳经济技术开发区',
            'value': '410972000000'
          }
        ],
        'id': '383901',
        'label': '濮阳市',
        'value': '410900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '387124',
            'label': '魏都区',
            'value': '411002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '387235',
            'label': '建安区',
            'value': '411003000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '387701',
            'label': '鄢陵县',
            'value': '411024000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '388100',
            'label': '襄城县',
            'value': '411025000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '388565',
            'label': '许昌经济技术开发区',
            'value': '411071000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '388593',
            'label': '禹州市',
            'value': '411081000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '389299',
            'label': '长葛市',
            'value': '411082000000'
          }
        ],
        'id': '387123',
        'label': '许昌市',
        'value': '411000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '389691',
            'label': '源汇区',
            'value': '411102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '389829',
            'label': '郾城区',
            'value': '411103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '390039',
            'label': '召陵区',
            'value': '411104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '390254',
            'label': '舞阳县',
            'value': '411121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '390666',
            'label': '临颍县',
            'value': '411122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '391050',
            'label': '漯河经济技术开发区',
            'value': '411171000000'
          }
        ],
        'id': '389690',
        'label': '漯河市',
        'value': '411100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '391086',
            'label': '湖滨区',
            'value': '411202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '391183',
            'label': '陕州区',
            'value': '411203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '391472',
            'label': '渑池县',
            'value': '411221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '391734',
            'label': '卢氏县',
            'value': '411224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '392042',
            'label': '河南三门峡经济开发区',
            'value': '411271000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '392050',
            'label': '义马市',
            'value': '411281000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '392093',
            'label': '灵宝市',
            'value': '411282000000'
          }
        ],
        'id': '391085',
        'label': '三门峡市',
        'value': '411200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '392578',
            'label': '宛城区',
            'value': '411302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '392841',
            'label': '卧龙区',
            'value': '411303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '393156',
            'label': '南召县',
            'value': '411321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '393526',
            'label': '方城县',
            'value': '411322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '394116',
            'label': '西峡县',
            'value': '411323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '394435',
            'label': '镇平县',
            'value': '411324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '394890',
            'label': '内乡县',
            'value': '411325000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '395203',
            'label': '淅川县',
            'value': '411326000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '395735',
            'label': '社旗县',
            'value': '411327000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '396009',
            'label': '唐河县',
            'value': '411328000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '396573',
            'label': '新野县',
            'value': '411329000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '396861',
            'label': '桐柏县',
            'value': '411330000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '397097',
            'label': '南阳高新技术产业开发区',
            'value': '411371000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '397118',
            'label': '南阳市城乡一体化示范区',
            'value': '411372000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '397181',
            'label': '邓州市',
            'value': '411381000000'
          }
        ],
        'id': '392577',
        'label': '南阳市',
        'value': '411300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '397844',
            'label': '梁园区',
            'value': '411402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '398108',
            'label': '睢阳区',
            'value': '411403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '398484',
            'label': '民权县',
            'value': '411421000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '399063',
            'label': '睢县',
            'value': '411422000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '399636',
            'label': '宁陵县',
            'value': '411423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '400016',
            'label': '柘城县',
            'value': '411424000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '400547',
            'label': '虞城县',
            'value': '411425000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '401195',
            'label': '夏邑县',
            'value': '411426000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '401963',
            'label': '豫东综合物流产业聚集区',
            'value': '411471000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '402036',
            'label': '河南商丘经济开发区',
            'value': '411472000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '402080',
            'label': '永城市',
            'value': '411481000000'
          }
        ],
        'id': '397843',
        'label': '商丘市',
        'value': '411400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '402883',
            'label': '浉河区',
            'value': '411502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '403171',
            'label': '平桥区',
            'value': '411503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '403522',
            'label': '罗山县',
            'value': '411521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '403850',
            'label': '光山县',
            'value': '411522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '404230',
            'label': '新县',
            'value': '411523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '404453',
            'label': '商城县',
            'value': '411524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '404848',
            'label': '固始县',
            'value': '411525000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '405538',
            'label': '潢川县',
            'value': '411526000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '405855',
            'label': '淮滨县',
            'value': '411527000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '406170',
            'label': '息县',
            'value': '411528000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '406554',
            'label': '信阳高新技术产业开发区',
            'value': '411571000000'
          }
        ],
        'id': '402882',
        'label': '信阳市',
        'value': '411500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '406567',
            'label': '川汇区',
            'value': '411602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '406738',
            'label': '淮阳区',
            'value': '411603000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '407232',
            'label': '扶沟县',
            'value': '411621000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '407660',
            'label': '西华县',
            'value': '411622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '408164',
            'label': '商水县',
            'value': '411623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '408784',
            'label': '沈丘县',
            'value': '411624000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '409380',
            'label': '郸城县',
            'value': '411625000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '409926',
            'label': '太康县',
            'value': '411627000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '410724',
            'label': '鹿邑县',
            'value': '411628000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '411305',
            'label': '河南周口经济开发区',
            'value': '411671000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '411329',
            'label': '项城市',
            'value': '411681000000'
          }
        ],
        'id': '406566',
        'label': '周口市',
        'value': '411600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '411853',
            'label': '驿城区',
            'value': '411702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '412080',
            'label': '西平县',
            'value': '411721000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '412387',
            'label': '上蔡县',
            'value': '411722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '412874',
            'label': '平舆县',
            'value': '411723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '413118',
            'label': '正阳县',
            'value': '411724000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '413434',
            'label': '确山县',
            'value': '411725000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '413650',
            'label': '泌阳县',
            'value': '411726000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '414031',
            'label': '汝南县',
            'value': '411727000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '414331',
            'label': '遂平县',
            'value': '411728000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '414554',
            'label': '新蔡县',
            'value': '411729000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '414937',
            'label': '河南驻马店经济开发区',
            'value': '411771000000'
          }
        ],
        'id': '411852',
        'label': '驻马店市',
        'value': '411700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '414967',
            'label': '济源市',
            'value': '419001000000'
          }
        ],
        'id': '414966',
        'label': '省直辖县级行政区划',
        'value': '419000000000'
      }
    ],
    'id': '17',
    'label': '河南省',
    'value': '41'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '415536',
            'label': '江岸区',
            'value': '420102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '415708',
            'label': '江汉区',
            'value': '420103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '415835',
            'label': '硚口区',
            'value': '420104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '415988',
            'label': '汉阳区',
            'value': '420105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '416116',
            'label': '武昌区',
            'value': '420106000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '416276',
            'label': '青山区',
            'value': '420107000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '416395',
            'label': '洪山区',
            'value': '420111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '416727',
            'label': '东西湖区',
            'value': '420112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '416903',
            'label': '汉南区',
            'value': '420113000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '416977',
            'label': '蔡甸区',
            'value': '420114000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '417401',
            'label': '江夏区',
            'value': '420115000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '417850',
            'label': '黄陂区',
            'value': '420116000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '418561',
            'label': '新洲区',
            'value': '420117000000'
          }
        ],
        'id': '415535',
        'label': '武汉市',
        'value': '420100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '419240',
            'label': '黄石港区',
            'value': '420202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '419279',
            'label': '西塞山区',
            'value': '420203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '419324',
            'label': '下陆区',
            'value': '420204000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '419368',
            'label': '铁山区',
            'value': '420205000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '419384',
            'label': '阳新县',
            'value': '420222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '419878',
            'label': '大冶市',
            'value': '420281000000'
          }
        ],
        'id': '419239',
        'label': '黄石市',
        'value': '420200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '420321',
            'label': '茅箭区',
            'value': '420302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '420409',
            'label': '张湾区',
            'value': '420303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '420528',
            'label': '郧阳区',
            'value': '420304000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '420899',
            'label': '郧西县',
            'value': '420322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '421206',
            'label': '竹山县',
            'value': '420323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '421468',
            'label': '竹溪县',
            'value': '420324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '421842',
            'label': '房县',
            'value': '420325000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '422168',
            'label': '丹江口市',
            'value': '420381000000'
          }
        ],
        'id': '420320',
        'label': '十堰市',
        'value': '420300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '422461',
            'label': '西陵区',
            'value': '420502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '422569',
            'label': '伍家岗区',
            'value': '420503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '422636',
            'label': '点军区',
            'value': '420504000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '422695',
            'label': '猇亭区',
            'value': '420505000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '422725',
            'label': '夷陵区',
            'value': '420506000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '422944',
            'label': '远安县',
            'value': '420525000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '423069',
            'label': '兴山县',
            'value': '420526000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '423174',
            'label': '秭归县',
            'value': '420527000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '423381',
            'label': '长阳土家族自治县',
            'value': '420528000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '423558',
            'label': '五峰土家族自治县',
            'value': '420529000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '423675',
            'label': '宜都市',
            'value': '420581000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '423842',
            'label': '当阳市',
            'value': '420582000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '424026',
            'label': '枝江市',
            'value': '420583000000'
          }
        ],
        'id': '422460',
        'label': '宜昌市',
        'value': '420500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '424255',
            'label': '襄城区',
            'value': '420602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '424439',
            'label': '樊城区',
            'value': '420606000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '424666',
            'label': '襄州区',
            'value': '420607000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '425166',
            'label': '南漳县',
            'value': '420624000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '425481',
            'label': '谷城县',
            'value': '420625000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '425783',
            'label': '保康县',
            'value': '420626000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '426073',
            'label': '老河口市',
            'value': '420682000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '426346',
            'label': '枣阳市',
            'value': '420683000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '426924',
            'label': '宜城市',
            'value': '420684000000'
          }
        ],
        'id': '424254',
        'label': '襄阳市',
        'value': '420600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '427181',
            'label': '梁子湖区',
            'value': '420702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '427275',
            'label': '华容区',
            'value': '420703000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '427395',
            'label': '鄂城区',
            'value': '420704000000'
          }
        ],
        'id': '427180',
        'label': '鄂州市',
        'value': '420700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '427579',
            'label': '东宝区',
            'value': '420802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '427800',
            'label': '掇刀区',
            'value': '420804000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '427936',
            'label': '沙洋县',
            'value': '420822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '428246',
            'label': '钟祥市',
            'value': '420881000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '428852',
            'label': '京山市',
            'value': '420882000000'
          }
        ],
        'id': '427578',
        'label': '荆门市',
        'value': '420800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '429295',
            'label': '孝南区',
            'value': '420902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '429626',
            'label': '孝昌县',
            'value': '420921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '430098',
            'label': '大悟县',
            'value': '420922000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '430490',
            'label': '云梦县',
            'value': '420923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '430800',
            'label': '应城市',
            'value': '420981000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '431234',
            'label': '安陆市',
            'value': '420982000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '431647',
            'label': '汉川市',
            'value': '420984000000'
          }
        ],
        'id': '429294',
        'label': '孝感市',
        'value': '420900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '432212',
            'label': '沙市区',
            'value': '421002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '432330',
            'label': '荆州区',
            'value': '421003000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '432518',
            'label': '公安县',
            'value': '421022000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '432858',
            'label': '监利县',
            'value': '421023000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '433287',
            'label': '江陵县',
            'value': '421024000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '433435',
            'label': '荆州经济技术开发区',
            'value': '421071000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '433504',
            'label': '石首市',
            'value': '421081000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '433724',
            'label': '洪湖市',
            'value': '421083000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '434045',
            'label': '松滋市',
            'value': '421087000000'
          }
        ],
        'id': '432211',
        'label': '荆州市',
        'value': '421000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '434341',
            'label': '黄州区',
            'value': '421102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '434497',
            'label': '团风县',
            'value': '421121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '434810',
            'label': '红安县',
            'value': '421122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '435250',
            'label': '罗田县',
            'value': '421123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '435723',
            'label': '英山县',
            'value': '421124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '436060',
            'label': '浠水县',
            'value': '421125000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '436749',
            'label': '蕲春县',
            'value': '421126000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '437354',
            'label': '黄梅县',
            'value': '421127000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '437881',
            'label': '龙感湖管理区',
            'value': '421171000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '437952',
            'label': '麻城市',
            'value': '421181000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '438450',
            'label': '武穴市',
            'value': '421182000000'
          }
        ],
        'id': '434340',
        'label': '黄冈市',
        'value': '421100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '438807',
            'label': '咸安区',
            'value': '421202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '439020',
            'label': '嘉鱼县',
            'value': '421221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '439135',
            'label': '通城县',
            'value': '421222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '439341',
            'label': '崇阳县',
            'value': '421223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '439561',
            'label': '通山县',
            'value': '421224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '439782',
            'label': '赤壁市',
            'value': '421281000000'
          }
        ],
        'id': '438806',
        'label': '咸宁市',
        'value': '421200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '439988',
            'label': '曾都区',
            'value': '421303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '440207',
            'label': '随县',
            'value': '421321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '440621',
            'label': '广水市',
            'value': '421381000000'
          }
        ],
        'id': '439987',
        'label': '随州市',
        'value': '421300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '441044',
            'label': '恩施市',
            'value': '422801000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '441270',
            'label': '利川市',
            'value': '422802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '441870',
            'label': '建始县',
            'value': '422822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '442291',
            'label': '巴东县',
            'value': '422823000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '442626',
            'label': '宣恩县',
            'value': '422825000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '442920',
            'label': '咸丰县',
            'value': '422826000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '443210',
            'label': '来凤县',
            'value': '422827000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '443415',
            'label': '鹤峰县',
            'value': '422828000000'
          }
        ],
        'id': '441043',
        'label': '恩施土家族苗族自治州',
        'value': '422800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '443644',
            'label': '仙桃市',
            'value': '429004000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '444430',
            'label': '潜江市',
            'value': '429005000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '444929',
            'label': '天门市',
            'value': '429006000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '445590',
            'label': '神农架林区',
            'value': '429021000000'
          }
        ],
        'id': '443643',
        'label': '省直辖县级行政区划',
        'value': '429000000000'
      }
    ],
    'id': '18',
    'label': '湖北省',
    'value': '42'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '445680',
            'label': '芙蓉区',
            'value': '430102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '445777',
            'label': '天心区',
            'value': '430103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '445885',
            'label': '岳麓区',
            'value': '430104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '446084',
            'label': '开福区',
            'value': '430105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '446208',
            'label': '雨花区',
            'value': '430111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '446379',
            'label': '望城区',
            'value': '430112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '446545',
            'label': '长沙县',
            'value': '430121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '446767',
            'label': '浏阳市',
            'value': '430181000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '447123',
            'label': '宁乡市',
            'value': '430182000000'
          }
        ],
        'id': '445679',
        'label': '长沙市',
        'value': '430100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '447432',
            'label': '荷塘区',
            'value': '430202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '447500',
            'label': '芦淞区',
            'value': '430203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '447589',
            'label': '石峰区',
            'value': '430204000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '447640',
            'label': '天元区',
            'value': '430211000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '447748',
            'label': '渌口区',
            'value': '430212000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '447896',
            'label': '攸县',
            'value': '430223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '448215',
            'label': '茶陵县',
            'value': '430224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '448480',
            'label': '炎陵县',
            'value': '430225000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '448624',
            'label': '云龙示范区',
            'value': '430271000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '448650',
            'label': '醴陵市',
            'value': '430281000000'
          }
        ],
        'id': '447431',
        'label': '株洲市',
        'value': '430200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '448947',
            'label': '雨湖区',
            'value': '430302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '449070',
            'label': '岳塘区',
            'value': '430304000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '449141',
            'label': '湘潭县',
            'value': '430321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '449514',
            'label': '湖南湘潭高新技术产业园区',
            'value': '430371000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '449535',
            'label': '湘潭昭山示范区',
            'value': '430372000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '449552',
            'label': '湘潭九华示范区',
            'value': '430373000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '449582',
            'label': '湘乡市',
            'value': '430381000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '449953',
            'label': '韶山市',
            'value': '430382000000'
          }
        ],
        'id': '448946',
        'label': '湘潭市',
        'value': '430300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '449997',
            'label': '珠晖区',
            'value': '430405000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '450082',
            'label': '雁峰区',
            'value': '430406000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '450141',
            'label': '石鼓区',
            'value': '430407000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '450197',
            'label': '蒸湘区',
            'value': '430408000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '450255',
            'label': '南岳区',
            'value': '430412000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '450284',
            'label': '衡阳县',
            'value': '430421000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '450801',
            'label': '衡南县',
            'value': '430422000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '451272',
            'label': '衡山县',
            'value': '430423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '451438',
            'label': '衡东县',
            'value': '430424000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '451714',
            'label': '祁东县',
            'value': '430426000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '452106',
            'label': '衡阳综合保税区',
            'value': '430471000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '452111',
            'label': '湖南衡阳高新技术产业园区',
            'value': '430472000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '452133',
            'label': '湖南衡阳松木经济开发区',
            'value': '430473000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '452142',
            'label': '耒阳市',
            'value': '430481000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '452551',
            'label': '常宁市',
            'value': '430482000000'
          }
        ],
        'id': '449996',
        'label': '衡阳市',
        'value': '430400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '452983',
            'label': '双清区',
            'value': '430502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '453087',
            'label': '大祥区',
            'value': '430503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '453200',
            'label': '北塔区',
            'value': '430511000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '453247',
            'label': '新邵县',
            'value': '430522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '453680',
            'label': '邵阳县',
            'value': '430523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '454137',
            'label': '隆回县',
            'value': '430524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '454735',
            'label': '洞口县',
            'value': '430525000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '455124',
            'label': '绥宁县',
            'value': '430527000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '455384',
            'label': '新宁县',
            'value': '430528000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '455727',
            'label': '城步苗族自治县',
            'value': '430529000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '455934',
            'label': '武冈市',
            'value': '430581000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '456268',
            'label': '邵东市',
            'value': '430582000000'
          }
        ],
        'id': '452982',
        'label': '邵阳市',
        'value': '430500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '456874',
            'label': '岳阳楼区',
            'value': '430602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '457076',
            'label': '云溪区',
            'value': '430603000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '457141',
            'label': '君山区',
            'value': '430611000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '457233',
            'label': '岳阳县',
            'value': '430621000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '457451',
            'label': '华容县',
            'value': '430623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '457691',
            'label': '湘阴县',
            'value': '430624000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '457926',
            'label': '平江县',
            'value': '430626000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '458495',
            'label': '岳阳市屈原管理区',
            'value': '430671000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '458532',
            'label': '汨罗市',
            'value': '430681000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '458734',
            'label': '临湘市',
            'value': '430682000000'
          }
        ],
        'id': '456873',
        'label': '岳阳市',
        'value': '430600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '458911',
            'label': '武陵区',
            'value': '430702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '459099',
            'label': '鼎城区',
            'value': '430703000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '459458',
            'label': '安乡县',
            'value': '430721000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '459643',
            'label': '汉寿县',
            'value': '430722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '459972',
            'label': '澧县',
            'value': '430723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '460283',
            'label': '临澧县',
            'value': '430724000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '460455',
            'label': '桃源县',
            'value': '430725000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '460915',
            'label': '石门县',
            'value': '430726000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '461274',
            'label': '常德市西洞庭管理区',
            'value': '430771000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '461296',
            'label': '津市市',
            'value': '430781000000'
          }
        ],
        'id': '458910',
        'label': '常德市',
        'value': '430700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '461386',
            'label': '永定区',
            'value': '430802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '461663',
            'label': '武陵源区',
            'value': '430811000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '461703',
            'label': '慈利县',
            'value': '430821000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '462159',
            'label': '桑植县',
            'value': '430822000000'
          }
        ],
        'id': '461385',
        'label': '张家界市',
        'value': '430800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '462482',
            'label': '资阳区',
            'value': '430902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '462616',
            'label': '赫山区',
            'value': '430903000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '462849',
            'label': '南县',
            'value': '430921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '463026',
            'label': '桃江县',
            'value': '430922000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '463289',
            'label': '安化县',
            'value': '430923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '463746',
            'label': '益阳市大通湖管理区',
            'value': '430971000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '463791',
            'label': '湖南益阳高新技术产业园区',
            'value': '430972000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '463822',
            'label': '沅江市',
            'value': '430981000000'
          }
        ],
        'id': '462481',
        'label': '益阳市',
        'value': '430900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '464037',
            'label': '北湖区',
            'value': '431002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '464219',
            'label': '苏仙区',
            'value': '431003000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '464420',
            'label': '桂阳县',
            'value': '431021000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '464814',
            'label': '宜章县',
            'value': '431022000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '465100',
            'label': '永兴县',
            'value': '431023000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '465394',
            'label': '嘉禾县',
            'value': '431024000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '465606',
            'label': '临武县',
            'value': '431025000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '465829',
            'label': '汝城县',
            'value': '431026000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '466096',
            'label': '桂东县',
            'value': '431027000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '466223',
            'label': '安仁县',
            'value': '431028000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '466399',
            'label': '资兴市',
            'value': '431081000000'
          }
        ],
        'id': '464036',
        'label': '郴州市',
        'value': '431000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '466630',
            'label': '零陵区',
            'value': '431102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '466981',
            'label': '冷水滩区',
            'value': '431103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '467203',
            'label': '祁阳县',
            'value': '431121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '467790',
            'label': '东安县',
            'value': '431122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '468152',
            'label': '双牌县',
            'value': '431123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '468286',
            'label': '道县',
            'value': '431124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '468680',
            'label': '江永县',
            'value': '431125000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '468802',
            'label': '宁远县',
            'value': '431126000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '469245',
            'label': '蓝山县',
            'value': '431127000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '469493',
            'label': '新田县',
            'value': '431128000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '469738',
            'label': '江华瑶族自治县',
            'value': '431129000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '470074',
            'label': '永州经济技术开发区',
            'value': '431171000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '470093',
            'label': '永州市金洞管理区',
            'value': '431172000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '470143',
            'label': '永州市回龙圩管理区',
            'value': '431173000000'
          }
        ],
        'id': '466629',
        'label': '永州市',
        'value': '431100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '470157',
            'label': '鹤城区',
            'value': '431202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '470297',
            'label': '中方县',
            'value': '431221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '470459',
            'label': '沅陵县',
            'value': '431222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '470885',
            'label': '辰溪县',
            'value': '431223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '471203',
            'label': '溆浦县',
            'value': '431224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '471645',
            'label': '会同县',
            'value': '431225000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '471907',
            'label': '麻阳苗族自治县',
            'value': '431226000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '472148',
            'label': '新晃侗族自治县',
            'value': '431227000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '472311',
            'label': '芷江侗族自治县',
            'value': '431228000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '472548',
            'label': '靖州苗族侗族自治县',
            'value': '431229000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '472698',
            'label': '通道侗族自治县',
            'value': '431230000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '472876',
            'label': '怀化市洪江管理区',
            'value': '431271000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '472916',
            'label': '洪江市',
            'value': '431281000000'
          }
        ],
        'id': '470156',
        'label': '怀化市',
        'value': '431200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '473156',
            'label': '娄星区',
            'value': '431302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '473402',
            'label': '双峰县',
            'value': '431321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '473943',
            'label': '新化县',
            'value': '431322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '474661',
            'label': '冷水江市',
            'value': '431381000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '474822',
            'label': '涟源市',
            'value': '431382000000'
          }
        ],
        'id': '473155',
        'label': '娄底市',
        'value': '431300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '475347',
            'label': '吉首市',
            'value': '433101000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '475510',
            'label': '泸溪县',
            'value': '433122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '475671',
            'label': '凤凰县',
            'value': '433123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '475971',
            'label': '花垣县',
            'value': '433124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '476225',
            'label': '保靖县',
            'value': '433125000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '476416',
            'label': '古丈县',
            'value': '433126000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '476545',
            'label': '永顺县',
            'value': '433127000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '476870',
            'label': '龙山县',
            'value': '433130000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '477289',
            'label': '湖南永顺经济开发区',
            'value': '433173000000'
          }
        ],
        'id': '475346',
        'label': '湘西土家族苗族自治州',
        'value': '433100000000'
      }
    ],
    'id': '19',
    'label': '湖南省',
    'value': '43'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '477294',
            'label': '荔湾区',
            'value': '440103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '477508',
            'label': '越秀区',
            'value': '440104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '477749',
            'label': '海珠区',
            'value': '440105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '478033',
            'label': '天河区',
            'value': '440106000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '478275',
            'label': '白云区',
            'value': '440111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '478700',
            'label': '黄埔区',
            'value': '440112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '478873',
            'label': '番禺区',
            'value': '440113000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '479166',
            'label': '花都区',
            'value': '440114000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '479429',
            'label': '南沙区',
            'value': '440115000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '479611',
            'label': '从化区',
            'value': '440117000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '479898',
            'label': '增城区',
            'value': '440118000000'
          }
        ],
        'id': '477293',
        'label': '广州市',
        'value': '440100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '480259',
            'label': '武江区',
            'value': '440203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '480354',
            'label': '浈江区',
            'value': '440204000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '480478',
            'label': '曲江区',
            'value': '440205000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '480599',
            'label': '始兴县',
            'value': '440222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '480739',
            'label': '仁化县',
            'value': '440224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '480876',
            'label': '翁源县',
            'value': '440229000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '481059',
            'label': '乳源瑶族自治县',
            'value': '440232000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '481188',
            'label': '新丰县',
            'value': '440233000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '481353',
            'label': '乐昌市',
            'value': '440281000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '481598',
            'label': '南雄市',
            'value': '440282000000'
          }
        ],
        'id': '480258',
        'label': '韶关市',
        'value': '440200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '481852',
            'label': '罗湖区',
            'value': '440303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '481975',
            'label': '福田区',
            'value': '440304000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '482103',
            'label': '南山区',
            'value': '440305000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '482221',
            'label': '宝安区',
            'value': '440306000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '482374',
            'label': '龙岗区',
            'value': '440307000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '482533',
            'label': '盐田区',
            'value': '440308000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '482565',
            'label': '龙华区',
            'value': '440309000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '482680',
            'label': '坪山区',
            'value': '440310000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '482712',
            'label': '光明区',
            'value': '440311000000'
          }
        ],
        'id': '481851',
        'label': '深圳市',
        'value': '440300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '482751',
            'label': '香洲区',
            'value': '440402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '482927',
            'label': '斗门区',
            'value': '440403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '483065',
            'label': '金湾区',
            'value': '440404000000'
          }
        ],
        'id': '482750',
        'label': '珠海市',
        'value': '440400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '483115',
            'label': '龙湖区',
            'value': '440507000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '483244',
            'label': '金平区',
            'value': '440511000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '483428',
            'label': '濠江区',
            'value': '440512000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '483496',
            'label': '潮阳区',
            'value': '440513000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '483783',
            'label': '潮南区',
            'value': '440514000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '484027',
            'label': '澄海区',
            'value': '440515000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '484223',
            'label': '南澳县',
            'value': '440523000000'
          }
        ],
        'id': '483114',
        'label': '汕头市',
        'value': '440500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '484274',
            'label': '禅城区',
            'value': '440604000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '484424',
            'label': '南海区',
            'value': '440605000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '484742',
            'label': '顺德区',
            'value': '440606000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '484958',
            'label': '三水区',
            'value': '440607000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '485047',
            'label': '高明区',
            'value': '440608000000'
          }
        ],
        'id': '484273',
        'label': '佛山市',
        'value': '440600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '485138',
            'label': '蓬江区',
            'value': '440703000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '485279',
            'label': '江海区',
            'value': '440704000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '485345',
            'label': '新会区',
            'value': '440705000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '485591',
            'label': '台山市',
            'value': '440781000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '485922',
            'label': '开平市',
            'value': '440783000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '486210',
            'label': '鹤山市',
            'value': '440784000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '486363',
            'label': '恩平市',
            'value': '440785000000'
          }
        ],
        'id': '485137',
        'label': '江门市',
        'value': '440700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '486552',
            'label': '赤坎区',
            'value': '440802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '486608',
            'label': '霞山区',
            'value': '440803000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '486700',
            'label': '坡头区',
            'value': '440804000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '486779',
            'label': '麻章区',
            'value': '440811000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '486930',
            'label': '遂溪县',
            'value': '440823000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '487300',
            'label': '徐闻县',
            'value': '440825000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '487521',
            'label': '廉江市',
            'value': '440881000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '487946',
            'label': '雷州市',
            'value': '440882000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '488454',
            'label': '吴川市',
            'value': '440883000000'
          }
        ],
        'id': '486551',
        'label': '湛江市',
        'value': '440800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '488667',
            'label': '茂南区',
            'value': '440902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '488924',
            'label': '电白区',
            'value': '440904000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '489361',
            'label': '高州市',
            'value': '440981000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '489884',
            'label': '化州市',
            'value': '440982000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '490289',
            'label': '信宜市',
            'value': '440983000000'
          }
        ],
        'id': '488666',
        'label': '茂名市',
        'value': '440900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '490714',
            'label': '端州区',
            'value': '441202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '490779',
            'label': '鼎湖区',
            'value': '441203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '490868',
            'label': '高要区',
            'value': '441204000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '491238',
            'label': '广宁县',
            'value': '441223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '491432',
            'label': '怀集县',
            'value': '441224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '491778',
            'label': '封开县',
            'value': '441225000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '491994',
            'label': '德庆县',
            'value': '441226000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '492201',
            'label': '四会市',
            'value': '441284000000'
          }
        ],
        'id': '490713',
        'label': '肇庆市',
        'value': '441200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '492382',
            'label': '惠城区',
            'value': '441302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '492676',
            'label': '惠阳区',
            'value': '441303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '492877',
            'label': '博罗县',
            'value': '441322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '493277',
            'label': '惠东县',
            'value': '441323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '493594',
            'label': '龙门县',
            'value': '441324000000'
          }
        ],
        'id': '492381',
        'label': '惠州市',
        'value': '441300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '493793',
            'label': '梅江区',
            'value': '441402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '493929',
            'label': '梅县区',
            'value': '441403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '494341',
            'label': '大埔县',
            'value': '441422000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '494617',
            'label': '丰顺县',
            'value': '441423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '494917',
            'label': '五华县',
            'value': '441424000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '495382',
            'label': '平远县',
            'value': '441426000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '495538',
            'label': '蕉岭县',
            'value': '441427000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '495659',
            'label': '兴宁市',
            'value': '441481000000'
          }
        ],
        'id': '493792',
        'label': '梅州市',
        'value': '441400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '496176',
            'label': '城区',
            'value': '441502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '496310',
            'label': '海丰县',
            'value': '441521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '496611',
            'label': '陆河县',
            'value': '441523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '496747',
            'label': '陆丰市',
            'value': '441581000000'
          }
        ],
        'id': '496175',
        'label': '汕尾市',
        'value': '441500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '497138',
            'label': '源城区',
            'value': '441602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '497223',
            'label': '紫金县',
            'value': '441621000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '497542',
            'label': '龙川县',
            'value': '441622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '497924',
            'label': '连平县',
            'value': '441623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '498113',
            'label': '和平县',
            'value': '441624000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '498376',
            'label': '东源县',
            'value': '441625000000'
          }
        ],
        'id': '497137',
        'label': '河源市',
        'value': '441600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '498685',
            'label': '江城区',
            'value': '441702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '498865',
            'label': '阳东区',
            'value': '441704000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '499063',
            'label': '阳西县',
            'value': '441721000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '499224',
            'label': '阳春市',
            'value': '441781000000'
          }
        ],
        'id': '498684',
        'label': '阳江市',
        'value': '441700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '499617',
            'label': '清城区',
            'value': '441802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '499790',
            'label': '清新区',
            'value': '441803000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '500009',
            'label': '佛冈县',
            'value': '441821000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '500106',
            'label': '阳山县',
            'value': '441823000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '500287',
            'label': '连山壮族瑶族自治县',
            'value': '441825000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '500351',
            'label': '连南瑶族自治县',
            'value': '441826000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '500430',
            'label': '英德市',
            'value': '441881000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '500754',
            'label': '连州市',
            'value': '441882000000'
          }
        ],
        'id': '499616',
        'label': '清远市',
        'value': '441800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '500941',
            'label': '东城街道',
            'value': '441900003000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '500967',
            'label': '南城街道',
            'value': '441900004000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '500986',
            'label': '万江街道',
            'value': '441900005000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501016',
            'label': '莞城街道',
            'value': '441900006000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501025',
            'label': '石碣镇',
            'value': '441900101000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501041',
            'label': '石龙镇',
            'value': '441900102000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501052',
            'label': '茶山镇',
            'value': '441900103000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501071',
            'label': '石排镇',
            'value': '441900104000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501091',
            'label': '企石镇',
            'value': '441900105000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501112',
            'label': '横沥镇',
            'value': '441900106000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501130',
            'label': '桥头镇',
            'value': '441900107000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501148',
            'label': '谢岗镇',
            'value': '441900108000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501161',
            'label': '东坑镇',
            'value': '441900109000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501178',
            'label': '常平镇',
            'value': '441900110000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501212',
            'label': '寮步镇',
            'value': '441900111000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501243',
            'label': '樟木头镇',
            'value': '441900112000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501255',
            'label': '大朗镇',
            'value': '441900113000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501284',
            'label': '黄江镇',
            'value': '441900114000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501292',
            'label': '清溪镇',
            'value': '441900115000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501315',
            'label': '塘厦镇',
            'value': '441900116000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501339',
            'label': '凤岗镇',
            'value': '441900117000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501352',
            'label': '大岭山镇',
            'value': '441900118000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501376',
            'label': '长安镇',
            'value': '441900119000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501390',
            'label': '虎门镇',
            'value': '441900121000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501423',
            'label': '厚街镇',
            'value': '441900122000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501448',
            'label': '沙田镇',
            'value': '441900123000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501467',
            'label': '道滘镇',
            'value': '441900124000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501482',
            'label': '洪梅镇',
            'value': '441900125000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501493',
            'label': '麻涌镇',
            'value': '441900126000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501509',
            'label': '望牛墩镇',
            'value': '441900127000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501532',
            'label': '中堂镇',
            'value': '441900128000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501553',
            'label': '高埗镇',
            'value': '441900129000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501573',
            'label': '松山湖',
            'value': '441900401000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501575',
            'label': '东莞港',
            'value': '441900402000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501577',
            'label': '东莞生态园',
            'value': '441900403000'
          }
        ],
        'id': '500940',
        'label': '东莞市',
        'value': '441900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '501580',
            'label': '石岐街道',
            'value': '442000001000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501600',
            'label': '东区街道',
            'value': '442000002000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501611',
            'label': '中山港街道',
            'value': '442000003000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501619',
            'label': '西区街道',
            'value': '442000004000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501629',
            'label': '南区街道',
            'value': '442000005000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501634',
            'label': '五桂山街道',
            'value': '442000006000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501640',
            'label': '小榄镇',
            'value': '442000100000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501656',
            'label': '黄圃镇',
            'value': '442000101000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501673',
            'label': '民众镇',
            'value': '442000102000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501693',
            'label': '东凤镇',
            'value': '442000103000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501708',
            'label': '东升镇',
            'value': '442000104000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501723',
            'label': '古镇镇',
            'value': '442000105000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501737',
            'label': '沙溪镇',
            'value': '442000106000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501754',
            'label': '坦洲镇',
            'value': '442000107000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501769',
            'label': '港口镇',
            'value': '442000108000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501779',
            'label': '三角镇',
            'value': '442000109000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501788',
            'label': '横栏镇',
            'value': '442000110000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501800',
            'label': '南头镇',
            'value': '442000111000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501807',
            'label': '阜沙镇',
            'value': '442000112000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501817',
            'label': '南朗镇',
            'value': '442000113000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501833',
            'label': '三乡镇',
            'value': '442000114000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501850',
            'label': '板芙镇',
            'value': '442000115000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501862',
            'label': '大涌镇',
            'value': '442000116000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '501874',
            'label': '神湾镇',
            'value': '442000117000'
          }
        ],
        'id': '501579',
        'label': '中山市',
        'value': '442000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '501882',
            'label': '湘桥区',
            'value': '445102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '502075',
            'label': '潮安区',
            'value': '445103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '502550',
            'label': '饶平县',
            'value': '445122000000'
          }
        ],
        'id': '501881',
        'label': '潮州市',
        'value': '445100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '502977',
            'label': '榕城区',
            'value': '445202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '503190',
            'label': '揭东区',
            'value': '445203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '503428',
            'label': '揭西县',
            'value': '445222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '503776',
            'label': '惠来县',
            'value': '445224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '504123',
            'label': '普宁市',
            'value': '445281000000'
          }
        ],
        'id': '502976',
        'label': '揭阳市',
        'value': '445200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '504736',
            'label': '云城区',
            'value': '445302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '504862',
            'label': '云安区',
            'value': '445303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '504986',
            'label': '新兴县',
            'value': '445321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '505204',
            'label': '郁南县',
            'value': '445322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '505428',
            'label': '罗定市',
            'value': '445381000000'
          }
        ],
        'id': '504735',
        'label': '云浮市',
        'value': '445300000000'
      }
    ],
    'id': '20',
    'label': '广东省',
    'value': '44'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '505789',
            'label': '兴宁区',
            'value': '450102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '505871',
            'label': '青秀区',
            'value': '450103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '505988',
            'label': '江南区',
            'value': '450105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '506118',
            'label': '西乡塘区',
            'value': '450107000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '506288',
            'label': '良庆区',
            'value': '450108000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '506376',
            'label': '邕宁区',
            'value': '450109000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '506457',
            'label': '武鸣区',
            'value': '450110000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '506702',
            'label': '隆安县',
            'value': '450123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '506852',
            'label': '马山县',
            'value': '450124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '507020',
            'label': '上林县',
            'value': '450125000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '507166',
            'label': '宾阳县',
            'value': '450126000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '507419',
            'label': '横县',
            'value': '450127000000'
          }
        ],
        'id': '505788',
        'label': '南宁市',
        'value': '450100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '507750',
            'label': '城中区',
            'value': '450202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '507791',
            'label': '鱼峰区',
            'value': '450203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '507899',
            'label': '柳南区',
            'value': '450204000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '508009',
            'label': '柳北区',
            'value': '450205000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '508120',
            'label': '柳江区',
            'value': '450206000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '508247',
            'label': '柳城县',
            'value': '450222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '508400',
            'label': '鹿寨县',
            'value': '450223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '508528',
            'label': '融安县',
            'value': '450224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '508690',
            'label': '融水苗族自治县',
            'value': '450225000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '508918',
            'label': '三江侗族自治县',
            'value': '450226000000'
          }
        ],
        'id': '507749',
        'label': '柳州市',
        'value': '450200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '509105',
            'label': '秀峰区',
            'value': '450302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '509137',
            'label': '叠彩区',
            'value': '450303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '509177',
            'label': '象山区',
            'value': '450304000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '509226',
            'label': '七星区',
            'value': '450305000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '509283',
            'label': '雁山区',
            'value': '450311000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '509331',
            'label': '临桂区',
            'value': '450312000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '509514',
            'label': '阳朔县',
            'value': '450321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '509638',
            'label': '灵川县',
            'value': '450323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '509800',
            'label': '全州县',
            'value': '450324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '510105',
            'label': '兴安县',
            'value': '450325000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '510241',
            'label': '永福县',
            'value': '450326000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '510350',
            'label': '灌阳县',
            'value': '450327000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '510502',
            'label': '龙胜各族自治县',
            'value': '450328000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '510641',
            'label': '资源县',
            'value': '450329000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '510723',
            'label': '平乐县',
            'value': '450330000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '510889',
            'label': '恭城瑶族自治县',
            'value': '450332000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '511026',
            'label': '荔浦市',
            'value': '450381000000'
          }
        ],
        'id': '509104',
        'label': '桂林市',
        'value': '450300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '511185',
            'label': '万秀区',
            'value': '450403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '511257',
            'label': '长洲区',
            'value': '450405000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '511308',
            'label': '龙圩区',
            'value': '450406000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '511392',
            'label': '苍梧县',
            'value': '450421000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '511548',
            'label': '藤县',
            'value': '450422000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '511864',
            'label': '蒙山县',
            'value': '450423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '511958',
            'label': '岑溪市',
            'value': '450481000000'
          }
        ],
        'id': '511184',
        'label': '梧州市',
        'value': '450400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '512256',
            'label': '海城区',
            'value': '450502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '512329',
            'label': '银海区',
            'value': '450503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '512387',
            'label': '铁山港区',
            'value': '450512000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '512434',
            'label': '合浦县',
            'value': '450521000000'
          }
        ],
        'id': '512255',
        'label': '北海市',
        'value': '450500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '512727',
            'label': '港口区',
            'value': '450602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '512775',
            'label': '防城区',
            'value': '450603000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '512958',
            'label': '上思县',
            'value': '450621000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '513061',
            'label': '东兴市',
            'value': '450681000000'
          }
        ],
        'id': '512726',
        'label': '防城港市',
        'value': '450600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '513108',
            'label': '钦南区',
            'value': '450702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '513301',
            'label': '钦北区',
            'value': '450703000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '513499',
            'label': '灵山县',
            'value': '450721000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '513933',
            'label': '浦北县',
            'value': '450722000000'
          }
        ],
        'id': '513107',
        'label': '钦州市',
        'value': '450700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '514226',
            'label': '港北区',
            'value': '450802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '514359',
            'label': '港南区',
            'value': '450803000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '514536',
            'label': '覃塘区',
            'value': '450804000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '514693',
            'label': '平南县',
            'value': '450821000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '515011',
            'label': '桂平市',
            'value': '450881000000'
          }
        ],
        'id': '514225',
        'label': '贵港市',
        'value': '450800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '515476',
            'label': '玉州区',
            'value': '450902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '515594',
            'label': '福绵区',
            'value': '450903000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '515717',
            'label': '容县',
            'value': '450921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '515960',
            'label': '陆川县',
            'value': '450922000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '516140',
            'label': '博白县',
            'value': '450923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '516528',
            'label': '兴业县',
            'value': '450924000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '516757',
            'label': '北流市',
            'value': '450981000000'
          }
        ],
        'id': '515475',
        'label': '玉林市',
        'value': '450900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '517099',
            'label': '右江区',
            'value': '451002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '517230',
            'label': '田阳区',
            'value': '451003000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '517398',
            'label': '田东县',
            'value': '451022000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '517581',
            'label': '平果县',
            'value': '451023000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '517779',
            'label': '德保县',
            'value': '451024000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '517979',
            'label': '那坡县',
            'value': '451026000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '518119',
            'label': '凌云县',
            'value': '451027000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '518238',
            'label': '乐业县',
            'value': '451028000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '518335',
            'label': '田林县',
            'value': '451029000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '518518',
            'label': '西林县',
            'value': '451030000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '518624',
            'label': '隆林各族自治县',
            'value': '451031000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '518820',
            'label': '靖西市',
            'value': '451081000000'
          }
        ],
        'id': '517098',
        'label': '百色市',
        'value': '451000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '519134',
            'label': '八步区',
            'value': '451102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '519354',
            'label': '平桂区',
            'value': '451103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '519488',
            'label': '昭平县',
            'value': '451121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '519660',
            'label': '钟山县',
            'value': '451122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '519789',
            'label': '富川瑶族自治县',
            'value': '451123000000'
          }
        ],
        'id': '519133',
        'label': '贺州市',
        'value': '451100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '519959',
            'label': '金城江区',
            'value': '451202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '520117',
            'label': '宜州区',
            'value': '451203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '520344',
            'label': '南丹县',
            'value': '451221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '520511',
            'label': '天峨县',
            'value': '451222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '520616',
            'label': '凤山县',
            'value': '451223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '520724',
            'label': '东兰县',
            'value': '451224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '520888',
            'label': '罗城仫佬族自治县',
            'value': '451225000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '521044',
            'label': '环江毛南族自治县',
            'value': '451226000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '521204',
            'label': '巴马瑶族自治县',
            'value': '451227000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '521324',
            'label': '都安瑶族自治县',
            'value': '451228000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '521598',
            'label': '大化瑶族自治县',
            'value': '451229000000'
          }
        ],
        'id': '519958',
        'label': '河池市',
        'value': '451200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '521776',
            'label': '兴宾区',
            'value': '451302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '522098',
            'label': '忻城县',
            'value': '451321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '522243',
            'label': '象州县',
            'value': '451322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '522381',
            'label': '武宣县',
            'value': '451323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '522544',
            'label': '金秀瑶族自治县',
            'value': '451324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '522638',
            'label': '合山市',
            'value': '451381000000'
          }
        ],
        'id': '521775',
        'label': '来宾市',
        'value': '451300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '522679',
            'label': '江州区',
            'value': '451402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '522836',
            'label': '扶绥县',
            'value': '451421000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '522984',
            'label': '宁明县',
            'value': '451422000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '523182',
            'label': '龙州县',
            'value': '451423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '523329',
            'label': '大新县',
            'value': '451424000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '523502',
            'label': '天等县',
            'value': '451425000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '523641',
            'label': '凭祥市',
            'value': '451481000000'
          }
        ],
        'id': '522678',
        'label': '崇左市',
        'value': '451400000000'
      }
    ],
    'id': '21',
    'label': '广西壮族自治区',
    'value': '45'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '523689',
            'label': '秀英区',
            'value': '460105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '523793',
            'label': '龙华区',
            'value': '460106000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '523934',
            'label': '琼山区',
            'value': '460107000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524066',
            'label': '美兰区',
            'value': '460108000000'
          }
        ],
        'id': '523688',
        'label': '海口市',
        'value': '460100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '524207',
            'label': '海棠区',
            'value': '460202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524232',
            'label': '吉阳区',
            'value': '460203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524273',
            'label': '天涯区',
            'value': '460204000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524331',
            'label': '崖州区',
            'value': '460205000000'
          }
        ],
        'id': '524206',
        'label': '三亚市',
        'value': '460200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '524366',
            'label': '西沙群岛',
            'value': '460321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524378',
            'label': '南沙群岛',
            'value': '460322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524381',
            'label': '中沙群岛的岛礁及其海域',
            'value': '460323000000'
          }
        ],
        'id': '524365',
        'label': '三沙市',
        'value': '460300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '524385',
            'label': '那大镇',
            'value': '460400100000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524427',
            'label': '和庆镇',
            'value': '460400101000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524442',
            'label': '南丰镇',
            'value': '460400102000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524455',
            'label': '大成镇',
            'value': '460400103000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524481',
            'label': '雅星镇',
            'value': '460400104000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524515',
            'label': '兰洋镇',
            'value': '460400105000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524535',
            'label': '光村镇',
            'value': '460400106000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524549',
            'label': '木棠镇',
            'value': '460400107000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524575',
            'label': '海头镇',
            'value': '460400108000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524590',
            'label': '峨蔓镇',
            'value': '460400109000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524604',
            'label': '王五镇',
            'value': '460400111000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524614',
            'label': '白马井镇',
            'value': '460400112000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524637',
            'label': '中和镇',
            'value': '460400113000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524650',
            'label': '排浦镇',
            'value': '460400114000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524660',
            'label': '东成镇',
            'value': '460400115000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524681',
            'label': '新州镇',
            'value': '460400116000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524713',
            'label': '洋浦经济开发区',
            'value': '460400499000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524741',
            'label': '华南热作学院',
            'value': '460400500000'
          }
        ],
        'id': '524384',
        'label': '儋州市',
        'value': '460400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '524744',
            'label': '五指山市',
            'value': '469001000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '524823',
            'label': '琼海市',
            'value': '469002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '525052',
            'label': '文昌市',
            'value': '469005000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '525368',
            'label': '万宁市',
            'value': '469006000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '525619',
            'label': '东方市',
            'value': '469007000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '525836',
            'label': '定安县',
            'value': '469021000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '525977',
            'label': '屯昌县',
            'value': '469022000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '526110',
            'label': '澄迈县',
            'value': '469023000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '526320',
            'label': '临高县',
            'value': '469024000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '526523',
            'label': '白沙黎族自治县',
            'value': '469025000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '526627',
            'label': '昌江黎族自治县',
            'value': '469026000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '526735',
            'label': '乐东黎族自治县',
            'value': '469027000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '526946',
            'label': '陵水黎族自治县',
            'value': '469028000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '527079',
            'label': '保亭黎族苗族自治县',
            'value': '469029000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '527158',
            'label': '琼中黎族苗族自治县',
            'value': '469030000000'
          }
        ],
        'id': '524743',
        'label': '省直辖县级行政区划',
        'value': '469000000000'
      }
    ],
    'id': '22',
    'label': '海南省',
    'value': '46'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '527296',
            'label': '万州区',
            'value': '500101000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '527983',
            'label': '涪陵区',
            'value': '500102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '528433',
            'label': '渝中区',
            'value': '500103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '528525',
            'label': '大渡口区',
            'value': '500104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '528628',
            'label': '江北区',
            'value': '500105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '528760',
            'label': '沙坪坝区',
            'value': '500106000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '528986',
            'label': '九龙坡区',
            'value': '500107000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '529229',
            'label': '南岸区',
            'value': '500108000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '529391',
            'label': '北碚区',
            'value': '500109000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '529595',
            'label': '綦江区',
            'value': '500110000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '530106',
            'label': '大足区',
            'value': '500111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '530443',
            'label': '渝北区',
            'value': '500112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '530884',
            'label': '巴南区',
            'value': '500113000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '531208',
            'label': '黔江区',
            'value': '500114000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '531458',
            'label': '长寿区',
            'value': '500115000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '531744',
            'label': '江津区',
            'value': '500116000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '532063',
            'label': '合川区',
            'value': '500117000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '532513',
            'label': '永川区',
            'value': '500118000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '532798',
            'label': '南川区',
            'value': '500119000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '533077',
            'label': '璧山区',
            'value': '500120000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '533282',
            'label': '铜梁区',
            'value': '500151000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '533644',
            'label': '潼南区',
            'value': '500152000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '533971',
            'label': '荣昌区',
            'value': '500153000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '534152',
            'label': '开州区',
            'value': '500154000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '534728',
            'label': '梁平区',
            'value': '500155000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '535104',
            'label': '武隆区',
            'value': '500156000000'
          }
        ],
        'id': '527295',
        'label': '市辖区',
        'value': '500100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '535348',
            'label': '城口县',
            'value': '500229000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '535578',
            'label': '丰都县',
            'value': '500230000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '535939',
            'label': '垫江县',
            'value': '500231000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '536267',
            'label': '忠县',
            'value': '500233000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '536669',
            'label': '云阳县',
            'value': '500235000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '537190',
            'label': '奉节县',
            'value': '500236000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '537613',
            'label': '巫山县',
            'value': '500237000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '537980',
            'label': '巫溪县',
            'value': '500238000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '538343',
            'label': '石柱土家族自治县',
            'value': '500240000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '538619',
            'label': '秀山土家族苗族自治县',
            'value': '500241000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '538915',
            'label': '酉阳土家族苗族自治县',
            'value': '500242000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '539233',
            'label': '彭水苗族土家族自治县',
            'value': '500243000000'
          }
        ],
        'id': '535347',
        'label': '县',
        'value': '500200000000'
      }
    ],
    'id': '23',
    'label': '重庆市',
    'value': '50'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '539570',
            'label': '锦江区',
            'value': '510104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '539676',
            'label': '青羊区',
            'value': '510105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '539770',
            'label': '金牛区',
            'value': '510106000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '539897',
            'label': '武侯区',
            'value': '510107000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '540053',
            'label': '成华区',
            'value': '510108000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '540173',
            'label': '龙泉驿区',
            'value': '510112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '540330',
            'label': '青白江区',
            'value': '510113000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '540466',
            'label': '新都区',
            'value': '510114000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '540735',
            'label': '温江区',
            'value': '510115000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '540867',
            'label': '双流区',
            'value': '510116000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '541194',
            'label': '郫都区',
            'value': '510117000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '541431',
            'label': '金堂县',
            'value': '510121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '541685',
            'label': '大邑县',
            'value': '510129000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '541924',
            'label': '蒲江县',
            'value': '510131000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '542071',
            'label': '新津县',
            'value': '510132000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '542190',
            'label': '都江堰市',
            'value': '510181000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '542466',
            'label': '彭州市',
            'value': '510182000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '542842',
            'label': '邛崃市',
            'value': '510183000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '543138',
            'label': '崇州市',
            'value': '510184000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '543417',
            'label': '简阳市',
            'value': '510185000000'
          }
        ],
        'id': '539569',
        'label': '成都市',
        'value': '510100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '544332',
            'label': '自流井区',
            'value': '510302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '544454',
            'label': '贡井区',
            'value': '510303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '544588',
            'label': '大安区',
            'value': '510304000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '544793',
            'label': '沿滩区',
            'value': '510311000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '544953',
            'label': '荣县',
            'value': '510321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '545322',
            'label': '富顺县',
            'value': '510322000000'
          }
        ],
        'id': '544331',
        'label': '自贡市',
        'value': '510300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '545615',
            'label': '东区',
            'value': '510402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '545695',
            'label': '西区',
            'value': '510403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '545745',
            'label': '仁和区',
            'value': '510411000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '545861',
            'label': '米易县',
            'value': '510421000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '545973',
            'label': '盐边县',
            'value': '510422000000'
          }
        ],
        'id': '545614',
        'label': '攀枝花市',
        'value': '510400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '546163',
            'label': '江阳区',
            'value': '510502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '546341',
            'label': '纳溪区',
            'value': '510503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '546560',
            'label': '龙马潭区',
            'value': '510504000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '546663',
            'label': '泸县',
            'value': '510521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '546990',
            'label': '合江县',
            'value': '510522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '547345',
            'label': '叙永县',
            'value': '510524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '547637',
            'label': '古蔺县',
            'value': '510525000000'
          }
        ],
        'id': '546162',
        'label': '泸州市',
        'value': '510500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '547971',
            'label': '旌阳区',
            'value': '510603000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '548219',
            'label': '罗江区',
            'value': '510604000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '548357',
            'label': '中江县',
            'value': '510623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '549242',
            'label': '广汉市',
            'value': '510681000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '549484',
            'label': '什邡市',
            'value': '510682000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '549672',
            'label': '绵竹市',
            'value': '510683000000'
          }
        ],
        'id': '547970',
        'label': '德阳市',
        'value': '510600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '549902',
            'label': '涪城区',
            'value': '510703000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '550183',
            'label': '游仙区',
            'value': '510704000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '550533',
            'label': '安州区',
            'value': '510705000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '550809',
            'label': '三台县',
            'value': '510722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '551915',
            'label': '盐亭县',
            'value': '510723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '552471',
            'label': '梓潼县',
            'value': '510725000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '552853',
            'label': '北川羌族自治县',
            'value': '510726000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '553220',
            'label': '平武县',
            'value': '510727000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '553510',
            'label': '江油市',
            'value': '510781000000'
          }
        ],
        'id': '549901',
        'label': '绵阳市',
        'value': '510700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '553994',
            'label': '利州区',
            'value': '510802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '554275',
            'label': '昭化区',
            'value': '510811000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '554541',
            'label': '朝天区',
            'value': '510812000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '554788',
            'label': '旺苍县',
            'value': '510821000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '555224',
            'label': '青川县',
            'value': '510822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '555502',
            'label': '剑阁县',
            'value': '510823000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '556139',
            'label': '苍溪县',
            'value': '510824000000'
          }
        ],
        'id': '553993',
        'label': '广元市',
        'value': '510800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '556993',
            'label': '船山区',
            'value': '510903000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '557314',
            'label': '安居区',
            'value': '510904000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '557857',
            'label': '蓬溪县',
            'value': '510921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '558428',
            'label': '大英县',
            'value': '510923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '558775',
            'label': '射洪市',
            'value': '510981000000'
          }
        ],
        'id': '556992',
        'label': '遂宁市',
        'value': '510900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '559315',
            'label': '市中区',
            'value': '511002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '559534',
            'label': '东兴区',
            'value': '511011000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '560056',
            'label': '威远县',
            'value': '511024000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '560451',
            'label': '资中县',
            'value': '511025000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '561325',
            'label': '内江经济开发区',
            'value': '511071000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '561356',
            'label': '隆昌市',
            'value': '511083000000'
          }
        ],
        'id': '559314',
        'label': '内江市',
        'value': '511000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '561790',
            'label': '市中区',
            'value': '511102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '562082',
            'label': '沙湾区',
            'value': '511111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '562245',
            'label': '五通桥区',
            'value': '511112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '562430',
            'label': '金口河区',
            'value': '511113000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '562482',
            'label': '犍为县',
            'value': '511123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '562902',
            'label': '井研县',
            'value': '511124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '563161',
            'label': '夹江县',
            'value': '511126000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '563295',
            'label': '沐川县',
            'value': '511129000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '563530',
            'label': '峨边彝族自治县',
            'value': '511132000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '563689',
            'label': '马边彝族自治县',
            'value': '511133000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '563833',
            'label': '峨眉山市',
            'value': '511181000000'
          }
        ],
        'id': '561789',
        'label': '乐山市',
        'value': '511100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '564118',
            'label': '顺庆区',
            'value': '511302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '564455',
            'label': '高坪区',
            'value': '511303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '564876',
            'label': '嘉陵区',
            'value': '511304000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '565494',
            'label': '南部县',
            'value': '511321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '566655',
            'label': '营山县',
            'value': '511322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '567369',
            'label': '蓬安县',
            'value': '511323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '568040',
            'label': '仪陇县',
            'value': '511324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '569015',
            'label': '西充县',
            'value': '511325000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '569660',
            'label': '阆中市',
            'value': '511381000000'
          }
        ],
        'id': '564117',
        'label': '南充市',
        'value': '511300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '570204',
            'label': '东坡区',
            'value': '511402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '570542',
            'label': '彭山区',
            'value': '511403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '570664',
            'label': '仁寿县',
            'value': '511421000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '571343',
            'label': '洪雅县',
            'value': '511423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '571518',
            'label': '丹棱县',
            'value': '511424000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '571604',
            'label': '青神县',
            'value': '511425000000'
          }
        ],
        'id': '570203',
        'label': '眉山市',
        'value': '511400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '571705',
            'label': '翠屏区',
            'value': '511502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '572095',
            'label': '南溪区',
            'value': '511503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '572315',
            'label': '叙州区',
            'value': '511504000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '572856',
            'label': '江安县',
            'value': '511523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '573213',
            'label': '长宁县',
            'value': '511524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '573508',
            'label': '高县',
            'value': '511525000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '573839',
            'label': '珙县',
            'value': '511526000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '574116',
            'label': '筠连县',
            'value': '511527000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '574389',
            'label': '兴文县',
            'value': '511528000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '574661',
            'label': '屏山县',
            'value': '511529000000'
          }
        ],
        'id': '571704',
        'label': '宜宾市',
        'value': '511500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '574949',
            'label': '广安区',
            'value': '511602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '575587',
            'label': '前锋区',
            'value': '511603000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '575888',
            'label': '岳池县',
            'value': '511621000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '576814',
            'label': '武胜县',
            'value': '511622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '577405',
            'label': '邻水县',
            'value': '511623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '577978',
            'label': '华蓥市',
            'value': '511681000000'
          }
        ],
        'id': '574948',
        'label': '广安市',
        'value': '511600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '578128',
            'label': '通川区',
            'value': '511702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '578425',
            'label': '达川区',
            'value': '511703000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '579163',
            'label': '宣汉县',
            'value': '511722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '579788',
            'label': '开江县',
            'value': '511723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '580036',
            'label': '大竹县',
            'value': '511724000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '580531',
            'label': '渠县',
            'value': '511725000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '581158',
            'label': '达州经济开发区',
            'value': '511771000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '581207',
            'label': '万源市',
            'value': '511781000000'
          }
        ],
        'id': '578127',
        'label': '达州市',
        'value': '511700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '581675',
            'label': '雨城区',
            'value': '511802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '581904',
            'label': '名山区',
            'value': '511803000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '582134',
            'label': '荥经县',
            'value': '511822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '582268',
            'label': '汉源县',
            'value': '511823000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '582509',
            'label': '石棉县',
            'value': '511824000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '582627',
            'label': '天全县',
            'value': '511825000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '582781',
            'label': '芦山县',
            'value': '511826000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '582838',
            'label': '宝兴县',
            'value': '511827000000'
          }
        ],
        'id': '581674',
        'label': '雅安市',
        'value': '511800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '582907',
            'label': '巴州区',
            'value': '511902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '583369',
            'label': '恩阳区',
            'value': '511903000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '583840',
            'label': '通江县',
            'value': '511921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '584458',
            'label': '南江县',
            'value': '511922000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '585137',
            'label': '平昌县',
            'value': '511923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '585734',
            'label': '巴中经济开发区',
            'value': '511971000000'
          }
        ],
        'id': '582906',
        'label': '巴中市',
        'value': '511900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '585780',
            'label': '雁江区',
            'value': '512002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '586337',
            'label': '安岳县',
            'value': '512021000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '587385',
            'label': '乐至县',
            'value': '512022000000'
          }
        ],
        'id': '585779',
        'label': '资阳市',
        'value': '512000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '588045',
            'label': '马尔康市',
            'value': '513201000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '588168',
            'label': '汶川县',
            'value': '513221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '588308',
            'label': '理县',
            'value': '513222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '588411',
            'label': '茂县',
            'value': '513223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '588585',
            'label': '松潘县',
            'value': '513224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '588760',
            'label': '九寨沟县',
            'value': '513225000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '588912',
            'label': '金川县',
            'value': '513226000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '589047',
            'label': '小金县',
            'value': '513227000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '589205',
            'label': '黑水县',
            'value': '513228000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '589351',
            'label': '壤塘县',
            'value': '513230000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '589427',
            'label': '阿坝县',
            'value': '513231000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '589538',
            'label': '若尔盖县',
            'value': '513232000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '589659',
            'label': '红原县',
            'value': '513233000000'
          }
        ],
        'id': '588044',
        'label': '阿坝藏族羌族自治州',
        'value': '513200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '589710',
            'label': '康定市',
            'value': '513301000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '589976',
            'label': '泸定县',
            'value': '513322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '590141',
            'label': '丹巴县',
            'value': '513323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '590342',
            'label': '九龙县',
            'value': '513324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '590426',
            'label': '雅江县',
            'value': '513325000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '590558',
            'label': '道孚县',
            'value': '513326000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '590741',
            'label': '炉霍县',
            'value': '513327000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '590933',
            'label': '甘孜县',
            'value': '513328000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '591179',
            'label': '新龙县',
            'value': '513329000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '591349',
            'label': '德格县',
            'value': '513330000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '591550',
            'label': '白玉县',
            'value': '513331000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '591728',
            'label': '石渠县',
            'value': '513332000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '591921',
            'label': '色达县',
            'value': '513333000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '592079',
            'label': '理塘县',
            'value': '513334000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '592322',
            'label': '巴塘县',
            'value': '513335000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '592465',
            'label': '乡城县',
            'value': '513336000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '592570',
            'label': '稻城县',
            'value': '513337000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '592709',
            'label': '得荣县',
            'value': '513338000000'
          }
        ],
        'id': '589709',
        'label': '甘孜藏族自治州',
        'value': '513300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '592853',
            'label': '西昌市',
            'value': '513401000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '593167',
            'label': '木里藏族自治县',
            'value': '513422000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '593320',
            'label': '盐源县',
            'value': '513423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '593626',
            'label': '德昌县',
            'value': '513424000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '593788',
            'label': '会理县',
            'value': '513425000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '594129',
            'label': '会东县',
            'value': '513426000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '594475',
            'label': '宁南县',
            'value': '513427000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '594635',
            'label': '普格县',
            'value': '513428000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '594831',
            'label': '布拖县',
            'value': '513429000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '595054',
            'label': '金阳县',
            'value': '513430000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '595267',
            'label': '昭觉县',
            'value': '513431000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '595589',
            'label': '喜德县',
            'value': '513432000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '595787',
            'label': '冕宁县',
            'value': '513433000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '596058',
            'label': '越西县',
            'value': '513434000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '596391',
            'label': '甘洛县',
            'value': '513435000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '596650',
            'label': '美姑县',
            'value': '513436000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '596982',
            'label': '雷波县',
            'value': '513437000000'
          }
        ],
        'id': '592852',
        'label': '凉山彝族自治州',
        'value': '513400000000'
      }
    ],
    'id': '24',
    'label': '四川省',
    'value': '51'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '597314',
            'label': '南明区',
            'value': '520102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '597545',
            'label': '云岩区',
            'value': '520103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '597751',
            'label': '花溪区',
            'value': '520111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '597993',
            'label': '乌当区',
            'value': '520112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '598115',
            'label': '白云区',
            'value': '520113000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '598225',
            'label': '观山湖区',
            'value': '520115000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '598364',
            'label': '开阳县',
            'value': '520121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '598519',
            'label': '息烽县',
            'value': '520122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '598709',
            'label': '修文县',
            'value': '520123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '598848',
            'label': '清镇市',
            'value': '520181000000'
          }
        ],
        'id': '597313',
        'label': '贵阳市',
        'value': '520100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '599093',
            'label': '钟山区',
            'value': '520201000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '599243',
            'label': '六枝特区',
            'value': '520203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '599515',
            'label': '水城县',
            'value': '520221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '599771',
            'label': '盘州市',
            'value': '520281000000'
          }
        ],
        'id': '599092',
        'label': '六盘水市',
        'value': '520200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '600330',
            'label': '红花岗区',
            'value': '520302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '600549',
            'label': '汇川区',
            'value': '520303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '600700',
            'label': '播州区',
            'value': '520304000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '600903',
            'label': '桐梓县',
            'value': '520322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '601157',
            'label': '绥阳县',
            'value': '520323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '601292',
            'label': '正安县',
            'value': '520324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '601467',
            'label': '道真仡佬族苗族自治县',
            'value': '520325000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '601567',
            'label': '务川仡佬族苗族自治县',
            'value': '520326000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '601707',
            'label': '凤冈县',
            'value': '520327000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '601809',
            'label': '湄潭县',
            'value': '520328000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '601962',
            'label': '余庆县',
            'value': '520329000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '602044',
            'label': '习水县',
            'value': '520330000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '602323',
            'label': '赤水市',
            'value': '520381000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '602468',
            'label': '仁怀市',
            'value': '520382000000'
          }
        ],
        'id': '600329',
        'label': '遵义市',
        'value': '520300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '602664',
            'label': '西秀区',
            'value': '520402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '603047',
            'label': '平坝区',
            'value': '520403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '603207',
            'label': '普定县',
            'value': '520422000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '603396',
            'label': '镇宁布依族苗族自治县',
            'value': '520423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '603626',
            'label': '关岭布依族苗族自治县',
            'value': '520424000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '603801',
            'label': '紫云苗族布依族自治县',
            'value': '520425000000'
          }
        ],
        'id': '602663',
        'label': '安顺市',
        'value': '520400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '603990',
            'label': '七星关区',
            'value': '520502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '604599',
            'label': '大方县',
            'value': '520521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '605030',
            'label': '黔西县',
            'value': '520522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '605448',
            'label': '金沙县',
            'value': '520523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '605717',
            'label': '织金县',
            'value': '520524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '606329',
            'label': '纳雍县',
            'value': '520525000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '606800',
            'label': '威宁彝族回族苗族自治县',
            'value': '520526000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '607468',
            'label': '赫章县',
            'value': '520527000000'
          }
        ],
        'id': '603989',
        'label': '毕节市',
        'value': '520500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '607981',
            'label': '碧江区',
            'value': '520602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '608089',
            'label': '万山区',
            'value': '520603000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '608200',
            'label': '江口县',
            'value': '520621000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '608316',
            'label': '玉屏侗族自治县',
            'value': '520622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '608421',
            'label': '石阡县',
            'value': '520623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '608752',
            'label': '思南县',
            'value': '520624000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '609309',
            'label': '印江土家族苗族自治县',
            'value': '520625000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '609701',
            'label': '德江县',
            'value': '520626000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '610067',
            'label': '沿河土家族自治县',
            'value': '520627000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '610540',
            'label': '松桃苗族自治县',
            'value': '520628000000'
          }
        ],
        'id': '607980',
        'label': '铜仁市',
        'value': '520600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '611096',
            'label': '兴义市',
            'value': '522301000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '611361',
            'label': '兴仁市',
            'value': '522302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '611547',
            'label': '普安县',
            'value': '522323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '611655',
            'label': '晴隆县',
            'value': '522324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '611781',
            'label': '贞丰县',
            'value': '522325000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '611967',
            'label': '望谟县',
            'value': '522326000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '612153',
            'label': '册亨县',
            'value': '522327000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '612299',
            'label': '安龙县',
            'value': '522328000000'
          }
        ],
        'id': '611095',
        'label': '黔西南布依族苗族自治州',
        'value': '522300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '612507',
            'label': '凯里市',
            'value': '522601000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '612726',
            'label': '黄平县',
            'value': '522622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '612893',
            'label': '施秉县',
            'value': '522623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '612978',
            'label': '三穗县',
            'value': '522624000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '613083',
            'label': '镇远县',
            'value': '522625000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '613225',
            'label': '岑巩县',
            'value': '522626000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '613334',
            'label': '天柱县',
            'value': '522627000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '613479',
            'label': '锦屏县',
            'value': '522628000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '613606',
            'label': '剑河县',
            'value': '522629000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '613803',
            'label': '台江县',
            'value': '522630000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '613880',
            'label': '黎平县',
            'value': '522631000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '614225',
            'label': '榕江县',
            'value': '522632000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '614491',
            'label': '从江县',
            'value': '522633000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '614737',
            'label': '雷山县',
            'value': '522634000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '614911',
            'label': '麻江县',
            'value': '522635000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '614989',
            'label': '丹寨县',
            'value': '522636000000'
          }
        ],
        'id': '612506',
        'label': '黔东南苗族侗族自治州',
        'value': '522600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '615117',
            'label': '都匀市',
            'value': '522701000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '615270',
            'label': '福泉市',
            'value': '522702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '615356',
            'label': '荔波县',
            'value': '522722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '615466',
            'label': '贵定县',
            'value': '522723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '615586',
            'label': '瓮安县',
            'value': '522725000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '615702',
            'label': '独山县',
            'value': '522726000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '615777',
            'label': '平塘县',
            'value': '522727000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '615916',
            'label': '罗甸县',
            'value': '522728000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '616109',
            'label': '长顺县',
            'value': '522729000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '616205',
            'label': '龙里县',
            'value': '522730000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '616291',
            'label': '惠水县',
            'value': '522731000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '616521',
            'label': '三都水族自治县',
            'value': '522732000000'
          }
        ],
        'id': '615116',
        'label': '黔南布依族苗族自治州',
        'value': '522700000000'
      }
    ],
    'id': '25',
    'label': '贵州省',
    'value': '52'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '616621',
            'label': '五华区',
            'value': '530102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '616735',
            'label': '盘龙区',
            'value': '530103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '616851',
            'label': '官渡区',
            'value': '530111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '616992',
            'label': '西山区',
            'value': '530112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '617125',
            'label': '东川区',
            'value': '530113000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '617303',
            'label': '呈贡区',
            'value': '530114000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '617395',
            'label': '晋宁区',
            'value': '530115000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '617541',
            'label': '富民县',
            'value': '530124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '617624',
            'label': '宜良县',
            'value': '530125000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '617774',
            'label': '石林彝族自治县',
            'value': '530126000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '617875',
            'label': '嵩明县',
            'value': '530127000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '617960',
            'label': '禄劝彝族苗族自治县',
            'value': '530128000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '618178',
            'label': '寻甸回族彝族自治县',
            'value': '530129000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '618372',
            'label': '安宁市',
            'value': '530181000000'
          }
        ],
        'id': '616620',
        'label': '昆明市',
        'value': '530100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '618482',
            'label': '麒麟区',
            'value': '530302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '618642',
            'label': '沾益区',
            'value': '530303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '618788',
            'label': '马龙区',
            'value': '530304000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '618874',
            'label': '陆良县',
            'value': '530322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '619036',
            'label': '师宗县',
            'value': '530323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '619157',
            'label': '罗平县',
            'value': '530324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '619325',
            'label': '富源县',
            'value': '530325000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '619499',
            'label': '会泽县',
            'value': '530326000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '619901',
            'label': '宣威市',
            'value': '530381000000'
          }
        ],
        'id': '618481',
        'label': '曲靖市',
        'value': '530300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '620292',
            'label': '红塔区',
            'value': '530402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '620408',
            'label': '江川区',
            'value': '530403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '620490',
            'label': '澄江县',
            'value': '530422000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '620537',
            'label': '通海县',
            'value': '530423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '620623',
            'label': '华宁县',
            'value': '530424000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '620706',
            'label': '易门县',
            'value': '530425000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '620772',
            'label': '峨山彝族自治县',
            'value': '530426000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '620857',
            'label': '新平彝族傣族自治县',
            'value': '530427000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '620993',
            'label': '元江哈尼族彝族傣族自治县',
            'value': '530428000000'
          }
        ],
        'id': '620291',
        'label': '玉溪市',
        'value': '530400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '621086',
            'label': '隆阳区',
            'value': '530502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '621460',
            'label': '施甸县',
            'value': '530521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '621614',
            'label': '龙陵县',
            'value': '530523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '621746',
            'label': '昌宁县',
            'value': '530524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '621884',
            'label': '腾冲市',
            'value': '530581000000'
          }
        ],
        'id': '621085',
        'label': '保山市',
        'value': '530500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '622125',
            'label': '昭阳区',
            'value': '530602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '622330',
            'label': '鲁甸县',
            'value': '530621000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '622440',
            'label': '巧家县',
            'value': '530622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '622641',
            'label': '盐津县',
            'value': '530623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '622749',
            'label': '大关县',
            'value': '530624000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '622843',
            'label': '永善县',
            'value': '530625000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '623003',
            'label': '绥江县',
            'value': '530626000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '623052',
            'label': '镇雄县',
            'value': '530627000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '623346',
            'label': '彝良县',
            'value': '530628000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '623502',
            'label': '威信县',
            'value': '530629000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '623600',
            'label': '水富市',
            'value': '530681000000'
          }
        ],
        'id': '622124',
        'label': '昭通市',
        'value': '530600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '623635',
            'label': '古城区',
            'value': '530702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '623707',
            'label': '玉龙纳西族自治县',
            'value': '530721000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '623828',
            'label': '永胜县',
            'value': '530722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '623994',
            'label': '华坪县',
            'value': '530723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '624064',
            'label': '宁蒗彝族自治县',
            'value': '530724000000'
          }
        ],
        'id': '623634',
        'label': '丽江市',
        'value': '530700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '624172',
            'label': '思茅区',
            'value': '530802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '624253',
            'label': '宁洱哈尼族彝族自治县',
            'value': '530821000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '624352',
            'label': '墨江哈尼族自治县',
            'value': '530822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '624536',
            'label': '景东彝族自治县',
            'value': '530823000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '624720',
            'label': '景谷傣族彝族自治县',
            'value': '530824000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '624873',
            'label': '镇沅彝族哈尼族拉祜族自治县',
            'value': '530825000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '624994',
            'label': '江城哈尼族彝族自治县',
            'value': '530826000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '625054',
            'label': '孟连傣族拉祜族佤族自治县',
            'value': '530827000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '625103',
            'label': '澜沧拉祜族自治县',
            'value': '530828000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '625289',
            'label': '西盟佤族自治县',
            'value': '530829000000'
          }
        ],
        'id': '624171',
        'label': '普洱市',
        'value': '530800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '625337',
            'label': '临翔区',
            'value': '530902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '625452',
            'label': '凤庆县',
            'value': '530921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '625653',
            'label': '云县',
            'value': '530922000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '625864',
            'label': '永德县',
            'value': '530923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '625995',
            'label': '镇康县',
            'value': '530924000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '626078',
            'label': '双江拉祜族佤族布朗族傣族自治县',
            'value': '530925000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '626161',
            'label': '耿马傣族佤族自治县',
            'value': '530926000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '626266',
            'label': '沧源佤族自治县',
            'value': '530927000000'
          }
        ],
        'id': '625336',
        'label': '临沧市',
        'value': '530900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '626374',
            'label': '楚雄市',
            'value': '532301000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '626544',
            'label': '双柏县',
            'value': '532322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '626638',
            'label': '牟定县',
            'value': '532323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '626735',
            'label': '南华县',
            'value': '532324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '626874',
            'label': '姚安县',
            'value': '532325000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '626961',
            'label': '大姚县',
            'value': '532326000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '627103',
            'label': '永仁县',
            'value': '532327000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '627174',
            'label': '元谋县',
            'value': '532328000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '627263',
            'label': '武定县',
            'value': '532329000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '627408',
            'label': '禄丰县',
            'value': '532331000000'
          }
        ],
        'id': '626373',
        'label': '楚雄彝族自治州',
        'value': '532300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '627589',
            'label': '个旧市',
            'value': '532501000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '627718',
            'label': '开远市',
            'value': '532502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '627804',
            'label': '蒙自市',
            'value': '532503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '627923',
            'label': '弥勒市',
            'value': '532504000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '628081',
            'label': '屏边苗族自治县',
            'value': '532523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '628169',
            'label': '建水县',
            'value': '532524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '628338',
            'label': '石屏县',
            'value': '532525000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '628463',
            'label': '泸西县',
            'value': '532527000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '628559',
            'label': '元阳县',
            'value': '532528000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '628712',
            'label': '红河县',
            'value': '532529000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '628817',
            'label': '金平苗族瑶族傣族自治县',
            'value': '532530000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '628929',
            'label': '绿春县',
            'value': '532531000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '629031',
            'label': '河口瑶族自治县',
            'value': '532532000000'
          }
        ],
        'id': '627588',
        'label': '红河哈尼族彝族自治州',
        'value': '532500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '629087',
            'label': '文山市',
            'value': '532601000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '629250',
            'label': '砚山县',
            'value': '532622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '629369',
            'label': '西畴县',
            'value': '532623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '629452',
            'label': '麻栗坡县',
            'value': '532624000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '629569',
            'label': '马关县',
            'value': '532625000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '629711',
            'label': '丘北县',
            'value': '532626000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '629825',
            'label': '广南县',
            'value': '532627000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '630021',
            'label': '富宁县',
            'value': '532628000000'
          }
        ],
        'id': '629086',
        'label': '文山壮族苗族自治州',
        'value': '532600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '630181',
            'label': '景洪市',
            'value': '532801000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '630317',
            'label': '勐海县',
            'value': '532822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '630423',
            'label': '勐腊县',
            'value': '532823000000'
          }
        ],
        'id': '630180',
        'label': '西双版纳傣族自治州',
        'value': '532800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '630502',
            'label': '大理市',
            'value': '532901000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '630658',
            'label': '漾濞彝族自治县',
            'value': '532922000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '630734',
            'label': '祥云县',
            'value': '532923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '630884',
            'label': '宾川县',
            'value': '532924000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '630985',
            'label': '弥渡县',
            'value': '532925000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '631083',
            'label': '南涧彝族自治县',
            'value': '532926000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '631173',
            'label': '巍山彝族回族自治县',
            'value': '532927000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '631267',
            'label': '永平县',
            'value': '532928000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '631350',
            'label': '云龙县',
            'value': '532929000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '631448',
            'label': '洱源县',
            'value': '532930000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '631548',
            'label': '剑川县',
            'value': '532931000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '631650',
            'label': '鹤庆县',
            'value': '532932000000'
          }
        ],
        'id': '630501',
        'label': '大理白族自治州',
        'value': '532900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '631778',
            'label': '瑞丽市',
            'value': '533102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '631834',
            'label': '芒市',
            'value': '533103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '631951',
            'label': '梁河县',
            'value': '533122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '632027',
            'label': '盈江县',
            'value': '533123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '632152',
            'label': '陇川县',
            'value': '533124000000'
          }
        ],
        'id': '631777',
        'label': '德宏傣族景颇族自治州',
        'value': '533100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '632241',
            'label': '泸水市',
            'value': '533301000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '632337',
            'label': '福贡县',
            'value': '533323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '632404',
            'label': '贡山独龙族怒族自治县',
            'value': '533324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '632438',
            'label': '兰坪白族普米族自治县',
            'value': '533325000000'
          }
        ],
        'id': '632240',
        'label': '怒江傈僳族自治州',
        'value': '533300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '632562',
            'label': '香格里拉市',
            'value': '533401000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '632638',
            'label': '德钦县',
            'value': '533422000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '632693',
            'label': '维西傈僳族自治县',
            'value': '533423000000'
          }
        ],
        'id': '632561',
        'label': '迪庆藏族自治州',
        'value': '533400000000'
      }
    ],
    'id': '26',
    'label': '云南省',
    'value': '53'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '632787',
            'label': '城关区',
            'value': '540102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '632852',
            'label': '堆龙德庆区',
            'value': '540103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '632894',
            'label': '达孜区',
            'value': '540104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '632921',
            'label': '林周县',
            'value': '540121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '632977',
            'label': '当雄县',
            'value': '540122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '633015',
            'label': '尼木县',
            'value': '540123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '633057',
            'label': '曲水县',
            'value': '540124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '633083',
            'label': '墨竹工卡县',
            'value': '540127000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '633132',
            'label': '格尔木藏青工业园区',
            'value': '540171000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '633135',
            'label': '拉萨经济技术开发区',
            'value': '540172000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '633138',
            'label': '西藏文化旅游创意园区',
            'value': '540173000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '633141',
            'label': '达孜工业园区',
            'value': '540174000000'
          }
        ],
        'id': '632786',
        'label': '拉萨市',
        'value': '540100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '633145',
            'label': '桑珠孜区',
            'value': '540202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '633334',
            'label': '南木林县',
            'value': '540221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '633498',
            'label': '江孜县',
            'value': '540222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '633673',
            'label': '定日县',
            'value': '540223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '633862',
            'label': '萨迦县',
            'value': '540224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '633981',
            'label': '拉孜县',
            'value': '540225000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '634091',
            'label': '昂仁县',
            'value': '540226000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '634294',
            'label': '谢通门县',
            'value': '540227000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '634409',
            'label': '白朗县',
            'value': '540228000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '634532',
            'label': '仁布县',
            'value': '540229000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '634615',
            'label': '康马县',
            'value': '540230000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '634672',
            'label': '定结县',
            'value': '540231000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '634753',
            'label': '仲巴县',
            'value': '540232000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '634825',
            'label': '亚东县',
            'value': '540233000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '634858',
            'label': '吉隆县',
            'value': '540234000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '634906',
            'label': '聂拉木县',
            'value': '540235000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '634958',
            'label': '萨嘎县',
            'value': '540236000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '635005',
            'label': '岗巴县',
            'value': '540237000000'
          }
        ],
        'id': '633144',
        'label': '日喀则市',
        'value': '540200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '635041',
            'label': '卡若区',
            'value': '540302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '635224',
            'label': '江达县',
            'value': '540321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '635333',
            'label': '贡觉县',
            'value': '540322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '635495',
            'label': '类乌齐县',
            'value': '540323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '635588',
            'label': '丁青县',
            'value': '540324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '635666',
            'label': '察雅县',
            'value': '540325000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '635818',
            'label': '八宿县',
            'value': '540326000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '635943',
            'label': '左贡县',
            'value': '540327000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '636082',
            'label': '芒康县',
            'value': '540328000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '636160',
            'label': '洛隆县',
            'value': '540329000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '636238',
            'label': '边坝县',
            'value': '540330000000'
          }
        ],
        'id': '635040',
        'label': '昌都市',
        'value': '540300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '636333',
            'label': '巴宜区',
            'value': '540402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '636414',
            'label': '工布江达县',
            'value': '540421000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '636504',
            'label': '米林县',
            'value': '540422000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '636580',
            'label': '墨脱县',
            'value': '540423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '636635',
            'label': '波密县',
            'value': '540424000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '636731',
            'label': '察隅县',
            'value': '540425000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '636835',
            'label': '朗县',
            'value': '540426000000'
          }
        ],
        'id': '636332',
        'label': '林芝市',
        'value': '540400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '636895',
            'label': '乃东区',
            'value': '540502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '636955',
            'label': '扎囊县',
            'value': '540521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '637023',
            'label': '贡嘎县',
            'value': '540522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '637075',
            'label': '桑日县',
            'value': '540523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '637123',
            'label': '琼结县',
            'value': '540524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '637148',
            'label': '曲松县',
            'value': '540525000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '637175',
            'label': '措美县',
            'value': '540526000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '637196',
            'label': '洛扎县',
            'value': '540527000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '637231',
            'label': '加查县',
            'value': '540528000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '637313',
            'label': '隆子县',
            'value': '540529000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '637405',
            'label': '错那县',
            'value': '540530000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '637443',
            'label': '浪卡子县',
            'value': '540531000000'
          }
        ],
        'id': '636894',
        'label': '山南市',
        'value': '540500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '637553',
            'label': '色尼区',
            'value': '540602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '637707',
            'label': '嘉黎县',
            'value': '540621000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '637840',
            'label': '比如县',
            'value': '540622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '638026',
            'label': '聂荣县',
            'value': '540623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '638179',
            'label': '安多县',
            'value': '540624000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '638267',
            'label': '申扎县',
            'value': '540625000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '638338',
            'label': '索县',
            'value': '540626000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '638473',
            'label': '班戈县',
            'value': '540627000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '638570',
            'label': '巴青县',
            'value': '540628000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '638737',
            'label': '尼玛县',
            'value': '540629000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '638829',
            'label': '双湖县',
            'value': '540630000000'
          }
        ],
        'id': '637552',
        'label': '那曲市',
        'value': '540600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '638869',
            'label': '普兰县',
            'value': '542521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '638883',
            'label': '札达县',
            'value': '542522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '638907',
            'label': '噶尔县',
            'value': '542523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '638928',
            'label': '日土县',
            'value': '542524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '638947',
            'label': '革吉县',
            'value': '542525000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '638973',
            'label': '改则县',
            'value': '542526000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '639030',
            'label': '措勤县',
            'value': '542527000000'
          }
        ],
        'id': '638868',
        'label': '阿里地区',
        'value': '542500000000'
      }
    ],
    'id': '27',
    'label': '西藏自治区',
    'value': '54'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '639059',
            'label': '新城区',
            'value': '610102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '639170',
            'label': '碑林区',
            'value': '610103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '639277',
            'label': '莲湖区',
            'value': '610104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '639418',
            'label': '灞桥区',
            'value': '610111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '639610',
            'label': '未央区',
            'value': '610112000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '639883',
            'label': '雁塔区',
            'value': '610113000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '640130',
            'label': '阎良区',
            'value': '610114000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '640238',
            'label': '临潼区',
            'value': '610115000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '640531',
            'label': '长安区',
            'value': '610116000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '641052',
            'label': '高陵区',
            'value': '610117000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '641163',
            'label': '鄠邑区',
            'value': '610118000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '641459',
            'label': '蓝田县',
            'value': '610122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '641827',
            'label': '周至县',
            'value': '610124000000'
          }
        ],
        'id': '639058',
        'label': '西安市',
        'value': '610100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '642129',
            'label': '王益区',
            'value': '610202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '642183',
            'label': '印台区',
            'value': '610203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '642288',
            'label': '耀州区',
            'value': '610204000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '642473',
            'label': '宜君县',
            'value': '610222000000'
          }
        ],
        'id': '642128',
        'label': '铜川市',
        'value': '610200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '642602',
            'label': '渭滨区',
            'value': '610302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '642739',
            'label': '金台区',
            'value': '610303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '642875',
            'label': '陈仓区',
            'value': '610304000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '643129',
            'label': '凤翔县',
            'value': '610322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '643308',
            'label': '岐山县',
            'value': '610323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '643477',
            'label': '扶风县',
            'value': '610324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '643608',
            'label': '眉县',
            'value': '610326000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '643718',
            'label': '陇县',
            'value': '610327000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '643836',
            'label': '千阳县',
            'value': '610328000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '643912',
            'label': '麟游县',
            'value': '610329000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '643990',
            'label': '凤县',
            'value': '610330000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '644074',
            'label': '太白县',
            'value': '610331000000'
          }
        ],
        'id': '642601',
        'label': '宝鸡市',
        'value': '610300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '644129',
            'label': '秦都区',
            'value': '610402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '644324',
            'label': '杨陵区',
            'value': '610403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '644408',
            'label': '渭城区',
            'value': '610404000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '644578',
            'label': '三原县',
            'value': '610422000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '644740',
            'label': '泾阳县',
            'value': '610423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '644976',
            'label': '乾县',
            'value': '610424000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '645174',
            'label': '礼泉县',
            'value': '610425000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '645418',
            'label': '永寿县',
            'value': '610426000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '645590',
            'label': '长武县',
            'value': '610428000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '645736',
            'label': '旬邑县',
            'value': '610429000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '645872',
            'label': '淳化县',
            'value': '610430000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '646012',
            'label': '武功县',
            'value': '610431000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '646210',
            'label': '兴平市',
            'value': '610481000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '646424',
            'label': '彬州市',
            'value': '610482000000'
          }
        ],
        'id': '644128',
        'label': '咸阳市',
        'value': '610400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '646597',
            'label': '临渭区',
            'value': '610502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '647024',
            'label': '华州区',
            'value': '610503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '647185',
            'label': '潼关县',
            'value': '610522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '647219',
            'label': '大荔县',
            'value': '610523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '647538',
            'label': '合阳县',
            'value': '610524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '647771',
            'label': '澄城县',
            'value': '610525000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '647956',
            'label': '蒲城县',
            'value': '610526000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '648266',
            'label': '白水县',
            'value': '610527000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '648414',
            'label': '富平县',
            'value': '610528000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '648713',
            'label': '韩城市',
            'value': '610581000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '648927',
            'label': '华阴市',
            'value': '610582000000'
          }
        ],
        'id': '646596',
        'label': '渭南市',
        'value': '610500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '649072',
            'label': '宝塔区',
            'value': '610602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '649461',
            'label': '安塞区',
            'value': '610603000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '649602',
            'label': '延长县',
            'value': '610621000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '649779',
            'label': '延川县',
            'value': '610622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '649965',
            'label': '志丹县',
            'value': '610625000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '650088',
            'label': '吴起县',
            'value': '610626000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '650192',
            'label': '甘泉县',
            'value': '610627000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '650270',
            'label': '富县',
            'value': '610628000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '650416',
            'label': '洛川县',
            'value': '610629000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '650629',
            'label': '宜川县',
            'value': '610630000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '650754',
            'label': '黄龙县',
            'value': '610631000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '650812',
            'label': '黄陵县',
            'value': '610632000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '650933',
            'label': '子长市',
            'value': '610681000000'
          }
        ],
        'id': '649071',
        'label': '延安市',
        'value': '610600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '651141',
            'label': '汉台区',
            'value': '610702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '651379',
            'label': '南郑区',
            'value': '610703000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '651715',
            'label': '城固县',
            'value': '610722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '652006',
            'label': '洋县',
            'value': '610723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '652312',
            'label': '西乡县',
            'value': '610724000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '652545',
            'label': '勉县',
            'value': '610725000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '652762',
            'label': '宁强县',
            'value': '610726000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '652994',
            'label': '略阳县',
            'value': '610727000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '653177',
            'label': '镇巴县',
            'value': '610728000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '653379',
            'label': '留坝县',
            'value': '610729000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '653464',
            'label': '佛坪县',
            'value': '610730000000'
          }
        ],
        'id': '651140',
        'label': '汉中市',
        'value': '610700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '653518',
            'label': '榆阳区',
            'value': '610802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '653942',
            'label': '横山区',
            'value': '610803000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '654340',
            'label': '府谷县',
            'value': '610822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '654542',
            'label': '靖边县',
            'value': '610824000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '654762',
            'label': '定边县',
            'value': '610825000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '654979',
            'label': '绥德县',
            'value': '610826000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '655342',
            'label': '米脂县',
            'value': '610827000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '655563',
            'label': '佳县',
            'value': '610828000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '655910',
            'label': '吴堡县',
            'value': '610829000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '656020',
            'label': '清涧县',
            'value': '610830000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '656365',
            'label': '子洲县',
            'value': '610831000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '656658',
            'label': '神木市',
            'value': '610881000000'
          }
        ],
        'id': '653517',
        'label': '榆林市',
        'value': '610800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '657044',
            'label': '汉滨区',
            'value': '610902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '657598',
            'label': '汉阴县',
            'value': '610921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '657759',
            'label': '石泉县',
            'value': '610922000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '657932',
            'label': '宁陕县',
            'value': '610923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '658024',
            'label': '紫阳县',
            'value': '610924000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '658239',
            'label': '岚皋县',
            'value': '610925000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '658388',
            'label': '平利县',
            'value': '610926000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '658545',
            'label': '镇坪县',
            'value': '610927000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '658615',
            'label': '旬阳县',
            'value': '610928000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '658943',
            'label': '白河县',
            'value': '610929000000'
          }
        ],
        'id': '657043',
        'label': '安康市',
        'value': '610900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '659077',
            'label': '商州区',
            'value': '611002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '659398',
            'label': '洛南县',
            'value': '611021000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '659659',
            'label': '丹凤县',
            'value': '611022000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '659827',
            'label': '商南县',
            'value': '611023000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '659964',
            'label': '山阳县',
            'value': '611024000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '660222',
            'label': '镇安县',
            'value': '611025000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '660398',
            'label': '柞水县',
            'value': '611026000000'
          }
        ],
        'id': '659076',
        'label': '商洛市',
        'value': '611000000000'
      }
    ],
    'id': '28',
    'label': '陕西省',
    'value': '61'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '660491',
            'label': '城关区',
            'value': '620102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '660696',
            'label': '七里河区',
            'value': '620103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '660849',
            'label': '西固区',
            'value': '620104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '660974',
            'label': '安宁区',
            'value': '620105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '661043',
            'label': '红古区',
            'value': '620111000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '661108',
            'label': '永登县',
            'value': '620121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '661335',
            'label': '皋兰县',
            'value': '620122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '661404',
            'label': '榆中县',
            'value': '620123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '661697',
            'label': '兰州新区',
            'value': '620171000000'
          }
        ],
        'id': '660490',
        'label': '兰州市',
        'value': '620100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '661758',
            'label': '市辖区',
            'value': '620201000000'
          }
        ],
        'id': '661757',
        'label': '嘉峪关市',
        'value': '620200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '661813',
            'label': '金川区',
            'value': '620302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '661865',
            'label': '永昌县',
            'value': '620321000000'
          }
        ],
        'id': '661812',
        'label': '金昌市',
        'value': '620300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '662006',
            'label': '白银区',
            'value': '620402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '662101',
            'label': '平川区',
            'value': '620403000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '662204',
            'label': '靖远县',
            'value': '620421000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '662410',
            'label': '会宁县',
            'value': '620422000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '662756',
            'label': '景泰县',
            'value': '620423000000'
          }
        ],
        'id': '662005',
        'label': '白银市',
        'value': '620400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '662913',
            'label': '秦州区',
            'value': '620502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '663404',
            'label': '麦积区',
            'value': '620503000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '663851',
            'label': '清水县',
            'value': '620521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '664139',
            'label': '秦安县',
            'value': '620522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '664593',
            'label': '甘谷县',
            'value': '620523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '665024',
            'label': '武山县',
            'value': '620524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '665394',
            'label': '张家川回族自治县',
            'value': '620525000000'
          }
        ],
        'id': '662912',
        'label': '天水市',
        'value': '620500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '665672',
            'label': '凉州区',
            'value': '620602000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '666263',
            'label': '民勤县',
            'value': '620621000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '666574',
            'label': '古浪县',
            'value': '620622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '666845',
            'label': '天祝藏族自治县',
            'value': '620623000000'
          }
        ],
        'id': '665671',
        'label': '武威市',
        'value': '620600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '667074',
            'label': '甘州区',
            'value': '620702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '667365',
            'label': '肃南裕固族自治县',
            'value': '620721000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '667483',
            'label': '民乐县',
            'value': '620722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '667674',
            'label': '临泽县',
            'value': '620723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '667770',
            'label': '高台县',
            'value': '620724000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '667927',
            'label': '山丹县',
            'value': '620725000000'
          }
        ],
        'id': '667073',
        'label': '张掖市',
        'value': '620700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '668062',
            'label': '崆峒区',
            'value': '620802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '668358',
            'label': '泾川县',
            'value': '620821000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '668599',
            'label': '灵台县',
            'value': '620822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '668808',
            'label': '崇信县',
            'value': '620823000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '668902',
            'label': '庄浪县',
            'value': '620825000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '669220',
            'label': '静宁县',
            'value': '620826000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '669586',
            'label': '华亭市',
            'value': '620881000000'
          }
        ],
        'id': '668061',
        'label': '平凉市',
        'value': '620800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '669727',
            'label': '肃州区',
            'value': '620902000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '669899',
            'label': '金塔县',
            'value': '620921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '670011',
            'label': '瓜州县',
            'value': '620922000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '670116',
            'label': '肃北蒙古族自治县',
            'value': '620923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '670149',
            'label': '阿克塞哈萨克族自治县',
            'value': '620924000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '670171',
            'label': '玉门市',
            'value': '620981000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '670273',
            'label': '敦煌市',
            'value': '620982000000'
          }
        ],
        'id': '669726',
        'label': '酒泉市',
        'value': '620900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '670351',
            'label': '西峰区',
            'value': '621002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '670478',
            'label': '庆城县',
            'value': '621021000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '670670',
            'label': '环县',
            'value': '621022000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '670959',
            'label': '华池县',
            'value': '621023000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '671104',
            'label': '合水县',
            'value': '621024000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '671210',
            'label': '正宁县',
            'value': '621025000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '671329',
            'label': '宁县',
            'value': '621026000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '671626',
            'label': '镇原县',
            'value': '621027000000'
          }
        ],
        'id': '670350',
        'label': '庆阳市',
        'value': '621000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '671868',
            'label': '安定区',
            'value': '621102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '672222',
            'label': '通渭县',
            'value': '621121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '672583',
            'label': '陇西县',
            'value': '621122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '672827',
            'label': '渭源县',
            'value': '621123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '673064',
            'label': '临洮县',
            'value': '621124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '673418',
            'label': '漳县',
            'value': '621125000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '673572',
            'label': '岷县',
            'value': '621126000000'
          }
        ],
        'id': '671867',
        'label': '定西市',
        'value': '621100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '673964',
            'label': '武都区',
            'value': '621202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '674714',
            'label': '成县',
            'value': '621221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '674991',
            'label': '文县',
            'value': '621222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '675324',
            'label': '宕昌县',
            'value': '621223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '675690',
            'label': '康县',
            'value': '621224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '676071',
            'label': '西和县',
            'value': '621225000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '676486',
            'label': '礼县',
            'value': '621226000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '677095',
            'label': '徽县',
            'value': '621227000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '677346',
            'label': '两当县',
            'value': '621228000000'
          }
        ],
        'id': '673963',
        'label': '陇南市',
        'value': '621200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '677480',
            'label': '临夏市',
            'value': '622901000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '677564',
            'label': '临夏县',
            'value': '622921000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '677813',
            'label': '康乐县',
            'value': '622922000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '677984',
            'label': '永靖县',
            'value': '622923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '678136',
            'label': '广河县',
            'value': '622924000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '678253',
            'label': '和政县',
            'value': '622925000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '678393',
            'label': '东乡族自治县',
            'value': '622926000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '678645',
            'label': '积石山保安族东乡族撒拉族自治县',
            'value': '622927000000'
          }
        ],
        'id': '677479',
        'label': '临夏回族自治州',
        'value': '622900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '678815',
            'label': '合作市',
            'value': '623001000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '678877',
            'label': '临潭县',
            'value': '623021000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '679044',
            'label': '卓尼县',
            'value': '623022000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '679166',
            'label': '舟曲县',
            'value': '623023000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '679401',
            'label': '迭部县',
            'value': '623024000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '679469',
            'label': '玛曲县',
            'value': '623025000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '679522',
            'label': '碌曲县',
            'value': '623026000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '679561',
            'label': '夏河县',
            'value': '623027000000'
          }
        ],
        'id': '678814',
        'label': '甘南藏族自治州',
        'value': '623000000000'
      }
    ],
    'id': '29',
    'label': '甘肃省',
    'value': '62'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '679651',
            'label': '城东区',
            'value': '630102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '679722',
            'label': '城中区',
            'value': '630103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '679794',
            'label': '城西区',
            'value': '630104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '679846',
            'label': '城北区',
            'value': '630105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '679924',
            'label': '大通回族土族自治县',
            'value': '630121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '680256',
            'label': '湟中县',
            'value': '630122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '680671',
            'label': '湟源县',
            'value': '630123000000'
          }
        ],
        'id': '679650',
        'label': '西宁市',
        'value': '630100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '680836',
            'label': '乐都区',
            'value': '630202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '681227',
            'label': '平安区',
            'value': '630203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '681356',
            'label': '民和回族土族自治县',
            'value': '630222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '681708',
            'label': '互助土族自治县',
            'value': '630223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '682039',
            'label': '化隆回族自治县',
            'value': '630224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '682428',
            'label': '循化撒拉族自治县',
            'value': '630225000000'
          }
        ],
        'id': '680835',
        'label': '海东市',
        'value': '630200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '682599',
            'label': '门源回族自治县',
            'value': '632221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '682744',
            'label': '祁连县',
            'value': '632222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '682802',
            'label': '海晏县',
            'value': '632223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '682845',
            'label': '刚察县',
            'value': '632224000000'
          }
        ],
        'id': '682598',
        'label': '海北藏族自治州',
        'value': '632200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '682892',
            'label': '同仁县',
            'value': '632321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '682986',
            'label': '尖扎县',
            'value': '632322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '683092',
            'label': '泽库县',
            'value': '632323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '683176',
            'label': '河南蒙古族自治县',
            'value': '632324000000'
          }
        ],
        'id': '682891',
        'label': '黄南藏族自治州',
        'value': '632300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '683229',
            'label': '共和县',
            'value': '632521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '683366',
            'label': '同德县',
            'value': '632522000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '683465',
            'label': '贵德县',
            'value': '632523000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '683606',
            'label': '兴海县',
            'value': '632524000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '683680',
            'label': '贵南县',
            'value': '632525000000'
          }
        ],
        'id': '683228',
        'label': '海南藏族自治州',
        'value': '632500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '683774',
            'label': '玛沁县',
            'value': '632621000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '683826',
            'label': '班玛县',
            'value': '632622000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '683870',
            'label': '甘德县',
            'value': '632623000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '683916',
            'label': '达日县',
            'value': '632624000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '683963',
            'label': '久治县',
            'value': '632625000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '683996',
            'label': '玛多县',
            'value': '632626000000'
          }
        ],
        'id': '683773',
        'label': '果洛藏族自治州',
        'value': '632600000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '684035',
            'label': '玉树市',
            'value': '632701000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '684128',
            'label': '杂多县',
            'value': '632722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '684178',
            'label': '称多县',
            'value': '632723000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '684251',
            'label': '治多县',
            'value': '632724000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '684284',
            'label': '囊谦县',
            'value': '632725000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '684372',
            'label': '曲麻莱县',
            'value': '632726000000'
          }
        ],
        'id': '684034',
        'label': '玉树藏族自治州',
        'value': '632700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '684403',
            'label': '格尔木市',
            'value': '632801000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '684489',
            'label': '德令哈市',
            'value': '632802000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '684566',
            'label': '茫崖市',
            'value': '632803000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '684582',
            'label': '乌兰县',
            'value': '632821000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '684633',
            'label': '都兰县',
            'value': '632822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '684758',
            'label': '天峻县',
            'value': '632823000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '684833',
            'label': '大柴旦行政委员会',
            'value': '632857000000'
          }
        ],
        'id': '684402',
        'label': '海西蒙古族藏族自治州',
        'value': '632800000000'
      }
    ],
    'id': '30',
    'label': '青海省',
    'value': '63'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '684842',
            'label': '兴庆区',
            'value': '640104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '684995',
            'label': '西夏区',
            'value': '640105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '685085',
            'label': '金凤区',
            'value': '640106000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '685165',
            'label': '永宁县',
            'value': '640121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '685267',
            'label': '贺兰县',
            'value': '640122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '685359',
            'label': '灵武市',
            'value': '640181000000'
          }
        ],
        'id': '684841',
        'label': '银川市',
        'value': '640100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '685473',
            'label': '大武口区',
            'value': '640202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '685547',
            'label': '惠农区',
            'value': '640205000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '685644',
            'label': '平罗县',
            'value': '640221000000'
          }
        ],
        'id': '685472',
        'label': '石嘴山市',
        'value': '640200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '685839',
            'label': '利通区',
            'value': '640302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '685986',
            'label': '红寺堡区',
            'value': '640303000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '686065',
            'label': '盐池县',
            'value': '640323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '686193',
            'label': '同心县',
            'value': '640324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '686352',
            'label': '青铜峡市',
            'value': '640381000000'
          }
        ],
        'id': '685838',
        'label': '吴忠市',
        'value': '640300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '686475',
            'label': '原州区',
            'value': '640402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '686681',
            'label': '西吉县',
            'value': '640422000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '687004',
            'label': '隆德县',
            'value': '640423000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '687141',
            'label': '泾源县',
            'value': '640424000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '687246',
            'label': '彭阳县',
            'value': '640425000000'
          }
        ],
        'id': '686474',
        'label': '固原市',
        'value': '640400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '687422',
            'label': '沙坡头区',
            'value': '640502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '687625',
            'label': '中宁县',
            'value': '640521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '687784',
            'label': '海原县',
            'value': '640522000000'
          }
        ],
        'id': '687421',
        'label': '中卫市',
        'value': '640500000000'
      }
    ],
    'id': '31',
    'label': '宁夏回族自治区',
    'value': '64'
  },
  {
    'level': 1,
    'children': [
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '687970',
            'label': '天山区',
            'value': '650102000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '688188',
            'label': '沙依巴克区',
            'value': '650103000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '688414',
            'label': '新市区',
            'value': '650104000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '688608',
            'label': '水磨沟区',
            'value': '650105000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '688784',
            'label': '头屯河区',
            'value': '650106000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '688929',
            'label': '达坂城区',
            'value': '650107000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '688979',
            'label': '米东区',
            'value': '650109000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '689151',
            'label': '乌鲁木齐县',
            'value': '650121000000'
          }
        ],
        'id': '687969',
        'label': '乌鲁木齐市',
        'value': '650100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '689210',
            'label': '独山子区',
            'value': '650202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '689233',
            'label': '克拉玛依区',
            'value': '650203000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '689358',
            'label': '白碱滩区',
            'value': '650204000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '689380',
            'label': '乌尔禾区',
            'value': '650205000000'
          }
        ],
        'id': '689209',
        'label': '克拉玛依市',
        'value': '650200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '689400',
            'label': '高昌区',
            'value': '650402000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '689520',
            'label': '鄯善县',
            'value': '650421000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '689642',
            'label': '托克逊县',
            'value': '650422000000'
          }
        ],
        'id': '689399',
        'label': '吐鲁番市',
        'value': '650400000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '689713',
            'label': '伊州区',
            'value': '650502000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '689978',
            'label': '巴里坤哈萨克自治县',
            'value': '650521000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '690074',
            'label': '伊吾县',
            'value': '650522000000'
          }
        ],
        'id': '689712',
        'label': '哈密市',
        'value': '650500000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '690132',
            'label': '昌吉市',
            'value': '652301000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '690318',
            'label': '阜康市',
            'value': '652302000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '690441',
            'label': '呼图壁县',
            'value': '652323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '690589',
            'label': '玛纳斯县',
            'value': '652324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '690826',
            'label': '奇台县',
            'value': '652325000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '690982',
            'label': '吉木萨尔县',
            'value': '652327000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '691077',
            'label': '木垒哈萨克自治县',
            'value': '652328000000'
          }
        ],
        'id': '690131',
        'label': '昌吉回族自治州',
        'value': '652300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '691156',
            'label': '博乐市',
            'value': '652701000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '691442',
            'label': '阿拉山口市',
            'value': '652702000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '691448',
            'label': '精河县',
            'value': '652722000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '691599',
            'label': '温泉县',
            'value': '652723000000'
          }
        ],
        'id': '691155',
        'label': '博尔塔拉蒙古自治州',
        'value': '652700000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '691725',
            'label': '库尔勒市',
            'value': '652801000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '691986',
            'label': '轮台县',
            'value': '652822000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '692079',
            'label': '尉犁县',
            'value': '652823000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '692204',
            'label': '若羌县',
            'value': '652824000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '692258',
            'label': '且末县',
            'value': '652825000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '692352',
            'label': '焉耆回族自治县',
            'value': '652826000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '692451',
            'label': '和静县',
            'value': '652827000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '692633',
            'label': '和硕县',
            'value': '652828000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '692699',
            'label': '博湖县',
            'value': '652829000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '692749',
            'label': '库尔勒经济技术开发区',
            'value': '652871000000'
          }
        ],
        'id': '691724',
        'label': '巴音郭楞蒙古自治州',
        'value': '652800000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '692754',
            'label': '阿克苏市',
            'value': '652901000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '692995',
            'label': '温宿县',
            'value': '652922000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '693167',
            'label': '库车县',
            'value': '652923000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '693457',
            'label': '沙雅县',
            'value': '652924000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '693654',
            'label': '新和县',
            'value': '652925000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '693808',
            'label': '拜城县',
            'value': '652926000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '694020',
            'label': '乌什县',
            'value': '652927000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '694149',
            'label': '阿瓦提县',
            'value': '652928000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '694335',
            'label': '柯坪县',
            'value': '652929000000'
          }
        ],
        'id': '692753',
        'label': '阿克苏地区',
        'value': '652900000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '694385',
            'label': '阿图什市',
            'value': '653001000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '694521',
            'label': '阿克陶县',
            'value': '653022000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '694690',
            'label': '阿合奇县',
            'value': '653023000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '694727',
            'label': '乌恰县',
            'value': '653024000000'
          }
        ],
        'id': '694384',
        'label': '克孜勒苏柯尔克孜自治州',
        'value': '653000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '694790',
            'label': '喀什市',
            'value': '653101000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '695096',
            'label': '疏附县',
            'value': '653121000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '695260',
            'label': '疏勒县',
            'value': '653122000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '695528',
            'label': '英吉沙县',
            'value': '653123000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '695748',
            'label': '泽普县',
            'value': '653124000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '695921',
            'label': '莎车县',
            'value': '653125000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '696553',
            'label': '叶城县',
            'value': '653126000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '696964',
            'label': '麦盖提县',
            'value': '653127000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '697182',
            'label': '岳普湖县',
            'value': '653128000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '697309',
            'label': '伽师县',
            'value': '653129000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '697668',
            'label': '巴楚县',
            'value': '653130000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '697926',
            'label': '塔什库尔干塔吉克自治县',
            'value': '653131000000'
          }
        ],
        'id': '694789',
        'label': '喀什地区',
        'value': '653100000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '697994',
            'label': '和田市',
            'value': '653201000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '698256',
            'label': '和田县',
            'value': '653221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '698532',
            'label': '墨玉县',
            'value': '653222000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '698998',
            'label': '皮山县',
            'value': '653223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '699254',
            'label': '洛浦县',
            'value': '653224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '699514',
            'label': '策勒县',
            'value': '653225000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '699668',
            'label': '于田县',
            'value': '653226000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '699912',
            'label': '民丰县',
            'value': '653227000000'
          }
        ],
        'id': '697993',
        'label': '和田地区',
        'value': '653200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '699965',
            'label': '伊宁市',
            'value': '654002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '700152',
            'label': '奎屯市',
            'value': '654003000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '700238',
            'label': '霍尔果斯市',
            'value': '654004000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '700294',
            'label': '伊宁县',
            'value': '654021000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '700477',
            'label': '察布查尔锡伯自治县',
            'value': '654022000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '700618',
            'label': '霍城县',
            'value': '654023000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '700791',
            'label': '巩留县',
            'value': '654024000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '700916',
            'label': '新源县',
            'value': '654025000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '701077',
            'label': '昭苏县',
            'value': '654026000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '701217',
            'label': '特克斯县',
            'value': '654027000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '701314',
            'label': '尼勒克县',
            'value': '654028000000'
          }
        ],
        'id': '699964',
        'label': '伊犁哈萨克自治州',
        'value': '654000000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '701428',
            'label': '塔城市',
            'value': '654201000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '701647',
            'label': '乌苏市',
            'value': '654202000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '702043',
            'label': '额敏县',
            'value': '654221000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '702330',
            'label': '沙湾县',
            'value': '654223000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '702798',
            'label': '托里县',
            'value': '654224000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '702897',
            'label': '裕民县',
            'value': '654225000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '702974',
            'label': '和布克赛尔蒙古自治县',
            'value': '654226000000'
          }
        ],
        'id': '701427',
        'label': '塔城地区',
        'value': '654200000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '703092',
            'label': '阿勒泰市',
            'value': '654301000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '703256',
            'label': '布尔津县',
            'value': '654321000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '703345',
            'label': '富蕴县',
            'value': '654322000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '703444',
            'label': '福海县',
            'value': '654323000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '703581',
            'label': '哈巴河县',
            'value': '654324000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '703670',
            'label': '青河县',
            'value': '654325000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '703741',
            'label': '吉木乃县',
            'value': '654326000000'
          }
        ],
        'id': '703091',
        'label': '阿勒泰地区',
        'value': '654300000000'
      },
      {
        'level': 2,
        'children': [
          {
            'level': 3,
            'children': [

            ],
            'id': '703803',
            'label': '石河子市',
            'value': '659001000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '703949',
            'label': '阿拉尔市',
            'value': '659002000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '704282',
            'label': '图木舒克市',
            'value': '659003000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '704415',
            'label': '五家渠市',
            'value': '659004000000'
          },
          {
            'level': 3,
            'children': [

            ],
            'id': '704466',
            'label': '铁门关市',
            'value': '659006000000'
          }
        ],
        'id': '703802',
        'label': '自治区直辖县级行政区划',
        'value': '659000000000'
      }
    ],
    'id': '32',
    'label': '新疆维吾尔自治区',
    'value': '65'
  }
]

for (let i = 0; i < cityData.length; i++) {
  const childrens = cityData[i].children
  for (let j = 0; j < childrens.length; j++) {
    if (childrens[j].children.length == 0) {
      delete childrens[j].children
    }
  }
}

export const provinces = cityData
