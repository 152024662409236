<template>
  <a-select
    v-model="modelValue"
    :disabled="disabled"
    style="width: 100%;margin-right:10px"
    :placeholder="placeholder"
    @change="changeAtion"
    :loading="isLoading"
  >
    <!-- :placeholder="placeholder" -->
    <!-- v-decorator="['areaSelect' + modelValue, { rules: [{ required: true, message: '请选择地区!' }] }]" -->

    <a-select-option v-for="province in dataArray" :key="province.code">
      {{ province.name }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  name: 'AreaSelectView',
  props: {
    value: {
      type: String
    },
    DataList: {
      type: Array
    },
    DataType: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    parentId: {
      type: String
    },
    level: {
      type: String
    }
  },
  data() {
    return { modelValue: undefined, dataArray: [], isLoading: false }
  },
  created() {
    console.log('area created')
    if (this.value) {
      this.modelValue = this.value
    }
    if (this.DataList) {
      this.dataArray = this.DataList
    } else {
      this.getApiData()
    }
  },
  methods: {
    async getApiData() {
      try {
        if (this.parentId || this.level) {
          console.log('this.level --' + this.level)
          console.log('this.parentId --' + this.parentId)
        } else {
          return
        }
        let params = {
          body: {
            params: {
              level: this.level,
              parentId: this.parentId
            }
          }
        }
        this.isLoading = true
        let res = await this.$axios.GetAreaList(params)
        console.log('GetAreaList', res)
        this.dataArray = res.data.body || []

        if (this.value) {
          let ProvinceObj = this.dataArray.filter(item => {
            if (item.code == this.value) {
              return item
            }
          })
          if (ProvinceObj && ProvinceObj.length > 0) {
            this.getObjectId(ProvinceObj)
          } else {
            this.$emit('input', '')
          }
        }
        this.isLoading = false
      } catch (e) {
        console.log(e)
      }
    },
    changeAtion(value) {
      console.log('changeAtion', value)
      this.modelValue = value
      let ProvinceObj = this.dataArray.filter(res => {
        if (res.code == value) {
          return res
        }
      })
      this.$emit('onChange', value, ProvinceObj[0])
    },
    getObjectId(ProvinceObj) {
      this.$emit('getObjectId', ProvinceObj[0].id)
    }
  },
  computed: {
    //非省级
    // IsGetGata() {
    //   return this.parentId && this.modelValue
    // }
  },
  watch: {
    value(v) {
      console.log('value', v)
      this.modelValue = v
    },
    modelValue(v) {
      console.log('modelValue', v)
      this.$emit('input', v)
      this.$emit('change', v)
    },
    parentId(old, v) {
      console.log(old, v)
      if (old == '') {
        return
      }
      //   this.$emit('input', '')
      //   this.$emit('onChange', '', {})
      this.getApiData()
    },
    IsGetGata() {
      console.log('IsGetGata')
      this.getApiData()
    }
  }
}
</script>

<style></style>
