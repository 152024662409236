var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news-center"},[_c('div',{staticClass:"news-head"},[_c('span',[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"news-content"},[_c('a-carousel',{staticStyle:{"height":"100%"},attrs:{"autoplay":true,"dotsClass":"indicate"}},[_vm._l((Math.ceil(_vm.news.length / 2)),function(page,index){return [_c('div',{key:index},_vm._l((_vm.news.slice(index * 2 , (index + 1) * 2)),function(item,idx){return _c('div',{key:idx,staticClass:"news-item"},[_c('div',{staticClass:"news-item-head"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(item.newsTypeName)+" "),_c('a-icon',{staticStyle:{"font-size":"22px","color":"#20BF8E","margin-left":"10px"},attrs:{"type":"right-circle"},on:{"click":function($event){return _vm.goNewsCenter(item.typeid)}}})],1)]),_vm._l((item.dataList),function(item1,idx){return _c('div',{key:idx,staticClass:"news-line",style:({ marginTop: item1.imgUrl ? '17px' : '', paddingBottom: item1.url ? '12px' : '' }),on:{"click":function($event){return _vm.goNews(item1.newsTypeId, item1.id)}}},[(item1.url)?[_c('img',{staticClass:"newImg",attrs:{"src":item1.url}}),_c('div',{staticStyle:{"margin-left":"8px"}},[_c('div',[_vm._v(_vm._s(item.dataList.length > 0 ? item1.title : ""))]),_c('div',{staticClass:"new-message",style:({
                                      maxWidth: '600px',
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      wordWrap: 'break-word',
                                      wordBreak: 'break-all',
                                  }),domProps:{"innerHTML":_vm.xss(_vm._s(_vm.formatHtml(item1.content)))}}),_c('div',{staticStyle:{"margin":"30px 0 0 0","text-align":"right"}},[_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v(" "+_vm._s(item1.modifiedTime ? item1.modifiedTime.slice(0, 10) : '')+" ")])])])]:_vm._e(),(!item1.url || idx === item.dataList.length)?[_c('div',{staticClass:"new-message",staticStyle:{"line-height":"26px"},attrs:{"id":"ellipsis","title":item1.title}},[_vm._v(_vm._s(item1.title))]),_c('div',{staticStyle:{"float":"right"}},[_vm._v(" "+_vm._s(item1.modifiedTime ? item1.modifiedTime.slice(0, 10) : '')+" ")])]:_vm._e()],2)})],2)}),0)]})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }