<template>
  <a-popover placement="bottomLeft" overlayClassName="machine_type_popover" trigger="click" @visibleChange="onVisibleChange">
    <template slot="content">
      <div class="machine_type_wrap ignore">
        <template v-if="maxLevel > 1">
          <div class="machine_type_list" v-if="machineType.length">
            <div class="item" v-for="item in machineType" :key="item.id" @click.capture="onChangeMachineType(item)">
              <a-checkbox :indeterminate="indeterminate && currentCheckId === item.id" :checked="checkAllId === item.id" @change="onCheckAllChange(item, $event)"></a-checkbox>
              <span :class="['label', currentCheckId === item.id ? 'current_select' : '']">
                <span>{{ item.dictValue }}</span>
                <a-icon :type="isLoading && currentCheckId === item.id ? 'redo' : 'right'" :spin="isLoading && currentCheckId === item.id" :class="['ant-cascader-picker-arrow', currentCheckId === item.id ? 'current_select' : '']" />
              </span>
            </div>
          </div>
          <div class="machine_type_children" v-show="mapMachineTypeChildren.length > 0">
            <a-checkbox-group v-model="checkedIds" @change="onChangeGroup">
              <div class="children_wrap">
                <div class="children_list" v-for="(arr, index) in mapMachineTypeChildren" :key="index">
                  <div class="item" v-for="item in arr" :key="item.id"><a-checkbox :value="item.dictKey">{{ item.dictValue }}</a-checkbox></div>
                </div>
              </div>
            </a-checkbox-group>
          </div>
        </template>
        <template v-else>
          <div class="machine_type_children" v-if="list.length">
            <a-checkbox-group v-model="checkedIds" @change="onChangeGroup">
              <div class="children_wrap">
                <div class="children_list" v-for="(arr, index) in mapList" :key="index">
                  <div class="item" v-for="item in arr" :key="item.id"><a-checkbox :value="item.dictKey">{{ item.dictValue }}</a-checkbox></div>
                </div>
              </div>
            </a-checkbox-group>
          </div>
        </template>
      </div>
    </template>
    <div class="select_wrap">
      <span tabindex="0" :class="['ant-cascader-picker', isFocus ? 'ant-cascader-picker-focused' : '']">
        <input :placeholder="selectTags.list.length ? '' : placeholder" type="text" readonly="true" class="ant-input ant-cascader-input ">
        <span class="tag_warp ant-cascader-picker-label">
          <span class="tag_box" v-for="item in selectTags.list" :key="item.id">{{ cname ? cname + '/' + item.dictValue : item.dictValue }}<a-icon class="close_icon" type="close" @click.stop="deleteTag(item)" /></span>
          <span class="tag_box" v-if="selectTags.count">{{ selectTags.count }}</span>
        </span>
        <a-icon type="down" :class="['ant-cascader-picker-arrow', isFocus ? 'ant-cascader-picker-arrow-expand' : '']" />
      </span>
    </div>
  </a-popover>
</template>

<script>
export default {
  name: "TypeSelection",
  props: {
    machineType: {
      type: Array,
      default: () => []
    },
    machineTypeChildren: {
      type: Object,
      default: () => ({})
    },
    placeholder: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    maxLevel: {
      type: Number,
      default: 2
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isFocus: false,
      indeterminate: false,
      cname: '',
      checkAllId: '',
      currentCheckId: '',
      checkedIds: [],
      checkedValue: {
        type: '',
        children: []
      }
    }
  },
  computed: {
    mapMachineTypeChildren() {
      const typeList = this.machineTypeChildren[this.currentCheckId]
      const maxLine = this.machineType.length
      const arr = []
      if (typeList) {
        const max = Math.ceil(typeList.length / maxLine)
        for (let index = 0; index < max; index++) {
          arr.push(typeList.slice(index * maxLine, (index + 1) * maxLine))
        }
      }
      return arr
    },
    mapList() {
      const typeList = this.list
      const maxLine = 7
      const arr = []
      if (typeList.length) {
        const max = Math.ceil(typeList.length / maxLine)
        for (let index = 0; index < max; index++) {
          arr.push(typeList.slice(index * maxLine, (index + 1) * maxLine))
        }
      }
      return arr
    },
    selectTags() {
      if (this.maxLevel > 1) {
        const typeList = this.machineTypeChildren[this.currentCheckId]
        if (typeList) {
          const list = typeList.filter(item => this.checkedIds.includes(item.dictKey))
          return { list: list.slice(0, 2), count: list.length > 2 ? `+${list.length - 2}` : '' }
        } else {
          return { list: [], count: '' }
        }
      } else {
        const typeList = this.list
        if (typeList.length) {
          const list = typeList.filter(item => this.checkedIds.includes(item.dictKey))
          return { list: list.slice(0, 1), count: list.length > 1 ? `+${list.length - 1}` : '' }
        } else {
          return { list: [], count: '' }
        }
      }
    }
  },
  watch: {
    machineType(val) {
      if (val.length) {
        // this.currentCheckId = val[0].id
        // this.checkedValue.type = val[0].dictKey
      }
    },
    checkedIds(val) {
      this.checkedValue.children = val
    },
    checkedValue: {
      handler: function (val) { 
        this.$emit('change', val)
      },
      deep: true
    }
  },
  methods: {
    // 下拉框显示隐藏的回调
    onVisibleChange(v) {
      this.isFocus = v
    },
    // 切换农机一级分类
    onChangeMachineType(val) {
      const { id, dictKey, dictValue } = val
      if (this.currentCheckId === id) return
      this.cname = dictValue
      this.checkedValue.type = dictKey
      this.currentCheckId = id
      this.checkAllId = ''
      this.checkedIds = []
      this.indeterminate = false
      if (this.machineTypeChildren[id]?.length > 0) return
      this.$emit('change-type', id)
    },
    // 农机类型全选
    onCheckAllChange(v, e) {
      const typeList = this.machineTypeChildren[this.currentCheckId]
      if (typeList) {
        this.checkAllId = e.target.checked ? v.id : ''
        this.checkedIds = e.target.checked ? typeList.map(item => item.dictKey) : []
        this.indeterminate = false
      } else {
        const unwatch = this.$watch(function () { return this.machineTypeChildren[this.currentCheckId] }, function (val) {
          this.checkAllId = e.target.checked ? v.id : ''
          this.checkedIds = e.target.checked ? val.map(item => item.dictKey) : []
          this.indeterminate = false
          unwatch()
        })
      }
    },
    // 选中的农机类型
    onChangeGroup(checkedValues) {
      if (this.maxLevel > 1) {
        const checkedList = this.machineTypeChildren[this.currentCheckId]
        const isAll = checkedList?.length === checkedValues.length
        this.checkAllId = isAll ? this.currentCheckId : ''
        this.indeterminate = !!checkedValues.length && checkedValues.length < checkedList.length;
      }
    },
    // 删除
    deleteTag(val) {
      console.log(val);
      this.checkedIds = this.checkedIds.filter(item => item != val.dictKey)
      if (this.maxLevel > 1) {
        const checkedList = this.machineTypeChildren[this.currentCheckId]
        const isAll = checkedList?.length === this.checkedIds.length
        this.checkAllId = isAll ? this.currentCheckId : ''
        this.indeterminate = !!this.checkedIds.length && this.checkedIds.length < checkedList.length;
      }
    },
    resetHandle() {
      this.indeterminate = false
      this.checkAllId = ''
      this.checkedIds = []
      this.checkedValue = {
        type: '',
        children: []
      }
      if (this.machineType.length) this.currentCheckId = this.machineType[0].id
      else this.currentCheckId = ''
    }
  }
};
</script>

<style lang="less" scoped>
.select_wrap {
  padding: 10px 0;
  .tag_warp {
    height: auto;
    margin: 0;
    transform: translateY(-50%);
    .tag_box {
      display: inline-block;
      padding: 4px 11px;
      margin-right: 8px;
      color: #888888;
      background: #F0F0F0;
      border-radius: 3px;
      .close_icon {
        margin-left: 8px;
      }
    }
  }
}
.machine_type_wrap.ignore {
  display: flex;
  .machine_type_list {
    padding: 10px 16px 0 16px;
    .item {
      height: 38px;
      .label {
        position: relative;
        padding: 0 18px 0 10px;
        cursor: pointer;
        .ant-cascader-picker-arrow {
          right: 0;
        }
      }
    }
  }
  .current_select {
    color: #20BF8E;
  }
  .machine_type_children {
    padding: 10px 32px 0 16px;
  }
  .children_wrap {
    display: flex;
  }
  .children_list {
    &:nth-child(n + 2) {
      margin-left: 32px;
    }
    .item {
      height: 38px;
    }
  }
}
.machine_type_wrap.ignore>div:nth-child(2) {
  border-left: 1px solid #d9d9d9;
}
</style>
