import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	isLogin: false,
    userInfo: {
		id: '',
		name: '',
		nickName: '',
		userName: '',
		phone: '',
		sex: '',
		lastLoginTime: ''
	},
    roles: [],
	menus: [],
	auditStatus: 0,
	certificateStatus: 0,
	microAppLoading: false, // 嵌入微应用loading
    searchData: {
        title: '',
        type: ''
    },
  },
  mutations: {
		setUserInfo(state, data) {
			console.log('setUserInfo', data);
			state.userInfo = {...data}
			state.isLogin = true
		},
		setRoles(state, data) {
			console.log('roles', data);
			state.roles = [...data]
		},
		removeUserInfo(state) {
			const userInfo = {
				id: '',
				name: '',
				nickName: '',
				userName: '',
				phone: '',
				sex: '',
				lastLoginTime: ''
			};
			state.isLogin = false
			state.userInfo = userInfo
		},
		removeRoles(state) {
			state.roles = []
		},
		setMenus(state, data) {
			console.log('menus', data);
			state.menus = [...data]
		},
		removeMenus(state) {
			state.menus = []
		},
		setCertification(state, data) {
			state.auditStatus = data.auditStatus
			state.certificateStatus = data.certificateStatus
		},
		setMicroAppLoading(state) {
			state.microAppLoading = !state.microAppLoading
		},
        setSearchTitle(state, {title,type}) {
            state.searchData = {
                title,
                type
            }
        }
  },
  getters: {
		userInfo: state => state.userInfo,
		roles: state => state.roles,
		isLogin: state => state.isLogin,
		menus: state => state.menus,
		microAppLoading: state => state.microAppLoading
  }
})