<template>
  <div class="publisher">
      <a-card style="width: 100%;height: 226px;">
        <div class="card-title">信息发布者</div>
        <div class="company">
            <img v-if="type === 's'" :src="data.certificateStatus === '0' ? companyIcon : personIcon" style="width: 45px;height: 45px;"/>
            <img v-if="type === 'd'" :src="data.certificateStatus === '1' ? companyIcon : personIcon" style="width: 45px;height: 45px;"/>
            <div class="company-info">
                <div>
                    {{ data.publisher }}
                </div>
                <img v-if="type === 's'" :src="data.certificateStatus === '0' ? authIcon : personAuth" style="width: 75px;height: 23px;margin-top: 5px;"/>
                <img v-if="type === 'd'" :src="data.certificateStatus === '1' ? authIcon : personAuth" style="width: 75px;height: 23px;margin-top: 5px;"/>
            </div>
        </div>
        <div class="telephone">
            <img :src="phoneIcon" style="width: 24px;height: 24px;margin-left: 27px;"/>
            <span style="margin-left: 20px;">{{ data.phone }}</span>
        </div>
    </a-card>
  </div>
</template>

<script>
export default {
name: "Publisher",
components: {
},
data () {
return {
    phoneIcon: require('@/assets/img/telephone.png'),
	companyIcon: require('@/assets/img/company.png'),
    personIcon: require('@/assets/img/personAvator.png'),
    authIcon: require('@/assets/img/authentication.png'),
    personAuth: require('@/assets/img/personAuth.png'),
    type: 's'
}
},
props: {
    data: {
        type: Object,
        default: () => ({
            publisher: '',
            phone: '',
            isCompany: false
        })
    }
},
watch: {

},
methods: {
    // // 查询认证状态
    // async getCertificationStatus() {
    //     const { data: { code, msg, body: { certificateStatus } } } = await this.$axios.getCertification({})
    //     if (code != 0) return this.$message.error(msg)
    //     this.certificateStatus = certificateStatus
    // },
},
computed: {

},
created () {
  this.type = this.$route.params.type
},
mounted () {

},
destroyed () {

},
}
</script>

<style scoped lang="less">

.publisher {
    .card-title {
        font-size: 15px;
        font-weight: 600;
        color: #333333;
        line-height: 21px;
    }

    .company {
        margin-top: 22px;
        .company-info {
            display: inline-block;
            vertical-align: top;
            margin-left: 10px;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 19px;
        }
    }

    .telephone {
        left: 18px;
        right: 18px;
        height: 57px;
        position: absolute;
        bottom: 35px;
        display: flex;
        align-items: center;
        background: #E8F7F3;
        font-size: 27px;
        font-weight: 500;
        color: @primary-color;
        line-height: 38px;
    }

    /deep/ .ant-card-body {
        padding: 20px 25px 35px 25px;
        font-size: 11px;
        font-weight: 400;
        color: #333333;
        line-height: 15px;
    }
}
</style>
