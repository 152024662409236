<template>
  <div class="news-center">
      <div class="news-head">
        <span>{{ title }}</span>
      </div>
      <div class="news-content">
          <a-carousel style="height: 100%;" :autoplay="true" dotsClass="indicate">
            <template v-for="(page, index) in Math.ceil(news.length / 2)">
                <div :key="index">
                    <div class="news-item" v-for="(item, idx) in news.slice(index * 2 , (index + 1) * 2)" :key="idx">
                        <div class="news-item-head">
                            <div class="item-title">
                                {{ item.newsTypeName }}
                                <a-icon type="right-circle" style="font-size: 22px;color: #20BF8E;margin-left: 10px;" @click="goNewsCenter(item.typeid)"/>
                            </div>
                        </div>
                        <div class="news-line" @click="goNews(item1.newsTypeId, item1.id)" :style="{ marginTop: item1.imgUrl ? '17px' : '', paddingBottom: item1.url ? '12px' : '' }" v-for="(item1, idx) in item.dataList" :key="idx">
                            <template v-if="item1.url">
                                <img class="newImg" :src="item1.url" />
                                <div style="margin-left: 8px;">
                                    <div>{{ item.dataList.length > 0 ? item1.title : "" }}</div>
                                    <div
                                    class="new-message"
                                    v-html="formatHtml(item1.content)"
                                    :style="{
                                        maxWidth: '600px',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        wordWrap: 'break-word',
                                        wordBreak: 'break-all',
                                    }"
                                    ></div>
                                    <div style="margin: 30px 0 0 0; text-align: right">
                                    <div style="display: inline-block">
                                        {{ item1.modifiedTime ? item1.modifiedTime.slice(0, 10) : '' }}
                                    </div>
                                    <!-- <div style="margin-left: 30px; display: inline-block">
                                        <img class="icon" :src="iconImg" />
                                        <span style="margin-left: 5px;">{{item1.visitStatistic || 0}}</span>
                                    </div> -->
                                    </div>
                                </div>
                            </template>
                            <template v-if="!item1.url || idx === item.dataList.length">
                                <div
                                    id="ellipsis"
                                    class="new-message"
                                    style="line-height: 26px"
                                    :title="item1.title"
                                >{{ item1.title }}</div>
                                <!-- <div style="margin-left: 30px; float: right">
                                    <img class="icon" :src="iconImg" /><span style="margin-left: 5px;">{{
                                    item1.visitStatistic || 0
                                    }}
                                    </span>
                                </div> -->
                                <div style="float: right">
                                    {{ item1.modifiedTime ? item1.modifiedTime.slice(0, 10) : '' }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
          </a-carousel>
      </div>
  </div>
</template>
<script>
export default {
name: "NewsCenter",
components: {
},
data () {
return {
    iconImg: require('@/assets/img/eye.png'),
    title: '新闻资讯'
}
},
props: {
    news: {
        type: Array,
        default: () => []
    }
},
watch: {

},
methods: {
    goNews(typeid, id) {
        this.$router.push({
            path: `/news/${typeid}/detail/${id}`,
            query: {
                type: 'detail',
                typeId: typeid
            }
        })
    },
    goNewsCenter(typeid) {
        this.$router.push({
            path: `/news/${typeid}/list`,
            query: {
                type: 'all',
                typeId: typeid
            }
        })
    },
    formatHtml(value) {
        value = value.replace(/<\/?[^>]*>/g,'')
        value = value.replace(/<\/?.+?>/g,'')
          value = value.replace(/(^\s+)|(\s+$)/g,"")
          return value;
    }
},
computed: {
	newsLen() {
		return Math.ceil(this.news.length / 2)
	}
},
created () {

},
mounted () {

},
destroyed () {

},
}
</script>

<style scoped lang="less">
    @import url('../../styles/components/newsCenter.less');
</style>
