<template>
  <div class="merchant-home">
    <!-- 看板 -->
    <div class="data-board">
      <a-row
        :gutter="[24,16]"
        type="flex"
        justify="space-between"
      >
        <a-col :sm="24" :md="24" :lg="12" :xl="6">
          <a-card class="board-item card-box-shadow">
            <a-row
              type="flex"
              justify="space-between"
            >
              <div class="node-name">上链企业数</div>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>累计上链企业数</span>
                </template>
                <div class="prompt">!</div>
              </a-tooltip>
            </a-row>
            <div class="borderPrice">{{ enterpriseInfo.enterCount }}</div>
            <div class="echartsInit">
              <div
                ref="dataLines"
                style="height: 100%"
              />
            </div>
            <a-row class="borderYear">昨日新增<span class="borderYearNum">{{ enterpriseInfo.yesterdayIncreaseCount }}</span></a-row>
          </a-card>
        </a-col>
        <a-col :sm="24" :md="24" :lg="12" :xl="6">
          <a-card class="board-item card-box-shadow">
            <a-row
              type="flex"
              justify="space-between"
            >
              <div class="node-name">上链商品数</div>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>累计上链商品总数</span>
                </template>
                <div class="prompt">!</div>
              </a-tooltip>
            </a-row>
            <div class="borderPrice">{{ goodsInfo.goodsCount }}</div>
            <div class="echartsInit">
              <div style="height: 100%" />
            </div>
            <a-row class="borderYear">昨日新增<span class="borderYearNum">{{ goodsInfo.yesterdayIncreaseCount }}</span></a-row>
          </a-card>
        </a-col>
        <a-col :sm="24" :md="24" :lg="12" :xl="6">
          <a-card class="board-item card-box-shadow">
            <a-row
              type="flex"
              justify="space-between"
            >
              <div class="node-name">赋码总数 (个)</div>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>过去12个月累计已绑定批次的溯源码总数及每月新增绑定总数</span>
                </template>
                <div class="prompt">!</div>
              </a-tooltip>
            </a-row>
            <div class="borderPrice">{{ codingInfo.count }}</div>
            <div class="echartsInit">
              <div
                ref="dataBars"
                style="height: 100%"
              />
            </div>
            <a-row class="borderYear">昨日新增<span class="borderYearNum">{{ codingInfo.yesterdayIncreaseCount }}</span></a-row>
          </a-card>
        </a-col>
        <a-col :sm="24" :md="24" :lg="12" :xl="6">
          <a-card class="board-item card-box-shadow">
            <a-row
              type="flex"
              justify="space-between"
            >
              <div class="node-name">扫码总数 (次)</div>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>溯源码累计被扫描的次数，上月扫码和去年上月扫码数量以及月同比和日环比</span>
                </template>
                <div class="prompt">!</div>
              </a-tooltip>
            </a-row>
            <div class="borderPrice">{{ scanCodeInfo.count }}</div>
            <div class="echartsInit">
              <div
                ref="dataBarsCode"
                style="height: 100%"
              />
            </div>
            <a-row
              type="flex"
              justify="space-between"
              class="borderYear"
              style="padding-top: 6px;"
            >
              <div>
                月同比
                <img
                  v-if="scanCodeInfo.monthCompare > 0"
                  src="~@/assets/img/toTop.png"
                  style="width: 16px;height: 16px;margin-top:-2px"
                >
                <img
                  v-else-if="scanCodeInfo.monthCompare < 0"
                  src="~@/assets/img/toBot.png"
                  style="width: 16px;height: 16px;margin-top:-2px"
                >
                {{ scanCodeInfo.monthCompare ? Math.abs(Number(scanCodeInfo.monthCompare)) : 0 }}%
              </div>
              <div>
                日环比
                <img
                  v-if="scanCodeInfo.dayFrom > 0"
                  src="~@/assets/img/toTop.png"
                  style="width: 16px;height: 16px;margin-top:-2px"
                >
                <img
                  v-else-if="scanCodeInfo.dayFrom < 0"
                  src="~@/assets/img/toBot.png"
                  style="width: 16px;height: 16px;margin-top:-2px"
                >
                {{ scanCodeInfo.dayFrom ? Math.abs(Number(scanCodeInfo.dayFrom)) : 0 }}%
              </div>
            </a-row>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <!-- 商品扫码量分析 -->
    <a-card class="data-bars card-box-shadow">
      <a-row
        type="flex"
        justify="space-between"
      >
        <div class="s-m-f-x">商品扫码量分析</div>
      </a-row>
      <a-row v-if="!shopCodeFlag" :gutter="[16,16]">
        <a-col :span="19">
          <div
            ref="chartFuma"
            style="width: 100%;height: 400px;"
          />
        </a-col>
        <a-col :span="5">
          <ul class="topList">
            <a-range-picker
              :placeholder="['开始月份', '结束月份']"
              :format="dateFormat"
              :value="value"
              :mode="mode2"
              :disabled="shopCodeFlag"
              @panelChange="handlePanelChange"
              @change="onChange('bar')"
            >
             <a-icon
                slot="suffixIcon"
                type="calendar"
               />
           </a-range-picker>
            <li class="t-l">
              <a-row
                v-for="(item, index) in shopCodeList"
                :key="index"
              >
                <a-col :span="6">
                  <p v-if="index < 3" :class="`top_${index}`" class="topTree">
                    {{ index + 1 }}
                  </p>
                  <p v-else class="topTree topOthers">
                    {{ index + 1 }}
                  </p>
                </a-col>
                <a-col :span="12">
                  <div class="goods-name">
                    {{ item.goodsName }}
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="goods-sum">{{ item.sum }}</div>
                </a-col>
              </a-row>
            </li>
          </ul>
        </a-col>
      </a-row>
      <div v-else class="noData">
        <img
          src="~@/assets/img/noData.png"
          alt=""
          style="margin-top: 120px"
        >
        <p>暂无数据</p>
      </div>
    </a-card>
    <div style="height: 8vh" />
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  data() {
    return {
      dateFormat: 'YYYY/MM',
      dataLines: null,
      dataBars: null,
      dataBarsCode: null,
      chartFuma: null,
      enterpriseInfo: { // 上链企业数
        enterCount: 0,
        yesterdayIncreaseCount: 0,
        staticalResponses: []
      },
      goodsInfo: { // 上链商品数
        goodsCount: 0,
        yesterdayIncreaseCount: 0
      },
      codingInfo: { // 赋码统计
        count: 0,
        yesterdayIncreaseCount: 0,
        staticalResponses: []
      },
      scanCodeInfo: { // 扫码统计
        count: 0,
        monthCount: 0,
        lastYearCount: '',
        monthCompare: 0,
        dayFrom: 0
      },
      shopCodeList: [], // 商品扫码量排名
      cityTopList: [], // 城市扫码排名
      geoCoordMap: [],
      BJData: [],
      shopCodeFlag: true,
      cityMap: true,
      mode2: ['month', 'month'],
      mapMode: ['month', 'month'],
      shopSelect: [],
      value: null,
      mapValue: null,
      startTime: null,
      endTime: null,
      goodStartTime: null,
      goodEendTime: null,
      firstSelect: '',
      goodsId: ''
    }
  },
  beforeMount() {
    setTimeout(() => {
      this.getHomePage()
      this.histogramAnalysis()
    }, 500)
  },
  mounted() {
    window.addEventListener('resize', this.chartResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.chartResize)
  },
  methods: {
    forNumber(num) {
      return num?.split('').reverse().join('').replace(/(\d{3})/g, '$1,').replace(/\,$/, '').split('').reverse().join('')
    },
    handlePanelChange(value, mode) {
      this.startTime = value[0]._d.getFullYear() + '-' + (value[0]._d.getMonth() + 1 < 10 ? '0' + (value[0]._d.getMonth() + 1) : value[0]._d.getMonth() + 1)
      this.endTime = value[1]._d.getFullYear() + '-' + (value[1]._d.getMonth() + 1 < 10 ? '0' + (value[1]._d.getMonth() + 1) : value[1]._d.getMonth() + 1)
      this.value = value
      this.mode2 = [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]]
      this.histogramAnalysis()
    },
    onChange(data) {
      if (data == 'bar') {
        this.startTime = undefined
        this.endTime = undefined
        this.histogramAnalysis()
      } else if (data == 'map') {
        this.goodStartTime = undefined
        this.goodEendTime = undefined
      }
      console.log(data)
    },
    shopSelectValue(goodsId) {
      this.firstSelect = goodsId
      this.goodsId = goodsId
      this.geoCoordMap = []
      this.BJData = []
    },
    // 窗口大小改变时重绘图表
    chartResize() {
      this.dataLines && this.dataLines.resize()
      this.dataBars && this.dataBars.resize()
      this.dataBarsCode && this.dataBarsCode.resize()
      this.chartFuma && this.chartFuma.resize()
    },
    // 批次总数-折线图
    initLine(xData, yData) {
      this.dataLines = echarts.init(this.$refs.dataLines)
      const option = {
        grid: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        },
        xAxis: {
          type: 'category',
          data: xData,
          boundaryGap: true,
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#4DAB6E'
            }
          },
          backgroundColor: '#DBEEE2'
        },
        series: [{
          data: yData,
          type: 'line',
          symbol: 'none',
          areaStyle: {},
          itemStyle: {
            color: '#4DAB6E'
          }
        }]
      }
      this.dataLines.setOption(option)
    },
    // 赋码总数-柱状图
    initBarTotal(xData, yData) {
      this.dataBars = echarts.init(this.$refs.dataBars)
      const option = {
        grid: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        },
        xAxis: {
          type: 'category',
          data: xData,
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#4DAB6E'
            }
          },
          backgroundColor: '#DBEEE2'
        },
        series: [{
          data: yData,
          type: 'bar',
          itemStyle: {
            color: '#33ABFB'
          }
        }]
      }
      this.dataBars.setOption(option)
    },
    // 扫码总数-柱状图
    initBarCode(xData, yData) {
      this.dataBarsCode = echarts.init(this.$refs.dataBarsCode)
      const option = {
        grid: {
          right: '23%',
          left: '30%'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none'
          }
        },
        xAxis: {
          show: false,
          type: 'value'
        },
        yAxis: [{
          type: 'category',
          inverse: true,
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          data: xData
        }, {
          type: 'category',
          inverse: true,
          axisTick: 'none',
          axisLine: 'none',
          show: true,
          axisLabel: {
            textStyle: {
              color: '#595959',
              fontSize: '12'
            }
          },
          data: yData
        }],
        series: [{
          name: '总数',
          type: 'bar',
          zlevel: 1,
          barWidth: 20,
          barCategoryGap: '20%',
          data: yData,
          itemStyle: {
            normal: {
              color: '#13C2C2',
              barBorderRadius: 3
            }
          }
        }
        ]
      }
      this.dataBarsCode.setOption(option)
    },
    // 扫码量分布-柱状图
    initBar(xData, yData) {
      this.chartFuma = echarts.init(this.$refs.chartFuma)
      const option = {
        grid: {
          top: 40,
          right: 20,
          bottom: 20,
          left: 50
        },
        xAxis: {
          type: 'category',
          minInterval: 1,
          data: xData,
          boundaryGap: [0, 0.01],
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisTick: {
            show: false
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [
          {
            name: '扫码量',
            type: 'bar',
            data: yData,
            barWidth: 22,
            itemStyle: {
              color: '#4DAB6E'
            }
          }
        ]
      }
      this.chartFuma.setOption(option)
    },
    // 小类统计
    async getHomePage() {
      try {
        const res = await this.$axios.getHomePage()
        const _data = res.data.body
        if (_data) {
          if (_data.goodsInfo.hasData) {
            this.goodsInfo = _data.goodsInfo
          } else {
            this.goodsInfo.yesterdayIncreaseCount = 0
          }
          if (_data.enterpriseInfo.hasData) {
            this.enterpriseInfo = _data.enterpriseInfo
            if (_data.enterpriseInfo.staticalResponses) {
              const xData = []
              const yData = []
              _data.enterpriseInfo.staticalResponses.map(item => {
                xData.push(item.date)
                yData.push(item.sum)
              })
              this.$nextTick(() => {
                this.initLine(xData, yData)
              })
            }
          } else {
            this.enterpriseInfo.yesterdayIncreaseCount = 0
          }
          if (_data.codingInfo.hasData) {
            this.codingInfo = _data.codingInfo
            if (_data.codingInfo.staticalResponses) {
              const xDataCoding = []
              const yDataCoding = []
              _data.codingInfo.staticalResponses.map(item => {
                xDataCoding.push(item.date)
                yDataCoding.push(item.sum)
              })
              this.$nextTick(() => {
                this.initBarTotal(xDataCoding, yDataCoding)
              })
            }
          } else {
            this.codingInfo.yesterdayIncreaseCount = 0
          }
          if (_data.scanCodeInfo.hasData) {
            this.scanCodeInfo = _data.scanCodeInfo
            if (_data.scanCodeInfo.lastYearCount && _data.scanCodeInfo.monthCount) {
              const xData = []
              const yData = []
              const newDate = new Date()
              const nowYear = newDate.getFullYear()
              const nowMonth = newDate.getMonth() + 1 < 10 ? '0' + newDate.getMonth() : newDate.getMonth()
              xData.push(nowYear + '-' + nowMonth)
              xData.push(nowYear - 1 + '-' + nowMonth)
              yData.push(this.scanCodeInfo.monthCount)
              yData.push(this.scanCodeInfo.lastYearCount)
              this.$nextTick(() => {
                this.initBarCode(xData, yData)
              })
            }
          } else {
            this.scanCodeInfo.yesterdayIncreaseCount = 0
          }
          this.goodsInfo.goodsCount = this.forNumber(this.goodsInfo.goodsCount ? this.goodsInfo.goodsCount.toString() : '0')
          // 批次统计
          this.enterpriseInfo.count = this.forNumber(this.enterpriseInfo.count ? this.enterpriseInfo.count.toString() : '0')
          // 赋码统计
          this.codingInfo.count = this.forNumber(this.codingInfo.count ? this.codingInfo.count.toString() : '0')
          // 扫码统计
          this.scanCodeInfo.count = this.forNumber(this.scanCodeInfo.count ? this.scanCodeInfo.count.toString() : '0')
        }
      } catch (error) {
        this.$utils.errorMsg(error)
      }
    },
    // 商品扫码柱状图分析
    async histogramAnalysis() {
      try {
        const param = {
          body: {
            params: {
              startTime: this.startTime,
              endTime: this.endTime
            }
          }
        }
        const res = await this.$axios.histogramAnalysis(param)
        const _data = res.data.body
        if (_data) {
          if (_data.hasGoodsData && _data.histogramResponse) {
            this.shopCodeFlag = false
            this.shopCodeList = _data.goodsResponse
            let xPrintData = []
            let yPrintData = []
            _data.histogramResponse.map(item => {
              xPrintData.push(item.date)
              yPrintData.push(item.sum)
            })
            this.$nextTick(() => {
              this.initBar(xPrintData, yPrintData)
            })
          } else {
            this.shopCodeFlag = true
          }
        }
      } catch (error) {
        this.$utils.errorMsg(error)
      }
    }
  }
}
</script>
<style scoped lang="less">
.merchant-home {
  .card-box-shadow {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  }
  .data-board {
    margin-bottom: 22px;
    .board-item {
      width: 100%;
      height: 140px;
      overflow: hidden;
      font-size: 12px;
      background-color: #fff;
      .node-name {
        font-size: 12px;
        font-weight: 500;
        color: #444444;
      }
      /deep/ .ant-card-body {
        padding: 13px 20px 7px;
        height: 100%;
        .ant-row-flex-space-between {
          align-items: center;
        }
      }
      .prompt {
        width: 14px;
        height: 14px;
        line-height: 14px;
        cursor: pointer;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.45);
        border-radius: 50%;
        color: rgba(0, 0, 0, 0.45);
      }
      .borderPrice {
        height: 30px;
        font-size: 22px;
        font-weight: 500;
        color: #333333;
        line-height: 30px;
        margin-top: 9px;
      }
      .echartsInit {
        width: 100%;
        height: calc(100% - 80px);
      }
      .borderYear {
        border-top: 1px solid #E8E8E8;
        height: 17px;
        font-size: 10px;
        font-weight: 400;
        color: #949494;
        line-height: 17px;
        padding-top: 10px;
        display: flex;
        align-items: center;
        .borderYearNum {
          margin-left: auto;
        }
      }
      .board-item-content {
        width: 100%;
        display: inline-block;
        vertical-align: middle;
        font-weight: 500;
        div:first-child {
          margin-bottom: 5px;
        }
      }
    }
  }
  .chartFuma {
    width: 100%;
    height: 100%;
  }
  .data-bars {
    margin-bottom: 22px;
    .s-m-f-x {
      font-size: 14px;
      font-weight: 500;
      color: #444444;
    }
    /deep/ .ant-card-body {
      padding: 15px 10px 0 15px;
      .ant-row-flex-space-between {
        align-items: center;
      }
    }
    .data-bars-header {
      width: 26%;
      height: 40px;
      line-height: 40px;
      margin-bottom: 20px;
    }
  }
  .topList {
    width: 100%;
    // height: 280px;
    padding: 0;
    font-size: 14px;
    list-style: none;
    color: #000000;
    margin-top: -25px;
    .topTree {
      width: 20px;
      text-align: center;
      font-size: 12px;
      border-radius: 50%;
      color: #fff;
    }
    .top_0 {
      background: #4DAB6E;
    }
    .top_1 {
      background: #FFD33A;
    }
    .top_2 {
      background: #529CFF;
    }
    .topOthers {
      color: #838383;
    }
    .t-l {
      border-radius: 4px;
      border: 1px solid #DEDEDE;
      padding: 10px 10px 0;
      margin-top: 12px;
      .goods-name {
        font-size: 10px;
        font-weight: 400;
        color: #979797;
      }
      .goods-sum {
        font-size: 12px;
        font-weight: 400;
        color: #4DAB6E;
      }
    }
  }
  .noData {
    height: 418px;
    text-align: center;
  }
}
</style>
