<template>
  <div class="regist">
		<div class="header">
			<img class="logo" :src="logo"/>
			<span class="title">{{ title }}</span>
			<!-- <a-divider type="vertical" style="height: 60px;"/>
			<span class="common">新用户注册</span> -->
		</div>
		<div class="neck">
			<div style="width: 4px;height: 14px;background: #20BF8E;display: inline-block;margin-left: 35%;"></div>
			<span class="blackTitle">新用户注册</span>
			<span class="greenTitle" @click="goLogin">使用已有账户登录</span>
		</div>
		<div class="regist-form">
			<a-form-model ref="ruleForm" :model="form" :rules="rules">
				<a-form-model-item ref="phone" prop="phone">
					<a-input v-model="form.phone" placeholder="请输入手机号">
                      <img slot="prefix" :src="phoneImg" style="width: 18px;height: 18px;"/>
					</a-input>
				</a-form-model-item>
				<a-form-model-item ref="password" prop="password">
					<a-input-password v-model="form.password" placeholder="请输入密码">
                      <img slot="prefix" :src="passImg" style="width: 18px;height: 18px;"/>
					</a-input-password>
				</a-form-model-item>
				<a-form-model-item ref="confirmPassword" prop="confirmPassword">
					<a-input-password v-model="form.confirmPassword" placeholder="确认密码">
                      <img slot="prefix" :src="passImg" style="width: 18px;height: 18px;"/>
					</a-input-password>
				</a-form-model-item>
				<a-form-model-item ref="code" prop="code">
					<a-input v-model.trim="form.code" placeholder="请输入短信验证码">
                        <img slot="prefix" :src="guardImg" style="width: 18px;height: 18px;"/>
						<div slot="suffix">
							<a-divider type="vertical" />
							<a-button type="primary" class="verificateBtn" :disabled="countDownDisabled" ghost @click="getValidate">
                  {{ countDownMsg }}
							</a-button>
						</div>
					</a-input>
				</a-form-model-item>
			</a-form-model>
		</div>
		<div class="fromsubmitcardclass">
			<div>
				<a-button type="primary" size="small" class="regist-btn" @click="onSubmit">
						注册
				</a-button>
			</div>
			<div class="tip">
				<a-radio defaultValue="false" :checked="radioChecked" @click="rdClick"></a-radio>
				<span>注册即代表您同意</span>
				<span style="text-decoration: underline;color: #20BF8E;">平台服务协议</span>
			</div>
			<!-- <div class="regist" @click="goLogin">使用已有账号登录</div> -->
		</div>
  </div>
</template>
<script>
let timer, time = 60

export default {
name: "Regist",
components: {
},
data () {
	let confirmPass = (rule, value, callback) => {
	  if (value === '') {
        callback(new Error('请输入至少6位密码'));
      } else if (value !== this.form.password) {
        callback(new Error("两次密码不一致，请重新输入！"));
      } else {
        callback();
      }
	}
	return {
		phoneRegx: /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/,
		logo: require('@/assets/img/suzhou_logo.png'),
		title: '宿州数字农业统一门户',
		form: {
			phone: '',
			password: '',
			code: '',
			confirmPassword: '',
		},
		rules: {
			phone: [
				{ required: true, message: '请输入手机号', trigger: 'blur' },
				{ min: 3, max: 12, message: '手机号码11位', trigger: 'blur' }
			],
			password: [
				{ required: true, message: '请输入至少6位密码', trigger: 'blur' },
				{ min: 6, max: 12, message: '密码长度6～12位', trigger: 'blur' }
			],
			confirmPassword: [
				{ validator: confirmPass, trigger: 'blur' },
				// { min: 6, max: 12, message: '密码长度6～12位', trigger: 'blur' }
			],
			code: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }]
		},
		countDownMsg: '获取验证码',
		countDownDisabled: false,
		radioChecked: false,
		passImg: require('@/assets/img/lock.png'),
		phoneImg: require('@/assets/img/phoneIcon.png'),
		guardImg: require('@/assets/img/guard.png'),
	}
},
props: {

},
watch: {

},
computed: {

},
created () {

},
mounted () {

},
destroyed () {

},
methods: {
	rdClick() {
      this.radioChecked = !this.radioChecked
    },
	onSubmit() {
		this.$refs.ruleForm.validate(valid => {
			if (valid) {
				console.log('valid', valid)
				if (!this.radioChecked) {
					this.$message.warn("请勾选平台服务协议！")
					return
				} else {
					this.register()
				}
			} else {
				this.$message.warning('请填写必填项！')
				return false
			}
		})		
	},
	register() {
		const formParam = {...this.form}
		delete formParam.confirmPassword
		const params = {
			body: {...formParam}
		}
		this.$axios.register(params).then(res => {
			if (res.data.code === '0') {
				this.$message.success('注册成功，请登录')
    		this.$router.push({ path: '/login' })
			} else {
				this.$message.error(res.data.msg)
			}
		})
	},
	goLogin() {
    this.$router.push({ path: '/login' })
	},
	// 获取验证码
	getValidate() {
		if (!this.phoneRegx.test(this.form.phone)) {
			return
		}
    timer = setInterval(this.countDown, 1000)
		const params = {
			body: {
				phone: this.form.phone
			}
		}
		this.$axios.registerSendCode(params).then(res => {
			if (res.data.code === '0') {
				this.$message.success('发送成功')
			} else {
				this.$message.error(res.data.msg)
			}
		})
	},
	countDown() {
		if (time === 0) {
			this.countDownMsg = '获取验证码'
			this.countDownDisabled = false
			time = 60
			clearInterval(timer)
		} else {
			this.countDownMsg = `重新发送(${time})`
			this.countDownDisabled = true
			time--
		}
	},
}
}
</script>
<style scoped lang="less">
	.regist {
		.header {
			padding: 29px 90px;
			background: #FFFFFF;
			box-shadow: 0px 2px 21px 1px rgba(33, 33, 33, 0.1);
			width: 100%;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-content: center;
			align-items: center;
			.logo {
					width: 42px;
					height: 42px;
			}
			.title {
					margin-left: 15px;
					font-size: 26px;
					font-weight: 700;
					color: @primary-color;
					line-height: 37px;
			}
			.common {
					font-size: 14px;
					font-weight: 400;
					color: #2A2A2A;
					line-height: 20px;
			}
		}

		.neck {
			font-weight: 600;
			margin-top: 63px;
			.blackTitle {
				margin-left: 5px;
				font-size: 20px;
				color: #2A2A2A;
				line-height: 28px;
			}
			.greenTitle {
				cursor: pointer;
				margin-left: 42%;
				font-size: 16px;
				color: @primary-color;
				line-height: 22px;
			}
		}

		.regist-form {
			width: 332px;
			margin: 58px auto;
			.verificateBtn {
				padding: 0;
				font-size: 14px;
				font-weight: 400;
				color: @primary-color;
				line-height: 20px;
			}
			/deep/ .ant-form-item-control-wrapper {
				border-bottom: 1px solid #979797;;
			}
			/deep/ .ant-input {
              border: none;
              padding-left: 41px;
            }
		}

		.fromsubmitcardclass {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			.tip {
				margin-top: 6px;
				font-size: 12px;
				font-weight: 400;
				color: #9D9D9D;
				line-height: 17px;
			}
			.regist {
				margin-top: 26px;
				cursor: pointer;
				text-align: center;
				font-size: 14px;
				font-weight: 400;
				color: @primary-color;
				line-height: 10px;
			}

			.regist-btn {
				font-size:18px; 
				width: 332px;
				height: 50px;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 22px;
				font-size: 18px;
			}
		}
	}
</style>
