import axios from 'axios'
import Cookies from 'js-cookie'
import md5 from 'js-md5'
import qs from 'qs'
import errorMsg from '@/utils/function/errorMsg'
import router from '@/router';


// 根据请求信息生成请求Key
const generateReqKey = (config) => {
  const { method, url, params, data } = config
  return [method, url, qs.stringify(params), qs.stringify(data)].join('&')
}

const pendingRequest = new Map()

// 把当前请求添加到pendingRequest对象
const addPendingRequest = (config) => {
  const requestKey = generateReqKey(config)
  config.cancelToken = config.cancelToken || new axios.CancelToken((cancel) => {
    if (!pendingRequest.has(requestKey)) {
      pendingRequest.set(requestKey, cancel)
    }
  })
}

// 检查是否存在重复请求，存在重复请求取消发送
const removePendingRequest = (config) => {
  const requestKey = generateReqKey(config)
  if (pendingRequest.has(requestKey)) {
    console.log('repeat request', config.url);
    const cancelToken = pendingRequest.get(requestKey)
    cancelToken(requestKey)
    pendingRequest.delete(requestKey)
  }
}

var Axios = axios.create({
  timeout: 10000,
  responseType: 'json'
})
Axios.interceptors.request.use(
  config => {
    if (config.data && config.data.body) {
      const stringBody = JSON.stringify(config.data.body)
      Object.assign(config.data, {
        id: md5(stringBody),
        sign: md5(stringBody + new Date().getTime()),
        timestamp: new Date().getTime()
      })
    }
    config.headers = {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: `Bearer ${Cookies.get('accessToken')}`
    }
    // removePendingRequest(config) // 检查是否存在重复请求，存在则取消请求
    // addPendingRequest(config) // 把当前请求信息添加到pendingRequest对象中
    return config
  },
  error => {
    return Promise.reject(error.data.error.message)
  }
)
// 返回状态判断(添加响应拦截器)
Axios.interceptors.response.use(
  res => {
    if (res.data && res.data.code == '-2') {
      Cookies.remove('userName')
      Cookies.remove('userId')
      Cookies.remove('enterpriseId')
      Cookies.remove('shopId')
      router.push('login')
      // location.href = `${location.protocol}//${location.host}/systemPlatform/login?returnUrl=${encodeURIComponent(
      //   location.href
      // )}`
      return {}
    }
    // removePendingRequest(res.config); // 从pendingRequest对象中移除请求
    return res
    // return Promise.reject(res.data.msg)

    // if (res.data && res.data.code == '-1') {
    //   return res
    // } else if (res.data && (res.data.code == '0' || res.data.status === 0)) {
    //   if (res.data.body) {
    //     return res.data.body
    //   }
    //   return res
    // } else if (res.status == 204) {
    //   return Promise.resolve('操作成功')
    // } else {
    //   return Promise.reject(res.data.msg)
    // }
  },
  error => {
    console.log('errorStatus', error.response)
    if (error.response && error.response.data && error.response.data.msg) {
      errorMsg(error.response.data.msg)
    }
    // removePendingRequest(error.config || {}); // 从pendingRequest对象中移除请求
    // if (axios.isCancel(error)) {
    //   console.log("已取消的重复请求：" + error.message);
    // } else {
    //   errorMsg(error.response.data.msg)
    // }
    // if (
    //   error.response &&
    //   error.response.status === 500 &&
    //   ['请重新登录', '您没有权限访问此系统'].includes(error.response.data.msg)
    // ) {
    // setTimeout(() => {
    //   location.href = `${location.protocol}//${location.host}/zhnjPlatform/login?returnUrl=${encodeURIComponent(
    //     location.href
    //   )}`
    // }, 500)
    // }
    return Promise.reject(error.response.data.msg)
  }
)

// 对axios的实例重新封装成一个plugin ,方便 Vue.use(xxxx)
// export default {
//   install: function(Vue, Option) {
//     Object.defineProperty(Vue.prototype, "$http", { value: Axios });
//   }
// };
// get请求
export const GET = url => {
  return body =>
    Axios.get(url, {
      params: body
    })
}
// post请求
export const POST = (
  url,
  config = {
    showLoading: true
  }
) => {
  return body => Axios.post(url, body, config)
}

export const PUT = (
  url,
  config = {
    showLoading: true
  }
) => {
  return body => Axios.put(url, body, config)
}

export const DELETE = url => {
  // console.log(url)
  return body =>
    Axios.delete(url, {
      params: body
    })
}

export default Axios
