<template>
  <div class="supplyDetail">
    <a-spin :spinning="loading">
		<a-breadcrumb separator=">">
			<a-breadcrumb-item>当前位置：<span style="cursor: pointer;" @click="goHall">{{ hallName }}</span></a-breadcrumb-item>
			<a-breadcrumb-item><a href="">{{ type === 's' ? supplyObj.varietyName : demandObj.demandVarieties }}</a></a-breadcrumb-item>
		</a-breadcrumb>
		<div class="supplyContainer">
			<div class="sc-left">
				<Purchase :type="type" :supplyObj="supplyObj" :demandObj="demandObj" />
				<div class="moduleTitle">
					<div class="vertical-line"></div>
					<span class="title" style="vertical-align: middle;margin-left: 4px;">{{ type === 's' ? '售卖详情' : '需求详情' }}</span>
				</div>
				<div style="margin-top: 20px;" v-html="type === 's' ? supplyObj.detail : demandObj.demandTail"></div>
				<div v-if="type === 's'" class="moduleTitle">
					<div class="vertical-line"></div>
					<span class="title" style="vertical-align: middle;margin-left: 4px;">订单评价</span>
				</div>
				<Evaluate v-if="type === 's'" :evaluateObj="evaluateObj" @getEvaluate="getEvaluate"/> 
				<div class="moduleTitle">
					<div class="vertical-line"></div>
					<span class="title" style="vertical-align: middle;margin-left: 4px;">交易必读</span>
				</div>
				<div style="background: #FAFAFA;color: #888888; padding: 20px;" v-html="platFormInfo"></div>
			</div>
			<div class="sc-right">
				<Publisher :data="{ 
					certificateStatus: type === 's' ? supplyObj.certificateStatus : demandObj.certificationType,
					publisher: type === 's' ? supplyObj.companyName : demandObj.releaseUserName,
					phone: type === 's' ? supplyObj.companyPhone : demandObj.contactTel,
					isCompany: type === 's' }"
				/>
				<Requirement :requires="requires" @getOtherRequire="refreshOther" style="margin: 15px 0;"/>
			</div>
		</div>
	</a-spin>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Purchase from '@/pages/SupplyDemand/detail/purchase.vue'
import Evaluate from '@/pages/SupplyDemand/detail/evaluate.vue'
import Publisher from '@/pages/SupplyDemand/detail/publisher.vue'
import Requirement from '@/pages/SupplyDemand/detail/requirement.vue'
// const Purchase = () => import('@/pages/SupplyDemand/detail/purchase.vue')
// const Evaluate = () => import('@/pages/SupplyDemand/detail/evaluate.vue')
// const Publisher = () => import('@/pages/SupplyDemand/detail/publisher.vue')
// const Requirement = () => import('@/pages/SupplyDemand/detail/requirement.vue')

export default {
name: "SDDetail",
components: {
	Purchase,
	Publisher,
	Requirement,
	Evaluate
},
data () {
return {
	loading: false,
	id: '',
	type: '',
	hallName: '', // 大厅名称
	platFormInfo: '',
	supplyObj: {
		auditStatus: "",
		categoryId: "",
		categoryName: "", // 品类名称
		collection: "", // 浏览量
		companyContacts: "",
		companyName: "",
		companyPhone: "",
		contacts: "",
		createdTime: "",
		creatorId: "",
		detail: "", // 售卖详情
		phone: "",
		picture: "",
		title: "",
		varietyId: "",
		visit: '',
		varietyName: "", // 品种名称
		id: "",
		isActive: 1,
		isFavorite: '', // 0：否 1：是
		modifiedTime: "",
		modifierId: "",
		originProvinceCode: "", // 产地省code
		originProvinceName: "", // 产地省name
		originCityCode: "", // 产地市code
		originCityName: "", // 产地市name
		originAreaCode: "", // 产地区code
		originAreaName: "", // 产地区name
		stockProvinceName: "", // 库存省name
		stockProvinceCode: "", // 库存省code
		stockCityCode: "", // 库存地市code
		stockCityName: "", // 库存城市name
		stockAreaCode: "", // 库存地市code
		stockAreaName: "", // 库存地市name
		certificateStatus: "", // 发布人认证状态
		price: ''
	},
	demandObj: {
		contactName: '', // 联系人
		contactTel: '', // 联系人电话
		createTime: '', // 创建时间
		demandCategory: '', //品类
		demandTail: "", //需求详情
		demandTitle: "", //主题
		count: '',
		time: '',
		demandVarieties: "", //品种
		id: "",
		isFavorites: "", //是否收藏 true, fales
		releaseTime: "", //发布时间
		releaseUserId: "", //发布人
		releaseUserName: "", //发布人名称
		shelvesStatus: "", //状态
		shippingAddress: "", //发布地址
		views: "", //访问量
		province: '',
		city: "", // 产地市name
		updateTime: '',
		certificationType: '', // 发布人认证状态
		price: ''
	},
	evaluateObj: {
		avg: 0, // 平均评分
		evaluateType: [
			{
				field: 'total',
				text: '全部',
				val: [1,2,3,4,5],
				count: 0
			},
			{
				field: 'good',
				text: '好评',
				val: [4,5],
				count: 0
			},
			{
				field: 'notBad',
				text: '中评',
				val: [3],
				count: 0
			},
			{
				field: 'bad',
				text: '差评',
				val: [1,2],
				count: 0
			}
		],
		evaluates: [] // 评价
	},
	requires: [] // 其他要求
}
},
props: {

},
watch: {

},
computed: {
    ...mapGetters(['userInfo']),
},
created () {
	const { id, type } =  this.$route.params
	this.id = id
	this.type = type
	this.hallName = this.type === 's' ? '供应大厅' : '需求大厅'
	this.getDetail()
	this.getEvaluateCount()
	this.getEvaluate()
	
	this.getPlatformInfo()
},
mounted () {

},
destroyed () {

},
methods: {
	// 请求其他要求
	refreshOther(id) {
		this.id = id
		this.getDetail()
		this.getEvaluateCount()
		this.getEvaluate()
		this.getOther()
		this.getPlatformInfo()
	},
	// 获取商品详情
	getDetail() {
		console.log('getDetail');
		const me = this.type === 's' ? 'getSupplyHallById' : 'queryDemandDetail'
		const param = this.type === 's' ? this.id : {
		  body:{
			params: {
			  id: this.id, //当前数据id
			  userId: this.userInfo.id //当前登录用户id
			}
		  }
		}
	    this.loading = true
		this.$axios[`${me}`](param).then(res => {
	    	this.loading = false
			console.log('detail', res);
			if (res.data.code === '0') {
				if (this.type === 's') { 
					this.supplyObj = Object.assign({}, this.supplyObj, res.data.body)
				} else {
					this.demandObj = Object.assign({}, this.demandObj, res.data.body)
				}
			}
			this.getOther()
 		})
	},
	// 评价数量查询
    getEvaluateCount() {
        this.$axios.queryEvaluateCount(this.id).then(res => {
            console.log('queryEvaluateCount', res);
			if (res.data.code === '0') {
                const result = { ...res.data.body }
				this.evaluateObj.avg = 0
                this.evaluateObj.evaluateType.forEach(obj => {
					obj.count = 0
				});
                Object.keys(result).forEach(key => {
                   if (key === 'avg') {
                    this.evaluateObj.avg = result.avg ? parseFloat(result.avg) : 0
                   } else {
                    const score = result[key] ? parseFloat(result[key]) : 0
                    this.evaluateObj.evaluateType.find(obj => obj.field === key).count = score
                   }
                })
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    // 获取订单评价
	getEvaluate(index = 1, sort = '-eavlueate' ) {
		const params = {
			body:{
				pageNum: 1,
				pageSize: 100,
				orders: sort,  // 时间排序传-createdTime  推荐排序传-eavlueate
				params: {
					evaluate: this.evaluateObj.evaluateType[index].val, // 差评传1，2   中评传3   好评传4，5
                    supplyId: this.id
				}
			}
		}
		this.$axios.getEvaluate(params).then(res => { 
            console.log('getEvaluate', res);
            if (res.data.code === '0') {
                this.evaluateObj.evaluates = [...res.data.body]
            } else {
                this.$message.error(res.data.msg)
            }
        })
	},
	// 获取其他要求
	getOther() {
      this.type === 's' ? this.supplyGetOther() : this.demandGetOther()
    },
	// 供应大厅获取其他要求
    supplyGetOther() {
        this.$axios.getOthers(this.id).then(res => {
            if (res.data.code === '0') {
                const result = [...res.data.body]
                this.requires = result.filter(o =>  o.id != this.id)
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
	// 需求大厅获取其他要求
    demandGetOther() {
      const params = {
        body: {
            pageNum: 1,
            pageSize: 10,
            orders:"+create_time",//按照需求时间升序     +release_time//按照发布时间升序  
            params: {
              shelvesStatus: 1, //1，上架   2，下架
              releaseUserId: this.demandObj.releaseUserId,        //当前登录用户id
            }
        }
      }
      this.$axios.demandGetOther(params).then(res => {
        if (res.data.code === '0') {
          console.log('getOthers', res);
          this.requires = [...res.data.body.dataList]
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
	getPlatformInfo() {
		const params = {
			body:{
				params:{
					model: this.type === 's' ? '供应' : '需求'
				}
			}
		}
		this.$axios.platformInfo(params).then(res => {
			console.log('platformInfo', res);
			if (res.data.code === '0') {
				this.platFormInfo = res.data.body.info
			} else {
				this.$message.error(res.data.msg)
			}
		})
	},
	// 返回供求大厅
	goHall() {
		const path = this.type === 's' ? '/npyx/index?key=1' : '/npyx/index?key=2'
        this.$router.push(path)
	}
}
}
</script>

<style scoped lang="less">
p {
	font-size: 11px;
	font-weight: 400;
	color: #333333;
	line-height: 15px;
}
.supplyDetail {
	position: relative;
	width: 1190px;
	height: 100%;
	margin: 0 auto;
	font-size: 11px;
	font-weight: 400;
	color: #888888;
	line-height: 15px;

	.supplyContainer {
		display: flex;
		.sc-left {
			width: 66.3%;
			.moduleTitle {
				margin-top: 60px;
				font-size: 18px;
				font-weight: 600;
				color: #333333;
				line-height: 24px;
				.vertical-line {
					width: 3px;
					height: 17px;
					line-height: 24px;
					background: @primary-color;
					display: inline-block;
					vertical-align: middle;
				}
			}
			
			.order-evaluate {

			}
		}
		.sc-right {
			display: flex;
			flex: 1;
			flex-direction: column;
		    margin-left: 24px;
		}
	}
}
</style>
