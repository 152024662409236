import { POST, ApiUrl } from '@/config/index'

// 农机共享列表
const queryModelShareList = POST(`${ApiUrl}/api/argimach/wechat/modelShares/action/queryList`)

// 农机预约
const machineryAppointment = POST(`${ApiUrl}/api/argimach/wechat/monitoring/action/save`)

export default {
    queryModelShareList,
    machineryAppointment
}