<template>
  <div>
    <a-upload
      :class="{ 'upload-hide-del': isView }"
      :action="uploadUrl"
      :file-list="fileList"
      :data="uploadData"
      list-type="picture-card"
      :before-upload="beforeUpload"
      @preview="handlePreview"
      @change="handleChange"
    >
      <div v-if="fileList.length < max && !isView">
        <a-icon type="plus" />
        <div>
          上传图片
        </div>
      </div>
    </a-upload>
    <a-modal
      :visible="previewVisible"
      centered
      :width="`${previewImgConfig.modalWidth}px`"
      :footer="null"
      @cancel="handleCancel"
    >
      <img
        alt="example"
        :style="{ width: previewImgConfig.imgWidth + 'px', height: previewImgConfig.imgHeight + 'px' }"
        :src="previewImage"
      >
    </a-modal>
  </div>
</template>
<script>
import { ossImagePath } from '@/config/index'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
import emitter from '@/mixins/emitter'
export default {
  name: 'Upload',
  mixins: [emitter],
  props: {
    bathPath: {
      type: String
    },
    size: {
      type: [Number, String],
      default: 5
    },
    max: {
      type: [Number, String],
      default: 5
    },
    imgUrls: {
      type: String
    },
    isView: {
      type: Boolean,
      default: false
    },
    previewImgConfig: {
      type: Object,
      default() {
        return {
          modalWidth: 798,
          imgWidth: 750,
          imgHeight: 370
        }
      }
    }
  },
  data() {
    return {
      previewImage: '',
      previewVisible: false,
      uploadUrl: '',
      uploadData: {
        file_name: '',
        dir_path: ''
      },
      fileList: []
    }
  },
  watch: {
    imgUrls(val) {
      this.fileList = val.split(',').map((url, index) => {
        return {
          uid: `${index + 1}`,
          status: 'done',
          name: 'image.png',
          url: this.$utils.ossPath.ossImg(url, this.previewImgConfig.imgWidth, this.previewImgConfig.imgHeight),
          oldUrl: url
        }
      })
    }
  },
  mounted() {
    if (this.imgUrls) {
      this.fileList = this.imgUrls.split(',').map((url, index) => {
        return {
          uid: `${index + 1}`,
          status: 'done',
          name: 'image.png',
          url: this.$utils.ossPath.ossImg(url, this.previewImgConfig.imgWidth, this.previewImgConfig.imgHeight),
          oldUrl: url
        }
      })
    }
  },
  methods: {
    uploadSuccess(file) {
      this.$emit('uploadSuccess', file.response.body.src_url)
    },
    removedUploaded(file) {
      let imgUrl = ''
      if (file.response) {
        imgUrl = file.response.body.src_url
      } else {
        imgUrl = file.oldUrl
      }
      this.$emit('removedUploaded', imgUrl)
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange({ file, fileList }) {
      if (file.status == 'done' && file.response) {
        if (file.response.code == '0') {
          // 上传成功
          this.uploadSuccess(file)
          this.$message.success('上传成功')
        } else if (file.response && file.response.code != '0') {
          // 上传失败
          fileList.map((item, index) => {
            if (item.response.code != '0') {
              fileList.splice(index, 1)
            }
          })
          this.$message.error('上传失败')
        }
      }
      if (file.status == 'error') {
        // 上传失败
        fileList.map((item, index) => {
          if (item.status == 'error') {
            fileList.splice(index, 1)
          }
        })
        this.$message.error('上传失败')
      }
      if (file.status == 'removed') {
        // 移除图片
        this.removedUploaded(file)
      }
      this.fileList = fileList
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < this.size
      const isImage = file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg'
      if (!isImage) {
        this.$message.error('只能上传jpg和png格式的文件')
        return Promise.reject()
      }
      if (!isLt2M) {
        this.$message.error(`上传图片大小不能超过${this.size}MB!`)
        return Promise.reject()
      }
      this.uploadData.file_name = file.lastModified + '.jpg'
      this.uploadData.dir_path = this.bathPath ? ossImagePath[this.bathPath] : ossImagePath.basic
    }
  }
}
</script>
<style lang="less" scoped>
.upload-hide-del {
  ::v-deep .anticon-delete {
    display: none;
  }
}
</style>
