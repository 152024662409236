<template>
  <div class="messageBoard">
    <div class="box_shadow_wrap">
      <a-table
        rowKey="id"
        :columns="tableConfig.columns"
        :data-source="tableConfig.data"
        :pagination="tableConfig.pagination"
        :loading="tableConfig.loading"
        :scroll="{ y: 550 }"
        @change="handleTableChange"
      >
        <template slot="num" slot-scope="text, record, index">
          {{
            (tableConfig.pagination.current - 1) *
              tableConfig.pagination.pageSize +
            parseInt(index) +
            1
          }}
        </template>
        <template slot="status" slot-scope="text">
          <a :style="{ color: text === '0' ? '#F20000' : '' }">{{
            states[`${text}`]
          }}</a>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MessageBoard",
  components: {},
  data() {
    return {
      tableConfig: {
        columns: [
          {
            title: "序号",
            dataIndex: "num",
            key: "num",
            width: 60,
            align: "center",
            scopedSlots: { customRender: "num" },
          },
          {
            dataIndex: "createdTime",
            key: "createdTime",
            title: "留言时间",
            ellipsis: true,
            width: 120,
          },
          {
            dataIndex: "content",
            key: "content",
            title: "留言内容",
            ellipsis: true,
            width: 120,
          },
          {
            dataIndex: "status",
            key: "status",
            title: "状态",
            ellipsis: true,
            scopedSlots: { customRender: "status" },
            width: 120,
          },
          {
            dataIndex: "reply",
            key: "reply",
            title: "平台回复",
            ellipsis: true,
            width: 120,
          },
        ],
        data: [],
        pagination: {
          current: 1,
          defaultCurrent: 1,
          pageSize: 10,
          pageSizeOptions: ["10", "20", "30", "40", "50"],
          defaultPageSize: 10,
          total: 0,
          showTotal: (total) => `共有${total}条`,
          showQuickJumper: true,
          showSizeChanger: true,
          onChange: (current) => {
            this.tableConfig.pagination.current = current;
            this.search();
          },
          onShowSizeChange: (current, pageSize) => {
            this.tableConfig.pagination.current = current;
            this.tableConfig.pagination.pageSize = pageSize;
            this.search();
          },
        },
        loading: false, // table loading
      },
      states: {
        0: "未读",
        1: "已读",
        2: "已回复",
      },
    };
  },
  props: {},
  watch: {},
  methods: {
    search() {
      const { current, pageSize } = this.tableConfig.pagination;
      const param = {
        body: {
          pageNum: current,
          pageSize,
          params: {
            creatorId: this.userInfo.id,
          },
        },
      };
      this.tableConfig.loading = true;
      this.$axios.messageQueryByPage(param).then((res) => {
        console.log("messageQueryByPage", res);
        this.tableConfig.loading = false;
        this.tableConfig.data = [];
        this.tableConfig.pagination.total = 0;
        if (res.data.code == "0") {
          if (res.data.body.dataList) {
            res.data.body.dataList.forEach((obj, index) => {
              this.tableConfig.data[index] = { ...obj };
            });
            this.tableConfig.pagination.total = res.data.body.totalCount
              ? parseFloat(res.data.body.totalCount)
              : 0;
          }
        }
      });
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination, filters, sorter);
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    this.search();
  },
  mounted() {},
  destroyed() {},
};
</script>

<style scoped lang="less">
@import "../../styles/pages/personalcenter.less";
.messageBoard {
  display: flex;
  flex-direction: column;
  padding-top: 2px;
  min-height: 100%;
  .box_shadow_wrap {
    flex: auto;
  }
}
</style>
