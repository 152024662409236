import MachineShare from '@/pages/MachineShare/index.vue'
import Layout from '@/layouts/BasicLayout/index.vue'

export default [
    {
        path: '/machineShare',
        name: 'machineShare',
        component: Layout,
        meta: {
          title: '农机共享',
        },
        children: [
          {
            path: '/',
            component: MachineShare,
            name: 'machineShare1',
            meta: {
              basePath: 'machineShare',
              title: '农机共享'
            }
          }
        ]
    },
]