import { GET, POST, PUT, DELETE, ApiUrl } from '@/config/index'
// 查询单个申请记录
const getApplyRecord = id => {
  return GET(`${ApiUrl}/api/backapp/finance/applyRecord/${id}`)()
}
// 删除申请记录
const carouselDelete = id => {
  return DELETE(`${ApiUrl}/api/backapp/finance/applyRecord/${id}`)
}
// 申请修改
const applyRecordModify = PUT(`${ApiUrl}/api/backapp/finance/applyRecord/`)
// 新增申请记录
const applyRecordAdd = POST(`${ApiUrl}/api/backapp/finance/applyRecord`)
// 分页查询申请记录
const applyRecordQueryByPage = POST(`${ApiUrl}/api/backapp/finance/applyRecord/action/queryByPage`)
// 获取行政区
const GetAreaList = POST(`${ApiUrl}/api/backapp/system/regionCity/action/queryList`)
// 新增申请记录
const addApplyRecord = POST(`${ApiUrl}/api/backapp/financeEnterprise/applyRecord/`)

// 门户首页金融轮播图
const queryFinanceCarouselList = POST(`${ApiUrl}/api/backapp/finance/carousel/action/queryCarouselList`)
// 门户首页金融模块数据展示
const queryFinanceListHome = POST(`${ApiUrl}/api/backapp/finance/product/action/queryListHome`)

// 产品详情
const productDetail = id => {
  return GET(`${ApiUrl}/api/backapp/finance/product/${id}`)()
}


export default {
  getApplyRecord,
  carouselDelete,
  applyRecordModify,
  applyRecordAdd,
  applyRecordQueryByPage,
  GetAreaList,
  addApplyRecord,
  queryFinanceListHome,
  queryFinanceCarouselList,
  productDetail
}
