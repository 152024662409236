<template>
  <div class="hall">
   <div class="hall-header">
        <div class="hall-name">{{ name }}</div>
        <div class="white-point"></div>
        <div class="white-btn" @click="goMore">查看更多</div>
   </div>
   <div class="hall-container">
       <div class="ag-share-table-head">
            <template v-for="(col, idx) in columns">
              <div class="ag-share-table-col-head" :key="idx" :style="{ width: col.width }">
                {{ col.title }}
              </div>
            </template>
          </div>
          <div id="ag-share-table" class="ag-share-table-body" @click="goDetail">
            <template v-for="(col, idx) in columns">
              <div class="ag-share-table-col-body" :key="idx" :style="{ width: col.width }">
                <template v-for="(row, index) in demands">
                  <div :key="index" :style="{ color: hoverIndex === index ? '#20BF8E' : '' }" :title="row[col.dataIndex]" @mouseenter="onMouseover(index)" @mouseleave="onMouseout">
                    {{ row[col.dataIndex] || '——' }}
                  </div>
                </template>
              </div>
            </template>
          </div>
   </div>
  </div>
</template>
<script>
const columns = [
    {
      dataIndex: 'demandCategory',
      title: '品类',
      width: '12%'
    },
    {
      dataIndex: 'demandVarieties',
      title: '品种',
      width: '12%'

    },
    {
      dataIndex: 'demandTitle',
      title: '标题',
      width: '12%'
    },
    {
      dataIndex: 'shippingAddress',
      title: '收货地',
      width: '13%'

    },
    {
      dataIndex: 'hopeShippingAddress',
      title: '期望货源',
      width: '13%'
    },
    {
      dataIndex: 'contactName',
      title: '联系人',
      width: '12%'
    },
    {
      dataIndex: 'releaseTime',
      title: '需求时间',
      width: '13%'
    },
    {
      dataIndex: 'releaseTime',
      title: '发布时间',
      width: '13%'
    }
  ]
export default {
name: "DemandHall",
components: {
},
data () {
return {
    hoverIndex: 0,
    name: '需求大厅',
    columns: Object.freeze(columns),
  data: []
}
},
props: {
  demands: {
    type: Array,
    default: () => []
  }
},
watch: {

},
methods: {
    goMore() {
        this.$router.push('/npyx/index?key=2')
    },
    onMouseover(index) {
      this.hoverIndex = index
    },
    onMouseout() {
      this.hoverIndex = ''
    },
    goDetail() {
      const id = this.demands[this.hoverIndex].id
      this.$router.push(`/npyx/sdDetail/d/${id}`)
    }
},
computed: {
    
},
created () {

},
mounted () {

},
destroyed () {

},
}
</script>

<style scoped lang="less">
    .hall {
        .hall-header {
            background-size: 100% 100%; /* 看情况，有 cover 和 contain 模拟小程序里边的 image 的 mode 属性 */
            background-repeat: no-repeat; /* 不让重复 */
            background-position: center center ; /* 固定展示的位置 */
            background-image: url('../../assets/img/demand-back.png');
            height: 100px;
             display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .hall-name {
              font-size: 24px;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 33px;
            }
            .white-point {
              height: 10px;
              width: 10px;
              margin-left: 10px;
              background: #FFFFFF;
              border-radius: 50%;
            }
            .white-btn {
              width: 101px;
              height: 32px;
              margin-left: 10px;
              opacity: 0.8;
              background: #FFFFFF;
              border-radius: 16px;
              font-size: 14px;
              font-weight: 600;
              color: #20BF8E;
              line-height: 32px;
              text-align: center;
              cursor: pointer;
            }
            .white-btn:hover {
              opacity: 1;
            }
        }
        .hall-container {
            margin-top: 40px;
            height: 100%;
            display: flex;
            flex-direction: column;
            .ag-share-table-head {
                display: flex;
                flex-direction: row;
                background: #F5F5F5;
            }
            .ag-share-table-body {
                display: flex;
                flex-direction: row;
                overflow: hidden;
                border-right: 1px solid #ebebeb;
            }
                
            .ag-share-table-col-head {
                font-size: 14px;
                color: #333333;
                line-height: 22px;
                font-weight: 600;
                padding: 17px 0 17px 20px;
                border: 1px solid #FFFFFF;
            }
            .ag-share-table-col-body {
                div {
                cursor: pointer;
                font-size: 14px;
                color: #333333;
                line-height: 14px;
                font-weight: 400;
                padding: 21px 0 21px 20px;
                text-align: left;
                text-overflow: ellipsis;
                overflow-wrap: break-word;
                word-break: break-all;
                white-space: nowrap;
                overflow: hidden;
                border-bottom: 1px solid #EBEBEB;
                }
            }
        }
    }
    .hall::before, .hall::after {
        display: block;
        content: '';
        clear: both;
    }
</style>
