import { POST, GET, ApiUrl } from '@/config/index'

// 新闻分类分页查询
const newsQueryByPage = POST(`${ApiUrl}/api/backapp/enterprise/portal/newsType/action/queryByPage`)

// 新闻分类下拉
const newsSelectOptions = POST(`${ApiUrl}/api/backapp/enterprise/portal/newsType/action/selectOptions`)

//新闻分页查询
const newsManageQueryByPage = POST(`${ApiUrl}/api/backapp/enterprise/portal/newsManage/action/queryByPage`)

// 新闻详情
const newGetById = (id,isStatic)  => {
  return GET(`${ApiUrl}/api/backapp/enterprise/portal/newsManage/action/getById?id=${id}&isStatic=${isStatic}`)()
}

export default {
    newsQueryByPage,
    newsSelectOptions,
    newsManageQueryByPage,
    newGetById
}