<template>
  <div>
    <iframe width="100%" style="height: 100vh;padding-top: 50px;" :src="ApiUrl + '/pdfjs/web/viewer.html?file=' + modalTraceData.imgUrl" />
  </div>
</template>
<script>
import { ApiUrl } from '@/config/index'
export default {
  props: {
    modalTraceData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      ApiUrl: ApiUrl
    }
  }
}
</script>
<style lang="less" scoped>
.btn {
  text-align: center;
  padding-top: 30px;
  .ant-btn + .ant-btn {
    margin-left: 10px;
  }
}
.pages {
  margin-top: 10px;
  color: #20BF8E;
  text-align: center;
}
</style>
