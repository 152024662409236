<template>
  <div class="requirement">
    <a-card style="width: 100%;">
        <div class="card-title">TA发布的其他{{ msg }}</div>
        <template v-for="(obj, idx) in requires">
          <div :key="idx" style="display: flex;flex-direction: row;flex-wrap: wrap;align-items: center;margin-bottom: 17px;cursor: pointer;" @click="getOther(obj.id)">
            <div style="margin-right: 4px; line-height: 20px;text-align: center;border-radius: 3px;padding: 2px 9px; color: #20BF8E;border: 1px solid #20BF8E;">
              {{ obj.categoryName || obj.demandCategory }}
            </div>
            <div style="margin-right: 4px; line-height: 20px;text-align: center;border-radius: 3px;padding: 2px 9px; color: #20BF8E;border: 1px solid #20BF8E;">
              {{ obj.varietyName || obj.demandVarieties }}
            </div>
            <div style="flex: 1;text-overflow: ellipsis;overflow-wrap: break-word;word-break: break-all;white-space: nowrap;overflow: hidden;">{{ obj.title || obj.demandTitle }}</div>
          </div>
        </template>
    </a-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
name: "requirement",
components: {
},
data () {
return {
  msg: ''
}
},
props: {
  requires: {
    type: Array,
    default: () => []
  }
},
watch: {

},
computed: {
    ...mapGetters(['userInfo']),
},
created () {
  this.msg = this.$route.params.type === 's' ? '供应' : '需求'
},
mounted () {

},
destroyed () {

},
methods: {
    getOther(id) {
      this.$emit('getOtherRequire', id)
    }
  }
}
</script>

<style scoped lang="less">
    .requirement {
      font-size: 11px;
      font-weight: 400;
      color: #333333;
      line-height: 15px;
        .card-title {
          font-size: 15px;
          font-weight: 600;
          color: #333333;
          line-height: 28px;
          margin-bottom: 21px;
        }
        div {
          display: inline-block;
        }

         /deep/ .ant-card-body {
          padding: 20px 25px 35px 25px;
          font-size: 11px;
          font-weight: 400;
          color: #333333;
          line-height: 15px;
        }
    }
</style>
