import PersonalCenter from '@/pages/PersonalCenter/index.vue';
import PersonalHome from '@/pages/PersonalCenter/Default/index.vue';
import Authentication from '@/pages/PersonalCenter/Default/authentication.vue';
import EnterpriseCertification from '@/pages/PersonalCenter/Default/enterpriseCertification.vue';
import PersonalCertification from '@/pages/PersonalCenter/Default/personalCertification.vue';
import MessageBoard from '@/pages/PersonalCenter/messageBoard.vue';
import MySupply from '@/pages/PersonalCenter/mySupply/index.vue';
import MySupplyEdit from '@/pages/PersonalCenter/mySupply/edit.vue';
import MyService from '@/pages/PersonalCenter/myService/index.vue';
import SupplyCollection from '@/pages/PersonalCenter/supplyCollection/index.vue';
import DemandCollection from '@/pages/PersonalCenter/demandCollection/index.vue';
import MyNeeds from '@/pages/PersonalCenter/myNeeds/index.vue';
import MyNeedsEdit from '@/pages/PersonalCenter/myNeeds/edit.vue';
import DemandOrder from '@/pages/PersonalCenter/demandOrder/index.vue';
import ServiceOrder from '@/pages/PersonalCenter/serviceOrder/index.vue';
import Information from '@/pages/Login/information.vue';
import MyReservation from '@/pages/PersonalCenter/MachineryAppointment/MyReservation.vue';

export default [
    {
        path: '/personalCenter',
        name: 'personalCenter',
        component: PersonalCenter,
        redirect: '/personalCenter/index',
        meta: {
            title: '个人中心',
        },
        children: [
            {
                path: 'index',
                component: PersonalHome,
                name: 'index',
                meta: {
                 basePath: '',
                 title: '首页'
                }
            },
            {
                path: 'authentication',
                component: Authentication,
                name: 'authentication',
                meta: {
                 basePath: '',
                 title: '认证的中转页面'
                }
            },
            {
                path: 'enterpriseCertification',
                component: EnterpriseCertification,
                name: 'enterpriseCertification',
                meta: {
                 basePath: '',
                 title: '企业认证'
                }
            },
            {
                path: 'personalCertification',
                component: PersonalCertification,
                name: 'personalCertification',
                meta: {
                 basePath: '',
                 title: '个人认证'
                }
            },
            {
                path: 'messageBoard',
                component: MessageBoard,
                name: 'messageBoard',
                meta: {
                 basePath: '',
                 title: '留言板'
                },
            },
            {
                path: 'mySupply',
                component: MySupply,
                name: 'mySupply',
                meta: {
                 basePath: '',
                 title: '我的供应'
                },
            },
            {
                path: 'mySupplyEdit/:type/:id',
                component: MySupplyEdit,
                name: 'mySupplyEdit',
                meta: {
                 basePath: '',
                 title: '我的供应编辑',
                 parentPath: '/personalCenter/mySupply'
                },
            },
            {
                path: 'myService',
                component: MyService,
                name: 'myService',
                meta: {
                 basePath: '',
                 title: '我的服务情况'
                },
            },
            {
                path: 'supplyCollection',
                component: SupplyCollection,
                name: 'supplyCollection',
                meta: {
                 basePath: '',
                 title: '供应收藏'
                },
            },
            {
                path: 'demandCollection',
                component: DemandCollection,
                name: 'demandCollection',
                meta: {
                 basePath: '',
                 title: '需求收藏'
                },
            },
            {
                path: 'myNeeds',
                component: MyNeeds,
                name: 'myNeeds',
                meta: {
                 basePath: '',
                 title: '我的需求'
                },
            },
            {
                path: 'myNeedsEdit/:path/:id',
                component: MyNeedsEdit,
                name: 'myNeedsEdit',
                props: true,
                meta: {
                 basePath: '',
                 title: '我的需求（编辑）',
                 parentPath: '/personalCenter/myNeeds'
                },
            },
            {
                path: 'demandOrder',
                component: DemandOrder,
                name: 'demandOrder',
                meta: {
                 basePath: '',
                 title: '需求订单'
                },
            },
            {
                path: 'serviceOrder',
                component: ServiceOrder,
                name: 'serviceOrder',
                meta: {
                 basePath: '',
                 title: '服务订单'
                },
            },
            {
                path: 'information',
                component: Information,
                name: 'information',
                meta: {
                 basePath: '',
                 title: '消息'
                },
            },
            {
                path: 'myReservation',
                component: MyReservation,
                name: 'MyReservation',
                meta: {
                 basePath: '',
                 title: '我的预约'
                },
            }
        ] 
    },
    {
        path: '/myNjkt/*',
        name: 'personalCenter',
        component: PersonalCenter,
        meta: {
            title: '我关注的专家',
        },
    }
]