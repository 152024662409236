<template>
  <div>
    <div class="search-wrapper">
      <a-form
        layout="inline"
        labelAlign="left"
        :labelCol="{ span: 4 }"
        :wrapperCol="{ span: 18 }"
      >
        <a-row>
          <a-col :span="12">
            <a-form-item label="企业名称：">
              <div class="form-item">
                <a-input
                  v-model="searchName"
                  size="large"
                  allow-clear
                  placeholder="请输入企业名称"
                />
                <a-button type="primary" size="large" @click="searchMerchant">查询</a-button>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table">
      <a-table
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        :pagination="pagination"
        size="middle"
        row-key="id"
      > 
        <template slot="traceStatus" slot-scope="scope">
          <a-tag v-if="scope.traceStatus === -1" color="red">上链失败</a-tag>
          <a-tag v-else-if="scope.traceStatus === 1" color="cyan">已上链</a-tag>
          <a-tag v-else-if="scope.traceStatus === 2" color="blue">上链中</a-tag>
          <a-tag v-else color="orange">未上链</a-tag>
        </template>
        <template slot="action" slot-scope="scope">
          <div class="table-operate-btn">
            <a-button type="link" style="color: #20BF8E" @click="handelEdit(scope.id)">企业详情</a-button>
            <a-button type="link" style="color: #1869FF" @click="handelSuYuan(scope.id)">溯源</a-button>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      columns: [
        {
          title: "企业名称",
          dataIndex: "name",
          align: 'left',
          ellipsis: true,
        },
        {
          title: "企业代码",
          align: 'left',
          dataIndex: "enterpriseCode",
        },
        {
          title: "上链状态",
          align: 'left',
          width: 120,
          scopedSlots: { customRender: "traceStatus" },
        },
        {
          title: "上链时间",
          align: 'left',
          width: 200,
          dataIndex: "traceTime",
        },
        {
          title: "操作",
          width: 200,
          fixed: "right",
          align: 'center',
          scopedSlots: { customRender: "action" }
        },
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "20", "30", "50"], // 这里注意只能是字符串，不能是数字
        showTotal: (total) => `共有 ${total}条`,
        onChange: (current) => {
          this.pagination.current = current;
          this.getMerchantList()
        },
        onShowSizeChange: (current, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getMerchantList()
        },
      },
      loading: false,
      tableData: [],
      searchName: ''
    };
  },
  mounted() {
    this.getMerchantList()
  },
  methods: {
    searchMerchant() {
      this.pagination.current = 1;
      this.getMerchantList()
    },
    handelEdit(id) {
      this.$router.push({
        path: `/blockchain/merchantDetail/${id}`
      })
    },
    handelSuYuan(id) {
      this.$router.push({
        path: `/blockchain/merchantSuYuan/${id}`
      })
    },
    async getMerchantList() {
      const params = {
        body: {
          pageNum: this.pagination.current,
          pageSize: this.pagination.pageSize,
          params: {
            name: this.searchName ? `*${this.searchName}*` : '',
            businessType: 1
          }
        }
      }
      this.loading = true
      this.$axios.merchantList(params).then(res => {
        this.loading = false
        const { dataList, totalCount } = res.data.body
        this.tableData = []
        this.pagination.total = 0
        if (res.data.code === '0') {
            this.tableData = [...dataList]
            this.pagination.total = totalCount ? parseFloat(totalCount) : 0
          }
        })
    }
  }
};
</script>
<style lang="less" scoped>
.search-wrapper {
  margin-bottom: 30px;
  /deep/ .ant-form-item {
    width: 100%;
  }
  .form-item {
    display: flex;
    flex-wrap: nowrap;
    button {
      margin-left: 16px;
    }
  }
}
.table {
  /deep/ .ant-table {
    .ant-table-column-title {
      font-weight: 500;
    }
    .table-operate-btn {
      // display: flex;
      // justify-content: center;
    }
  }
}
</style>