<template>
  <div class="default_container">
    <a-row>
      <a-col :span="12">
        <div class="box_shadow_wrap statistics_left">
          <div class="global_title">我的供应</div>
          <a-row class="statistics">
            <a-col :span="8" v-for="(item, index) in supplyStatistics" :key="index">
              <div class="statistics_value">{{ item.value }}</div>
              <div class="statistics_name">{{ item.name }}</div>
            </a-col>
          </a-row>
          <div class="echants_main" style="width: 100%; height: 300px"></div>
        </div>
      </a-col>
      <a-col :span="12">
        <div class="box_shadow_wrap statistics_right">
          <div class="global_title">我的需求</div>
          <a-row class="statistics">
            <a-col :span="8" v-for="(item, index) in demandStatistics" :key="index">
              <div class="statistics_value">{{ item.value }}</div>
              <div class="statistics_name">{{ item.name }}</div>
            </a-col>
          </a-row>
          <div class="echants_main" style="width: 100%; height: 300px"></div>
        </div>
      </a-col>
    </a-row>
    <a-row class="margin_top">
      <a-col :span="12">
        <div class="box_shadow_wrap statistics_left">
          <div class="global_title">我的服务订单</div>
          <ul class="message_list clearfix">
            <li v-for="(item, index) in sercicOrderMessage" :key="index" @click="goLinks(item.url)">
              <img :src="item.iconImg" alt="">  
              <div style="margin: 5px 0;">{{item.messageName}}</div>
              <div class="btn">{{item.num}}{{item.msg}}</div>
            </li>
          </ul>
        </div>
      </a-col>
      <a-col :span="12">
        <div class="box_shadow_wrap statistics_right">
          <div class="global_title">我的需求订单</div>
          <ul class="message_list clearfix">
            <li v-for="(item, index) in demandOrderMessage" :key="index" @click="goLinks(item.url)">
              <img :src="item.iconImg" alt="">  
              <div style="margin: 5px 0;">{{item.messageName}}</div>
              <div class="btn">{{item.num}}{{item.msg}}</div>
            </li>
          </ul>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import * as echarts from "echarts";
let myChart = []
export default {
  name: "Default",
  data() {
    return {
      supplyStatistics: [
        {
          name: '访问量',
          value: 0 
        },
        {
          name: '我的供应',
          value: 0
        },
        {
          name: '新增收藏',
          value: 0
        }
      ],
      demandStatistics: [
        {
          name: '访问量',
          value: 0 
        },
        {
          name: '我的需求',
          value: 0
        },
        {
          name: '新增收藏',
          value: 0
        }
      ],
      supplyData: {},
      demandData: {},
      sercicOrderMessage: [
        {
          iconImg: require("@/assets/img/msgData.png"),
          messageName: "执行中",
          num: 0,
          msg: '条新订单',
          url: '/personalCenter/serviceOrder?status=1'
        },
        {
          iconImg: require("@/assets/img/msgFinish.png"),
          messageName: "已完成",
          num: 0,
          msg: '条新评价',
          url: '/personalCenter/serviceOrder?status=2'
        },
      ],
      demandOrderMessage: [
        {
          iconImg: require("@/assets/img/msgData.png"),
          messageName: "执行中",
          num: 0,
          msg: '条订单进度更新',
          url: '/personalCenter/demandOrder?status=1'
        },
        {
          iconImg: require("@/assets/img/appraise.png"),
          messageName: "待评价",
          num: 0,
          msg: '条待评价',
          url: '/personalCenter/demandOrder?status=2'
        },
        {
          iconImg: require("@/assets/img/msgFinish.png"),
          messageName: "已完成",
          num: 0,
          msg: '条新评价',
          url: '/personalCenter/demandOrder'
        }
      ],
    };
  },
  async created() {
    await this.getMySupply()
    await this.getMyDemand()
    this.initEchants()
    this.getMySupplyOrder()
    this.getMyDemandOrder()
  },
  destroyed() {
    myChart = []
  },
  mounted() {
    let chartDom = document.querySelectorAll(".echants_main");
    chartDom.forEach(element => {
      const initChart = echarts.init(element);
      initChart.showLoading();
      myChart.push(initChart)
    })
  },
  methods: {
    // 初始化echants
    initEchants() {
      myChart.forEach((item, index) => {
        const data = index == 1 ? this.demandData.data : this.supplyData.data
        const date = index == 1 ? this.demandData.date : this.supplyData.date
        
        let option = {
          xAxis: {
            type: "category",
            data: date,
            axisLine: {
              show: false,
            },
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              show: true,
              formatter: function (value) {
                return value.split('-').slice(1).join('-')
              }
            }
          },
          yAxis: {
            type: "value",
          },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            top: 24,
            left: 24,
            right: 24,
            bottom: 24,
            containLabel: true,
          },
          series: [
            {
              name: "日访问量",
              data: data,
              type: "line",
              smooth: true,
              areaStyle: {
                color: "#20BF8E",
                opacity: 0.3,
              },
              itemStyle: {
                color: "#20BF8E",
              },
              lineStyle: {
                color: "#20BF8E",
              },
            },
          ],
        };
        item.hideLoading();
        item.setOption(option);
      })
    },
    // 获取我的供应数据
    async getMySupply() {
      const { data: { code, msg, body } } = await this.$axios.getMySupplyData({})
      if (code != 0) return this.$message.error(msg)
      this.supplyData = body
      this.supplyStatistics.forEach((item, index) => {
        if (index == 0) this.$set(item, 'value', body.visitNum)
        if (index == 1) this.$set(item, 'value', body.supplyNum)
        if (index == 2) this.$set(item, 'value', body.newCollection)
      })
    },
    // 我的需求
    async getMyDemand() {
      const { data: { code, msg, body } } = await this.$axios.getMyDemandData({})
      if (code != 0) return this.$message.error(msg)
      this.demandData = body
      this.demandStatistics.forEach((item, index) => {
        if (index == 0) this.$set(item, 'value', body.visitNum)
        if (index == 1) this.$set(item, 'value', body.demandNum)
        if (index == 2) this.$set(item, 'value', body.newCollection)
      })
    },
    // 获取我的服务订单
    async getMySupplyOrder() {
      const { data: { code, msg, body } } = await this.$axios.getMySupplyOrder({})
      if (code != 0) return this.$message.error(msg)
      if (!body) return
      this.sercicOrderMessage.forEach((item, index) => {
        if (index == 0) this.$set(item, 'num', body.supplyProcessingNum)
        if (index == 1) this.$set(item, 'num', body.supplyFinishedNum)
      })
    },
    // 获取我的需求订单
    async getMyDemandOrder() {
      const { data: { code, msg, body } } = await this.$axios.getMyDemandOrder({})
      if (code != 0) return this.$message.error(msg)
      if (!body) return
      this.demandOrderMessage.forEach((item, index) => {
        if (index == 0) this.$set(item, 'num', body.demandProcessingNum)
        if (index == 1) this.$set(item, 'num', body.demandWaitEvaluateNum)
        if (index == 2) this.$set(item, 'num', body.demandFinishedNum)
      })
    },
    // 跳转
    goLinks(url) {
      this.$router.push(url)
    }
  },
};
</script>
<style lang="less" scoped>
@import "../../../styles/pages/personalcenter.less";
.default_container {
  padding-top: 2px;
  .statistics {
    margin: 24px 0;
    text-align: center;
    .statistics_value {
      font-size: 22px;
      font-weight: bold;
    }
    .statistics_name {
      font-size: 12px;
      color: #999;
    }
  }
  .statistics_left {
    margin-right: 12px;
  }
  .statistics_right {
    margin-left: 12px;
  }
  .message_list {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 16px);
    margin: 16px 0 0;
    li {
      float: left;
      list-style: none;
      text-align: center;
      border-radius: 4px;
      margin-top: 16px;
      padding: 12px;
      margin-right: 16px;
      &:hover {
        cursor: pointer;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
      }
      .btn {
        height: 30px;
        line-height: 28px;
        padding: 0 12px;
        border-radius: 25px;
        color: #d92e2e;
        background: rgba(217, 46, 46, 0.1);
        border: 1px solid #D92E2E;
      }
    }
  }
  .margin_top {
    margin-top: 24px;
  }
}
</style>