import Home from '@/pages/Home'
import Layout from '@/layouts/BasicLayout/index.vue'

export default [
    {
      path: '',
      name: 'home',
      redirect: '/home',
      component: Layout,
      meta: {
        title: '首页',
        basePath: ''
      },
      children: [
        {
          path: '',
          component: Home,
          name: '',
          meta: {
            basePath: '',
            title: '首页'
          }
        }
      ]
    },
    {
        path: '/home',
        name: 'home',
        component: Layout,
        meta: {
            title: '首页',
            basePath: ''
          },
          children: [
            {
              path: '/',
              component: Home,
              name: '',
              meta: {
                basePath: 'home',
                title: '首页'
              }
            }
          ]
    },
    {
      path: '/waiter',
      name: 'waiter',
      component: Layout,
      meta: {
        title: 'waiter',
        basePath: ''
      }
    },
    {
      path: '/seller',
      name: 'seller',
      component: Layout,
      meta: {
        title: 'seller',
        basePath: ''
      }
    },
    {
      path: '/njkt/*',
      name: 'njkt',
      component: Layout,
      meta: {
        title: 'njkt',
        basePath: ''
      }
    }
]