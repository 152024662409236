<template>
  <div class="personal_certificate_container box_shadow_wrap">
    <a-spin :spinning="spinning">
      <div class="main">
        <div class="global_title">我的认证</div>
        <div class="steps_wrap">
          <a-steps :current="current" :status="status">
            <a-step v-for="(item, index) in steps" :key="item" :title="item">
              <template v-if="index == 1 && auditStatus == 2">
                <div class="ico_wrap" slot="icon">
                  <a-icon type="exclamation" />
                </div>
                <div slot="description" style="padding-right: 10px;">原因：{{auditOpinion}}</div>
              </template>
            </a-step>
          </a-steps>
        </div>
        <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
          <a-form-model-item required label="真实姓名" prop="trueName">
            <a-input v-model="ruleForm.trueName" :disabled="disabled" placeholder="请输入真实姓名" />
          </a-form-model-item>
          <a-form-model-item required label="身份证号" prop="idNumber">
            <a-input v-model="ruleForm.idNumber" :disabled="disabled" placeholder="请输入身份证号" />
          </a-form-model-item>
          <a-form-model-item required label="联系电话" prop="phone">
            <a-input v-model.number="ruleForm.phone" :disabled="disabled" placeholder="请输入联系电话" />
          </a-form-model-item>
          <a-form-model-item label="联系QQ" prop="qq">
            <a-input v-model="ruleForm.qq" :disabled="disabled" placeholder="请输入联系QQ" />
          </a-form-model-item>
          <a-form-model-item required label="证件照片" prop="photo" ref="photo" :autoLink="false" :wrapper-col="{ span: 14 }">
            <div class="clearfix" v-if="!spinning">
              <bj-upload :default-list="previewList" :disabled="disabled" :action="uploadUrl" :max="2" @change="uploadList"></bj-upload>
              <div class="tips" v-if="!disabled">请上传身份证正反面照片</div>
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-spin>
    
    <div class="footer">
      <a-space :size="26">
        <template v-if="certificateStatus == 0">
          <a-button @click="goBack">取消</a-button>
          <a-button type="primary" :loading="loading" @click="submitForm">认证</a-button>
        </template>
        <template v-else-if="auditStatus == 2">
          <a-button :loading="loading" @click="handleCancel"><img class="btn_icon" src="@/assets/img/icon/revocation.png">撤销申请</a-button>
          <a-button type="primary" :loading="loading" @click="submitForm">重新提交</a-button>
        </template>
        <template v-else-if="auditStatus == 1">
          <a-button type="primary" @click="goBack"><img class="btn_icon" src="@/assets/img/icon/back.png">返回</a-button>
        </template>
        <template v-else-if="disabled">
          <a-button :loading="loading" @click="handleCancel"><img class="btn_icon" src="@/assets/img/icon/revocation.png">撤销</a-button>
          <a-button type="primary" @click="goBack"><img class="btn_icon" src="@/assets/img/icon/back.png">返回</a-button>
        </template>
      </a-space>
    </div>
  </div>
</template>
<script>
import { ApiUrl } from '@/config/index'

export default {
  data() {
    const validateTrueName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入真实姓名'));
      } else {
        callback();
      }
    };
    const validateIdNumber = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入身份证号'));
      } else if (!this.$utils.test.idCard(value)) {
        callback(new Error('身份证格式错误'));
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入联系电话'));
      } else if (!this.$utils.test.mobile(value)) {
        callback(new Error('电话证格式错误'));
      } else {
        callback();
      }
    };
    const validatePhoto = (rule, value, callback) => {
      const lent = this.ruleForm.photo.split(';').length
      if (lent < 2) {
        callback(new Error('请上传证件照片'));
      } else {
        callback();
      }
    };
    return {
      uploadUrl: `${ApiUrl}/api/backapp/system/fileUpload/oss/input`,
      queryId: '',
      auditOpinion: '',
      loading: false,
      spinning: false,
      previewList: [],
      ruleForm: {
        trueName: '',
        idNumber: '',
        phone: '',
        qq: '',
        photo: ''
      },
      rules: {
        trueName: [{ validator: validateTrueName, trigger: 'change' }],
        idNumber: [{ validator: validateIdNumber, trigger: 'change' }],
        phone: [{ validator: validatePhone, trigger: 'change' }],
        photo: [{ validator: validatePhoto, trigger: 'change' }]
      },
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 8 },
      }
    }
  },
  computed: {
    auditStatus() {
      return this.$store.state.auditStatus
    },
    certificateStatus() {
      return this.$store.state.certificateStatus
    },
    disabled() {
      return this.certificateStatus == 1 && this.auditStatus != 2
    },
    steps() {
      return ['提交认证', this.auditStatus == 2 ? '驳回认证' : '认证审核','认证完成']
    },
    status() {
      return this.auditStatus == 2 ? 'error' : 'process'
    },
    current() {
      if (this.auditStatus == 1) return 2
      else if (this.certificateStatus == 1) return 1
      else return 0
    }
  },
  async created() {
    await this.getCertificationStatus()
    if (this.certificateStatus == 1) this.getCertificateInfo()
  },
  methods: {
    // 提价表单
    submitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) return false

        if (this.auditStatus == 2) {
          this.resubmit()
        } else {
          this.certification()
        }
      });
    },
    // 认证
    async certification() {
      try {
        this.loading = true
        const pic = this.ruleForm.photo.split(';')
        const [frontPicture, backPicture] = pic
        const { data: { code, msg } } = await this.$axios.personalCertification({
          body: {
            trueName: this.ruleForm.trueName,  // 真实姓名
            idNumber: this.ruleForm.idNumber, // 身份证号
            phone: this.ruleForm.phone, // 联系电话
            qq: this.ruleForm.qq, // 联系QQ
            frontPicture: frontPicture || '', // 身份证正面照片
            backPicture: backPicture || '' // 身份证反面照片
          }
        })
        this.loading = false
        if (code != 0) return this.$message.error(msg)
        this.$message.success('提交成功')
        this.initPreview(pic)
        this.getCertificationStatus()
      } catch (error) {
        this.loading = false
      }
    },
    // 重新提交
    async resubmit() {
      try {
        this.loading = true
        const pic = this.ruleForm.photo.split(';')
        const [frontPicture, backPicture] = pic
        const { data: { code, msg } } = await this.$axios.resubmitPersonalCertificate({
          body: {
            id: this.queryId,
            trueName: this.ruleForm.trueName,  // 真实姓名
            idNumber: this.ruleForm.idNumber, // 身份证号
            phone: this.ruleForm.phone, // 联系电话
            qq: this.ruleForm.qq, // 联系QQ
            frontPicture: frontPicture || '', // 身份证正面照片
            backPicture: backPicture || '' // 身份证反面照片
          }
        })
        this.loading = false
        if (code != 0) return this.$message.error(msg)
        this.$message.success('提交成功')
        this.initPreview(pic)
        this.getCertificationStatus()
      } catch (error) {
        this.loading = false
      }
    },
    // 撤销认证
    handleCancel() {
      this.$confirm({
        title: '确定撤销吗?',
        confirmLoading: true,
        onOk: async () => {
          this.loading = true
          const { data: { code, msg } } = await this.$axios.personalCertificateCancel({})
          this.loading = false
          if (code != 0) return this.$message.error(msg)
          this.$message.success('撤销成功')
          this.queryId = ''
          const pic = this.ruleForm.photo.split(';')
          this.initPreview(pic)
          this.getCertificationStatus()
        },
        onCancel: () => {
          this.loading = false
        }
      });
    },
    // 查询认证状态
    async getCertificationStatus() {
      const { data: { code, msg, body } } = await this.$axios.getCertification({})
      if (code != 0) return this.$message.error(msg)
      this.$store.commit('setCertification', body)
    },
    // 取消操作
    goBack() {
      this.$router.go(-1)
    },
    // 图片上传
    uploadList(e) {
      this.ruleForm.photo = e
      this.$refs.photo.onFieldChange()
    },
    // 认证查询
    async getCertificateInfo() {
      this.spinning = true
      const { data: { code, msg, body } } = await this.$axios.getCertificateInfo({})
      this.spinning = false
      if (code != 0) return this.$message.error(msg)
      
      this.queryId = body.id
      this.auditOpinion = body.auditOpinion

      const pic = []
      const { frontPicture, backPicture, trueName, idNumber, phone, qq } = body
      if (frontPicture) pic.push(frontPicture)
      if (backPicture) pic.push(backPicture)
      
      this.ruleForm = {
        trueName: trueName,
        idNumber: idNumber,
        phone: phone,
        qq: qq
      }

      this.initPreview(pic)
    },
    // 初始化上传图片
    initPreview(arr = []) {
      this.previewList = arr.map((item, index) => ({uid: `-${index + 1}`, name: 'image.png', status: 'done', url: item}))
      this.ruleForm.photo = arr.join(';')
      this.$nextTick(() => {
        this.$refs.photo.onFieldChange()
      })
    }
  }
};
</script>

<style scoped lang="less">
@import '../../../styles/pages/personalcenter.less';
.personal_certificate_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2px;
  min-height: calc(100% - 2px);
  .steps_wrap {
    margin: 45px 177px 58px;
  }
  .tips {
    line-height: normal;
    color: #ccc;
  }
  .footer {
    display: flex;
    justify-content: center;
  }
  .ico_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    text-align: center;
    border: 1px solid #f5222d;
    border-radius: 32px;
    transition: background-color 0.3s, border-color 0.3s;
  }
  .img_list {
    display: flex;
    flex-wrap: wrap;
    img {
      width: 104px;
      height: 104px;
      margin: 0 8px 8px 0;
    }
  }
}
.btn_icon {
  width: 16px;
  margin-right: 3px;
  vertical-align: text-bottom;
}
</style>
