import Vue from 'vue'
import LoginModal from './loginModal.vue'

LoginModal.newInstance = (config) => {
	const _props = config || {}
	const Instance = new Vue({
		data: Object.assign({}, _props),
		render(h) {
			return h(LoginModal, {
				props: {},
				on: {
					'on-cancel': () => {
						this.cancel();
					},
					// 'on-ok': (value) => {
					// 	this.ok(value);
					// },
				}
			})
		},
    methods: {
	  cancel() {
		this.$children[0].visible = false;
		this.remove();
	  },
	  remove() {
        // this.destroy();
        setTimeout(() => {
          this.destroy();
        }, 100);
      },
      destroy() {
        this.$destroy();
        document.body.removeChild(this.$el);
        this.onRemove();
      },
      onRemove() {}
		}
	})

	const component = Instance.$mount()
	document.body.appendChild(component.$el);
  const loginInstance = Instance.$children[0];

	return {
    remove() {
      loginInstance.visible = false;
      loginInstance.$parent.remove();
    },
    component: loginInstance
  };
}

export default LoginModal.newInstance