<template>
  <div class="partner">
    <div class="partner-content">
      <div class="partner-item" v-for="(item, index) in partners.slice(0, 8)" :key="index">
        <img :src="item.picture" style="cursor:pointer" @click="openLink(item.url)"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
name: "Partner",
components: {
},
data () {
return {
  title: '合作伙伴',
}
},
props: {
  partners: {
    type: Array,
    default: () => []
  }
},
watch: {

},
methods: {
  //合作伙伴跳转
    openLink(url){
      if (!/^(http:|https:)/i.test(url)){
        url = "http://" + url; 
      }
      window.open(url)
    },
},
computed: {

},
created () {

},
mounted () {

},
destroyed () {

},
}
</script>
<style scoped lang="less">
    @import url('../../styles/components/partner.less');
</style>
