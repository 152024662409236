<template>
  <div class="supplyDemandDetail">
    <a-spin style="height: 100%" :spinning="loading">
      <a-tabs
        :default-active-key="defaultKey"
        :activeKey="activeKey"
        @change="tabChange"
      >
        <a-tab-pane key="1" tab="供应大厅" forceRender>
          <Supply :preData="preData" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="需求大厅" force-render>
          <Demand :preData="preData1" />
        </a-tab-pane>
      </a-tabs>
    </a-spin>
  </div>
</template>

<script>
import  Demand from "./demand.vue";
import  Supply  from "./supply.vue";
export default {
  name: "supplyDemandHall",
  components: {
    Demand,
    Supply,
  },
  data() {
    return {
      preData: {
        hots: [],
        category: [],
        searchTitle: "",
      },
      preData1: {
        hots: [],
        category: [],
        searchTitle: "",
      },
      activeKey: "1",
      defaultKey: "1",
      loading: false,
    };
  },
  props: {},
  watch: {},
  methods: {
    tabChange(key) {
      this.activeKey = key;
    },
    async getHotAndCategory() {
      this.loading = true;
      const res1 = await this.$axios.queryHot();
      const res2 = await this.$axios.queryCategory();
      const res3 = await this.$axios.queryHotDemand();

      this.loading = false;
      if (res1.data.code === "0") {
        this.preData.hots = [...res1.data.body];
      }
      if (res2.data.code === "0") {
        this.preData.category = [...res2.data.body];
        this.preData1.category = [...res2.data.body];
      }
      if (res3.data.code === "0") {
        this.preData1.hots = [...res3.data.body];
      }
    },
  },
  computed: {},
  created() {
    this.getHotAndCategory();
    if (this.$route.query.key) {
      this.activeKey = this.$route.query.key;
    }
  },
  mounted() {
    console.log("hall mounted");
  },
  destroyed() {},
};
</script>

<style scoped lang="less">
.supplyDemandDetail {
  padding-top: 20px;
  position: relative;
  height: 100%;
  margin: 0 auto;
  width: 1190px;
  // /deep/ .ant-tabs-nav-scroll,/deep/ .ant-tabs-nav-wrap{
  //   overflow:visible
  // }

  /deep/ .ant-tabs-bar {
    margin: 0;
    background: #f9f9f9;
    border: 1px solid #e2e2e2;

		font-size: 16px;
		font-weight: 400;
		color: #333333;
		line-height: 22px;
		.ant-tabs-ink-bar {
			background-color: #FFFFFF;
		}
    .ant-tabs-nav:has(> .ant-tabs-tab-active){
          border-top: 2px solid #4DAB6E;
      }
		.ant-tabs-nav {
			font-size: 16px;
			font-weight: 400;
			color: #333333;
			line-height: 22px;
     
			.ant-tabs-tab-active {
				background: #FFFFFF;
          // box-shadow:0 2px 0 #4DAB6E;

        border-top: 2px solid #4dab6e;
        // &:before{
        //   position: absolute;
        //   top: -1px;
        //   left: 0;
        //   width: 100%;
        //   box-shadow: 2px 0 0 #4DAB6E;
        //   border-radius: 0;
        //   transition: all 0.3s;
        //   content: '';
        //   pointer-events: none
        // }
        // border-left: 1px solid #E2E2E2;
        // border-right: 1px solid #E2E2E2;
      }

      .ant-tabs-tab {
        margin: 0;
        padding: 9px 30px;
      }
    }
  }
}
</style>
