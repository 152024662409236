import { POST, PUT, GET, ApiUrl } from '@/config/index'

// 首页-我的供应
const getMySupplyData = POST(`${ApiUrl}/api/backapp/processEnterprise/statistics/action/mySupply`)

// 首页-我的需求
const getMyDemandData = POST(`${ApiUrl}/api/backapp/processEnterprise/statistics/action/myDemand`)

// 首页-我的服务订单
const getMySupplyOrder = POST(`${ApiUrl}/api/backapp/processEnterprise/statistics/action/mySupplyOrder`)

// 首页-我的需求订单
const getMyDemandOrder = POST(`${ApiUrl}/api/backapp/processEnterprise/statistics/action/myDemandOrder`)

// 首页-认证状态查询
const getCertification = POST(`${ApiUrl}/api/backapp/processEnterprise/certification/action/getCertification`)

// 首页-个人认证查询
const getCertificateInfo = GET(`${ApiUrl}/api/backapp/processEnterprise/personalCertification/action/getCertificateInfo`)

// 首页-个人认证撤销
const personalCertificateCancel = GET(`${ApiUrl}/api/backapp/processEnterprise/personalCertification/action/cancel`)

// 首页-个人认证重新提交
const resubmitPersonalCertificate = PUT(`${ApiUrl}/api/backapp/processEnterprise/personalCertification/`)

// 首页-个人认证
const personalCertification = POST(`${ApiUrl}/api/backapp/processEnterprise/personalCertification/`)

// 首页-企业认证
const enterpriseCertification = POST(`${ApiUrl}/api/backapp/processEnterprise/enterpriseCertification/`)

// 首页-企业认证查询
const getEnterpriseCertificateInfo = GET(`${ApiUrl}/api/backapp/processEnterprise/enterpriseCertification/action/getCertificateInfo`)

// 首页-企业认证撤销
const enterpriseCertificationCancel = GET(`${ApiUrl}/api/backapp/processEnterprise/enterpriseCertification/action/cancel`)

// 首页-企业认证重新提交
const resubmitEnterpriseCertification = PUT(`${ApiUrl}/api/backapp/processEnterprise/enterpriseCertification/`)

// 获取收藏列表
const getCollectList = POST(`${ApiUrl}/api/backapp/processEnterprise/favorites/action/queryByPage`)

// 取消收藏
const cancelCollect = POST(`${ApiUrl}/api/backapp/processEnterprise/favorites/action/deleteById`)

// 获取我的需求列表不同状态数量
const getDemandCount = POST(`${ApiUrl}/api/backapp/processEnterprise/demand/action/queryCountByStatus`)

// 获取我的需求列表
const getDemandList = POST(`${ApiUrl}/api/backapp/processEnterprise/demand/action/queryByPage`)

// 我的需求-下架
const outDemand = PUT(`${ApiUrl}/api/backapp/processEnterprise/demand/`)

// 我的需求-上架
const putawayDemand = PUT(`${ApiUrl}/api/backapp/processEnterprise/demand/`)

// 我的需求-删除
const deleteDemand = POST(`${ApiUrl}/api/backapp/processEnterprise/demand/action/deleteById`)

// 我的需求-详情
const getDemandDetail = POST(`${ApiUrl}/api/backapp/processEnterprise/demand/action/detail`)

// 我的需求-品类树结构
const getDemandCategory= GET(`${ApiUrl}/api/backapp/processEnterprise/category/action/queryProcessCategory`)

// 我的需求-发布
const publishDemand = POST(`${ApiUrl}/api/backapp/processEnterprise/demand/`)

// 我的需求-编辑
const editDemand = PUT(`${ApiUrl}/api/backapp/processEnterprise/demand/`)

// 需求订单-订单列表
const getDemandOrderList = POST(`${ApiUrl}/api/backapp/processEnterprise/order/action/queryDemandOrder`)

// 需求订单-评价订单
const evaluateDemandOrder = POST(`${ApiUrl}/api/backapp/processEnterprise/order/action/evaluate`)

// 需求订单-取消订单
const cancelDemandOrder = POST(`${ApiUrl}/api/backapp/processEnterprise/order/action/buyerCancel`)

// 需求订单-修改
const modifyDemandOrder = PUT(`${ApiUrl}/api/backapp/processEnterprise/order/`)

// 需求订单-从新下单
const demandReorder = POST(`${ApiUrl}/api/backapp/processEnterprise/order/action/reOrder`)

// 需求订单-订单数量
const getDemandOrderCount = POST(`${ApiUrl}/api/backapp/processEnterprise/order/action/queryDemandOrderCount`)

// 服务订单-订单列表
const getServiceOrderList = POST(`${ApiUrl}/api/backapp/processEnterprise/order/action/querySupplyOrder`)

// 服务订单-订单数量
const getServiceOrderCount = POST(`${ApiUrl}/api/backapp/processEnterprise/order/action/querySupplyOrderCount`)

// 服务订单-维护服务进度
const serviceProgress = POST(`${ApiUrl}/api/backapp/processEnterprise/order/action/progress`)

// 服务订单-回复订单
const replServiceOrder = POST(`${ApiUrl}/api/backapp/processEnterprise/order/action/reply`)

// 服务订单-取消订单
const cancelServiceOrder = POST(`${ApiUrl}/api/backapp/processEnterprise/order/action/sellerCancel`)

// 服务订单-订单完成
const finishedServiceOrder = POST(`${ApiUrl}/api/backapp/processEnterprise/order/action/finished`)

// 供应管理-我的服务情况
const getMyService = POST(`${ApiUrl}/api/backapp/processEnterprise/demand/action/getMyService`)

// 农机预约-我的预约列表
const getMyAppointment = POST(`${ApiUrl}/api/backapp/argimach/appointment/action/myAppointment`)

// 农机预约-我的预约列表数量
const getMyAppointmentCount = POST(`${ApiUrl}/api/backapp/argimach/appointment/action/myAppointmentCount`)

// 农机预约-我的预约-评价
const appointmentEvaluate = POST(`${ApiUrl}/api/argimach/wechat/monitoring/action/modify`)

export default {
    getMySupplyData,
    getMyDemandData,
    getMySupplyOrder,
    getMyDemandOrder,
    getCertification,
    getCertificateInfo,
    personalCertificateCancel,
    resubmitPersonalCertificate,
    personalCertification,
    enterpriseCertification,
    getEnterpriseCertificateInfo,
    enterpriseCertificationCancel,
    resubmitEnterpriseCertification,
    getCollectList,
    cancelCollect,
    getDemandCount,
    getDemandList,
    outDemand,
    putawayDemand,
    deleteDemand,
    getDemandDetail,
    getDemandCategory,
    publishDemand,
    editDemand,
    getDemandOrderList,
    evaluateDemandOrder,
    cancelDemandOrder,
    modifyDemandOrder,
    demandReorder,
    getDemandOrderCount,
    getServiceOrderList,
    getServiceOrderCount,
    serviceProgress,
    replServiceOrder,
    cancelServiceOrder,
    finishedServiceOrder,
    getMyService,
    getMyAppointment,
    getMyAppointmentCount,
    appointmentEvaluate
}