import About from '@/pages/About';
import Layout from '@/layouts/BasicLayout/index.vue'

export default [
    {
        path: '/about',
        name: 'about',
        component: Layout,
        meta: {
          title: '关于',
          basePath: 'about'
        },
        children: [
          {
            path: '',
            component: About,
            name: '',
            meta: {
              basePath: 'about',
              title: '关于'
            }
          }
        ]
      },
]