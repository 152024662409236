<template>
  <div>
    <div class="search-wrapper">
      <a-input-search
        style="width: 530px;"
        v-model="inputVal"
        size="large"
        placeholder="请输入追溯码"
        enter-button="搜索"
        @search="search"
      />
    </div>
    <div v-if="QRCodeUrl" class="sao-yi-sao">
      <div class="QR-code">
        <vue-qr :logoSrc="require('@/assets/img/qrcode-logo.png')" :margin="2" :text="QRCodeUrl" :logo-scale="0.2" :size="300"/>
      </div>
      <div class="tips">
        <div>微信扫一扫</div>
        <div>在手机上预览</div>
      </div>
    </div>
    <template v-if="QRCodeUrl">
      <div v-if="showContent" class="con-radio">
        <a-radio-group size="large" v-model="conRadio" button-style="solid">
          <a-radio-button :value="1">商品信息</a-radio-button>
          <a-radio-button :value="2">溯源信息</a-radio-button>
          <a-radio-button :value="3">企业信息</a-radio-button>
        </a-radio-group>
        <div class="tips">溯源由蚂蚁链提供技术支持</div>
      </div>
      <div v-if="showContent" class="content">
        <goods :form-data.sync="goodsData" v-if="conRadio === 1" />
        <su-yuan :form-data.sync="suYuanData" v-else-if="conRadio === 2" />
        <merchant :form-data.sync="merchantData" v-else />
      </div>
      <div v-if="!showContent" class="search-dynamic">
        <img src="@/assets/img/suzhou.png">
        <img src="@/assets/img/sousuo-gif.gif">
        <img src="@/assets/img/mayi.png">
      </div>
    </template>
    <div v-else class="no-data">
      <img src="@/assets/img/suyuan-example.png">
    </div>
  </div>
</template>

<script>
import { syUrl } from "@/config/index"
import VueQr from 'vue-qr'
import axios from "axios"
import goods from "../components/goods.vue"
import suYuan from "../components/suYuan.vue"
import merchant from "../components/merchant.vue"
export default {
  name: "Blockchain",
  components: { goods, suYuan, merchant, VueQr },
  data() {
    return {
      inputVal: "",
      showQECode: false,
      conRadio: 1,
      goodsData: {},
      suYuanData: {},
      merchantData: {},
      QRCodeUrl: '',
      showContent: false
    };
  },
  props: {},
  watch: {},
  methods: {
    search() {
      if (!this.inputVal) {
        this.$message.warn("请输入追溯码!");
        return;
      }
      this.showContent = false
      axios
        .get(
          `${syUrl}/gateway/api/backapp/sourcetrace/sourceCodeRecordDetails/action/getTraceInfo/${this.inputVal}`
        )
        .then((res) => {
          if (res.status === 200 && res.data && res.data.body) {
            const _data = res.data.body
            this.goodsData = _data.goods
            this.suYuanData = _data.sourceTrace
            this.merchantData = _data.merchant
          }
          this.showQECode = true;
          setTimeout(() => {
            this.showContent = true
          }, 800);
          this.$nextTick(() => {
            const url = `${syUrl}/blockchainMerchant/traceToSource?sourceCode=${this.inputVal}`;
            this.QRCodeUrl  = url
          });
        })
        .catch((e) => {
          console.log(e.response);
          if (e.response.data.code === "-1") {
            this.$message.warn(`${e.response.data.msg}`);
            this.showQECode = false;
          }
        });
    },
  },
  computed: {},
  created() {},
  mounted() {},
  destroyed() {},
};
</script>
<style lang="less" scoped>
.search-wrapper {
  margin-bottom: 30px;
}
.sao-yi-sao {
  display: flex;
  flex-direction: column;
  float: right;
  z-index: 1;
  margin-top: -95px;
  align-items: center;
  .QR-code {
    width: 88px;
    height: 88px;
    background: #4DAB6E;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 79px;
    }
  }
  .tips > div {
    font-size: 8px;
    color: #333;
    text-align: center;
  }
}
.con-radio {
  display: flex;
  align-items: center;
}
.content {
  padding: 0 20px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  .tips {
    margin-left: 100px;
    font-weight: 500;
  }
}
.search-dynamic {
  display: flex;
  align-items: center;
  padding: 78px 0 200px 20px;
}
</style>
