import axios from 'axios'
import { ApiUrl } from '@/config/index'
/**
 * 上传图片接口
 * @param {*} file
 */
async function uploadImage(file) {
  const from = new FormData()

  from.append('file_name', new Date().getTime() + '.jpg')
  from.append('dir_path', '')
  from.append('file', file)

  const res = await axios.request({
    url: `${ApiUrl}/api/backapp/system/fileUpload/oss/input`,
    method: 'POST',
    data: from
  })

  //   console.log(res);
  const imgUrl = res.data
  return imgUrl
}

export default uploadImage
