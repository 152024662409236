import Vue from 'vue'

import VueRouter from 'vue-router'
import antdV from '@/config/antDesignVue'
import App from './App.vue'
import Api from '@/api'
import xss from 'xss'
import utils from '@/utils'
import 'video.js/dist/video-js.css'

import { VueJsonp } from 'vue-jsonp'
// 引入模块化的公用css
import router from '@/router';
import store from '@/store'
import '@/components/global/'
// 登录弹框引入
import LoginModal from '@/pages/Login/loginModal'
// 申请弹框引入
import information from '@/pages/Login/information'
import './assets/css/customized.antd.less';
import VueMeta from'vue-meta'
import * as Echarts from 'echarts';
// 引入全局过滤器
import filter from '@/utils/filter'
Object.keys(filter).forEach(key => {
  Vue.filter(key, filter[key])
})
Vue.prototype.$Echarts = Echarts;

Vue.use(VueMeta)
Vue.use(antdV)
Vue.use(VueRouter)
Vue.use(VueJsonp)
// Vue.use(Antd);

Vue.config.productionTip = false
Vue.prototype.$utils = utils
Vue.prototype.xss = xss
Vue.prototype.$axios = Api
Vue.prototype.$loginModal = LoginModal;
Vue.prototype.$information = information;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#main-app')
