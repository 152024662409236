<template>
    <div class="star-product">
        <div class="product-left"> 
            <div class="title">{{ title }}</div>
            <div class="message">{{ message }}</div>
            <button class="btnStyle">{{ btnMsg }}</button>
        </div>
        <div class="product-right">
            <div class="pro-item" v-for="(pro, index) in products" :key="index">
                <img :src="pro.img"/>
                <div class="pro-info">
                    <div class="flex-item">
                        <span class="red-font">¥{{ pro.price }}</span>
                        <span>{{ pro.price }}人收货</span>
                    </div>
                    <div class="black-font">{{ pro.name }}</div>
                    <div class="flex-item" style="margin-top: 28px;">
                        <span class="shop-font">{{ pro.shop }}</span>
                        <span>{{ pro.place }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'StarProduct',
    props: {
        // products: {
        //     type: String,
        //     default: () => []
        // }
    },
    data() {
        return {
            title: '明星产品',
            message: '品种丰富 产地货源',
            btnMsg: '查看更多',
            products: [
                {
                  img: require('@/assets/img/pro1.jpg'),
                  price: 28.8,
                  count: 736,
                  name: '清脆新鲜大黄瓜',
                  shop: '明杰直营店',
                  place: '安徽宿州'
                },
                {
                  img: require('@/assets/img/pro2.jpg'),
                  price: 28.8,
                  count: 736,
                  name: '早大白黄心马铃薯',
                  shop: '明杰直营店',
                  place: '安徽宿州'
                },
                {
                  img: require('@/assets/img/pro3.jpg'),
                  price: 28.8,
                  count: 736,
                  name: '肉肉肉肉肉肉肉肉',
                  shop: '明杰直营店',
                  place: '安徽宿州'
                },
                {
                  img: require('@/assets/img/pro4.jpg'),
                  price: 28.8,
                  count: 736,
                  name: '鸡腿鸡腿鸡腿鸡腿',
                  shop: '明杰直营店',
                  place: '安徽宿州'
                },
                {
                  img: require('@/assets/img/pro1.jpg'),
                  price: 28.8,
                  count: 736,
                  name: '清脆新鲜大黄瓜',
                  shop: '明杰直营店',
                  place: '安徽宿州'
                },
                {
                  img: require('@/assets/img/pro2.jpg'),
                  price: 28.8,
                  count: 736,
                  name: '早大白黄心马铃薯',
                  shop: '明杰直营店',
                  place: '安徽宿州'
                },
                {
                  img: require('@/assets/img/pro3.jpg'),
                  price: 28.8,
                  count: 736,
                  name: '肉肉肉肉肉肉肉肉',
                  shop: '明杰直营店',
                  place: '安徽宿州'
                },
                {
                  img: require('@/assets/img/pro4.jpg'),
                  price: 28.8,
                  count: 736,
                  name: '鸡腿鸡腿鸡腿鸡腿',
                  shop: '明杰直营店',
                  place: '安徽宿州'
                }
            ]
        }
    },
    created() {
        
    },
    mounted() {
        
    },
    methods: {
        
    }
}
</script>
<style lang="less" scoped>
@import url('../../styles/components/starProduct.less');
</style>