<template>
  <div class="su-yuan-modal">
    <a-modal v-model="visible" title="溯源信息" width="920px" :destroy-on-close="true" :footer="null">
      <div v-if="QRCodeUrl" style="position: absolute;right: -164px;top: 0;">
        <div class="QR-code">
          <vue-qr
            :logoSrc="require('@/assets/img/qrcode-logo.png')"
            :margin="2"
            :text="QRCodeUrl"
            :logo-margin="2"
            :size="138"
          />
        </div>
        <div style="text-align: center;color: #fff;padding-top: 10px;">
          <div>微信扫一扫</div>
          <div>在手机上预览</div>
        </div>
      </div>
      <div class="con-radio">
        <a-radio-group size="large" v-model="conRadio" button-style="solid">
          <a-radio-button :value="1">商品信息</a-radio-button>
          <a-radio-button :value="2">溯源信息</a-radio-button>
          <a-radio-button :value="3">企业信息</a-radio-button>
        </a-radio-group>
      </div>
      <div class="content" style="box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);padding: 0 20px;">
        <goods :form-data.sync="goodsData" v-if="conRadio === 1" />
        <su-yuan :form-data.sync="suYuanData" v-else-if="conRadio === 2" />
        <merchant :form-data.sync="merchantData" v-else />
      </div>
    </a-modal>
  </div>
</template>
<script>
import VueQr from 'vue-qr'
import goods from "../components/goods.vue";
import suYuan from "../components/suYuan.vue";
import merchant from "../components/merchant.vue";
export default {
  components: { goods, suYuan, merchant, VueQr },
  data () {
    return {
      showQECode: false,
      conRadio: 1,
      goodsData: {},
      suYuanData: {},
      merchantData: {},
      QRCodeUrl: '',
      visible: false
    }
  },
  methods: {
    showSuYuan(id) {
      this.visible = true
      this.getTraceInfoByGoodsId(id)
    },
    async getTraceInfoByGoodsId(id) {
      try {
        const res = await this.$axios.getTraceInfoByGoodsId(id);
        if (res.status === 200 && res.data && res.data.body) {
            const _data = res.data.body
            this.goodsData = _data.goods
            this.suYuanData = _data.sourceTrace
            this.merchantData = _data.merchant
          }
          this.showQECode = true;
          this.$nextTick(() => {
            this.QRCodeUrl  = res.data.body.scanCodeUrl;
          });
      } catch (error) {
        if (typeof error == "string") {
          this.$message.error(error);
        } else {
          this.$message.error("查询失败");
        }
      }
    }
  }
};
</script>