<template>
  <div class="singlefileClass">
    <a-upload
      name="file"
      list-type="picture-card"
      :file-list="fileList"
      class="avatar-uploader"
      :accept="uploadAccept"
      :action="UploadUrl"
      :disabled="isDisabled"
      method="post"
      :data="uploadParam"
      :before-upload="beforeUpload"
      @preview="handlePreview"
      @change="handleChange"
    >
      <div v-if="fileList.length < MaxNumber">
        <a-icon type="plus" />
        <div class="ant-upload-text">
          上传
        </div>
      </div>
    </a-upload>
    <!-- <span> {{ ruleText }}</span> -->
    <span class="filelableclass"> {{ lable }}</span>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'SingleFile',
  props: {
    UploadUrl: {
      type: String
    },
    MaxNumber: {
      type: Number,
      default: 5
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    lable: {
      type: String,
      default: ''
    },
    ruleText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fileList: [],
      previewVisible: false,
      previewImage: '',
      uploadAccept: 'image/png, image/jpeg',
      isSure: true,
      currentValue: ''
    }
  },
  created() {
    if (this.value) {
      let obj = {
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: this.value
      }
      this.fileList = [obj]
    } else {
      // this.fileList = []
    }
    if (!this.isDisabled) {
      //不可编辑
      // const root = document.getElementsByClassName('singlefileClass')[0]
      const deleview = document.getElementsByClassName('anticon-delete')
      console.log(deleview)
    }
  },

  methods: {
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange({ file, fileList }) {
      console.log('handleChange', file, fileList)
      this.fileList = fileList
      const urls = []
      this.fileList.forEach(ele => {
        const obj = ele.response
        if (obj) {
          console.log('obj', obj.body.auth_url)
          urls.push(obj.body.auth_url)
        }
      })
      console.log(urls)
      console.log(this.fileList)
      if (urls && urls.length > 0) {
        this.$emit('change', urls[0])
        this.$emit('input', urls[0])
      } else {
        // this.$emit('input', '')
        // this.$emit('change', '')
      }
      if (!this.isSure) {
        this.clareData()
      }

      //   this.form.modelImgUrl = urls.join(';')
    },
    // 上传文件之前的钩子
    beforeUpload(file, fileList) {
      console.log('beforeUpload', file, fileList)
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('请上传png、jpg格式图片!')
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        // this.fileList = []
        this.isSure = false
        this.clareData()
        this.$message.error('图片必须小于5MB!')
      } else {
        this.isSure = true
      }
      return isJpgOrPng && isLt2M
    },
    clareData() {
      this.fileList = []
      this.$emit('input', '')
      this.$emit('change', '')
    },
    // 上传参数
    uploadParam(file) {
      return {
        file_name: file.name,
        dir_path: ''
      }
    }
  },
  watch: {
    value(v) {
      if (v) {
        let obj = {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: v
        }
        this.fileList = [obj]
        this.$emit('change', v)
        this.$emit('input', v)
      } else {
        this.$emit('change')
        this.$emit('input', v)
      }
      // this.$emit('change')
      // console.log(v)
    }
  }
}
</script>

<style lang="less" scoped>
.singlefileClass {
  position: relative;
  margin-right: 10px;
  .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
  .filelableclass {
    position: absolute;
    bottom: -10px;
    left: 0px;
    padding-left: 30px;
    // right: 0;
    text-align: center;
    // padding-right: 14px;
  }
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }
  // /deep/.anticon-delete {
  //   display: none !important;
  // }
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
</style>
