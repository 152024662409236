<template>
  <div class="reference">
      <div class="re-title">行情参考</div>
      <div class="re-list">
          <template v-for="(item, index) in reList">
              <div class="reItem" :key="index" :style="'background-image:url('+item.img+')'" @click="open(item.url)">
                <span>{{ item.name }}</span>
            </div>
          </template>
          
      </div>
  </div>
</template>

<script>
export default {
name: "Reference",
components: {
},
data () {
return {
    reList: [
        {
            name: '红星全球农批中心',
            img: require('../../assets/img/refer1.png'),
            url: 'http://www.hxdsc.net/index.php?m=content&c=index&a=lists&catid=16'
        },
        {
            name: '北京新发地',
            img: require('../../assets/img/refer2.png'),
            url: 'http://www.xinfadi.com.cn/priceDetail.html'
        },
        {
            name: '嘉兴水果市场',
            img: require('../../assets/img/refer3.png'),
            url: 'http://www.jxsgsc.com/gphq.asp'
        },
        {
            name: '万邦国际农产品',
            img: require('../../assets/img/refer4.png'),
            url: 'http://www.wbncp.com/Price.aspx'
        },
        {
            name: '广州江南果菜批发市场经营管理有限公司',
            img: require('../../assets/img/refer5.png'),
            url: 'http://jnmarket.net/'
        }
    ]
}
}, 
methods: {
    open(url) {
        window.open(url, '_blank')
    }
},
}
</script>

<style scoped lang="less">
    .reference {
        margin-bottom: 60px;
        .re-title {
            padding: 40px 0;
            font-size: 24px;
            font-weight: 600;
            color: #333333;
            line-height: 30px;
        }
        .re-list {
            display: flex;
            flex-direction: row;

            .reItem {
                height: 200px;
                width: 210px;
                background-size: 100%, 100%; /* 看情况，有 cover 和 contain 模拟小程序里边的 image 的 mode 属性 */
                background-repeat: no-repeat; /* 不让重复 */
                background-position: center center ; /* 固定展示的位置 */
                padding: 10px;
                cursor: pointer;
                span {
                    font-size: 18px;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 25px;
                }
            }
            .reItem:hover {
                margin-bottom: 10px;
                margin-top: -10px;
                box-shadow: 0px 4px 20px 0px rgba(19, 66, 56, 0.2);
                transition: all 0.5s ease;
            }
            .reItem:nth-child(3) {
                height: 200px;
                width: 210px;
                background-size: 150% 150%;
                background-repeat: no-repeat; /* 不让重复 */
                background-position: center center ; /* 固定展示的位置 */
                padding: 10px;
            }
            .reItem:not(:first-child) {
                margin-left: 3%;
            }
            
        }
    }
</style>
