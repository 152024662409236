import {
    Button,
    Checkbox,
    Menu,
    Icon,
    FormModel,
    Input,
    Select,
    Dropdown,
    Row,
    Col,
    Card,
    Breadcrumb,
    Table,
    Pagination,
    Tag,
    LocaleProvider,
    ConfigProvider,
    Carousel,
    Spin,
    Skeleton,
    Divider,
    Modal,
    message,
    Radio,
    Popover,
    Tabs,
    Upload,
    DatePicker,
    Cascader,
    Steps,
    Form,
    Rate,
    Space,
    Tooltip
  } from 'ant-design-vue'

  const components = [
    Button,
    Checkbox,
    Icon,
    Select,
    Dropdown,
    Row,
    Col,
    Card,
    Breadcrumb,
    Table,
    Pagination,
    Tag,
    LocaleProvider,
    ConfigProvider,
    Carousel,
    Spin,
    Skeleton,
    Divider,
    Modal,
    Radio,
    Popover,
    Tabs,
    Upload,
    DatePicker,
    Cascader,
    Steps,
    Rate,
    Space,
    Tooltip
  ]

const antdV = {
   install: function(Vue) {
    Vue.use(message)
    Vue.use(Menu)
    Vue.use(FormModel)
    Vue.use(Input)
    Vue.use(Tabs)
    Vue.use(DatePicker)
    Vue.use(Steps)
    Vue.use(Breadcrumb)
    Vue.use(Form)
    Vue.use(Rate)
    Vue.use(Space)
    Vue.use(Select)
    Vue.use(Checkbox)
    Vue.use(Radio)
    Vue.use(Tooltip)
    Vue.use(Cascader)
    Vue.prototype.$info = Modal.info;
    Vue.prototype.$success = Modal.success;
    Vue.prototype.$error = Modal.error;
    Vue.prototype.$warning = Modal.warning;
    Vue.prototype.$confirm = Modal.confirm;
    Vue.prototype.$message = message;
    components.forEach(com => {
        Vue.component(com.name, com)
    })
    // Vue.component(Menu.Item.name, Menu.Item)
    // Vue.component(Menu.SubMenu.name, Menu.SubMenu)
   }
}

export default antdV