<template>
  <div class="wt-title">
    <span>{{ title }}</span>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'SectionLine',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="less" scoped>
.wt-title {
  font-weight: bold;
  font-size: 14px;
  position: relative;
  line-height: 32px;
  height: 35px;
  padding-left: 10px;
  border-bottom: 1px solid #DFDFDF;
  &::before {
    content: '';
    display: inline-block;
    width: 4px;
    background: #20BF8E;
    height: 20px;
    position: absolute;
    left: 0;
    top: 6px;
  }
}
</style>