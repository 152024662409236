<template>
  <div class="authentication_container">
    <div class="box_shadow_wrap">
      <div class="global_title">我的认证</div>
      <div class="steps_wrap">
        <a-steps>
          <a-step title="提交认证" status="wait" />
          <a-step title="认证审核" />
          <a-step title="认证完成" />
        </a-steps>
      </div>
      <div class="main_box">
        <p class="title">您当前尚未认证，点击进行个人认证/企业认证</p>
        <div class="certification_type">
          <div class="certification_item" @click="link('/personalCenter/personalCertification')">
            <img src="../../../assets/img/personal.png">
            <div>个人认证</div>
          </div>
          <div class="certification_item" @click="link('/personalCenter/enterpriseCertification')">
            <img src="../../../assets/img/enterprise.png">
            <div>企业认证</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "authentication",
  data() {
    return {};
  },
  methods: {
    link(url) {
      this.$router.replace({ path: url });
    }
  }
};
</script>
<style scoped lang="less">
@import '../../../styles/pages/personalcenter.less';
.authentication_container {
  height: 100%;
  padding-top: 2px;
  .box_shadow_wrap {
    height: 100%;
  }
  .steps_wrap {
    margin: 45px 177px 58px;
  }
  .main_box {
    text-align: center;
    .title {
      margin-bottom: 40px;
    }
    .certification_type {
      display: flex;
      justify-content: center;
    }
    .certification_item {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      width: 230px;
      height: 172px;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      cursor: pointer;
      &:last-child {
        margin-left: 60px;
      }
      div {
        margin: 10px 0 20px;
        font-weight: 500;
        color: #20BF8E;
      }
    }
  }
}
</style>