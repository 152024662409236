import { GET, POST, ApiUrl } from '@/config/index'
// 根据类型查询字典
const getByTypes = types => {
  return GET(`${ApiUrl}/api/backapp/system/dict/action/getByTypes/${types}`)()
}
// 根据父项ID获取子项
const getByParentId = parentId => {
  return GET(`${ApiUrl}/api/backapp/system/dict/action/getByParentId/${parentId}`)()
}
// 根据类型和字典key值获取value
const queryByKeysAndType = type => {
  return POST(`${ApiUrl}/api/backapp/system/dict/action/queryByKeysAndType/${type}`)
}
export default {
  getByTypes: getByTypes,
  getByParentId: getByParentId,
  queryByKeysAndType: queryByKeysAndType
}
