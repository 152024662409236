<template>
  <div>
    <a-modal v-model="visible" title="登录" :width="428" :centered="true" :footer="null" @ok="handleOk" @cancel="handleCancel">
      <div align="center">
        <a-row>
          <a-col>
            <a-input v-model.trim="phone" style="width: 300px;" placeholder="手机号" />
          </a-col>
        </a-row>
        <a-row style="margin-top: 10px;">
          <a-col>
            <a-input v-model.trim="verifyCode" style="width: 300px;" placeholder="验证码">
              <div slot="suffix">
                <a-divider type="vertical" />
                <a-button type="primary" :loading="loading" ghost @click="getVerifyCode">
                  获取验证码
                </a-button>
              </div>
            </a-input>
          </a-col>
        </a-row>
        <a-row type="flex" justify="end">
          <a @click="forgetPwd">忘记密码</a>
        </a-row>
        <a-button style="width: 300px;margin-top: 24px;" type="primary" @click="login">
          登录
        </a-button>
        <a-row type="flex" justify="end">
          <a @click="registAccount">注册账号</a>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
name: "LoginModal",
components: {
},
data () {
return {
  visible: true,
  isCenter: true,
  phone: '',
  verifyCode: '',
  loading: false
}
},
props: {

},
watch: {

},
methods: {
  handleOk() {

  },
  handleCancel() {
    this.$emit('on-cancel');
  },
  getVerifyCode() {
    this.loading = true
  },
  forgetPwd() {

  },
  login() {

  },
  registAccount() {

  }
},
computed: {

},
created () {

},
mounted () {

},
destroyed () {

},
}
</script>

<style scoped lang="less">
  /deep/ .ant-modal {
    .ant-modal-body {
      padding: 30px 62px 45px 62px;
    }
    .ant-modal-header {
      border-bottom: none;
      text-align: center;
      padding: 16px 24px 0 24px;
    }
  } 
</style>
