import { GET, POST, ApiUrl } from '@/config/index'
// 登录
const login = POST(`${ApiUrl}/api/backapp/system/users/action/login`)
// 获取功能菜单
const getValidate = GET(`${ApiUrl}/api/backapp/system/validate/`)
// 退出登录
const loginOut = GET(`${ApiUrl}/api/backapp/system/users/action/logout`)
// 单点登录
const singleLogin = POST(`${ApiUrl}/api/enterprise/member/singleLogin/action/getUserInfo`)

// 注册发送验证码
const registerSendCode = POST(`${ApiUrl}/api/backapp/enterprise/portal/user/action/register/sendCode`)
// 注册
const register = POST(`${ApiUrl}/api/backapp/enterprise/portal/user/action/register`)
// 登录发送验证码
const loginSendCode = POST(`${ApiUrl}/api/backapp/enterprise/portal/user/action/login/sendCode`)
// 登录
const userLogin = POST(`${ApiUrl}/api/backapp/enterprise/portal/user/action/login`)
export default {
  login: login,
  loginOut: loginOut,
  singleLogin: singleLogin,
  getValidate: getValidate,
  registerSendCode,
  register,
  loginSendCode,
  userLogin
}
