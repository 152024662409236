import News from '@/pages/News/index.vue'
import NewsDetail from '@/pages/News/detail.vue'
import NewsList from '@/pages/News/list.vue'
import Layout from '@/layouts/BasicLayout/index.vue'

export default [
    {
        path: '/news',
        name: 'news',
        component: Layout,
        children: [
          {
            path: '',
            component: News,
            name: '',
            meta: {
              basePath: 'news',
              title: '新闻资讯'
            },
            children: [
              {
                path: '/*/list',
                component: NewsList,
                name: 'NewsList',
                meta: {
                  basePath: 'news',
                  title: '列表'
                }
              },
              {
                  path: '/*/detail/:id',
                  component: NewsDetail,
                  name: 'NewsDetail',
                  meta: {
                    basePath: 'news',
                    title: '详情'
                  }
                },
          ]
          }
        ]
    },
]