<template>
  <div class="evaluate">
      <div class="evaluate-header">
          <div style="color: #888888;">
              与描述相符
          </div>
          <div class="evaluate-mark">
              <template v-if="evaluateObj.avg !== 0">
                  <span>{{ evaluateObj.avg }}</span>
                  <a-rate style="margin-left: 39px;" :default-value="0" :value="evaluateObj.avg" disabled allow-half />
              </template>
              <template v-else>
                <div style="font-size: 11px;font-weight: 400;color: #888888; line-height: 15px;padding: 8px 0;">暂无评价</div>
              </template>
          </div>
          <div class="evaluate-filter">
            <div class="evaluate-filter-btns">
                <template v-for="(btn, idx) in evaluateObj.evaluateType">
                    <div :key="idx" :class="{ 'btnStyle': true, 'btnActive': idx === typeActive }" @click="selectType(idx)">
                        <span>{{ btn.text }}</span>
                        <span style="margin-left: 3px;">{{ btn.count }}</span>
                    </div>
                </template>
            </div>
            <div class="dropBtn">
                <a-dropdown style="color: #333333">
                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                        {{ sortMsg }}<a-icon type="down" />
                    </a>
                    <a-menu slot="overlay" @click="onClick">
                        <a-menu-item key="0">
                            <a href="javascript:;">推荐排序</a>
                        </a-menu-item>
                        <a-menu-item key="1">
                            <a href="javascript:;">时间排序</a>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </div>
        </div>
      </div>
      <div class="evaluate-content">
          <template v-for="(obj, idx) in evaluateObj.evaluates">
              <div :key="idx">
                <a-divider :dashed="idx !== 0"/>
                <div class="userLine">
                    <img :src="obj.evaluatorPicture"/>
                    <div class="nickLine">
                        <div class="nickName">{{ obj.evaluatorName }}</div>
                        <div class="eTime">{{ obj.evaluateTime }}</div>
                    </div>
                    <div class="eRate">
                        <a-rate :default-value="0" :value="parseFloat(obj.evaluate)" disabled allow-half />
                    </div>
                </div>
                <div style="margin: 15px 0 0 58px;">
                    {{ obj.evaluateDescription }}
                </div>
                <div class="evaluateImg">
                    <template v-for="(img, idx) in obj.evaluatePicture.split(';')">
                        <img v-if="img" :src="img" :key="idx"/>
                    </template>
                </div>
                <template v-for="(ele, index) in obj.replyList">
                    <div :key="index" class="reply">
                        <div class="typeTitle">
                            <span>{{ ele.type === '1' ? '追加评论' : '回复'}}</span>
                            <span style="margin-top: 8px;font-size: 12px;color: #888888;line-height: 17px;float: right;">{{ ele.createdTime }}</span>
                        </div>
                        <div>{{ ele.content }}</div>
                    </div>
                </template>
              </div>
          </template>
      </div>
      <a-divider />
      <div class="evaluate-footer">
          <a-pagination v-model="current" :total="total" show-less-items />
      </div>
  </div>
</template>

<script>
export default {
name: "Evaluate",
components: {
},
data () {
return {
    current: 1,
    typeActive: 1,    
    sortTypes: [
        {
            name: '推荐排序',
            value: '-eavlueate'
        },
        {
            name: '时间排序',
            value: '-createdTime'
        }
    ],
    sortVal: '-eavlueate',
    sortMsg: '推荐排序',
}
},
props: {
    evaluateObj: {
        type: Object,
        default: () => ({
            avg: 0, // 平均评分
            evaluateType: [
                {
                    field: 'total',
                    text: '全部',
                    val: [1,2,3,4,5],
                    count: 0
                },
                {
                    field: 'good',
                    text: '好评',
                    val: [4,5],
                    count: 0
                },
                {
                    field: 'notBad',
                    text: '中评',
                    val: [3],
                    count: 0
                },
                {
                    field: 'bad',
                    text: '差评',
                    val: [1,2],
                    count: 0
                }
            ],
            evaluates: [] // 评价
        })
    }
},
watch: {
    
},
computed: {
    total() {
        return this.evaluateObj.evaluates.length
    }
},
created () {

},
mounted () {

},
destroyed () {

},
methods: {
    selectType(idx) {
        this.typeActive = idx
        this.$emit('getEvaluate', idx, this.sortVal )
    },
    onClick({ key }) {
      this.sortMsg = this.sortTypes[key].name
      this.sortVal = this.sortTypes[key].value
      this.$emit('getEvaluate', this.typeActive, this.sortVal )
    }
}
}
</script>

<style scoped lang="less">
.evaluate {
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    color: #555555;
    /deep/ .ant-rate {
        font-size: 15px;
        color: #FF8C00;
    }
    .evaluate-header {
        margin: 20px 0 20px 40px;
        .evaluate-mark {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 27px;
            font-weight: 600;
            color: #FF8C00;
            line-height: 38px;
        }

        .evaluate-filter {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .evaluate-filter-btns {
                display: flex;
                flex-direction: row;
                .btnStyle {
                    width: 85px;
                    height: 34px;
                    line-height: 34px;
                    background: #E8F8F3;
                    border-radius: 4px;
                    color: @primary-color;
                    text-align: center;
                    margin-right: 10px;
                    cursor: pointer;
                }
                .btnActive {
                    background: @primary-color;
                    border-radius: 4px;
                    color: #FFFFFF;
                }
            }
        }
    }
    .evaluate-content {
        .userLine {
            position: relative;
            font-size: 9px;
            font-weight: 400;
            color: #888888;
            line-height: 13px;
            .eRate {
                display: inline-block;
                vertical-align: middle;
                position: absolute; 
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }
            .nickLine {
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
                .nickName {
                    font-size: 12px;
                    font-weight: 500;
                    color: #333333;
                    line-height: 17px;
                }
            }
            
            img {
                width: 36px;
                height: 36px;
            }
        }

        .evaluateImg {
            margin: 15px 0 0 58px;
            img {
                width: 90px;
                height: 90px;
                margin-right: 10px;
            }
        }

        .reply {
            background: #FAFAFA;
            margin: 15px 0 0 58px;
            padding: 20px 30px;
            .typeTitle {
                font-size: 12px;
                font-weight: 500;
                color: #333333;
                line-height: 17px;
            }
        }

        
    }
    .evaluate-footer {
        text-align: right;
    }
}
</style>
